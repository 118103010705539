import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { FilterMatchMode, FilterOperator } from 'primereact/api';
import React, { useState, useEffect } from 'react';
import { Calendar } from 'primereact/calendar';
import moment from 'moment';

import MigrationService from "../../Service/MigrationService";
import MessageConverter from "../MessageConverter"
import MessageHandler from "../MessageHandler"
import { DateFilter } from "../DateFilter"

function MigratedChildren()
{
    const [migrationList, setMigrationList] = useState([]);
    const [loading, setLoading] = useState(true);

    const [filters, setFilters] = useState({
        birthdate: { value: null, matchMode: FilterMatchMode.DATE_IS },
        dateRemoveData: { value: null, matchMode: FilterMatchMode.DATE_IS },
        fullname: { value: null, matchMode: FilterMatchMode.CONTAINS },
        schoolFromName: { value: null, matchMode: FilterMatchMode.CONTAINS },
        schoolToName: { value: null, matchMode: FilterMatchMode.CONTAINS },
    });

    const [msg, setMsg] = useState([]);

    const newMessageHandler = (enteredMsg) =>
    {
        setMsg(enteredMsg);
    }

    const setMessageHandler = (msg) =>
    {
        newMessageHandler(msg);
    }

    // Omzetten van de data
    const getMigrationList = (data) =>
    {
        return [...(data || [])].map((d) =>
        {
            d.birthdate = new Date(d.birthdate);
            d.dateRemoveData = new Date(d.dateRemoveData);

            return d;
        });
    };

    const birthdateBody = (rowData) =>
    {
        return <>{moment(rowData.birthdate).format("DD-MM-YYYY")}</>;
    };

    const dateRemoveDataBody = (rowData) =>
    {
        return <>{moment(rowData.dateRemoveData).format("DD-MM-YYYY")}</>;
    };

    useEffect(() =>
    {
        MigrationService.getAllAccepted().then((res) =>
        {
            setMigrationList(getMigrationList(res.data));
            setLoading(false);
        }).catch((err) => setMessageHandler(MessageConverter.convertBusinessMsg(err)));
    }, []);

    return (
        <form autoComplete="off">
            <MessageHandler messages={msg} />
            <div className="maincontent-item">
                <div className="card">
                    <DataTable
                        value={migrationList}
                        dataKey="id"
                        size="small"
                        paginator={true}
                        paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                        currentPageReportTemplate="{first} - {last} van {totalRecords}"
                        rows={10}
                        loading={loading}
                        rowsPerPageOptions={[10, 20, 50, 100]}
                        responsiveLayout="scroll"
                        filters={filters}
                        filterDisplay="row"
                        emptyMessage="Geen resultaten gevonden"
                        stateKey="Migration-MigratedChildren">
                        <Column field="fullname"
                            filter={true}
                            filterPlaceholder="Zoek op naam"
                            sortable={true}
                            showFilterMenu={false}

                            style={{ width: '15rem' }}
                            header="Kind" />
                        <Column header="Geboortedatum"
                            filterField="birthdate"
                            sortable={true}
                            dataType="date"
                            showFilterMenu={false}
                            style={{ width: '10rem' }}
                            field="birthdate"
                            body={birthdateBody}
                            filter={true}
                            filterElement={DateFilter} />
                        <Column field="schoolFromName"
                            sortable={true}
                            filter={true}
                            showFilterMenu={false}
                            filterPlaceholder="Zoek op locatie"
                            style={{ width: '15rem' }}
                            header="Versturende locatie" />
                        <Column field="schoolToName"
                            sortable={true}
                            filter={true}
                            showFilterMenu={false}
                            filterPlaceholder="Zoek op locatie"
                            style={{ width: '15rem' }}
                            header="Ontvangende locatie" />
                        <Column header="Datum waarop de gegevens verwijderd worden"
                            sortable={true}
                            filterField="dateRemoveData"
                            dataType="date"
                            showFilterMenu={false}
                            style={{ width: '10rem' }}
                            field="dateRemoveData"
                            body={dateRemoveDataBody}
                            filter={true}
                            filterElement={DateFilter} />
                    </DataTable>
                    <div className="clear"></div>
                </div>
            </div>
        </form>
    );
}

export default MigratedChildren;