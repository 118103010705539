import '../GeneralStyling.scss'
import './SoCoRegistration.scss'
import GroupService from '../../Service/GroupService'
import StudentService from '../../Service/StudentService'
import SoCoRegistrationService from '../../Service/SoCoRegistrationService'
import MessageConverter from '../MessageConverter'
import MessageHandler from "../MessageHandler";

import { ListBox } from 'primereact/listbox';
import Select from 'react-select';
import React, { useEffect, useState } from 'react';
import moment from 'moment'
import { BlockUI } from 'primereact/blockui';
import { Editor, EditorState, convertFromRaw, CompositeDecorator } from "draft-js";
import { Dialog } from 'primereact/dialog';
import HelpPageService from '../../Service/HelpPageService';
import HelpItemDescription from '../HelpPage/HelpItemDescription';

function CloseRegistration()
{
    const [groups, setGroups] = useState([]);
    const [selectedGroup, setSelectedGroup] = useState([]);
    const [blocked, setBlocked] = useState(false);

    const [helpItem, setHelpItem] = useState(null);
    const [helpVisible, setHelpVisible] = useState(false);
    const [helpButtonHidden, sethelpButtonHidden] = useState(true);
    const currentPathName = window.location.pathname;

    const type = [
        {
            id: "1",
            label: "Invoer door leraar",
            value: 1
        }, {
            id: "2",
            label: "Invoer door kind",
            value: 2
        }
    ];
    const [selectedType, setSelectedType] = useState(null);

    const [students, setStudents] = useState([]);
    const [selectedStudent, setSelectedStudent] = useState([]);

    const [closeDate, setCloseDate] = useState(moment().format('YYYY-MM-DD'));
    const [subItemsHidden, setSubItemsHidden] = useState(true);
    const [registrationIDs, setRegistrationIDs] = useState([]);
    const [msg, setMsg] = useState([]);

    const [defaultSelected, setDefaultSelected] = useState(null);

    const newMessageHandler = (enteredMsg) =>
    {
        setMsg(enteredMsg);
    }

    const setMessageHandler = (msg) =>
    {
        newMessageHandler(msg);
    }

    // Get the groups
    useEffect(() =>
    {
        GroupService.getGroupForRegSoCo().then((res) =>
        {
            setGroups(res.data);
            if (res.data.length == 1)
            {
                setDefaultSelected(res.data[0]);
                setSelectedGroup(res.data[0]);
                setSubItemsHidden(true);
                setSelectedType(null);
            }
        }).catch((err) => setMessageHandler(MessageConverter.convertBusinessMsg(err)));
    }, []);

    useEffect(() =>
    {
        if (selectedType !== null && selectedGroup !== null)
        {
            setMessageHandler();
            if (selectedType.length !== 0 && selectedGroup.length !== 0)
            {
                StudentService.getStudentListToClose(selectedGroup.id, selectedType.value).then((res) =>
                {
                    setSelectedStudent(res.data);
                    setStudents(res.data);
                }).catch((err) => setMessageHandler(MessageConverter.convertBusinessMsg(err)));
            }
        }
    }, [selectedGroup, selectedType]);

    const closeReg = async (e) =>
    {
        e.preventDefault();
        setMessageHandler();
        try
        {
            if (selectedStudent.length !== 0) {
                setBlocked(true);
                var studentID = selectedStudent.map(student => student.studentSchoolID);
                var i;
                var tempRegistrationIDList = [];

                for (i = 0; i < studentID.length; i++)
                {
                    await SoCoRegistrationService.getOpenRegSoCo(studentID[i], selectedGroup.id, selectedType.value).then((res) =>
                    {
                        //Temporary Registration ID List created so only one call will be done to close all relevant registrations
                        tempRegistrationIDList.push(res.data.soCoRegistration.id);

                        //need to be checked here inside the for-loop otherwise the code will be called before data is returned
                        if (i == studentID.length - 1)
                        {
                            SoCoRegistrationService.closeSocoRegistration(tempRegistrationIDList, closeDate).then((res) =>
                            {
                                setMessageHandler(MessageConverter.convertBusinessMsg("Registratie(s) afgesloten", 0));
                                setSubItemsHidden(true);
                                setSelectedType(null);
                                setRegistrationIDs(null);
                                setSelectedStudent(null);
                                setBlocked(false);
                            }).catch((err) =>
                            {
                                setMessageHandler(MessageConverter.convertBusinessMsg(err));
                                setBlocked(false);
                            });
                        }
                    }).catch((err) => {
                        setMessageHandler(MessageConverter.convertBusinessMsg(err));
                        setSelectedType(null);
                        setRegistrationIDs(null);
                        setSelectedStudent(null);
                        setSubItemsHidden(true);
                        setBlocked(false);
                    });
                }
            } else { setMessageHandler(MessageConverter.convertBusinessMsg("Geen kind(eren) geselecteerd", 1)) }
        }
        catch (err)
        {
            setMessageHandler(MessageConverter.convertBusinessMsg(err))
        }
    }

    // Prevent going back
    useEffect(() =>
    {
        window.addEventListener('popstate', (e) =>
        {
            window.history.go(1);
        });
    }, []);

    // Preload helpItems
    useEffect(() => {
        if (helpItem == null) {
            HelpPageService.getHelpItembyPage(currentPathName).then((res) => {
                if (res.data != undefined && res.data != null && res.data != '') {
                    setHelpItem(res.data);
                    sethelpButtonHidden(false);
                }
                else {
                    sethelpButtonHidden(true);
                }
            }).catch(err => {
                setMessageHandler(MessageConverter.convertBusinessMsg(err));
            });
        }
    }, []);

    return (
        <div id="Page">
            <BlockUI blocked={blocked} fullScreen />
            <div id="MainContentContainer">
                <div className="page-information">
                    <div id="PageHeader" className="page-header">
                        <span>Registratie afsluiten</span>
                    </div>
                    <div id="InfoButton">
                        <div className="btn-info" onClick={() => setHelpVisible(true)} hidden={helpButtonHidden}></div>
                    </div>
                    <Dialog
                        header={"Registratie afsluiten"}
                        visible={helpVisible}
                        modal={true}
                        onHide={() => setHelpVisible(false)}
                        style={{ width: '75vw' }}
                    >
                        <Editor className="textfield" editorState={HelpItemDescription(helpItem)} readOnly="true" />
                    </Dialog>
                    <div className="clear" />
                </div>
                <div>
                    <div className="maincontent-header-bar">
                        <div className="header-bar-item">
                            <label>Groep</label>
                            <Select
                                key={selectedGroup}
                                options={groups}
                                getOptionLabel={(option) => option.name}
                                getOptionValue={(option) => option.id}
                                isSearchable={true}
                                isMulti={false}
                                placeholder="Selecteer een groep"
                                onChange={(gr) =>
                                {
                                    setSelectedGroup(gr);
                                    setSubItemsHidden(true);
                                    setSelectedType(null);
                                }}
                                value={groups.find((element) => { return element.id == selectedGroup?.id })}
                                noOptionsMessage={() => 'Geen resultaten gevonden'}
                                defaultValue={defaultSelected}
                            />
                        </div>
                        <div className="header-bar-item">
                            <label>Type</label>
                            <Select
                                key={selectedType}
                                options={type}
                                getOptionLabel={(option) => option.label}
                                getOptionValue={(option) => option.value}
                                isSearchable={true}
                                isMulti={false}
                                placeholder="Selecteer een type"
                                onChange={(ty) =>
                                {
                                    setSelectedType(ty);
                                    setSubItemsHidden(false);
                                }}
                                value={type.find((element) => { return element.id == selectedType?.id })}
                                noOptionsMessage={() => 'Geen resultaten gevonden'}
                            />
                        </div>
                    </div>
                    <div className="message-div">
                        <MessageHandler messages={msg} />
                    </div>
                    <div className="maincontent-sub-bar" hidden={subItemsHidden}>
                        <div className="sub-bar-item">
                            <label>Kind(eren)</label>
                            <ListBox
                                multiple
                                value={selectedStudent}
                                onChange={(e) => { setSelectedStudent(e.value); }}
                                options={students}
                                optionLabel="fullname"
                                emptyMessage="Geen resultaten gevonden"
                                listStyle={{ maxHeight: '355px' }}
                            />
                        </div>
                        <div className="sub-bar-item">
                            <label htmlFor="closeDate">Afsluitdatum</label>
                            <input
                                id="closeDate"
                                type="date"
                                max={moment().format('YYYY-MM-DD')}
                                onChange={(e) => setCloseDate(e.target.value)}
                                defaultValue={moment().format('YYYY-MM-DD')}
                                className="closeDate-input"
                            />
                        </div>
                        <button className="main-buttons" onClick={closeReg}> Registratie(s) afsluiten </button>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default CloseRegistration;