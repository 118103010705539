import '../../GeneralStyling.scss'
import ReportService from "../../../Service/Report/SoCo/SoCoReportService";
import SchoolyearService from "../../../Service/SchoolyearService";
import GroupService from "../../../Service/GroupService";
import StudentService from "../../../Service/StudentService";
import MessageConverter from "../../MessageConverter";
import MessageHandler from "../../MessageHandler";
import HelpPageService from '../../../Service/HelpPageService';

import React, { useState, useEffect } from 'react';
import Select from 'react-select';
import { ListBox } from 'primereact/listbox';
import { BlockUI } from 'primereact/blockui';
import { Editor, EditorState, convertFromRaw, CompositeDecorator } from "draft-js";
import { Dialog } from 'primereact/dialog';
import HelpItemDescription from '../../HelpPage/HelpItemDescription';

function SoCoIndividualReport()
{
    const [blocked, setBlocked] = useState(false);

    const [schoolyears, setSchoolyears] = useState([]);
    const [groups, setGroups] = useState([]);
    const [students, setStudents] = useState([]);
    const [subItemsVisible, setSubItemsVisible] = useState(true);

    const [selectedSchoolyear, setSelectedSchoolyear] = useState();
    const [selectedGroup, setSelectedGroup] = useState(null);
    const [selectedStudents, setSelectedStudents] = useState(null);

    const [includeComment, setIncludeComment] = useState(true);
    const [includeObservation, setincludeObservation] = useState(true);
    const [hideSubQuestions, setHideSubQuestions] = useState(true);
    const [type, setType] = useState(1);
    const [startDomainOnNewPage, setStartDomainOnNewPage] = useState(true);
    const [msg, setMsg] = useState([]);
    const [defaultSelectedSchoolyear, setDefaultSelectedSchoolyear] = useState(null);
    const [defaultSelectedGroup, setDefaultSelectedGroup] = useState(null);

    const [helpItem, setHelpItem] = useState(null);
    const [helpVisible, setHelpVisible] = useState(false);
    const [helpButtonHidden, sethelpButtonHidden] = useState(true);
    const currentPathName = window.location.pathname;

    // Prevent going back
    useEffect(() =>
    {
        window.addEventListener('popstate', (e) =>
        {
            window.history.go(1);
        });
    }, []);

    // Preload helpItems
    useEffect(() => {
        if (helpItem == null) {
            HelpPageService.getHelpItembyPage(currentPathName).then((res) => {
                if (res.data != undefined && res.data != null && res.data != '') {
                    setHelpItem(res.data);
                    sethelpButtonHidden(false);
                }
                else {
                    sethelpButtonHidden(true);
                }
            }).catch(err => {
                setMessageHandler(MessageConverter.convertBusinessMsg(err));
            });
        }
    }, []);

    const newMessageHandler = (enteredMsg) =>
    {
        setMsg(enteredMsg);
    }

    const setMessageHandler = (msg) =>
    {
        newMessageHandler(msg);
    }

    const getReport = async () =>
    {
        setMessageHandler();
        if (selectedStudents !== null && selectedStudents.length !== 0)
        {
            setBlocked(true)
            // get stu ids
            var stuSchoolIDs = selectedStudents.map((stu) => stu.studentSchoolID);
            try
            {
                ReportService.getSoCoStudentReport(stuSchoolIDs, selectedGroup.id, selectedSchoolyear.id, includeComment, includeObservation, hideSubQuestions, startDomainOnNewPage, type).then((reportRes) =>
                {
                    setBlocked(false);
                    if (reportRes.status == 200)
                    {
                        const url = window.URL.createObjectURL(new Blob([reportRes.data], { type: 'application/pdf' }));
                        const link = document.createElement('a');
                        link.href = url;
                        if (selectedStudents.length > 1)
                        {
                            if (type == 1)
                                link.setAttribute('download', 'Individueel rapport.pdf');
                            else
                                link.setAttribute('download', 'Kindlijst.pdf');
                        }
                        else
                        {
                            if (type == 1)
                                link.setAttribute('download', 'Individueel rapport ' + selectedStudents[0].fullname + '.pdf');
                            else
                                link.setAttribute('download', 'Kindlijst ' + selectedStudents[0].fullname + '.pdf');
                        }
                        document.body.appendChild(link);
                        link.click();
                    }
                    else if (reportRes.status == 204)
                    {
                        setMessageHandler(MessageConverter.convertBusinessMsg("Het rapport bevat geen data", 1));
                    }
                    else setMessageHandler(MessageConverter.convertBusinessMsg(reportRes.status))
                }).catch((err) => { setMessageHandler(MessageConverter.convertBusinessMsg(err?.response.status)); setBlocked(false) });
            } catch (error)
            {
                setMessageHandler(MessageConverter.convertBusinessMsg(error));
                setBlocked(false);
            }
        } else setMessageHandler(MessageConverter.convertBusinessMsg("Selecteer een kind", 1))
    }

    useEffect(() =>
    {
        setSelectedGroup(null);
        setSelectedStudents(null);
        setGroups(null);
        setStudents(null);
        setDefaultSelectedGroup(null);

        if (selectedSchoolyear !== undefined && selectedSchoolyear != null)
        {
            GroupService.getGroupsWithClosedSoCoRegistrations(selectedSchoolyear.id, type).then((groupRes) =>
            {
                setGroups(groupRes.data);
                if (groupRes.data.length == 1)
                {
                    setDefaultSelectedGroup(groupRes.data[0]);
                    if (groupRes.data[0] != selectedGroup)
                        setSelectedGroup(groupRes.data[0]);
                    setSubItemsVisible(false);
                }
            }).catch((err) => setMessageHandler(MessageConverter.convertBusinessMsg(err)));
        }
        else
        {
            setGroups(null);
            setStudents(null);
        }
    }, [selectedSchoolyear]);

    useEffect(() =>
    {
        setStudents(null);
        setSelectedStudents(null);
        if (selectedGroup !== undefined && selectedGroup != null)
        {
            StudentService.getStudentWithClosedSoCoRegistration(selectedGroup.id, type).then((studentRes) =>
            {
                setStudents(studentRes.data);

            }).catch((err) => setMessageHandler(MessageConverter.convertBusinessMsg(err)));
        }
    }, [selectedGroup, type]);

    useEffect(() =>
    {
        SchoolyearService.getSchoolyearListForSocoReports().then((schoolyearRes) =>
        {
            setSchoolyears(schoolyearRes.data);
            if (schoolyearRes.data.length == 1)
            {
                setDefaultSelectedSchoolyear(schoolyearRes.data[0]);
                setSelectedSchoolyear(schoolyearRes.data[0]);
                setSubItemsVisible(true);
                setSelectedGroup(null);
            } else
            {
                const currentSchoolyear = schoolyearRes.data.find((element) => element.active == true)
                if (currentSchoolyear !== undefined)
                {
                    setDefaultSelectedSchoolyear(currentSchoolyear);
                    setSelectedSchoolyear(currentSchoolyear);
                    setSelectedGroup(null);
                    setSubItemsVisible(false);
                }
            }
        }).catch((err) => setMessageHandler(MessageConverter.convertBusinessMsg(err)));
    }, []);

    return (
        <div id="ManageChildPage">
            <BlockUI blocked={blocked} fullScreen />
            <div id="MainContentContainer">
                <div className="page-information">
                    <div id="PageHeader" className="page-header">
                        <span>Rapportage: Individueel</span>
                    </div>
                    <div>
                        <div id="InfoButton" onClick={() => setHelpVisible(true)} hidden={helpButtonHidden}>
                            <i className="btn-info"></i>
                        </div>
                        <Dialog
                            header={"Rapportage: Individueel"}
                            visible={helpVisible}
                            modal={true}
                            onHide={() => setHelpVisible(false)}
                            style={{ width: '75vw' }}
                        >
                            <Editor className="textfield" editorState={HelpItemDescription(helpItem)} readOnly="true" />
                        </Dialog>
                    </div>
                    <div className="clear"></div>
                </div>
                <div>
                    <div className="maincontent-header-bar">
                        <div className="header-bar-item">
                            <label>Schooljaar</label>
                            <Select
                                options={schoolyears}
                                getOptionLabel={(option) => option.name}
                                getOptionValue={(option) => option.id}
                                onChange={(sy) =>
                                {
                                    setSelectedGroup(null);
                                    setSelectedSchoolyear(sy);
                                    setSubItemsVisible(true);
                                }}
                                value={schoolyears.find((element) => { return element.id == selectedSchoolyear?.id })}
                                isSearchable={true}
                                isMulti={false}
                                id="schoolyear"
                                placeholder="Selecteer een schooljaar"
                                noOptionsMessage={() => 'Geen resultaten gevonden'}
                                defaultValue={defaultSelectedSchoolyear}
                            />
                        </div>
                        <div className="header-bar-item">
                            <label>Groep</label>
                            <Select
                                key={groups}
                                options={groups}
                                getOptionLabel={(option) => option.name}
                                getOptionValue={(option) => option.id}
                                onChange={(g) =>
                                {
                                    setSelectedStudents(null);
                                    setSelectedGroup(g)
                                    setSubItemsVisible(false);
                                }}
                                value={schoolyears.find((element) => { return element.id == selectedGroup?.id })}
                                isSearchable={true}
                                isMulti={false}
                                id="group"
                                placeholder="Selecteer een groep"
                                noOptionsMessage={() => 'Geen resultaten gevonden'}
                                defaultValue={defaultSelectedGroup}
                            />
                        </div>
                    </div>
                    <div className="message-div">
                        <MessageHandler messages={msg} />
                    </div>
                    <div className="maincontent-sub-bar" hidden={subItemsVisible}>
                        <div className="page-divider">
                            <div className="align-left">
                                <div className="sub-bar-item">
                                    <label>Selectie</label>
                                    <div className="radio-buttons">
                                        <input
                                            type="radio"
                                            id="mainquestions"
                                            value={true}
                                            checked={hideSubQuestions}
                                            onChange={() => setHideSubQuestions(true)} />
                                        <label htmlFor="mainquestions">Hoofdvragen</label>
                                        <input
                                            type="radio"
                                            id="subquestions"
                                            value={false}
                                            checked={!hideSubQuestions}
                                            onChange={() => setHideSubQuestions(false)} />
                                        <label htmlFor="subquestions">Hoofd- en subvragen</label>
                                    </div>
                                </div>
                                <div className="sub-bar-item">
                                    <label>Opmerkingen tonen</label>
                                    <div className="radio-buttons">
                                        <input
                                            type="radio"
                                            id="comment-ja"
                                            value={true}
                                            checked={includeComment}
                                            onChange={() => { setIncludeComment(true); setStartDomainOnNewPage(true); } }
                                        />
                                        <label htmlFor="comment-ja">Ja</label>
                                        <input
                                            type="radio"
                                            id="comment-nee"
                                            value={false}
                                            checked={!includeComment}
                                            onChange={() => { setIncludeComment(false); setincludeObservation(false); } }
                                        />
                                        <label htmlFor="comment-nee">Nee</label>
                                    </div>
                                </div>
                                <div className="sub-bar-item">
                                    <label>Observatie tonen</label>
                                    <div className="radio-buttons">
                                        <input
                                            type="radio"
                                            id="comment-ja"
                                            value={true}
                                            checked={includeObservation}
                                            onChange={() => { setincludeObservation(true); setIncludeComment(true); setStartDomainOnNewPage(true); } }
                                        />
                                        <label htmlFor="comment-ja">Ja</label>
                                        <input
                                            type="radio"
                                            id="comment-nee"
                                            value={false}
                                            checked={!includeObservation}
                                            onChange={() => setincludeObservation(false)}
                                        />
                                        <label htmlFor="comment-nee">Nee</label>
                                    </div>
                                </div>
                                <div className="sub-bar-item">
                                    <label>Type</label>
                                    <div className="radio-buttons">
                                        <input
                                            type="radio"
                                            id="basic"
                                            value={1}
                                            checked={type == 1}
                                            onChange={() => setType(1)}
                                        />
                                        <label htmlFor="basic">Leraar</label>
                                        <input
                                            type="radio"
                                            id="childlist"
                                            value={2}
                                            checked={type == 2}
                                            onChange={() => setType(2)}
                                        />
                                        <label htmlFor="childlist">Kind</label>
                                    </div>
                                </div>
                                <div className="sub-bar-item">
                                    <label>Start domein op nieuwe pagina</label>
                                    <div className="radio-buttons">
                                        <input
                                            type="radio"
                                            id="newpage-yes"
                                            value={true}
                                            checked={startDomainOnNewPage}
                                            onChange={() => setStartDomainOnNewPage(true)}
                                        />
                                        <label htmlFor="newpage-yes">Ja</label>
                                        <input
                                            type="radio"
                                            id="newpage-no"
                                            value={false}
                                            checked={!startDomainOnNewPage}
                                            onChange={() => { setStartDomainOnNewPage(false); setIncludeComment(false); setincludeObservation(false); } }
                                        />
                                        <label htmlFor="newpage-no">Nee</label>
                                    </div>
                                </div>
                                <button onClick={getReport} className="main-buttons"> Inzien </button>
                            </div>
                            <div className="align-right">
                                <div className="sub-bar-item">
                                    <label>Kind(eren)</label>
                                    <ListBox
                                        multiple
                                        value={selectedStudents}
                                        onChange={(s) =>
                                        {
                                            setSelectedStudents(s.value)
                                        }}
                                        options={students}
                                        optionLabel="fullname"
                                        listStyle={{ maxHeight: '355px' }}
                                        emptyMessage="Geen resultaten gevonden"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default SoCoIndividualReport;