import '../GeneralStyling.scss'
import MessageConverter from "../MessageConverter";
import MessageHandler from "../MessageHandler";

import moment from 'moment'
import React, { useState, useEffect, useCallback } from 'react';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { Dialog } from 'primereact/dialog';
import { FilterMatchMode } from 'primereact/api';
import { useNavigate, useLocation } from 'react-router-dom';
import { BlockUI } from 'primereact/blockui';
import HelpPageService from '../../Service/HelpPageService';

function Overview() {
    const navigate = useNavigate();

    const [list, setList] = useState([]);
    const [loading, setLoading] = useState(true);
    const [selectedMessage, setSelectedMessage] = useState(null);
    const [blocked, setBlocked] = useState(false);

    const [filters, setFilters] = useState({
        'pagina': { value: null, matchMode: FilterMatchMode.CONTAINS },
    });

    const [msg, setMsg] = useState([]);

    const newMessageHandler = (enteredMsg) => {
        setMsg(enteredMsg);
    }

    const setMessageHandler = (msg) => {
        newMessageHandler(msg);
    }

    const [visibleDelete, setVisibleDelete] = useState(false);
    const [refreshKey, setRefreshKey] = useState(0);

    useEffect(() => {
        HelpPageService.getHelpItems().then((res) => {
            setList(res.data);
            setLoading(false);
        }).catch((err) => setMessageHandler(MessageConverter.convertBusinessMsg(err)));
    }, [refreshKey]);

    const reParseUrl = textToParse => {
        var checkForBaseUrl = true;

        while (checkForBaseUrl) {
            if (textToParse.includes("\"/PublicImages")) {
                textToParse = textToParse.replace("\"/PublicImages", "\"" + process.env.REACT_APP_API_URL + "/PublicImages");
            }
            else {
                checkForBaseUrl = false;
            }
        }
        return textToParse;
    }

    const editItem = useCallback(() => {
        setMessageHandler();
        if (selectedMessage == null) {
            setMessageHandler(MessageConverter.convertBusinessMsg("Geen item geselecteerd", 1));
        }
        else {
            setBlocked(true);
            if (selectedMessage.content != null) {
                selectedMessage.content = reParseUrl(selectedMessage.content);
            }
            navigate("/HelpItem", { state: { message: selectedMessage } });
            setBlocked(false);
        }
    }, [selectedMessage]);

    const addItem = useCallback(() => {
        setMessageHandler();
        setBlocked(true);
        navigate("/HelpItem");
        setBlocked(false);
    }, []);

    const deleteMessage = useCallback(() => {
        setBlocked(true);
        HelpPageService.deleteHelpItem(selectedMessage.id).then(() => {
            setRefreshKey(oldKey => oldKey + 1);
            setMessageHandler(MessageConverter.convertBusinessMsg("Help tekst is succesvol verwijderd", 0));
            setVisibleDelete(false);
            setBlocked(false);
        }).catch(err => {
            setMessageHandler(MessageConverter.convertBusinessMsg(err));
            setBlocked(false);
            setVisibleDelete(false);
        });
    }, [selectedMessage]);

    const onDeleteButton = useCallback(() => {
        setMessageHandler();
        if (selectedMessage == null) {
            setMessageHandler(MessageConverter.convertBusinessMsg("Geen item geselecteerd"));
        }
        else {
            setVisibleDelete(true);
        }
    }, [selectedMessage]);

    const { state } = useLocation();
    useEffect(() => {
        if (state !== null) {
            const { confirmedMessage } = state;
            setMessageHandler(MessageConverter.convertBusinessMsg(confirmedMessage, 0));
        }
    }, []);

    // Prevent going back
    useEffect(() => {
        window.addEventListener('popstate', (e) => {
            window.history.go(1);
        });
    }, []);

    return (
        <div id="Page">
            <BlockUI blocked={blocked} fullScreen />
            <div id="MainContentContainer">
                <div className="page-information">
                    <div id="PageHeader" className="page-header">
                        <span>Help: Overzicht</span>
                    </div>
                    <div className="clear"></div>
                </div>
                <div>
                    <div className="maincontent">
                        <div className="message-div">
                            <MessageHandler messages={msg} />
                        </div>
                        <div className="card">
                            <DataTable
                                value={list}
                                dataKey="id"
                                size="small"
                                paginator={true}
                                paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                                currentPageReportTemplate="{first} - {last} van {totalRecords}"
                                rows={10}
                                rowsPerPageOptions={[10, 20, 50, 100]}
                                filters={filters} filterDisplay="row"
                                loading={loading}
                                selection={selectedMessage}
                                onSelectionChange={e => { setSelectedMessage(e.value[0]); }}
                                responsiveLayout="scroll"
                                selectionMode="multiple"
                                emptyMessage="Geen resultaten gevonden">
                                <Column field="description"
                                    sortable={true}
                                    filter={true}
                                    showFilterMenu={false}
                                    filterPlaceholder="Zoek op omschrijving"
                                    header="Omschrijving">
                                </Column>
                                <Column field="url"
                                    sortable={true}
                                    filter={true}
                                    showFilterMenu={false}
                                    filterPlaceholder="Zoek op url"
                                    header="Url">
                                </Column>
                            </DataTable>
                            <div className="clear"></div>
                        </div>
                    </div>
                    <div className="buttons">
                        <input type="submit" value="Toevoegen" className="management-buttons" onClick={addItem} />
                        <input type="submit" value="Bewerken" className="management-buttons" onClick={editItem} />
                        <input type="submit" value="Verwijderen" className="management-buttons" onClick={onDeleteButton} />
                    </div>
                    <Dialog
                        className="delete-dialog"
                        header={"Weet je zeker dat je deze help tekst wilt verwijderen?"}
                        visible={visibleDelete}
                        onHide={() => setVisibleDelete(false)}
                        draggable={false}
                    >
                        <input type="submit" value="Ja" className="delete-button" onClick={deleteMessage} />
                        <input type="submit" value="Nee" className="cancel-delete-button" onClick={() => setVisibleDelete(false)} />
                    </Dialog>
                </div>
            </div>
        </div>
    );
}

export default Overview;