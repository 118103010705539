import '../../GeneralStyling.scss'
import ReportService from "../../../Service/Report/SoCo/SoCoReportService";
import SchoolyearService from "../../../Service/SchoolyearService";
import MessageConverter from "../../MessageConverter";
import MessageHandler from "../../MessageHandler";
import HelpPageService from '../../../Service/HelpPageService';

import React, { useState, useEffect } from 'react';
import Select from 'react-select';
import { BlockUI } from 'primereact/blockui';
import { Dialog } from 'primereact/dialog';
import { Editor, EditorState, convertFromRaw, CompositeDecorator } from "draft-js";
import HelpItemDescription from '../../HelpPage/HelpItemDescription';

function SoCoIndividualReport()
{
    const [blocked, setBlocked] = useState(false);

    const [schoolyears, setSchoolyears] = useState([]);
    const [selectedSchoolyear, setSelectedSchoolyear] = useState();

    const [hideSubQuestions, setHideSubQuestions] = useState(true);
    const [type, setType] = useState(1);
    const [period, setPeriod] = useState(1);
    const [startDomainOnNewPage, setStartDomainOnNewPage] = useState(true);
    const [subItemsVisible, setSubItemsVisible] = useState(true);
    const [grouping, setGrouping] = useState(1);
    const [msg, setMsg] = useState([]);
    const [defaultSelected, setDefaultSelected] = useState(null);

    const [helpItem, setHelpItem] = useState(null);
    const [helpVisible, setHelpVisible] = useState(false);
    const [helpButtonHidden, sethelpButtonHidden] = useState(true);
    const currentPathName = window.location.pathname;

    // Prevent going back
    useEffect(() =>
    {
        window.addEventListener('popstate', (e) =>
        {
            window.history.go(1);
        });
    }, []);

    // Preload helpItems
    useEffect(() => {
        if (helpItem == null) {
            HelpPageService.getHelpItembyPage(currentPathName).then((res) => {
                if (res.data != undefined && res.data != null && res.data != '') {
                    setHelpItem(res.data);
                    sethelpButtonHidden(false);
                }
                else {
                    sethelpButtonHidden(true);
                }
            }).catch(err => {
                setMessageHandler(MessageConverter.convertBusinessMsg(err));
            });
        }
    }, []);

    const newMessageHandler = (enteredMsg) =>
    {
        setMsg(enteredMsg);
    }

    const setMessageHandler = (msg) =>
    {
        newMessageHandler(msg);
    }

    const getReport = async () =>
    {
        setMessageHandler();
        setBlocked(true);
        ReportService.getSoCoCrossSectionReport(selectedSchoolyear.id, hideSubQuestions, period, startDomainOnNewPage, type, grouping).then((reportRes) =>
        {
            setBlocked(false);
            if (reportRes.status === 200)
            {
                const url = window.URL.createObjectURL(new Blob([reportRes.data], { type: 'application/pdf' }));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', 'Dwarsdoorsnede.pdf');
                document.body.appendChild(link);
                link.click();
            }
            else if (reportRes.status == 204)
            {
                setMessageHandler(MessageConverter.convertBusinessMsg("Het rapport bevat geen data", 1));
            }
            else setMessageHandler(MessageConverter.convertBusinessMsg(reportRes.status));
        }).catch((err) =>
        {
            setMessageHandler(MessageConverter.convertBusinessMsg(err.response.status));
            setBlocked(false);

        });
    }

    useEffect(() =>
    {
        SchoolyearService.getSchoolyearListForSocoReports().then((schoolyearRes) =>
        {
            setSchoolyears(schoolyearRes.data);
            if (schoolyearRes.data.length == 1)
            {
                setDefaultSelected(schoolyearRes.data[0]);
                setSelectedSchoolyear(schoolyearRes.data[0]);
                setSubItemsVisible(false);
            } else
            {
                const currentSchoolyear = schoolyearRes.data.find((element) => element.active == true)
                if (currentSchoolyear !== undefined)
                {
                    setDefaultSelected(currentSchoolyear);
                    setSelectedSchoolyear(currentSchoolyear);
                    setSubItemsVisible(false);
                }
            }
        }).catch((err) => setMessageHandler(MessageConverter.convertBusinessMsg(err)));
    }, []);

    return (
        <div id="Page">
            <BlockUI blocked={blocked} fullScreen />
            <div id="MainContentContainer">
                <div className="page-information">
                    <div id="PageHeader" className="page-header">
                        <span>Rapportage: Dwarsdoorsnede</span>
                    </div>
                    <div>
                        <div id="InfoButton" onClick={() => setHelpVisible(true)} hidden={helpButtonHidden}>
                            <i className="btn-info"></i>
                        </div>
                        <Dialog
                            header={"Rapportage: Dwarsdoorsnede"}
                            visible={helpVisible}
                            modal={true}
                            onHide={() => setHelpVisible(false)}
                            style={{ width: '75vw' }}
                        >
                            <Editor className="textfield" editorState={HelpItemDescription(helpItem)} readOnly="true" />
                        </Dialog>
                    </div>
                    <div className="clear"></div>
                </div>
                <div>
                    <div className="maincontent-header-bar">
                        <div className="header-bar-item">
                            <label htmlFor="schoolyear">Schooljaar</label>
                            <Select
                                options={schoolyears}
                                getOptionLabel={(option) => option.name}
                                getOptionValue={(option) => option.id}
                                onChange={(sy) =>
                                {
                                    setSelectedSchoolyear(sy);
                                    setSubItemsVisible(false);
                                }}
                                value={schoolyears.find((element) => { return element.id == selectedSchoolyear?.id })}
                                isSearchable={true}
                                isMulti={false}
                                id="schoolyear"
                                placeholder="Selecteer een schooljaar"
                                noOptionsMessage={() => 'Geen resultaten gevonden'}
                                defaultValue={defaultSelected}
                            />
                        </div>
                    </div>
                    <div className="message-div">
                        <MessageHandler messages={msg} />
                    </div>
                    <div className="maincontent-sub-bar" hidden={subItemsVisible}>
                        <div className="sub-bar-item">
                            <label htmlFor="selection">Selectie</label>
                            <div className="radio-buttons">
                                <input
                                    type="radio"
                                    id="mainquestions"
                                    value={true}
                                    checked={hideSubQuestions}
                                    onChange={() => setHideSubQuestions(true)} />
                                <label htmlFor="mainquestions">Hoofdvragen</label>
                                <input
                                    type="radio"
                                    id="subquestions"
                                    value={false}
                                    checked={!hideSubQuestions}
                                    onChange={() => setHideSubQuestions(false)} />
                                <label htmlFor="subquestions">Hoofd- en subvragen</label>
                            </div>
                        </div>
                        <div className="sub-bar-item">
                            <label>Type</label>
                            <div className="radio-buttons">
                                <input
                                    type="radio"
                                    id="basic"
                                    value={1}
                                    checked={type == 1}
                                    onChange={() => setType(1)}
                                />
                                <label htmlFor="basic">Leraar</label>
                                <input
                                    type="radio"
                                    id="childlist"
                                    value={2}
                                    checked={type == 2}
                                    onChange={() => setType(2)}
                                />
                                <label htmlFor="childlist">Kind</label>
                            </div>
                        </div>
                        <div className="sub-bar-item">
                            <label>Periode</label>
                            <div className="radio-buttons">
                                <input
                                    type="radio"
                                    id="periodm"
                                    value={1}
                                    checked={period == 1}
                                    onChange={() => setPeriod(1)} />
                                <label htmlFor="periodm">M</label>
                                <input
                                    type="radio"
                                    id="periode"
                                    value={2}
                                    checked={period == 2}
                                    onChange={() => setPeriod(2)} />
                                <label htmlFor="periode">E</label>

                            </div>
                        </div>
                        <div className="sub-bar-item">
                            <label>Groepering</label>
                            <div className="radio-buttons">
                                <input
                                    type="radio"
                                    id="perGroupyear"
                                    value={1}
                                    checked={grouping == 1}
                                    onChange={() => setGrouping(1)}
                                />
                                <label htmlFor="perGroupyear">Per groepsjaar</label>
                                <input
                                    type="radio"
                                    id="perGroup"
                                    value={2}
                                    checked={grouping == 2}
                                    onChange={() => setGrouping(2)}
                                />
                                <label htmlFor="perGroup">Per groep</label>
                            </div>
                        </div>
                        <div className="sub-bar-item">
                            <label>Start domein op nieuwe pagina</label>
                            <div className="radio-buttons">
                                <input
                                    type="radio"
                                    id="yes"
                                    value={true}
                                    checked={startDomainOnNewPage}
                                    onChange={() => setStartDomainOnNewPage(true)}
                                />
                                <label htmlFor="yes">Ja</label>
                                <input
                                    type="radio"
                                    id="no"
                                    value={false}
                                    checked={!startDomainOnNewPage}
                                    onChange={() => setStartDomainOnNewPage(false)}
                                />
                                <label htmlFor="no">Nee</label>
                            </div>
                        </div>
                        <button onClick={getReport} className="main-buttons"> Inzien </button>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default SoCoIndividualReport;