import '../GeneralStyling.scss'
import './GroupManagement.scss'
import UserService from '../../Service/UserService';
import MessageConverter from "../MessageConverter";
import MessageHandler from "../MessageHandler";

import React, { useState, useEffect, useCallback } from 'react';
import { Link, useLocation } from "react-router-dom";
import { ListBox } from 'primereact/listbox';
import { BlockUI } from 'primereact/blockui';

function UserJoinGroup()
{
    const { state } = useLocation();
    const [groupDTO, setGroupDTO] = useState([]);
    const [listJoinedToGroup, setListJoinedToGroup] = useState([]);
    const [listAvailableForGroup, setListAvailableForGroup] = useState([]);
    const [selectedUserJoin, setSelectedUserJoin] = useState([]);
    const [selectedUserUnjoin, setSelectedUserUnjoin] = useState([]);
    const [refreshKey, setRefreshKey] = useState(0);
    const [blocked, setBlocked] = useState(false);
    const [msg, setMsg] = useState([]);

    const newMessageHandler = (enteredMsg) =>
    {
        setMsg(enteredMsg);
    }

    const setMessageHandler = (msg) =>
    {
        newMessageHandler(msg);
    }

    useEffect(() =>
    {
        // Get groupDTO
        setGroupDTO(state.group);
        // Get list available for group
        try
        {
            UserService.getListAvailableForGroup(state.group.id).then((res) =>
            {
                setListAvailableForGroup(res.data);
            }).catch(err => setMessageHandler(MessageConverter.convertBusinessMsg(err)))
        } catch (error)
        {
            setMessageHandler(MessageConverter.convertBusinessMsg(error))
        }
    }, [refreshKey]);

    useEffect(() =>
    {
        // Get list joined to group
        try
        {
            UserService.getListJoinedToGroup(state.group.id).then((res) =>
            {
                setListJoinedToGroup(res.data);
            }).catch(err => setMessageHandler(MessageConverter.convertBusinessMsg(err)))
        } catch (error)
        {
            setMessageHandler(MessageConverter.convertBusinessMsg(error))
        }
    }, [refreshKey]);

    const onJoin = useCallback(() =>
    {
        setMessageHandler();
        if (selectedUserJoin !== null && selectedUserJoin.length !== 0)
        {
            setBlocked(true);
            UserService.joinToGroup(selectedUserJoin.userID, groupDTO.id).then(() =>
            {
                setRefreshKey(oldKey => oldKey + 1);
                setBlocked(false);
                setMessageHandler(MessageConverter.convertBusinessMsg("Gebruiker succesvol gekoppeld aan groep", 0))
            })
        } else setMessageHandler(MessageConverter.convertBusinessMsg("Geen gebruiker geselecteerd", 1))
    }, [selectedUserJoin]);

    const onUnjoin = useCallback(() =>
    {
        setMessageHandler();
        if (selectedUserUnjoin !== null && selectedUserUnjoin.length !== 0)
        {
            setBlocked(true);
            UserService.unjoinToGroup(selectedUserUnjoin.userID, groupDTO.id).then(() =>
            {
                setRefreshKey(oldKey => oldKey + 1);
                setBlocked(false);
                setMessageHandler(MessageConverter.convertBusinessMsg("Gebruiker succesvol ontkoppeld van groep", 0))
            })
        } else setMessageHandler(MessageConverter.convertBusinessMsg("Geen gebruiker geselecteerd", 1))
    }, [selectedUserUnjoin]);

    return (
        <div id="Page">
            <BlockUI blocked={blocked} fullScreen />
            <div id="MainContentContainer">
                <div className="page-information">
                    <div id="PageHeader" className="page-header">
                        <span>Groep: Gebruiker koppelen</span>
                    </div>
                    <div className="clear"></div>
                </div>
                <div className="maincontent">
                    <div className="message-div">
                        <MessageHandler messages={msg} />
                    </div>
                    <div className="maincontent-item">
                        <label>Groepjaar:</label>
                        <label>Groep {groupDTO.groupyearID}</label>
                    </div>
                    <div className="maincontent-item">
                        <label>Naam:</label>
                        <label>{groupDTO.name}</label>
                    </div>

                    <div className="listbox-component">
                        <label className="user-join-1">Niet gekoppeld aan groep</label>
                        <label className="user-join-2"></label>
                        <label className="user-join-3">Gekoppeld aan groep</label>
                    </div>
                    <div className="listbox-component">
                        <ListBox
                            className="user-join-1"
                            value={selectedUserJoin}
                            onChange={(e) =>
                            {
                                setSelectedUserJoin(e.value);
                            }}
                            options={listAvailableForGroup}
                            emptyMessage="Geen resultaten gevonden"
                            optionLabel="fullname" />
                        <div className="user-join-2">
                            <div className="icon" onClick={onUnjoin}>
                                <i className="fa-solid fa-chevron-left" />
                            </div>
                            <div className="icon" onClick={onJoin}>
                                <i className="fa-solid fa-chevron-right" />
                            </div>
                        </div>
                        <ListBox
                            value={selectedUserUnjoin}
                            className="user-join-3"
                            onChange={(e) =>
                            {
                                setSelectedUserUnjoin(e.value);
                            }}
                            options={listJoinedToGroup}
                            emptyMessage="Geen resultaten gevonden"
                            optionLabel="fullname" />
                    </div>
                    <div className="maincontent-item-buttons">
                        <Link to="/Groepen">
                            <input type="submit" value="Terug" className="user-add-buttons" />
                        </Link>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default UserJoinGroup;