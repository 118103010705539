import React, { useEffect } from 'react';
import '../SoCoRegistration.scss'
import { useState } from 'react';

const soCoRegistrationScoreList = [];

function HeadContent(props)
{    
    const checkAndSetAnswer = (soCoSignalItemID, score, soCoRegistrationID) =>
    {
        var index = soCoRegistrationScoreList.findIndex(x => x.soCoSignalItemID == soCoSignalItemID);
        index === -1 ? soCoRegistrationScoreList.push({ soCoSignalItemID, score, soCoRegistrationID }) : soCoRegistrationScoreList[index].score = score;
    }

    return (
        <div className="collapsible">
            <div className="question-header"  >
                <div className="aligning-left-student">
                    <div className="title">{props.title}</div>
                </div>
                <div className="aligning-right">
                    <label className="label-yes">Ja</label>
                    <input
                        type="radio"
                        className="radio-green"
                        name={props.questionID}
                        value={1}
                        defaultChecked={props.score === 1}
                        onChange={() =>
                        {
                            checkAndSetAnswer(props.questionID, 1, props.soCoRegistrationID);
                        }}
                    />
                    <label className="label-sometimes">Soms</label>
                    <input
                        type="radio"
                        className="radio-orange"
                        name={props.questionID}
                        value={2}
                        defaultChecked={props.score === 2}
                        onChange={() =>
                        {
                            checkAndSetAnswer(props.questionID, 2, props.soCoRegistrationID);
                        }}
                    />
                    <label className="label-no">Nee</label>
                    <input
                        className="radio-red"
                        type="radio"
                        name={props.questionID}
                        value={3}
                        defaultChecked={props.score === 3}
                        onChange={() =>
                        {
                            checkAndSetAnswer(props.questionID, 3, props.soCoRegistrationID);
                        }}
                    />
                </div>
            </div>
        </div>
    );
}

function Question(props)
{

    useEffect(() =>
    {
        if (props !== null && props.soCoRegistrationScoreList !== undefined && props.soCoRegistrationScoreList.length > 0 && props.soCoRegistrationID != undefined)
        {
            var i;

            for (i = 0; i < props.soCoRegistrationScoreList.length; i++)
            {
                var soCoSignalItemID = props.soCoRegistrationScoreList[i].soCoSignalItemID;
                var score = props.soCoRegistrationScoreList[i].score;

                var index = soCoRegistrationScoreList.findIndex(x => x.soCoSignalItemID == soCoSignalItemID);
                index === -1 ? soCoRegistrationScoreList.push(props.soCoRegistrationScoreList[i]) : soCoRegistrationScoreList[index].score = score;
            }
        }
    }, []);

    const getSoCoRegistrationScore = (id) => {
        var i;
        for (i = 0; i < props.soCoRegistrationScoreList.length; i++) {
            if (props.soCoRegistrationScoreList[i].soCoSignalItemID === id) {
                return props.soCoRegistrationScoreList[i].score;
            }
        }
    }

    if (props.question !== undefined)
    {
        return (
            props.question.map((question) =>
            {
                return (
                    <div className="preferences" key={question.id}>
                        <HeadContent
                            title={question.name}
                            questionID={question.id}
                            soCoRegistrationID={props.soCoRegistrationID}
                            score={getSoCoRegistrationScore(question.id)}
                        />
                    </div>
                );
            })
        )
    }
}

const QuestionHandlerStudent = {
    Question,
    soCoRegistrationScoreList
}

export default QuestionHandlerStudent;