import baseService from "../../../Service/BaseService";
import AuthService from "../../../Service/AuthService";


const getSoCoStudentReport = (studenSchoolIds, groupID, schoolyearID, includeComment, includeObservation, hideSubQuestions, startDomainOnNewPage, type) =>
{
    return new Promise((resolve, reject) =>
    {
        baseService
            .post(`/SoCoRegistration/studentreport`,
                {
                    studentSchoolIDs: studenSchoolIds,
                    groupID: groupID,
                    schoolyearID: schoolyearID,
                    includeComment: includeComment,
                    includeObservation: includeObservation,
                    hideSubQuestions: hideSubQuestions,
                    startDomainOnNewPage: startDomainOnNewPage,
                    type: type
                },
                {
                    headers: {
                        Accept: 'application/octet-stream',
                        "x-api-version": "1.0",
                        Authorization: `Bearer ${AuthService.getLocalAccessToken()}`,
                    },
                    responseType: 'blob',
                })
            .then((response) =>
            {
                resolve(response);
            })
            .catch((error) =>
            {
                reject(error)
            });
    });
};

const getSoCoGroupReport = (groupIDs, groupyearIDs, studenSchoolIds, schoolyearID, closeDate, mainQuestions, startDomainOnNewPage, type) =>
{
    return new Promise((resolve, reject) =>
    {
        baseService
            .post(`/SoCoRegistration/groupreport`,
                {
                    groupIDs: groupIDs,
                    groupyearIDs: groupyearIDs,
                    studentSchoolIDs: studenSchoolIds,
                    schoolyearID: schoolyearID,
                    closeDate: closeDate,
                    mainQuestions: mainQuestions,
                    type: type,
                    startDomainOnNewPage: startDomainOnNewPage
                },
                {
                    headers: {
                        Accept: 'application/octet-stream',
                        "x-api-version": "1.0",
                        Authorization: `Bearer ${AuthService.getLocalAccessToken()}`,
                    },
                    responseType: 'blob',
                })
            .then((response) =>
            {
                resolve(response);
            })
            .catch((error) => { reject(error) });
    });
};

const getSoCoCrossSectionReport = (schoolyearID, mainQuestions, period, startDomainOnNewPage, type, grouping) =>
{
    return new Promise((resolve, reject) =>
    {
        baseService
            .post(`/SoCoRegistration/school-report-cross-section`,
                {
                    schoolyearID: schoolyearID,
                    mainQuestions: mainQuestions,
                    period: period,
                    type: type,
                    startDomainOnNewPage: startDomainOnNewPage,
                    grouping: grouping
                },
                {
                    headers: {
                        Accept: 'application/octet-stream',
                        "x-api-version": "1.0",
                        Authorization: `Bearer ${AuthService.getLocalAccessToken()}`,
                    },
                    responseType: 'blob',
                })
            .then((response) =>
            {
                resolve(response);
            })
            .catch((error) => { reject(error) });
    });
};

const getSoCoTrendReport = (schoolyearMinID, schoolyearMaxID, groupyears, period, type) =>
{
    return new Promise((resolve, reject) =>
    {
        baseService
            .put(`/SoCoRegistration/school-report-trend`,
                {
                    schoolyearMinID: schoolyearMinID,
                    schoolyearMaxID: schoolyearMaxID,
                    groupyears: groupyears,
                    period: period,
                    type: type
                },
                {
                    headers: {
                        Accept: 'application/octet-stream',
                        "x-api-version": "1.0",
                        Authorization: `Bearer ${AuthService.getLocalAccessToken()}`,
                    },
                    responseType: 'blob',
                })
            .then((response) =>
            {
                resolve(response);
            })
            .catch((error) => { reject(error) });
    });
};

const getClosedatesForGroupReport = (groupIDs, stuSchoolIDs, type) =>
{
    return new Promise((resolve, reject) =>
    {
        baseService
            .put(`/SoCoRegistration/get-closedates-for-groupreport`,
                {
                    groupIDs: groupIDs,
                    studentSchoolIDs: stuSchoolIDs,
                    type: type
                })
            .then((response) =>
            {
                resolve(response);
            })
            .catch((error) => { reject(error) });
    });
};

const SoCoReportService = {
    getSoCoStudentReport,
    getSoCoGroupReport,
    getSoCoCrossSectionReport,
    getClosedatesForGroupReport,
    getSoCoTrendReport
};

export default SoCoReportService;