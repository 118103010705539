import '../Login/Login.scss';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import UserService from "../../Service/UserService";
import AuthService from "../../Service/AuthService";
import SchoolService from "../../Service/SchoolService";
import MessageConverter from "../MessageConverter";
import MessageHandler from "../MessageHandler";

function TwoFALogin()
{
    const [code, setCode] = useState("");
    const navigate = useNavigate();
    const [msg, setMsg] = useState([]);

    const newMessageHandler = (enteredMsg) =>
    {
        setMsg(enteredMsg);
    }

    const setMessageHandler = (msg) =>
    {
        newMessageHandler(msg);
    }

    const login = (e) =>
    {
        setMessageHandler();
        if (code !== "")
        {
            e.preventDefault();
            try
            {
                UserService.confirmMfaCode(code).then((res) =>
                {
                    AuthService.updateNewAccessToken(res.data);
                    e.preventDefault;
                    try
                    {
                        SchoolService.getSchool(AuthService.getSchoolID()).then((schoolRes) =>
                        {
                            AuthService.setCurrentSchoolname(schoolRes.data.name);

                            if (AuthService.getSchoolID() == 1)
                            {
                                e.preventDefault;
                                try
                                {
                                    AuthService.getPermissions().then((res) =>
                                    {
                                        AuthService.setPermissions(res);
                                        navigate("/AdoptUser", { replace: true })
                                    });
                                } catch (error)
                                {
                                    setMessageHandler(MessageConverter.convertBusinessMsg(error))
                                }
                            }
                            else
                            {
                                e.preventDefault;
                                try
                                {
                                    AuthService.getPermissions().then((res) =>
                                    {
                                        AuthService.setPermissions(res);
                                        navigate("/Home", { replace: true })
                                    });
                                } catch (error)
                                {
                                    setMessageHandler(MessageConverter.convertBusinessMsg(error))
                                }
                            }
                        }).catch(err => setMessageHandler(MessageConverter.convertBusinessMsg(err)));
                    } catch (error)
                    {
                        setMessageHandler(MessageConverter.convertBusinessMsg(error))
                    }
                }).catch(err => setMessageHandler(MessageConverter.convertBusinessMsg(err)));
            } catch (error)
            {
                setMessageHandler(MessageConverter.convertBusinessMsg(error))
            }
        } else setMessageHandler(MessageConverter.convertBusinessMsg("Geen code ingevuld", 1));
    }

    const reset = (e) =>
    {
        e.preventDefault();
        setMessageHandler();
        UserService.resetMfaSetupData().then((res) =>
        {
            navigate("/TwoFA", { state: { code: res.data } })
        }).catch(err => setMessageHandler(MessageConverter.convertBusinessMsg(err)));
    }

    return (
        <div id="content">
                <div>
                    <div className="login-container">
                        <form>
                            <div className="login-header">
                                <div className="login-header-title">
                                    Observeren, registreren en stimuleren van ontwikkeling
                                </div>
                                <div className="clear"></div>
                            </div>
                            <div className="message-div">
                                <MessageHandler messages={msg} />
                            </div>
                            <div className="login-user">
                                <div className="login-user-email">
                                <span>Beveiligingscode:</span>
                                    <input
                                        type="text"
                                        id="email"
                                        required
                                        onChange={(e) => setCode(e.target.value)}
                                    >
                                    </input>
                                </div>
                            </div>
                            <div className="login-buttons">
                                <div className="login-forgotpass">
                                    <a onClick={reset}>Opnieuw instellen</a>
                                </div>
                                <div className="login-button">
                                    <input type="submit" value="Inloggen" className="login-button-style" onClick={login} />
                                </div>
                                <div className="clear"></div>
                            </div>
                        </form>
                    </div>
                </div>
                <input type="image" className="kijk-logo" alt="logo kijk verder" src="/img/kijk-logo.png"></input>
            </div>

        //<div id="content">
        //    <div id="MainContent_udpContainer">
        //        <div className="forgotpw-container">
        //            <div className="forgotpw-header">
        //                <div className="forgotpw-header-title">Two factor authentication</div>
        //                <div className="clear"></div>
        //            </div>
        //            <div className="forgotpw-email">
        //                <div className="forgotpw-wrapper">
        //                    <span>Beveiligingscode:</span>
        //                    <input
        //                        type="email"
        //                        id="email"
        //                        className="login-field"
        //                        placeholder="Vul je beveiligingscode in"
        //                        required
        //                        onChange={(e) => setCode(e.target.value)} />
        //                </div>
        //            </div>
        //            <div className="forgotpw-buttons">
        //                <div className="request-button">
        //                    <input type="submit" className="forgotpw-button-style" value="Inloggen" onClick={login} />
        //                </div>
        //            </div>
        //        </div>
        //    </div>
        //    <input type="image" className="kijk-logo" src="/img/kijk-logo.png" alt="logo kijk"></input>
        //</div>
    );
}

export default TwoFALogin;