import baseService from "../Service/BaseService";

const getListForSchool = (schoolid) =>
{
    return new Promise((resolve, reject) =>
    {
        baseService
            .get(`/SchoolLicense/listforschool/${schoolid}`, {})
            .then((response) =>
            {
                resolve(response);
            })
            .catch((error) => reject(error));
    });
};

const terminateSchoolLicense = (licenseDTO) =>
{
    return new Promise((resolve, reject) =>
    {
        baseService
            .put(
                '/SchoolLicense/terminate', licenseDTO, {})
            .then((response) =>
            {
                resolve(response);
            })
            .catch((error) => reject(error));
    });
};

const SchoolLicenseService = {
    getListForSchool,
    terminateSchoolLicense
};

export default SchoolLicenseService;