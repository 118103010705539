import React from 'react';
import '../GeneralStyle.scss'
import Login from '../../Components/Login/Login'
import Footer from '../../Components/Footer/Footer'

function UserLogin()
{
    return (
        <div id="wrapper">
            <div id="upd-main">
                <div className="main">
                    <div className="main-wrapper">
                        <div id="mainRight" className="main-right-full-width">
                            <Login />
                        </div>
                    </div>
                </div>
                <div className="footer">
                    <Footer />
                </div>
            </div>
        </div>
    );
}

export default UserLogin;