import React, { useState } from "react";
import { Steps } from 'primereact/steps';
import { Link, useNavigate, useLocation } from 'react-router-dom';

import MessageConverter from "../MessageConverter";
import MessageHandler from "../MessageHandler";
import '../GeneralStyling.scss'
import './TwoFA.scss'
import UserService from "../../Service/UserService";
import AuthService from "../../Service/AuthService";
import SchoolService from "../../Service/SchoolService";

function TwoFAAppleDesktop()
{
	const navigate = useNavigate();
	const { state } = useLocation();
	const qrCode = (state.qrCode);
	const [activeIndex, setActiveIndex] = useState(0);
	const [code, setCode] = useState([]);
	const [hideStep1, setHideStep1] = useState(false);
	const [hideStep2, setHideStep2] = useState(true);
	const [hideStep3, setHideStep3] = useState(true);
	const [msg, setMsg] = useState([]);

	const newMessageHandler = (enteredMsg) =>
	{
		setMsg(enteredMsg);
	}

	const setMessageHandler = (msg) =>
	{
		newMessageHandler(msg);
	}

	const steps = [
		{
			label: 'App installeren'
		},
		{
			label: 'Verificatiecode invoeren'
		},
		{
			label: 'Gereed'
		},
	];

	const onNextStep1 = () =>
	{
		setHideStep1(true);
		setHideStep2(false);
		setActiveIndex(1);
	}

	const onBackStep2 = () =>
	{
		setHideStep1(false);
		setHideStep2(true);
		setActiveIndex(0);
	}

	const onNextStep2 = (e) =>
	{
		e.preventDefault();
		setMessageHandler();
		if (code !== "")
		{
			UserService.confirmMfaCode(code).then((res) =>
			{
				AuthService.updateNewAccessToken(res.data);
				setHideStep2(true);
				setHideStep3(false);
				setActiveIndex(2);
			}).catch(err => setMessageHandler(MessageConverter.convertBusinessMsg(err)));
		} else setMessageHandler(MessageConverter.convertBusinessMsg("Geen code ingevuld", 1));
	}

	const finish = (e) =>
	{
		e.preventDefault();
		setMessageHandler();
		try
		{
			SchoolService.getSchool(AuthService.getSchoolID()).then((schoolRes) =>
			{
				AuthService.setCurrentSchoolname(schoolRes.data.name);

				if (AuthService.getSchoolID() == 1)
				{
					AuthService.getPermissions().then((res) =>
					{
						AuthService.setPermissions(res);
						navigate("/AdoptUser", { replace: true })
					});
				}
				else
				{
					AuthService.getPermissions().then((res) =>
					{
						AuthService.setPermissions(res);
						navigate("/Home", { replace: true })
					});
				}
			}).catch(err => setMessageHandler(MessageConverter.convertBusinessMsg(err)));
		} catch (error)
		{
			setMessageHandler(MessageConverter.convertBusinessMsg(error))
		}
	}

	return (
		<div id="ManageChildPage">
			<div id="MainContentContainer">
				<div className="page-information">
					<div id="PageHeader" className="page-header">
						<span>Login: Tweestapsverificatie instellen</span>
					</div>
					<div className="clear"></div>
				</div>
				<div className="maincontent">
					<div className="message-div">
						<MessageHandler messages={msg} />
					</div>
					<p>
						Voor jouw account moet tweestapsverificatie ingesteld worden, wat verplicht is gesteld op jullie school/locatie. Dit is een methode waarmee de identiteit van gebruikers beter vastgesteld kan worden.
						Hiermee voorkomen we onrechtmatig gebruik van jouw account. De stappen voor het instellen hoef je maar eenmalig te doorlopen.
						Als de tweestapsverificatie succesvol is ingesteld, dien je in het vervolg bij het inloggen de verificatiecode in te vullen die je op je gekozen apparaat ontvangt.
						Volg de stappen om tweestapsverificatie in te stellen.
					</p>
					<div className="TwoFA" hidden={hideStep1}>
						<Steps model={steps} readOnly={true} activeIndex={activeIndex} />
						<div className="content">
							<label>Stap 1: Verificatie app installeren</label>
							<p>Beschik je over een pc of laptop met een Mac besturingssysteem? Dan kun je gebruik maken van het gratis programmma Step Two om de verificatie in te stellen</p>
						</div>
						<div className="step">
							<div className="desktop-step-image">
								<img src="/img/2fa/step1/desktopmac/img1.png" alt="Step Two downloaden" className="desktop-image" />
							</div>
							<div className="step-text">
								<label>Step Two downloaden</label>
								<p>Open op je computer het programma 'App Store' en zoek op Step Two. Klik op de knop 'Installeren' en na de installatie op de knop 'Openen'. Klik met de rechtermuisknop in het Dock op het icoon van Step Two, ga naar 'Opties' en klik op 'Permanent in Dock'.</p>
							</div>
						</div>
						<div className="step-buttons">
							<Link to="/TwoFA">
								<input type="submit" value="Vorige" className="management-buttons" />
							</Link>
							<input type="submit" value="Volgende" className="management-buttons" onClick={onNextStep1} />
						</div>
					</div>

					<div className="TwoFA" hidden={hideStep2}>
						<Steps model={steps} readOnly={true} activeIndex={activeIndex} />
						<div className="content">
							<label>Stap 2: Verificatie code invoeren</label>
							<p>Na het installeren van de applicatie is de verificatie in te stellen.</p>
						</div>
						<div className="step">
							<div className="desktop-step-image">
								<img src="/img/2fa/step2/desktopmac/img1.png" alt="Koppelen aan Step Two" className="desktop-image" />
							</div>
							<div className="step-text">
								<label>Je KIJK! account koppelen aan Step Two</label>
								<p>Klik op het plusje en kies voor 'Scan tweestaps QR-code'. De scanner wordt geopend.</p>
							</div>
						</div>

						<div className="step">
							<div className="desktop-step-image">
								<img className="qr-code" src={qrCode} />
							</div>
							<div className="step-text">
								<label>QR-code koppelen</label>
								<p>Sleep de afbeelding van de QR-code op het venster van de scanner in het programma TwoStep zodat de code gekoppeld kan worden.</p>
							</div>
						</div>

						<div className="step">
							<div className="desktop-step-image">
								<img src="/img/2fa/step2/desktopmac/img3.png" alt="Scherm met verificatiecode" className="desktop-image" />
							</div>
							<div className="step-text">
								<label>Verificatiecode invullen</label>
								<p>Er verschijnt een code in beeld. Na het invullen klik je op deze pagina op 'Volgende'. Je kunt de Step Two app afsluiten.</p>
								<p><i>De code die hier links op de afbeelding weergegeven wordt, is slechts een voorbeeld en kan niet worden gebruikt.</i></p>
								<label className="label-input">Vul de code hieronder in:</label>
								<input type="text" onChange={(e) => setCode(e.target.value)}></input>
							</div>
						</div>
						<div className="step-buttons">
							<input type="submit" value="Vorige" className="management-buttons" onClick={onBackStep2} />
							<input type="submit" value="Volgende" className="management-buttons" onClick={onNextStep2} />
						</div>
					</div>

					<div className="TwoFA" hidden={hideStep3}>
						<Steps model={steps} readOnly={true} activeIndex={activeIndex} />
						<div className="content">
							<label>Stap 3: Gereed</label>
							<p>Je hebt succesvol de tweestapsverificatie voor jouw account ingesteld. Vanaf nu dien je gebruik te maken van deze methode als je in wilt loggen in je KIJK! account. Zorg dat je je smartphone bij de hand hebt als je de volgende keer gaat inloggen in KIJK! Inloggen gaat in het vervolg met onderstaande stappen:</p>
							<div className="end-image">
								<div className="column-1">
									<label>Vul je e-mail adres en wachtwoord in</label>
									<img src="/img/2fa/step3/img1.png" alt="Login pagina" />
								</div>
								<div className="column-2">
									<label>Verificatiecode op je smartphone</label>
									<img src="/img/2fa/step3/img2desktopmac.png" alt="Code in beeld" />
								</div>
								<div className="column-3">
									<label>Vul de code in om in te loggen</label>
									<img src="/img/2fa/step3/img3.png" alt="Invulveld inloggen" />
								</div>
							</div>
						</div>
						<div className="step-buttons">
							<input type="submit" value="Doorgaan" className="management-buttons" onClick={finish} />
						</div>
					</div>

				</div>
			</div>
		</div>
	);
}

export default TwoFAAppleDesktop;