import baseService from "./BaseService";

const getOpenSoCoObservation = (id) => {
    return new Promise((resolve, reject) => {
        baseService
            .get(`/SoCoObservation/get-open-obs/${id}`, {})
            .then((response) => {
                resolve(response);
            })
            .catch((error) => reject(error));
    });
};

const SaveSoCoObservationComment = (observationComment) => {
    return new Promise((resolve, reject) => {
        baseService
            .put(`/SoCoObservationComment/create`, observationComment, {})
            .then((response) => {
                resolve(response);
            })
            .catch((error) => reject(error));
    });
};

const getSoCoObservationComment = (id) => {
    return new Promise((resolve, reject) => {
        baseService
            .get(`/SoCoObservationComment/${id}`, {})
            .then((response) => {
                resolve(response);
            })
            .catch((error) => reject(error));
    });
};

const updateSoCoObservationComment = (soCoObservationComment) => {
    return new Promise((resolve, reject) => {
        baseService
            .put(`/SoCoObservationComment/update/`, soCoObservationComment, {})
            .then((response) => {
                resolve(response);
            })
            .catch((error) => reject(error));
    });
};

const GetOpenSoCoObservationComments = (studentID) => {
    return new Promise((resolve, reject) => {
        baseService
            .get(`/SoCoObservationComment/get-open-obs/${studentID}`, {})
            .then((response) => {
                resolve(response);
            })
            .catch((error) => reject(error));
    });
};

const deleteSoCoObservationComment = (id) => {
    return new Promise((resolve, reject) => {
        baseService
            .delete(`/SoCoObservationComment/${id}`, {})
            .then((response) => {
                resolve(response);
            })
            .catch((error) => reject(error));
    });
};


const SoCoObservation = {
    getOpenSoCoObservation,
    SaveSoCoObservationComment,
    GetOpenSoCoObservationComments,
    getSoCoObservationComment,
    updateSoCoObservationComment,
    deleteSoCoObservationComment
};

export default SoCoObservation