import baseService from "../Service/BaseService";

// Get the list of all available groups for user
const getGroupListForOverview = () =>
{
    return new Promise((resolve, reject) =>
    {
        baseService
            .get('/Group/list-for-overview/', {})
            .then((response) =>
            {
                resolve(response);
            })
            .catch((error) => { reject(error) });
    });
};

// Get the group by id
const getGroup = (groupID) =>
{
    return new Promise((resolve, reject) =>
    {
        baseService
            .get(`/Group/${groupID}`, {})
            .then((response) =>
            {
                resolve(response);
            })
            .catch((error) => reject(error));
    });
};

// Create new group
const createGroup = (groupDTO) =>
{
    return new Promise((resolve, reject) =>
    {
        baseService
            .put(
                '/Group/create', groupDTO, {})
            .then((response) =>
            {
                resolve(response);
            })
            .catch((error) => reject(error));
    });
};

// Update group
const updateGroup = (groupDTO) =>
{
    return new Promise((resolve, reject) =>
    {
        baseService
            .put(
                '/Group/update', groupDTO, {})
            .then((response) =>
            {
                resolve(response);
            })
            .catch((error) => reject(error));
    });
};

// Get all the groups of the current year
const getAllInCurrentYearGroup = () =>
{
    return new Promise((resolve, reject) =>
    {
        baseService
            .get('/Group/get-all-in-current-year', {})
            .then((response) =>
            {
                resolve(response);
            })
            .catch((error) => reject(error));
    });
};

const deleteGroup = (id) =>
{
    return new Promise((resolve, reject) =>
    {
        baseService.delete(`/Group/${id}`, {})
            .then((response) =>
            {
                resolve(response);
            })
            .catch((error) => reject(error));
    });
};

// Get the list of groups with closed soco registrations
const getGroupsWithClosedSoCoRegistrations = (schoolyearID, type, selectedGroupyears) =>
{
    return new Promise((resolve, reject) =>
    {
        if (schoolyearID === undefined || schoolyearID == null)
            reject();
        else
        {
            // groupyears, comma separated
            var groupyears = '0';
            if (selectedGroupyears !== undefined)
                groupyears = selectedGroupyears.map((gy) => gy.id).toString();
            baseService
                .get(`/Group/soco/get-groups-with-closed-registration/${schoolyearID}/${type}/${groupyears}`, {})
                .then((response) =>
                {
                    resolve(response);
                })
                .catch((error) => { reject(error) });
        }
    });
};

// Get the list of groups for soco registration
const getGroupForRegSoCo = () =>
{
    return new Promise((resolve, reject) =>
    {
        baseService
            .get('/Group/soco/get-for-reg', {})
            .then((response) => 
            {
                resolve(response);
            })
            .catch((error) => reject(error));
    });
};

const GroupService = {
    getGroupListForOverview,
    getGroup,
    createGroup,
    updateGroup,
    getAllInCurrentYearGroup,
    deleteGroup,
    getGroupsWithClosedSoCoRegistrations,
    getGroupForRegSoCo
};

export default GroupService;