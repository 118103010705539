import '../GeneralStyling.scss'
import StudentService from "../../Service/StudentService";
import MessageConverter from "../MessageConverter";
import MessageHandler from "../MessageHandler";
import { DateFilter } from "../DateFilter"

import moment from 'moment';
import React, { useState, useEffect, useCallback } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { FilterMatchMode, FilterOperator } from 'primereact/api';
import { Dialog } from 'primereact/dialog';
import { BlockUI } from 'primereact/blockui';
import { Editor, EditorState, convertFromRaw, CompositeDecorator } from "draft-js";
import HelpPageService from '../../Service/HelpPageService';
import HelpItemDescription from '../HelpPage/HelpItemDescription';

function StudentManagement()
{
    const [blocked, setBlocked] = useState(false);
    const [visible, setVisible] = useState(false);
    const [visibleDelete, setVisibleDelete] = useState(false);
    const [studentListForSchool, setStudentListForSchool] = useState([]);
    const [dateRemoveData, setDateRemoveData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [selectedStudent, setSelectedStudent] = useState(null);
    const [fullname, setFullname] = useState("");
    const [refreshKey, setRefreshKey] = useState(0);
    const [visibleArchive, setVisibleArchive] = useState(false);
    const navigate = useNavigate();

    const [filters, setFilters] = useState({
        birthdate: { value: null, matchMode: FilterMatchMode.DATE_IS },
        fullname: { value: null, matchMode: FilterMatchMode.CONTAINS },
        groupname: { value: null, matchMode: FilterMatchMode.CONTAINS },
    });

    const [helpItem, setHelpItem] = useState(null);
    const [helpVisible, setHelpVisible] = useState(false);
    const [helpButtonHidden, sethelpButtonHidden] = useState(true);
    const currentPathName = window.location.pathname;

    const [msg, setMsg] = useState([]);

    const newMessageHandler = (enteredMsg) =>
    {
        setMsg(enteredMsg);
    }

    const setMessageHandler = (msg) =>
    {
        newMessageHandler(msg);
    }

    // Omzetten van de data
    const getCustomers = (data) =>
    {
        return [...(data || [])].map((d) =>
        {
            d.birthdate = new Date(d.birthdate);

            return d;
        });
    };

    const datebody = (rowData) =>
    {
        return <>{moment(rowData.birthdate).format("DD-MM-YYYY")}</>;
    };

    const addStudent = () =>
    {
        setBlocked(true);
        navigate("/KindItem");
        setBlocked(false);
    }

    const editStudent = useCallback(() =>
    {
        setMessageHandler();
        if (selectedStudent == null)
        {
            setMessageHandler(MessageConverter.convertBusinessMsg("Geen kind geselecteerd", 1));
        }
        else
        {
            setBlocked(true);
            navigate("/KindItem", { state: { studentID: selectedStudent.id, studentSchoolID: selectedStudent.studentSchoolID } });
            setBlocked(false);
        }
    }, [selectedStudent])

    const MigrateStudent = useCallback(() =>
    {
        setMessageHandler();
        if (selectedStudent == null)
        {
            setMessageHandler(MessageConverter.convertBusinessMsg("Geen kind geselecteerd", 1));
        }
        else
        {
            setBlocked(true);
            navigate("/KindVerhuizen", { state: { student: selectedStudent } });
            setBlocked(false);
        }
    }, [selectedStudent])

    const deleteStudent = useCallback(() =>
    {
        setBlocked(true);
        StudentService.deleteStudent(selectedStudent.id).then(() =>
        {
            setBlocked(false);
            setRefreshKey(oldKey => oldKey + 1);
            setMessageHandler(MessageConverter.convertBusinessMsg("Kind is succesvol verwijderd", 0));
            setVisibleDelete(false);
        }).catch(err =>
        {
            setMessageHandler(MessageConverter.convertBusinessMsg(err));
            setBlocked(false);
            setVisibleDelete(false);
        })
    }, [selectedStudent])

    const onDeleteButton = useCallback(() =>
    {
        setMessageHandler();
        if (selectedStudent == null)
        {
            setMessageHandler(MessageConverter.convertBusinessMsg("Geen kind geselecteerd", 1));
        }
        else
        {
            setVisibleDelete(true);
        }
    }, [selectedStudent])

    useEffect(() =>
    {
        StudentService.getStudentListForSchool().then((res) =>
        {
            setStudentListForSchool(getCustomers(res.data));
            setLoading(false);
        }).catch(err =>
        {
            setMessageHandler(MessageConverter.convertBusinessMsg(err));
            setBlocked(false);
        });
    }, [refreshKey]);

    // General message
    const { state } = useLocation();
    useEffect(() =>
    {
        if (state !== null)
        {
            const { confirmedMessage } = state;
            setMessageHandler(MessageConverter.convertBusinessMsg(confirmedMessage, 0));
        }
    }, []);

    const onButtonArchive = useCallback((e) =>
    {
        e.preventDefault();
        setMessageHandler();
        if (selectedStudent !== null && selectedStudent !== undefined)
        {
            setBlocked(true);
            StudentService.calculateArchiveDate(moment(selectedStudent?.birthdate).format("YYYY-MM-DD")).then((res) =>
            {
                setDateRemoveData(moment(res.data).format('YYYY-MM-DD'));
                setVisibleArchive(true);
                setBlocked(false);
            }).catch(err =>
            {
                setMessageHandler(MessageConverter.convertBusinessMsg(err));
                setBlocked(false);
            });
        } else setMessageHandler(MessageConverter.convertBusinessMsg("Geen kind geselecteerd", 1));
    }, [selectedStudent])

    const archive = useCallback((e) =>
    {
        e.preventDefault();
        setBlocked(true);
        try
        {
            StudentService.archiveStudent(selectedStudent.studentSchoolID, dateRemoveData).then(() =>
            {
                setVisibleArchive(false);
                setSelectedStudent(null);
                setDateRemoveData(null);
                setRefreshKey(oldKey => oldKey + 1);
                setMessageHandler(MessageConverter.convertBusinessMsg("Kind succesvol gearchiveerd", 0));
                setBlocked(false);
            }).catch((err) =>
            {
                setMessageHandler(MessageConverter.convertBusinessMsg(err));
                setBlocked(false);
            });
        } catch (error)
        {
            setMessageHandler(MessageConverter.convertBusinessMsg(error));
        }
    }, [selectedStudent, dateRemoveData]);

    // Prevent going back
    useEffect(() =>
    {
        window.addEventListener('popstate', (e) =>
        {
            window.history.go(1);
        });
    }, []);

    // Preload helpItems
    useEffect(() => {
        if (helpItem == null) {
            HelpPageService.getHelpItembyPage(currentPathName).then((res) => {
                if (res.data != undefined && res.data != null && res.data != '') {
                    setHelpItem(res.data);
                    sethelpButtonHidden(false);
                }
                else {
                    sethelpButtonHidden(true);
                }
            }).catch(err => {
                setMessageHandler(MessageConverter.convertBusinessMsg(err));
            });
        }
    }, []);

    return (
        <div id="Page">
            <BlockUI blocked={blocked} fullScreen />
            <div id="MainContentContainer">
                <div className="page-information">
                    <div id="PageHeader" className="page-header">
                        <span>Kinderen: Overzicht</span>
                    </div>
                    <div id="InfoButton">
                        <div className="btn-info" onClick={() => setHelpVisible(true)} hidden={helpButtonHidden}></div>
                    </div>
                    <Dialog
                        header={"Kinderen: Overzicht"}
                        visible={helpVisible}
                        modal={true}
                        onHide={() => setHelpVisible(false)}
                        style={{ width: '75vw' }}
                    >
                        <Editor className="textfield" editorState={HelpItemDescription(helpItem)} readOnly="true" />
                    </Dialog>
                    <div className="clear"></div>
                </div>
                <div>
                    <div className="maincontent">
                        <div className="message-div">
                            <MessageHandler messages={msg} />
                        </div>
                        <div className="card">
                            <DataTable
                                value={studentListForSchool}
                                size="small"
                                paginator={true}
                                paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                                currentPageReportTemplate="{first} - {last} van {totalRecords}"
                                rows={10}
                                rowsPerPageOptions={[10, 20, 50, 100]}
                                loading={loading}
                                filters={filters}
                                filterDisplay="row"
                                responsiveLayout="scroll"
                                selectionMode="multiple"
                                selection={selectedStudent}
                                onSelectionChange={e => { setSelectedStudent(e.value[0]); setFullname(e.value[0]?.fullname) }}
                                emptyMessage="Geen resultaten gevonden"
                                stateKey="StudentManagement-Overview"
                            >
                                <Column field="fullname"
                                    filter={true}
                                    sortable={true}
                                    filterPlaceholder="Zoek op naam"
                                    style={{ width: '10rem' }}
                                    showFilterMenu={false}
                                    header="Naam" />
                                <Column header="Geboortedatum"
                                    filterField="birthdate"
                                    sortable={true}
                                    dataType="date"
                                    style={{ width: '10rem' }}
                                    field="birthdate"
                                    body={datebody}
                                    filter={true}
                                    showFilterMenu={false}
                                    filterElement={DateFilter}
                                />
                                <Column field="groupname"
                                    filter={true}
                                    sortable={true}
                                    style={{ width: '10rem' }}
                                    showFilterMenu={false}
                                    filterPlaceholder="Zoek op groep"
                                    header="Groep" />
                            </DataTable>
                            <div className="clear"></div>
                        </div>
                    </div>
                    <div className="buttons">
                        <input type="submit" value="Toevoegen" className="management-buttons" onClick={addStudent} />
                        <input type="submit" value="Bewerken" className="management-buttons" onClick={editStudent} />
                        <input type="submit" value="Verwijderen" className="management-buttons" onClick={onDeleteButton} />
                        <input type="submit" value="Archiveren" className="management-buttons" onClick={onButtonArchive} />
                        <input type="submit" value="Verhuizen" className="management-buttons" onClick={MigrateStudent} />
                    </div>
                    <Dialog
                        className="delete-dialog"
                        header={"Weet je zeker dat je " + fullname + " wilt verwijderen?"}
                        visible={visibleDelete}
                        onHide={() => setVisibleDelete(false)}
                        draggable={false}
                    > Door dit kind te verwijderen, verwijder je alle registraties en rapportages. Deze kun je op geen enkele manier terughalen.
                        <br />
                        <input type="submit" value="Ja" className="delete-button" onClick={deleteStudent} />
                        <input type="submit" value="Nee" className="cancel-delete-button" onClick={() => setVisibleDelete(false)} />
                    </Dialog>
                    <Dialog
                        className="delete-dialog"
                        header={"Weet je zeker dat je " + fullname + " wilt archiveren?"}
                        visible={visibleArchive}
                        onHide={() => setVisibleArchive(false)}
                        draggable={false}
                    >
                        <input type="submit" value="Ja" className="delete-button" onClick={archive} />
                        <input type="submit" value="Nee" className="cancel-delete-button" onClick={() => setVisibleArchive(false)} />
                    </Dialog>
                    <Dialog
                        className="delete-dialog"
                        header={"Op welke datum moet de data van " + fullname + " verwijderd worden?"}
                        visible={visibleArchive}
                        onHide={() => setVisibleArchive(false)}
                        draggable={false}
                    >
                        <form>
                            <div className="maincontent-item" >
                                <input
                                    type="date"
                                    min={moment().format('YYYY-MM-DD')}
                                    required
                                    defaultValue={dateRemoveData}
                                    onChange={(e) => setDateRemoveData(e.target.value)}
                                />
                            </div>
                            <input type="submit" value="Archiveren" className="management-buttons" onClick={archive} />
                        </form>
                    </Dialog>
                </div>
            </div>
        </div>
    );
}

export default StudentManagement;