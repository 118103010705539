import React from 'react';
import ResetPassword from '../Components/ForgotPassword/ResetPassword';

function ResetPasswordPage()
{
    return (
        <div id="wrapper" className="reset-pw">
            <div id="upd-main">
                <div className="main">
                    <div className="main-wrapper">
                        <div id="mainRight" className="main-right-full-width">
                            <ResetPassword />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default ResetPasswordPage;