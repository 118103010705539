import React, { useEffect, useState } from 'react';
import Select from 'react-select';
import { useNavigate } from 'react-router-dom';
import { Dialog } from 'primereact/dialog';
import { Editor, EditorState, convertFromRaw, CompositeDecorator } from "draft-js";

import AuthService from '../../../Service/AuthService';
import SoCoSignalItemService from '../../../Service/SoCoSignalItemService';
import GroupService from '../../../Service/GroupService';
import StudentService from '../../../Service/StudentService';
import '../SoCoRegistration.scss'
import '../../GeneralStyling.scss'
import MessageConverter from "../../MessageConverter";
import MessageHandler from "../../MessageHandler";
import SoCoRegistrationService from "../../../Service/SoCoRegistrationService";
import UserService from "../../../Service/UserService";
import HelpPageService from '../../../Service/HelpPageService';
import HelpItemDescription from '../../HelpPage/HelpItemDescription';

function Overview()
{
    const [groupsForReg, setGroupsForReg] = useState([]);
    const [selectedGroup, setSelectedGroup] = useState([]);

    const [selectedStudent, setSelectedStudent] = useState([]);
    const [students, setStudents] = useState([]);

    const [hideStudent, setHideStudent] = useState(true);
    const [visible, setVisible] = useState(false);

    const [soCoRegistration, setSoCoRegistration] = useState([]);
    const [soCoRegistrationScoreList, setSoCoRegistrationScoreList] = useState([]);
    const [previousSoCoRegistration, setPreviousSoCoRegistration] = useState([]);
    const navigate = useNavigate();
    const [defaultSelected, setDefaultSelected] = useState(null);
    const [msg, setMsg] = useState([]);

    const [helpItem, setHelpItem] = useState(null);
    const [helpVisible, setHelpVisible] = useState(false);
    const [helpButtonHidden, sethelpButtonHidden] = useState(true);
    const currentPathName = window.location.pathname;

    const newMessageHandler = (enteredMsg) =>
    {
        setMsg(enteredMsg);
    }

    const setMessageHandler = (msg) =>
    {
        newMessageHandler(msg);
    }

    // Get the groups
    useEffect(() =>
    {
        GroupService.getGroupForRegSoCo().then((res) =>
        {
            setGroupsForReg(res.data);
            if (res.data.length == 1)
            {
                setDefaultSelected(res.data[0]);
                setSelectedStudent(null);
                setSelectedGroup(res.data[0]);
                setHideStudent(false);
            }
        }).catch((err) => setMessageHandler(MessageConverter.convertBusinessMsg(err)));
    }, []);

    // Get the students
    useEffect(() =>
    {
        if (selectedGroup !== undefined && selectedGroup != null && selectedGroup.id > 0)
        {
            StudentService.getStudentsAssignedToGroup(selectedGroup.id).then((res) =>
            {
                setStudents(res.data);
            }).catch((err) => setMessageHandler(MessageConverter.convertBusinessMsg(err)));
        }
    }, [selectedGroup]);

    // Get the open registrations of the selected student
    useEffect(() =>
    {
        if (selectedStudent !== null && selectedGroup !== null)
        {
            if (selectedStudent !== undefined && selectedGroup.id !== undefined)
            {
                try
                {
                    SoCoRegistrationService.getOpenRegSoCo(selectedStudent.studentSchoolID, selectedGroup.id, 2).then((result) =>
                    {
                            setSoCoRegistrationScoreList(result.data.soCoRegistrationScoreList);
                            setSoCoRegistration(result.data.soCoRegistration);
                            setPreviousSoCoRegistration(result.data.previousSoCoRegistration);
                    })
                } catch (err)
                {
                    setMessageHandler(MessageConverter.convertBusinessMsg(err))
                }
            }
        }
    }, [selectedGroup, selectedStudent]);

    // Get the questions
    useEffect(() =>
    {
        if ((soCoRegistration.length !== 0) && (previousSoCoRegistration.length !== 0))
        {
            SoCoSignalItemService.getListForGroupyearSoCo(selectedGroup.groupyearID, 2).then((result) =>
            {
                //setQuestions(result.data);
                UserService.getStudentToken(selectedStudent.studentSchoolID).then((authRes) =>
                {
                    AuthService.logout().then(() =>
                    {
                        AuthService.setAuth(authRes.data);
                        navigate("/Kindlijst", { state: { questions: result.data, soCoRegistration: soCoRegistration, previousSoCoRegistration: previousSoCoRegistration, soCoRegistrationScoreList: soCoRegistrationScoreList } })
                    });
                })
            })
        }
    }, [soCoRegistration, previousSoCoRegistration])

    // Prevent going back
    useEffect(() =>
    {
        window.addEventListener('popstate', (e) =>
        {
            window.history.go(1);
        });
    }, []);

    // Preload helpItems
    useEffect(() => {
        if (helpItem == null) {
            HelpPageService.getHelpItembyPage(currentPathName).then((res) => {
                if (res.data != undefined && res.data != null && res.data != '') {
                    setHelpItem(res.data);
                    sethelpButtonHidden(false);
                }
                else {
                    sethelpButtonHidden(true);
                }
            }).catch(err => {
                setMessageHandler(MessageConverter.convertBusinessMsg(err));
            });
        }
    }, []);

    return (
        <div id="Page">
            <div id="MainContentContainer">
                <div className="page-information">
                    <div id="PageHeader" className="page-header">
                        <span>Registratie: Invoer kind</span>
                    </div>
                    <div id="InfoButton">
                        <div className="btn-info" onClick={() => setHelpVisible(true)} hidden={helpButtonHidden}></div>
                    </div>
                    <Dialog
                        header={"Registratie: Invoer kind"}
                        visible={helpVisible}
                        modal={true}
                        onHide={() => setHelpVisible(false)}
                        style={{ width: '75vw' }}
                    >
                        <Editor className="textfield" editorState={HelpItemDescription(helpItem)} readOnly="true" />
                    </Dialog>
                    <div className="clear" />
                </div>
                <div>
                    <div className="maincontent-header-bar">
                        <div className="header-bar-item">
                            <label>Groep</label>
                            <Select
                                key={selectedGroup}
                                options={groupsForReg}
                                getOptionLabel={(option) => option.name}
                                getOptionValue={(option) => option.id}
                                isSearchable={true}
                                isMulti={false}
                                placeholder="Selecteer een groep"
                                onChange={(gr) =>
                                {
                                    setSelectedStudent(null);
                                    setSelectedGroup(gr);
                                    setHideStudent(false);
                                }}
                                noOptionsMessage={() => 'Geen resultaten gevonden'}
                                value={groupsForReg.find((element) => { return element.id == selectedGroup?.id })}
                                defaultValue={defaultSelected}
                            />
                        </div>
                        <div className="header-bar-item" hidden={hideStudent}>
                            <label>Kind</label>
                            <Select
                                key={students}
                                options={students}
                                getOptionLabel={(option) => option.fullname}
                                getOptionValue={(option) => option.id}
                                isSearchable={true}
                                isMulti={false}
                                placeholder="Selecteer een kind"
                                onChange={(st) => { setSelectedStudent(st) }}
                                noOptionsMessage={() => 'Geen resultaten gevonden'}
                                value={students.find((element) => { return element.id == selectedStudent?.id })}
                            />
                        </div>
                    </div>
                    <div className="message-div">
                        <MessageHandler messages={msg} />
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Overview;