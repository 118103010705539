import BaseService from "../Service/BaseService";


// Get the list of all the users
const getUserList = () =>
{
    return new Promise((resolve, reject) =>
    {
        BaseService
            .get('/User/list', {})
            .then((response) =>
            {
                resolve(response);
            })
            .catch((error) => reject(error));
    });
};

// Get the list of all the users
const getAdoptUserList = () =>
{
    return new Promise((resolve, reject) =>
    {
        BaseService
            .get('/User/adopt-user-list', {})
            .then((response) =>
            {
                resolve(response);
            })
            .catch((error) => reject(error));
    });
};

// Adopt user and get new token
const adoptUser = (userSchoolID) =>
{
    return new Promise((resolve, reject) =>
    {
        BaseService
            .get(`/User/adopt-user/${userSchoolID}`, {})
            .then((response) =>
            {
                resolve(response);
            })
            .catch((error) => reject(error));
    });
};

// Create new user
const createUser = (
    userDTO,
    userSchoolDTO
) =>
{
    return new Promise((resolve, reject) =>
    {
        BaseService
            .put(
                '/User/create',
                {
                    userDTO,
                    userSchoolDTO
                }
            )
            .then((response) =>
            {
                resolve(response);
            })
            .catch((error) => reject(error));
    });
};

// Update user
const updateUser = (
    userDto,
    userSchoolDto
) =>
{
    return new Promise((resolve, reject) =>
    {
        BaseService
            .put(
                '/User/update',
                {
                    userDto,
                    userSchoolDto
                }
            )
            .then((response) =>
            {
                resolve(response);
            })
            .catch((error) => reject(error));
    });
};

// set selected school
const setSchool = (schoolID) => 
{
    return new Promise((resolve, reject) =>
    {
        BaseService.put(`/User/setschool/${schoolID}`, {}, {})
            .then((response) =>
            {
                resolve(response);
            })
            .catch((error) => reject(error));
    });
};

// Get the user information
const getUser = (userID) =>
{
    return new Promise((resolve, reject) =>
    {
        BaseService
            .get(`/User/${userID}`, {})
            .then((response) =>
            {
                resolve(response);
            })
            .catch((error) => reject(error));
    });
};

// Get the user information by email
const getUserByEmail = (email) =>
{
    return new Promise((resolve, reject) =>
    {
        BaseService
            .get(`/User/getbyemail/${email}`, {})
            .then((response) =>
            {
                resolve(response);
            })
            .catch((error) => reject(error));
    });
};

// Request new password
const forgotPasswordRequest = (email) =>
{
    return new Promise((resolve, reject) =>
    {
        BaseService
            .put(
                '/User/forgot-password-request', { email },
            )
            .then((response) =>
            {
                resolve(response);
            })
            .catch((error) => reject(error));
    });
};

const resetPassword = (userId, code, password) =>
{
    return new Promise((resolve, reject) =>
    {
        BaseService
            .put(
                '/User/reset-password', { userId, code, password }, {}
            )
            .then((response) =>
            {
                resolve(response);
            })
            .catch((error) => reject(error));
    });
};

const getListJoinedToGroup = (groupID) =>
{
    return new Promise((resolve, reject) =>
    {
        BaseService
            .get(`/User/list-joined-to-group/${groupID}`, {})
            .then((response) =>
            {
                resolve(response);
            })
            .catch((error) => reject(error));
    });
};

const getListAvailableForGroup = (groupID) =>
{
    return new Promise((resolve, reject) =>
    {
        BaseService
            .get(`/User/list-available-for-group/${groupID}`, {})
            .then((response) =>
            {
                resolve(response);
            })
            .catch((error) => reject(error));
    });
};

const joinToGroup = (userID, groupID) =>
{
    return new Promise((resolve, reject) =>
    {
        BaseService
            .put(
                '/User/join-to-group', { userID, groupID }, {}
            )
            .then((response) =>
            {
                resolve(response);
            })
            .catch((error) => reject(error));
    });
};

const unjoinToGroup = (userID, groupID) =>
{
    return new Promise((resolve, reject) =>
    {
        BaseService
            .put(
                '/User/unjoin-to-group', { userID, groupID }, {}
            )
            .then((response) =>
            {
                resolve(response);
            })
            .catch((error) => reject(error));
    });
};

const getMfaSetupData = () =>
{
    return new Promise((resolve, reject) =>
    {
        BaseService
            .get('/User/get-mfa-setup-data', {})
            .then((response) =>
            {
                resolve(response);
            })
            .catch((error) => reject(error));
    });
};

const resetMfaSetupData = () =>
{
    return new Promise((resolve, reject) =>
    {
        BaseService
            .get('/User/reset-mfa-setup-data', {})
            .then((response) =>
            {
                resolve(response);
            })
            .catch((error) => reject(error));
    });
};

const confirmMfaCode = (code) =>
{
    return new Promise((resolve, reject) =>
    {
        BaseService
            .put(
                `/User/confirm-mfa/${code}`, {}, {}
            )
            .then((response) =>
            {
                resolve(response);
            })
            .catch((error) => reject(error));
    });
};

const isMfaSet = () =>
{
    return new Promise((resolve, reject) =>
    {
        BaseService
            .get('/User/is-mfa-set', {})
            .then((response) =>
            {
                resolve(response);
            })
            .catch((error) => reject(error));
    });
};

// Get the user information
const getStudentToken = (studentSchoolID) =>
{
    return new Promise((resolve, reject) =>
    {
        BaseService
            .get(`/User/soco/get-student-token/${studentSchoolID}`, {})
            .then((response) =>
            {
                resolve(response);
            })
            .catch((error) => reject(error));
    });
};

// Get the user information
const getUserInfo = () =>
{
    return new Promise((resolve, reject) =>
    {
        BaseService
            .get('/User/get-info', {})
            .then((response) =>
            {
                resolve(response);
            })
            .catch((error) => reject(error));
    });
};

const UserService = {
    setSchool,
    createUser,
    getUserList,
    getAdoptUserList,
    adoptUser,
    getUser,
    updateUser,
    getUserByEmail,
    forgotPasswordRequest,
    resetPassword,
    getListJoinedToGroup,
    getListAvailableForGroup,
    joinToGroup,
    unjoinToGroup,
    getMfaSetupData,
    resetMfaSetupData,
    confirmMfaCode,
    isMfaSet,
    getStudentToken,
    getUserInfo
};

export default UserService;