import baseService from "../Service/BaseService";

const getIPRestrition = () =>
{
    return new Promise((resolve, reject) =>
    {
        baseService
            .get('/Setting/get-ip-restriction', {})
            .then((response) =>
            {
                resolve(response);
            })
            .catch((error) => { reject(error) });
    });
};

const toggleIPRestriction = (restrictionDTO) =>
{
    return new Promise((resolve, reject) =>
    {
        baseService
            .put(
                '/Setting/toggle-ip-restriction', restrictionDTO, {})
            .then((response) =>
            {
                resolve(response);
            })
            .catch((error) => reject(error));
    });
};

const getMfaSetting = () =>
{
    return new Promise((resolve, reject) =>
    {
        baseService
            .get('/Setting/get-mfa-settings', {})
            .then((response) =>
            {
                resolve(response);
            })
            .catch((error) => { reject(error) });
    });
};

const saveMfaSetting = (mfaDTO) =>
{
    return new Promise((resolve, reject) =>
    {
        baseService
            .put(
                '/Setting/save-mfa-setting', mfaDTO, {})
            .then((response) =>
            {
                resolve(response);
            })
            .catch((error) => reject(error));
    });
};

const createSetting = (settingDTO) =>
{
    return new Promise((resolve, reject) =>
    {
        baseService
            .put(
                '/Setting/create', settingDTO, {})
            .then((response) =>
            {
                resolve(response);
            })
            .catch((error) => reject(error));
    });
};

const getObservationRemoteVisibleTypeSetting = () =>
{
    return new Promise((resolve, reject) =>
    {
        baseService
            .get('/Setting/get-observation-remote-visible-type-setting', {})
            .then((response) =>
            {
                resolve(response);
            })
            .catch((error) => { reject(error) });
    });
};

const getObservationRemoteVisibleType = () =>
{
    return new Promise((resolve, reject) =>
    {
        baseService
            .get('/Setting/get-observation-remote-visible-type', {})
            .then((response) =>
            {
                resolve(response);
            })
            .catch((error) => { reject(error) });
    });
};

const saveObservationRemoteVisibleTypeSetting = (dto) =>
{
    return new Promise((resolve, reject) =>
    {
        baseService
            .put(
                '/Setting/save-observation-remote-visible-type', dto, {})
            .then((response) =>
            {
                resolve(response);
            })
            .catch((error) => reject(error));
    });
};

const SettingSerivce = {
    getIPRestrition,
    toggleIPRestriction,
    getMfaSetting,
    saveMfaSetting,
    createSetting,
    getObservationRemoteVisibleType,
    getObservationRemoteVisibleTypeSetting,
    saveObservationRemoteVisibleTypeSetting
};

export default SettingSerivce;