import BaseService from "../Service/BaseService";

// Get the list of schools for the logged in user
const getUserSchoolList = () =>
{
    return new Promise((resolve, reject) =>
    {
        BaseService
            .get('/UserSchool/list-for-user', {})
            .then((response) =>
            {
                resolve(response);
            })
            .catch((error) => reject(error));
    });
};

// Delete a user
const deleteUserSchool = (userSchoolID) =>
{
    return new Promise((resolve, reject) =>
    {
        BaseService.delete(`/UserSchool/${userSchoolID}`, {})
            .then((response) =>
            {
                resolve(response);
            })
            .catch((error) => reject(error));
    });
};

// Get the user school information
const getUserSchool = (id) =>
{
    return new Promise((resolve, reject) =>
    {
        BaseService
            .get(`/UserSchool/${id}`, {})
            .then((response) =>
            {
                resolve(response);
            })
            .catch((error) => reject(error));
    });
};

const UserSchoolService = {
    getUserSchoolList,
    deleteUserSchool,
    getUserSchool
};

export default UserSchoolService;