import './Login.scss';
import { useNavigate } from "react-router-dom";
import React, { useState, useEffect } from 'react';
import Select from 'react-select';

import AuthService from "../../Service/AuthService";
import UserSchoolService from "../../Service/UserSchoolService";
import UserService from "../../Service/UserService";
import SchoolService from "../../Service/SchoolService";
import MessageConverter from "../MessageConverter";
import MessageHandler from "../MessageHandler";

function SelectSchool()
{
    const navigate = useNavigate();
    const [userSchoolList, setUserSchoolList] = useState([]);
    const [selectedSchool, setSelectedSchool] = useState(0);
    const [msg, setMsg] = useState([]);

    const newMessageHandler = (enteredMsg) =>
    {
        setMsg(enteredMsg);
    }

    const setMessageHandler = (msg) =>
    {
        newMessageHandler(msg);
    }

    const handleSubmit = async (e) => 
    {
        e.preventDefault();
        setMessageHandler();
        if (selectedSchool !== 0)
        {
            AuthService.setPermissions(null);
            UserService.setSchool(selectedSchool.schoolID)
                .then((res) =>
                {
                    if (res?.status === 200)
                    {
                        AuthService.updateNewAccessToken(res.data);
                        AuthService.setSchoolID(selectedSchool.schoolID);
                        if (res.data.mfaSetupNeeded === true)
                        {
                            navigate("/TwoFA", { replace: true });
                        } else
                        {
                            if (res.data.mfaRedirectNeeded == true)
                            {
                                navigate("/login/TwoFA");
                            }
                            else
                            {
                                // set school name
                                SchoolService.getSchool(selectedSchool.schoolID).then((schoolRes) =>
                                {
                                    if (res?.status === 200)
                                    {
                                        AuthService.setCurrentSchoolname(schoolRes.data.name);
                                        AuthService.getPermissions().then((res) =>
                                        {
                                            AuthService.setPermissions(res);
                                            navigate("/Home", { replace: true })
                                        });
                                    }
                                    else
                                        setMessageHandler(MessageConverter.convertBusinessMsg(schoolRes?.status));
                                });
                            };
                        };
                    }
                    else
                    {
                        setMessageHandler(MessageConverter.convertBusinessMsg(schoolRes?.status));
                    }
                })
                .catch(err => { setMessageHandler(MessageConverter.convertBusinessMsg(err)) });
        } else setMessageHandler(MessageConverter.convertBusinessMsg("Geen school geselecteerd", 1))
    };

    useEffect(() =>
    {
        UserSchoolService.getUserSchoolList().then((res) =>
        {
            setUserSchoolList(res.data);
        });
    }, []);

    return (
        <div id="login-head" className="login-bg">
            <div id="header">
                <div className="login-header-button-wrapper">
                    <a href="https://www.bazaltgroep.nl/kijk/" className="login-header-button floatLeft" target="_blank">
                        Meer informatie over KIJK!
                    </a>
                    <a href="https://licenties.bazalt.nl/DemoRequest.aspx?product=KIJK-VERDER" className="login-header-button floatRight" target="_blank">
                        Vraag een demo aan
                    </a>
                    <div className="clear"></div>
                </div>
            </div>

            <div id="content">
                <div>
                    <div className="login-container">
                        <form onSubmit={handleSubmit}>
                            <div className="login-header">
                                <div className="login-header-title">
                                    Observeren, registreren en stimuleren van ontwikkeling
                                </div>
                                <div className="clear"></div>
                            </div>
                            <div className="message-div">
                                <MessageHandler messages={msg} />
                            </div>
                            <div className="login-user">
                                <Select
                                    className="select-school"
                                    options={userSchoolList}
                                    getOptionLabel={(option) => option.schoolName}
                                    getOptionValue={(option) => option.schoolID}
                                    onChange={(gr) =>
                                    {
                                        setSelectedSchool(gr);
                                    }}
                                    value={userSchoolList.find((element) => { return element.schoolID == setSelectedSchool?.schoolID })}
                                    isSearchable={true}
                                    isMulti={false}
                                    placeholder="Selecteer een school"
                                    noOptionsMessage={() => 'Geen resultaten gevonden'}
                                />
                            </div>
                            <div className="login-buttons">
                                <div className="login-button">
                                    <input type="submit" value="Selecteer school" className="login-button-style" />
                                </div>
                                <div className="clear"></div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default SelectSchool;