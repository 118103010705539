import React, { useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';

import '../GeneralStyling.scss'
import JoinToSchoolService from "../../Service/JoinToSchoolService"

function UserJoinConfirm()
{
    const navigate = useNavigate();
    const location = useLocation()
    let urlElements = location.pathname.split('/')

    useEffect(() =>
    {
        if (urlElements[3] === ":userID")
        {
            navigate("/", { state: { errorMessage: "Koppelen mislukt. Ongeldige URL" } });
        } else
        {
            handleConfirm();
        }
    }, [])

    const handleConfirm = async () =>
    {
        try
        {
            await JoinToSchoolService.confirmJoinToSchool(
                parseInt(urlElements[3]),
                parseInt(urlElements[4]),
                urlElements[5],
            ).then(() =>
            {
                navigate("/", { state: { confirmedMessage: "Koppelen is gelukt" } });
            });
        } catch (err)
        {
            navigate("/", { state: { errorMessage: (err) } });
        }
    };
}

export default UserJoinConfirm;