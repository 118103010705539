import '../GeneralStyling.scss'
import MessageConverter from "../MessageConverter";
import UserService from "../../Service/UserService"
import UserSchoolService from "../../Service/UserSchoolService"
import ExternalSystemSettingsService from "../../Service/ExternalSystemSettingsService"
import MessageHandler from "../MessageHandler";

import React, { useState, useEffect, useCallback } from 'react';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { FilterMatchMode } from 'primereact/api';
import { Dropdown } from 'primereact/dropdown';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import { Dialog } from 'primereact/dialog';
import { BlockUI } from 'primereact/blockui';
import { Editor, EditorState, convertFromRaw, CompositeDecorator } from "draft-js";
import AuthService from '../../Service/AuthService';
import HelpPageService from '../../Service/HelpPageService';
import HelpItemDescription from '../HelpPage/HelpItemDescription';

function UserManagement()
{
    const navigate = useNavigate();

    const [blocked, setBlocked] = useState(false);
    const [visible, setVisible] = useState(false);
    const [visibleDelete, setVisibleDelete] = useState(false);
    const [permissions, setPermissions] = useState(null);
    const [editAllowed, setEditAllowed] = useState(false);
    const [loading, setLoading] = useState(true);

    const [fullname, setFullname] = useState("");
    const [userList, setUserList] = useState([]);
    const [selectedUser, setSelectedUser] = useState(null);

    const [refreshKey, setRefreshKey] = useState(0);
    const [filters, setFilters] = useState({
        'fullname': { value: null, matchMode: FilterMatchMode.CONTAINS },
        'email': { value: null, matchMode: FilterMatchMode.CONTAINS },
        'roleID': { value: null, matchMode: FilterMatchMode.EQUALS },
        'status': { value: null, matchMode: FilterMatchMode.EQUALS },
        'active': { value: null, matchMode: FilterMatchMode.EQUALS },
        'blocked': { value: null, matchMode: FilterMatchMode.EQUALS },
    });
    const roles = [
        'Estrategy admin', 'Bazalt admin', 'Beheerder', 'Directie/Intern Begeleider', 'Leraar', 'Adviseur', 'Manager', 'LicenseAdministration'
    ];
    const dropdownState = [
        'Ja', 'Nee'
    ];
    const [msg, setMsg] = useState([]);
    const [uwlrMsg, setUwlrMsg] = useState([]);

    const [helpItem, setHelpItem] = useState(null);
    const [helpVisible, setHelpVisible] = useState(false);
    const [helpButtonHidden, sethelpButtonHidden] = useState(true);
    const currentPathName = window.location.pathname;

    const newMessageHandler = (enteredMsg) =>
    {
        setMsg(enteredMsg);
    }

    const setMessageHandler = (msg) =>
    {
        newMessageHandler(msg);
    }

    const UwlrMessageHandler = (props) =>
    {
        if (props.msg !== undefined && props.msg.length !== 0)
        {
            return (
                <p className="uwlr-message">
                    {props.msg}
                </p>)

        } else return <></>
    }

    const rolesBodyTemplate = (rowData) =>
    {
        return <span className={`customer-badge status-${rowData.roleID}`}>{rowData.roleID}</span>;
    }
    const rolesItemTemplate = (option) =>
    {
        return <span className={`customer-badge status-${option}`}>{option}</span>;
    }
    const rolesRowFilterTemplate = (options) =>
    {
        return <Dropdown value={options.value} options={roles}
            onChange={(e) => options.filterApplyCallback(e.value)}
            itemTemplate={rolesItemTemplate} placeholder="Selecteer een rol"
            className="element" showClear />;
    }

    const activeBodyTemplate = (rowData) =>
    {
        return <span className={`customer-badge active-${rowData.active}`}>{rowData.active}</span>;
    }
    const activeItemTemplate = (option) =>
    {
        return <span className={`customer-badge active-${option}`}>{option}</span>;

    }
    const activeRowFilterTemplate = (option) =>
    {
        return <Dropdown value={option.value} options={dropdownState}
            onChange={(e) => option.filterApplyCallback(e.value)}
            itemTemplate={activeItemTemplate} placeholder="Actief"
            className="element" showClear />;
    }

    const blockedBodyTemplate = (rowData) =>
    {
        return <span className={`customer-badge active-${rowData.blocked}`}>{rowData.blocked}</span>;

    }
    const blockedItemTemplate = (option) =>
    {
        return <span className={`customer-badge active-${option}`}>{option}</span>;

    }
    const blockedRowFilterTemplate = (option) =>
    {
        return <Dropdown value={option.value} options={dropdownState}
            onChange={(e) => option.filterApplyCallback(e.value)}
            itemTemplate={blockedItemTemplate} placeholder="Geblokkeerd"
            className="element" showClear />;
    }

    useEffect(() =>
    {
        AuthService.getPermissions().then((res) =>
        {
            setPermissions(res);
        })
    }, []);

    useEffect(() =>
    {
        if (permissions != null)
            setEditAllowed(permissions.find((p) => p.name === 'ADMIN_USER_EDIT') != undefined);
    }, [permissions]);

    // Preload helpItems
    useEffect(() => {
        if (helpItem == null) {
            HelpPageService.getHelpItembyPage(currentPathName).then((res) => {
                if (res.data != undefined && res.data != null && res.data != '') {
                    setHelpItem(res.data);
                    sethelpButtonHidden(false);
                }
                else {
                    sethelpButtonHidden(true);
                }
            }).catch(err => {
                setMessageHandler(MessageConverter.convertBusinessMsg(err));
            });
        }
    }, []);

    // Edit user
    const editUser = useCallback(() =>
    {
        setMessageHandler();
        setUwlrMsg();
        if (!editAllowed)
        {
            setMessageHandler(MessageConverter.convertBusinessMsg("Geen rechten", 1))
            return;
        }
        if (selectedUser !== null)
        {
            try
            {
                setBlocked(true);
                UserService.getUser(selectedUser.userID).then((res) =>
                {
                    if (res.data.eckID !== null)
                    {
                        ExternalSystemSettingsService.getCurrentESS().then((res) =>
                        {
                            if (res.status == 200 && res.data.active == true)
                            {
                                setUwlrMsg("Bewerken is niet toegestaan. Reden hiervoor is dat deze informatie wordt overgenomen middels de UWLR koppeling die jullie hebben ingesteld. Om de data aan te passen, zul je dit in jullie administratie systeem moeten doen. Dit wordt dan aanstaande nacht automatisch verwerkt.");
                            } else
                            {
                                navigate("/GebruikerItem", { state: { userSchoolID: selectedUser.userSchoolID } });
                            }
                            setBlocked(false);                            
                        }).catch(err => { setMessageHandler(MessageConverter.convertBusinessMsg(err)); });
                    } else
                    {
                        setBlocked(true);
                        navigate("/GebruikerItem", { state: { userSchoolID: selectedUser.userSchoolID } });
                        setBlocked(false);
                    }
                })

            } catch (err)
            {
                setMessageHandler(MessageConverter.convertBusinessMsg(err));
            }
        }
        else
        {
            setMessageHandler(MessageConverter.convertBusinessMsg("Geen gebruiker geselecteerd", 1));
        }
    }, [selectedUser]);

    // Add user
    const addUser = useCallback(() =>
    {
        setMessageHandler();
        setUwlrMsg();
        if (!editAllowed)
        {
            setMessageHandler(MessageConverter.convertBusinessMsg("Geen rechten", 1))
            return;
        }
        setBlocked(true);
        navigate("/GebruikerItem");
        setBlocked(false);
    }, [editAllowed]);

    // Delete user
    const deleteUser = useCallback(() =>
    {
        setBlocked(true);
        UserSchoolService.deleteUserSchool(selectedUser.userSchoolID).then(() =>
        {
            setRefreshKey(oldKey => oldKey + 1);
            setMessageHandler(MessageConverter.convertBusinessMsg("Gebruiker is succesvol verwijderd", 0));
            setVisibleDelete(false);
            setBlocked(false);
        }).catch(err =>
        {
            setMessageHandler(MessageConverter.convertBusinessMsg(err));
            setBlocked(false);
            setVisibleDelete(false);
        });
    }, [selectedUser]);

    const onDeleteButton = useCallback(() =>
    {
        setMessageHandler();
        setUwlrMsg();
        if (selectedUser == null)
        {
            setMessageHandler(MessageConverter.convertBusinessMsg("Geen gebruiker geselecteerd"));
        }
        else
        {
            setVisibleDelete(true);
        }
    })

    useEffect(() =>
    {
        UserService.getUserList().then((res) =>
        {
            setUserList(res.data);
            setLoading(false);
            res.data.map(content =>
            {
                if (content.roleID == 1)
                { return content.roleID = "Estrategy Admin" }
                if (content.roleID == 2)
                { return content.roleID = "Bazalt Admin" }
                if (content.roleID == 3)
                { return content.roleID = "Beheerder" }
                if (content.roleID == 4)
                { return content.roleID = "Directie / Intern Begeleider" }
                if (content.roleID == 5)
                { return content.roleID = "Leraar" }
                if (content.roleID == 6)
                { return content.roleID = "Adviseur" }
                if (content.roleID == 7)
                { return content.roleID = "Manager" }
                if (content.roleID == 8)
                { return content.roleID = "LicenseAdministration" }
            });

            res.data.map(activeResult =>
            {
                if (activeResult.active == true)
                { return activeResult.active = "Ja" }
                if (activeResult.active == false)
                { return activeResult.active = "Nee" }
            })

            res.data.map(blockedResult =>
            {
                if (blockedResult.blocked == true)
                { return blockedResult.blocked = "Ja" }
                if (blockedResult.blocked == false)
                { return blockedResult.blocked = "Nee" }
            })

            res.data.map(twoFAResult =>
            {
                if (twoFAResult.twoFASet == true)
                { return twoFAResult.twoFASet = "Ja" }
                if (twoFAResult.twoFASet == false)
                { return twoFAResult.twoFASet = "Nee" }
            })

        });
    }, [refreshKey]);

    // General message
    const { state } = useLocation();
    useEffect(() =>
    {
        if (state !== null)
        {
            const { confirmedMessage } = state;
            setMessageHandler(MessageConverter.convertBusinessMsg(confirmedMessage, 0));
        }
    }, []);

    // Prevent going back
    useEffect(() =>
    {
        window.addEventListener('popstate', (e) =>
        {
            window.history.go(1);
        });
    }, []);

    return (
        <div id="Page">
            <BlockUI blocked={blocked} fullScreen />
            <div id="MainContentContainer">
                <div className="page-information">
                    <div id="PageHeader" className="page-header">
                        <span>Gebruikers: Overzicht</span>
                    </div>
                    <div id="InfoButton">
                        <div className="btn-info" onClick={() => setHelpVisible(true)} hidden={helpButtonHidden}></div>
                    </div>
                    <Dialog
                        header={"Gebruikers: Overzicht"}
                        visible={helpVisible}
                        modal={true}
                        onHide={() => setHelpVisible(false)}
                        style={{ width: '75vw' }}
                    >
                        <Editor className="textfield" editorState={HelpItemDescription(helpItem)} readOnly="true" />
                    </Dialog>
                    <div className="clear"></div>
                </div>
                <div>
                    <div className="maincontent">
                        <div className="message-div">
                            <MessageHandler messages={msg} />
                        </div>
                        <div className="message-div">
                            <UwlrMessageHandler msg={uwlrMsg} />
                        </div>
                        <div className="card">
                            <DataTable
                                value={userList}
                                size="small"
                                paginator={true}
                                paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                                currentPageReportTemplate="{first} - {last} van {totalRecords}"
                                rows={10}
                                rowsPerPageOptions={[10, 20, 50, 100]}
                                dataKey="userSchoolID"
                                filters={filters} filterDisplay="row"
                                loading={loading}
                                responsiveLayout="scroll"
                                selectionMode="multiple"
                                selection={selectedUser}
                                onSelectionChange={e => { setSelectedUser(e.value[0]); setFullname(e.value[0]?.fullname) }}
                                emptyMessage="Geen resultaten gevonden"
                                stateKey="UserManagement-Overview">
                                <Column field="fullname"
                                    header="Gebruiker"
                                    filter={true}
                                    filterPlaceholder="Zoek op naam"
                                    sortable={true}
                                    showFilterMenu={false}
                                    style={{ minWidth: '12rem' }}>
                                </Column>
                                <Column field="email"
                                    header="Email"
                                    filter={true}
                                    sortable={true}
                                    filterPlaceholder="Zoek op email"
                                    showFilterMenu={false}
                                    style={{ minWidth: '12rem' }}>
                                </Column>
                                <Column field="roleID"
                                    header="Rol"
                                    body={rolesBodyTemplate}
                                    sortable={true}
                                    filter={true}
                                    showFilterMenu={false}
                                    filterElement={rolesRowFilterTemplate}>
                                </Column>
                                <Column field="active"
                                    header="Actief"
                                    body={activeBodyTemplate}
                                    filter={true}
                                    sortable={true}
                                    showFilterMenu={false}
                                    filterElement={activeRowFilterTemplate}>
                                </Column>
                                <Column field="blocked"
                                    header="Geblokkeerd"
                                    body={blockedBodyTemplate}
                                    sortable={true}
                                    filter={true}
                                    showFilterMenu={false}
                                    filterElement={blockedRowFilterTemplate}>
                                </Column>
                                <Column field="twoFASet"
                                    header="2FA" >
                                </Column>
                            </DataTable>
                            <div className="clear"></div>
                        </div>
                    </div>
                    <div className="buttons" >
                        {editAllowed ? <input type="submit" value="Toevoegen" className="management-buttons" onClick={addUser} visible={editAllowed} /> : ""}
                        {editAllowed ? <input type="submit" value="Bewerken" className="management-buttons" onClick={editUser} /> : ""}
                        <input type="submit" value="Verwijderen" className="management-buttons" onClick={onDeleteButton} />
                        {editAllowed ? <Link to="/GebruikerKoppelen">
                            <input type="submit" value="Gebruiker Koppelen" className="management-buttons" />
                        </Link> : ""}
                        {editAllowed ? <Link to="/GebruikerKoppelen/Overzicht">
                            <input type="submit" value="Koppel verzoeken" className="management-buttons" />
                        </Link> : ""}
                    </div>
                    <Dialog
                        className="delete-dialog"
                        header={"Weet je zeker dat je " + fullname + " wilt verwijderen?"}
                        visible={visibleDelete}
                        onHide={() => setVisibleDelete(false)}
                        draggable={false}
                    >
                        <input type="submit" value="Ja" className="delete-button" onClick={deleteUser} />
                        <input type="submit" value="Nee" className="cancel-delete-button" onClick={() => setVisibleDelete(false)} />
                    </Dialog>
                </div>
            </div>
        </div>
    );
}

export default UserManagement;