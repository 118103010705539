import CommunicationScreenService from "../../Service/CommunicationScreenService";
import '../GeneralStyling.scss';
import './CommunicationScreen.scss'
import MessageConverter from "../MessageConverter";
import MessageHandler from "../MessageHandler";

import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import Select from 'react-select';
import { Editor } from 'react-draft-wysiwyg';
import { BlockUI } from 'primereact/blockui';

function Overview()
{
	const navigate = useNavigate();
	const { state } = useLocation();
	const [title, setTitle] = useState("");
	const [startDate, setStartDate] = useState("");
	const [endDate, setEndDate] = useState("");
	const [list, setList] = useState({});
	const [contentState, setContentState] = useState(state == null ? "" : JSON.parse(state.message.content))
	const [blocked, setBlocked] = useState(false);

	const type = [
		{
			id: "1",
			label: "Melding",
			value: 1
		},
		{
			id: "2",
			label: "Tips & Weetjes",
			value: 2
		},
	]
	const [msg, setMsg] = useState([]);

	const newMessageHandler = (enteredMsg) =>
	{
		setMsg(enteredMsg);
	}

	const setMessageHandler = (msg) =>
	{
		newMessageHandler(msg);
	}

	const onContentStateChange = (contentState) =>
	{
		setContentState(contentState);
		setList({ ...list, content: JSON.stringify(contentState) });
	};

	useEffect(() =>
	{
		if (state !== null)
		{
			const message = state.message;
			CommunicationScreenService.getCS(message.id).then((res) => {
				setList(res.data);
			})

			setTitle("Bewerken");
		} else if (state === null)
		{
			setTitle("Toevoegen");
		}
	}, []);

	// Convert dates
	useEffect(() =>
	{
		var startDate = list.startDate;
		var endDate = list.endDate;
		if (startDate !== undefined)
		{
			var convertDate = moment(startDate).format('YYYY-MM-DD');
			setStartDate(convertDate);
		}
		if (endDate !== undefined)
		{
			var convertDate = moment(endDate).format('YYYY-MM-DD');
			setEndDate(convertDate);
		}
	})

	const saveMessage = async (e) =>
	{
		e.preventDefault();
		setMessageHandler();
		if (list.active !== undefined)
		{
			if (list.content !== undefined)
			{ 
				setBlocked(true);
				try
				{
					if (state !== null)
					{
						await CommunicationScreenService.updateCSItem(
							list
						).then(() =>
						{
							navigate("/CommunicationScreen", { state: { confirmedMessage: "Bericht is succesvol opgeslagen" } });
							setBlocked(false);
						});
					} else if (state === null)
					{
						await CommunicationScreenService.createCSItem(
							list
						).then(() =>
						{
							navigate("/CommunicationScreen", { state: { confirmedMessage: "Bericht is succesvol toegevoegd" } });
							setBlocked(false);
						});
					}
				}
				catch (err)
				{
					setMessageHandler(MessageConverter.convertBusinessMsg(err))
					setBlocked(false);
				}
			} else setMessageHandler(MessageConverter.convertBusinessMsg("Geen inhoud ingevuld", 1))
		} else setMessageHandler(MessageConverter.convertBusinessMsg("Actief is niet ingevuld", 1))
	}

    return (
		<div id="Page">
			<BlockUI blocked={blocked} fullScreen />
            <div id="MainContentContainer">
                <div className="page-information">
                    <div id="PageHeader" className="page-header">
						<span>Communicatiescherm: {title} </span>
                    </div>
                    <div className="clear"></div>
                </div>
                <div>
					<div className="maincontent">
						<div className="message-div">
							<MessageHandler messages={msg} />
						</div>
						<form autoComplete="off" onSubmit={saveMessage}>
							<div className="maincontent-item">
								<label htmlFor="Omschrijving">Omschrijving*</label>
								<input
									id="Omschrijving"
									type="text"
									required={true}
									onInvalid={e => e.target.setCustomValidity("Dit veld is verplicht")}
									onInput={e => e.target.setCustomValidity("")}
									title="Dit veld is verplicht"
									defaultValue={list.description}
									onChange={(e) => setList({ ...list, description: e.target.value })}
								/>
							</div>

							<div className="maincontent-item">
								<label htmlFor="startDate">Start</label>
								<input
									id="startDate"
									type="date"
									defaultValue={startDate}
									max={endDate}
									onChange={(e) => setList({ ...list, startDate: e.target.value })} />
							</div>

							<div className="maincontent-item">
								<label htmlFor="endDate">Eind</label>
								<input
									id="endDate"
									type="date"
									defaultValue={endDate}
									min={startDate}
									onChange={(e) => setList({ ...list, endDate: e.target.value })} />
							</div>

							<div className="maincontent-item">
								<label>Type*</label>
								<Select
									options={type}
									required={true}
									getOptionLabel={(option) => option.label}
									getOptionValue={(option) => option.value}
									placeholder="Selecteer een type"
									value={type.find((element) => { return element.value == list.type })}
									onChange={(selectedRole) => setList({ ...list, type: selectedRole.value })}
									isSearchable={true}
									isMulti={false}
									noOptionsMessage={() => 'Geen resultaten gevonden'}
								/>
							</div>

							<div className="maincontent-item">
								<label>Actief*</label>
								<div className="radio-buttons">
									<input
										type="radio"
										id="ja"
										name="active"
										checked={list.active === true}
										onChange={() => setList({ ...list, active: true })} />
									<label htmlFor="ja" className="communication-radiobutton">Ja</label>

									<input
										type="radio"
										id="nee"
										name="active"
										checked={list.active === false}
										onChange={() => setList({ ...list, active: false })} />
									<label htmlFor="nee">Nee</label>
								</div>
							</div>

							<div className="maincontent-item">
								<label>Inhoud*</label>
							</div>
							<div className="maincontent-item">
								<Editor
									toolbar={
										{
											options: ['inline', 'blockType', 'list', 'link', 'history','image'],
											inline: {
												inDropdown: false,
												className: undefined,
												component: undefined,
												dropdownClassName: undefined,
												options: ['bold', 'italic', 'underline', 'strikethrough', 'monospace', 'superscript', 'subscript'],
											},
											blockType: {
												inDropdown: true,
												options: ['Normal', 'H1', 'H2', 'H3', 'H4', 'H5', 'H6'],
												className: undefined,
												component: undefined,
												dropdownClassName: undefined,
											},
											list: {
												inDropdown: false,
												className: undefined,
												component: undefined,
												dropdownClassName: undefined,
												options: ['unordered', 'ordered', 'indent', 'outdent'],
											},
											link: {
												inDropdown: false,
												className: undefined,
												component: undefined,
												popupClassName: undefined,
												dropdownClassName: undefined,
												showOpenOptionOnHover: true,
												defaultTargetOption: '_self',
												options: ['link', 'unlink'],
												linkCallback: undefined
											},
											image: {
												className: undefined,
												component: undefined,
												popupClassName: undefined,
												urlEnabled: true,
												uploadEnabled: true,
												alignmentEnabled: true,
												uploadcallback: undefined,
												previewImage: false,
												inputaccept: 'image/gif,image/jpeg,image/jpg,image/png,image/svg',
												alt: { present: false, mandatory: false },
												defaultsize: {
													height: 'auto',
													width: 'auto',
												},
											history: {
												inDropdown: false,
												className: undefined,
												component: undefined,
												dropdownClassName: undefined,
												options: ['undo', 'redo'],
											}
											}
										}
									}
									wrapperClassName="demo-wrapper"
									editorClassName="demo-editor"
									onContentStateChange={onContentStateChange}
									initialContentState={contentState}
								/>
							</div>
							<div className="maincontent-item-buttons">
								<input
									type="submit"
									value="Opslaan"
									className="user-add-buttons" />
								<Link to="/CommunicationScreen">
									<input type="submit" value="Annuleren" className="user-add-buttons" />
								</Link>
							</div>
						</form>
					</div>
                </div>
            </div>
        </div>
    );
}

export default Overview;