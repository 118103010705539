import baseService from "./BaseService";

const getHelpItems = () => {
    return new Promise((resolve, reject) => {
        baseService
            .get('Helppage/list', {})
            .then((response) => {
                resolve(response);
            })
            .catch((error) => reject(error));
    });
};

const getHelpItem = (id) => {
    return new Promise((resolve, reject) => {
        baseService
            .get(`/Helppage/${id}`, {})
            .then((response) => {
                resolve(response);
            })
            .catch((error) => reject(error));
    });
};

const deleteHelpItem = (id) => {
    return new Promise((resolve, reject) => {
        baseService.delete(`/HelpPage/${id}`, {})
            .then((response) => {
                resolve(response);
            })
            .catch((error) => reject(error));
    });
};

const createHelpItem = (itemDTO) => {
    return new Promise((resolve, reject) => {
        baseService
            .put(
                '/Helppage/create', itemDTO, {})
            .then((response) => {
                resolve(response);
            })
            .catch((error) => reject(error));
    });
};

const updateHelpItem = (itemDTO) => {
    return new Promise((resolve, reject) => {
        baseService
            .put(
                '/Helppage/update', itemDTO, {})
            .then((response) => {
                resolve(response);
            })
            .catch((error) => reject(error));
    });
};

const UploadHelpItemImage = (itemDTO) => {
    return new Promise((resolve, reject) => {
        baseService
            .put(
                '/Helppage/upload-image', itemDTO, {})
            .then((response) => {
                resolve(response);
            })
            .catch((error) => reject(error));
    });
};

const getHelpItembyPage = (helppageUrl) => {
    return new Promise((resolve, reject) => {
        baseService
            .get(`/Helppage/get-by-page?helppageUrl=${helppageUrl}`, {})
            .then((response) => {
                resolve(response);
            })
            .catch((error) => reject(error));
    });
};

const getHelpImages = () => {
    return new Promise((resolve, reject) => {
        baseService
            .get('Helppage/get-helpimages', {})
            .then((response) => {
                resolve(response);
            })
            .catch((error) => reject(error));
    });
};

const deleteImage = (imageName) => {
    return new Promise((resolve, reject) => {
        baseService
            .get(`/Helppage/delete-image?imageName=${imageName}`, {})
            .then((response) => {
                resolve(response);
            })
            .catch((error) => reject(error));
    });
};

const HelpPageService = {
    getHelpItems,
    getHelpItem,
    deleteHelpItem,
    createHelpItem,
    updateHelpItem,
    UploadHelpItemImage,
    getHelpItembyPage,
    getHelpImages,
    deleteImage
    };

export default HelpPageService