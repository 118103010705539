import '../GeneralStyling.scss'
import MessageConverter from "../MessageConverter";
import SchoolService from '../../Service/SchoolService'
import ExternalSystemSettingsService from "../../Service/ExternalSystemSettingsService"
import UploadHandler from '../UploadHandler'
import MessageHandler from "../MessageHandler";
import HelpPageService from '../../Service/HelpPageService';

import React, { useState, useEffect } from 'react';
import { BlockUI } from 'primereact/blockui';
import { Dialog } from 'primereact/dialog';
import { Editor, EditorState, convertFromRaw, CompositeDecorator } from "draft-js";
import HelpItemDescription from '../HelpPage/HelpItemDescription';

function EdexImport()
{
    const [fileDTO, setFileDTO] = useState({});
    const [hiddenButtons, setHiddenButtons] = useState(true);
    const [refreshKey, setRefreshKey] = useState(0);
    const [blocked, setBlocked] = useState(false);
    const [hiddenUWLR, setHiddenUWLR] = useState(false);
    const [msg, setMsg] = useState([]);
    const [uwlrMsg, setUwlrMsg] = useState([]);

    const [helpItem, setHelpItem] = useState(null);
    const [helpVisible, setHelpVisible] = useState(false);
    const [helpButtonHidden, sethelpButtonHidden] = useState(true);
    const currentPathName = window.location.pathname;

    const newMessageHandler = (enteredMsg) =>
    {
        setMsg(enteredMsg);
    }

    const setMessageHandler = (msg) =>
    {
        newMessageHandler(msg);
    }

    const UwlrMessageHandler = (props) =>
    {
        if (props.msg !== undefined && props.msg.length !== 0)
        {
            return (
                <p className="uwlr-message">
                    {props.msg}
                </p>)

        } else return <></>
    }

    const onSaveFile = (e) =>
    {
        setMessageHandler();
        setBlocked(true);
        e.preventDefault();
        try
        {
            SchoolService.uploadEdexML(fileDTO).then(() =>
            {
                setMessageHandler(MessageConverter.convertBusinessMsg("Edex bestand uploaden gelukt", 0));
                setFileDTO({});
                setHiddenButtons(true);
                setRefreshKey(oldKey => oldKey + 1);
                setBlocked(false);
            }).catch((err) => { setMessageHandler(MessageConverter.convertBusinessMsg(err)); setBlocked(false); });
        } catch (error)
        {
            setMessageHandler(MessageConverter.convertBusinessMsg(error));
            setBlocked(false);
        }
    }

    const handleFileChange = (e) =>
    {
        setBlocked(true);
        if (e.target.files)
        {
            UploadHandler.GetAttachmentDto(e.target.files[0]).then((res) =>
            {
                setFileDTO(res);
                setHiddenButtons(false);
                setBlocked(false);
            });
        }
    };

    // Prevent going back
    useEffect(() =>
    {
        window.addEventListener('popstate', (e) =>
        {
            window.history.go(1);
        });
    }, []);

    // Preload helpItems
    useEffect(() =>
    {
        if (helpItem == null)
        {
            HelpPageService.getHelpItembyPage(currentPathName).then((res) =>
            {
                if (res.data != undefined && res.data != null && res.data != '')
                {
                    setHelpItem(res.data);
                    sethelpButtonHidden(false);
                }
                else
                {
                    sethelpButtonHidden(true);
                }
            }).catch(err =>
            {
                setMessageHandler(MessageConverter.convertBusinessMsg(err));
            });
        }
    }, []);

    useEffect(() =>
    {
        ExternalSystemSettingsService.getCurrentESS().then((res) =>
        {
            if (res.status == 200 && res.data.active == true)
            {
                setUwlrMsg("Edex importeren is niet toegestaan. Reden hiervoor is dat deze informatie wordt overgenomen middels de UWLR koppeling die jullie hebben ingesteld. Om de data toe te voegen, zul je dit in jullie administratie systeem moeten doen. Dit wordt dan aanstaande nacht automatisch verwerkt.");
                setHiddenUWLR(true);
            }
        }).catch((err) => { /*do nothing; ess not exists*/ setBlocked(false); });
    }, []);

    return (
        <div id="Page">
            <BlockUI blocked={blocked} fullScreen />
            <div id="MainContentContainer">
                <div className="page-information">
                    <div id="PageHeader" className="page-header">
                        <span>Edex import</span>
                    </div>
                    <div id="InfoButton">
                        <div className="btn-info" onClick={() => setHelpVisible(true)} hidden={helpButtonHidden}></div>
                    </div>
                    <Dialog
                        header={"Edex import"}
                        visible={helpVisible}
                        modal={true}
                        onHide={() => setHelpVisible(false)}
                        style={{ width: '75vw' }}
                    >
                        <Editor className="textfield" editorState={HelpItemDescription(helpItem)} readOnly="true" />
                    </Dialog>
                    <div className="clear"></div>
                </div>
                <div>
                    <div className="maincontent">
                        <div className="message-div">
                            <UwlrMessageHandler msg={uwlrMsg} />
                        </div>
                        <div className="message-div">
                            <MessageHandler messages={msg} />
                        </div>
                        <p hidden={hiddenUWLR}>
                            Via deze pagina is het mogelijk om gegevens van kinderen, groepen en gebruikers te importeren in KIJK! Verder door middel van een Edex import.
                            De meeste Leerling Administratie Systemen (LAS) zoals ParnasSys en Esis ondersteunen het exporteren naar een EdeXML bestandsformaat.
                            Kies bij het exporteren in jouw LAS voor het XML-formaat (EdeXML). Dat Edex bestand kun je hieronder op deze pagina importeren KIJK! Verder.
                            <br /><br />
                            Leerkrachten worden alleen ingelezen als er in het bestand ook een e-mailadres is ingevuld.
                            <br /><br />
                            Let op dat bestaande gegevens in KIJK! Verder van kinderen, groepen en gebruikers alleen overschreven worden als de gegevens exact overeenkomen.
                            Bij kinderen gaat dat om de voor- en achternaam en de geboortedatum.
                            Voor groepen gaat dat om de naam en het groepsjaar, en voor gebruikers gaat dat om het e-mailadres.
                            Als de genoemde gegevens niet overeenkomen, zal er een duplicaat ontstaan. Om dit te voorkomen zorg je ervoor dat in je (LAS) de gegevens exact overeenkomt met de naamgeving in KIJK! Verder
                            <br /><br />
                        </p>
                        <div className="maincontent-item-vertical" hidden={hiddenUWLR}>
                            <label>Selecteer hieronder je bestand (.xml) door te klikken op de knop 'Bestand kiezen'</label>
                            <input
                                required={true}
                                key={refreshKey}
                                type="file"
                                title="Dit veld is verplicht"
                                accept="text/xml"
                                onChange={handleFileChange}
                            />
                        </div>
                        <div className="maincontent-item-buttons" hidden={hiddenButtons}>
                            <input
                                type="submit"
                                value="Opslaan"
                                className="user-add-buttons" onClick={onSaveFile} />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default EdexImport;