import React, { useState } from 'react';
import './GeneralStyle.scss'
import "bootstrap/dist/css/bootstrap.min.css";
import SideNav from '../Components/SideNav/SideNav'
import Footer from '../Components/Footer/Footer'
import Header from '../Components/Header/Header'
import Home from '../Components/Home/Home'
import MessageHandler from '../Components/MessageHandler';

function HomePage()
{
    const [msg, setMsg] = useState([]);

    const newMessageHandler = (enteredMsg) =>
    {
        setMsg(enteredMsg);
    }
    return (
        <div id="upd-main">
            <div className="main">
                <div className="main-wrapper">
                    <div id="mainLeft" className="main-left">
                        <SideNav/>
                    </div>
                    <div id="mainRight" className="main-right">
                        <Header />
                        <MessageHandler messages={msg} />
                        <Home onNewMessage={newMessageHandler} />
                    </div>
                    <div className="clear"></div>
                </div>
            </div>
            <div className="footer">
                <Footer />
            </div>
        </div>
    );
}


export default HomePage;