import '../GeneralStyling.scss'
import React, { useEffect, useState } from 'react';
import { TabView, TabPanel } from 'primereact/tabview';
import ReceivedChildren from "./ReceivedChildren"
import ChilderenToMigrate from "./ChilderenToMigrate"
import MigratedChildren from "./MigratedChildren"
import HelpPageService from '../../Service/HelpPageService';
import HelpItemDescription from '../HelpPage/HelpItemDescription';

import { Dialog } from 'primereact/dialog';
import { Editor, EditorState, convertFromRaw, CompositeDecorator } from "draft-js";

function Migration()
{
    const [helpItem, setHelpItem] = useState(null);
    const [helpVisible, setHelpVisible] = useState(false);
    const [helpButtonHidden, sethelpButtonHidden] = useState(true);
    const currentPathName = window.location.pathname;

    // Preload helpItems
    useEffect(() => {
        if (helpItem == null) {
            HelpPageService.getHelpItembyPage(currentPathName).then((res) => {
                if (res.data != undefined && res.data != null && res.data != '') {
                    setHelpItem(res.data);
                    sethelpButtonHidden(false);
                }
                else {
                    sethelpButtonHidden(true);
                }
            }).catch(err => {
                setMessageHandler(MessageConverter.convertBusinessMsg(err));
            });
        }
    }, []);

    // Prevent going back
    useEffect(() =>
    {
        window.addEventListener('popstate', (e) =>
        {
            window.history.go(1);
        });
    }, []);

    return (
        <div id="Page">
            <div id="MainContentContainer">
                <div className="page-information">
                    <div id="PageHeader" className="page-header">
                        <span>Verhuizing: Overzicht</span>
                    </div>
                    <div id="InfoButton">
                        <div className="btn-info" onClick={() => setHelpVisible(true)} hidden={helpButtonHidden}></div>
                    </div>
                    <Dialog
                        header={"Instellingen: Overzicht"}
                        visible={helpVisible}
                        modal={true}
                        onHide={() => setHelpVisible(false)}
                        style={{ width: '75vw' }}
                    >
                        <Editor className="textfield" editorState={HelpItemDescription(helpItem)} readOnly="true" />
                    </Dialog>
                    <div className="clear"></div>
                </div>
                <div>
                    <div className="maincontent">
                        <TabView>
                            <TabPanel header="Te ontvangen kinderen">
                                <ReceivedChildren />
                            </TabPanel>
                            <TabPanel header="Te verhuizen kinderen">
                                <ChilderenToMigrate />
                            </TabPanel>
                            <TabPanel header="Verhuisde kinderen">
                                <MigratedChildren />
                            </TabPanel>
                        </TabView>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Migration;