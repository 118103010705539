import SideNav, { NavItem, NavIcon, NavText } from '@trendmicro/react-sidenav';
import '@trendmicro/react-sidenav/dist/react-sidenav.css';
import { useNavigate } from 'react-router-dom';
import './SideNav.scss';
import React, { useState, useEffect } from 'react';
import AuthService from "../../Service/AuthService";

function MySideNav()
{
    const [permissions, setPermissions] = useState([]);
    const navigate = useNavigate();

    useEffect(() =>
    {
        AuthService.getPermissions().then((res) =>
        {
            setPermissions(res);
        });
    }, []);

    const permissionCheck = (permission) =>
    {
        var i;
        if (permissions === undefined || permissions == null)
            return true;
        // Loop through all the permissions
        for (i = 0; i < permissions.length; i++)
        {
            // If the permission is in the list return false (the navItem will not be hidden)
            if (permissions[i].name === permission)
            {
                return false;
            }
        }
        // If the permission is not in the list return true (the navItem will be hidden)
        return true;
    };


    return <SideNav
        onSelect={selected =>
        {
            navigate('/' + selected, { replace: true })
        }} className="navbar-menu">

        <SideNav.Toggle />
        <SideNav.Nav>

            <NavItem eventKey="Observation" hidden={permissionCheck('MENU_OBSERVATION')}>
                <NavIcon><img src="/img/menu_observations.png" className="navbar-icon" /></NavIcon>
                <NavText>Observatie</NavText>
                <NavItem eventKey="Observatie" hidden={permissionCheck('MENU_OBSERVATION_REGISTRATION')}>
                    <NavText>Observatie Invoer</NavText>
                </NavItem>
            </NavItem>
            <NavItem eventKey="Input" hidden={permissionCheck('MENU_REGISTRATION')}>
                <NavIcon><img src="/img/menu_input.png" className="navbar-icon" /></NavIcon>
                <NavText>Registratie</NavText>
                <NavItem eventKey="Registratie/Leraar" hidden={permissionCheck('MENU_REGISTRATION_SOCO_TEACHER')}>
                    <NavText>Registratie leraar</NavText>
                </NavItem>
                <NavItem eventKey="Registratie/Kind" hidden={permissionCheck('MENU_REGISTRATION_SOCO_STUDENT')}>
                    <NavText>Registratie kind</NavText>
                </NavItem>
                <NavItem eventKey="Registratie/Afsluiten" hidden={permissionCheck('MENU_REGISTRATION_SOCO_TEACHER')}>
                    <NavText>Registratie afsluiten</NavText>
                </NavItem>
            </NavItem>

            <NavItem eventKey="Report" hidden={permissionCheck('MENU_REPORT')}>
                <NavIcon><img src="/img/menu_report.png" className="navbar-icon" /></NavIcon>
                <NavText>Rapportage</NavText>
                <NavItem eventKey="Rapportage/SoCo/Individueel" hidden={permissionCheck('MENU_REPORT_SOCO_INDIVIDUAL')}>
                    <NavText>Individueel</NavText>
                </NavItem>
                <NavItem eventKey="Rapportage/SoCo/Groep" hidden={permissionCheck('MENU_REPORT_SOCO_GROUP')}>
                    <NavText>Groep</NavText>
                </NavItem>
                <NavItem eventKey="Rapportage/SoCo/Dwarsdoorsnede" hidden={permissionCheck('MENU_REPORT_SOCO_SCHOOL')}>
                    <NavText>Dwarsdoorsnede</NavText>
                </NavItem>
                .<NavItem eventKey="Rapportage/SoCo/Trend" hidden={permissionCheck('MENU_REPORT_SOCO_SCHOOL')}>
                    <NavText>Trend</NavText>
                </NavItem>
            </NavItem>

            <NavItem eventKey="Management" hidden={permissionCheck('MENU_ADMIN')}>
                <NavIcon><img src="/img/menu_admin.png" className="navbar-icon" /></NavIcon>
                <NavText>Beheer</NavText>
                <NavItem eventKey="Gebruikers" hidden={permissionCheck('MENU_ADMIN_USERS')}>
                    <NavText>Gebruikers</NavText>
                </NavItem>
                <NavItem eventKey="Kinderen" hidden={permissionCheck('MENU_ADMIN_STUDENTS')}>
                    <NavText>Kinderen</NavText>
                </NavItem>
                <NavItem eventKey="Groepen" hidden={permissionCheck('MENU_ADMIN_GROUPS')}>
                    <NavText>Groepen</NavText>
                </NavItem>
                <NavItem eventKey="EdexImport" hidden={permissionCheck('MENU_ADMIN_EDEX_IMPORT')}>
                    <NavText>Edex Import</NavText>
                </NavItem>
                <NavItem eventKey="KindArchief" hidden={permissionCheck('MENU_ADMIN_STUDENT_ARCHIVE')}>
                    <NavText>Kind Archief</NavText>
                </NavItem>
                <NavItem eventKey="VerhuisOverzicht" hidden={permissionCheck('MENU_ADMIN_STUDENT_MIGRATION')}>
                    <NavText>Kind Verhuisoverzicht</NavText>
                </NavItem>
                <NavItem eventKey="RegistratieBewerken" hidden={permissionCheck('MENU_ADMIN_SOCO_EDIT_REG')}>
                    <NavText>Registratie Bewerken</NavText>
                </NavItem>
                <NavItem eventKey="Instellingen" hidden={permissionCheck('MENU_ADMIN_SETTINGS')}>
                    <NavText>Instellingen</NavText>
                </NavItem>
                <NavItem eventKey="Info" hidden={permissionCheck('MENU_ADMIN_INFO')}>
                    <NavText>Gegevens</NavText>
                </NavItem>
                <NavItem eventKey="CommunicationScreen" hidden={permissionCheck('MENU_ADMIN_COMMUNICATIONSCREEN')}>
                    <NavText>Communicatiescherm</NavText>
                </NavItem>
                <NavItem eventKey="HelpBeheer" hidden={permissionCheck('MENU_ADMIN_HELPMANAGEMENT')}>
                    <NavText>Help</NavText>
                </NavItem>
                <NavItem eventKey="SchoolYear" hidden={permissionCheck('MENU_ADMIN_SCHOOLYEAR')}>
                    <NavText>Schooljaar</NavText>
                </NavItem>
                <NavItem eventKey="AdoptUser" hidden={permissionCheck('MENU_ADOPT_USER')}>
                    <NavText>Gebruiker overnemen</NavText>
                </NavItem>
            </NavItem>

            <NavItem eventKey="Documentation" hidden={permissionCheck('MENU_DOCUMENTATION')}>
                <NavIcon><img src="/img/menu_documentation.png" className="navbar-icon" /></NavIcon>
                <NavText>Documentatie</NavText>
                <NavItem eventKey="Documentatie/Overzicht" hidden={permissionCheck('MENU_DOCUMENTATION_VIEW')}>
                    <NavText>Werkmaterialen</NavText>
                </NavItem>
                <NavItem eventKey="Documentation/Bewerken" hidden={permissionCheck('MENU_DOCUMENTATION_EDIT')}>
                    <NavText>Werkmaterialen beheer</NavText>
                </NavItem>
            </NavItem>
        </SideNav.Nav>
    </SideNav>
}

export default MySideNav;
