import baseService from "../Service/BaseService";

const getCSList = () =>
{
    return new Promise((resolve, reject) =>
    {
        baseService
            .get('CommunicationScreen/list', {})
            .then((response) =>
            {
                resolve(response);
            })
            .catch((error) => reject(error));
    });
};

const getCSListUI = () =>
{
    return new Promise((resolve, reject) =>
    {
        baseService
            .get('CommunicationScreen/list-for-ui', {})
            .then((response) =>
            {
                resolve(response);
            })
            .catch((error) => reject(error));
    });
};

const getCS = (id) =>
{
    return new Promise((resolve, reject) =>
    {
        baseService
            .get(`/CommunicationScreen/${id}`, {})
            .then((response) =>
            {
                resolve(response);
            })
            .catch((error) => reject(error));
    });
};

const deleteCSItem = (id) =>
{
    return new Promise((resolve, reject) =>
    {
        baseService.delete(`/CommunicationScreen/${id}`, {})
            .then((response) =>
            {
                resolve(response);
            })
            .catch((error) => reject(error));
    });
};

const createCSItem = (itemDTO) =>
{
    return new Promise((resolve, reject) =>
    {
        baseService
            .put(
                '/CommunicationScreen/create', itemDTO, {})
            .then((response) =>
            {
                resolve(response);
            })
            .catch((error) => reject(error));
    });
};

const updateCSItem = (itemDTO) =>
{
    return new Promise((resolve, reject) =>
    {
        baseService
            .put(
                '/CommunicationScreen/update', itemDTO, {})
            .then((response) =>
            {
                resolve(response);
            })
            .catch((error) => reject(error));
    });
};

const getMaintenanceMessage = () =>
{
    return new Promise((resolve, reject) =>
    {
        baseService
            .get('/CommunicationScreen/maintenance-message', {})
            .then((response) =>
            {
                resolve(response);
            })
            .catch((error) => reject(error));
    });
};

const CommunicationScreenService = {
    getCSList,
    getCSListUI,
    getCS,
    deleteCSItem,
    createCSItem,
    updateCSItem,
    getMaintenanceMessage
};

export default CommunicationScreenService;