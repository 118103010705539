import React from 'react';
import '../GeneralStyle.scss'
import UserJoinConfirm from '../../Components/UserManagement/UserJoinConfirm'

function ConfirmJoinUser()
{
    return (
        <div id="wrapper">
            <div id="upd-main">
                <div className="main">
                    <div className="main-wrapper">
                        <div id="mainRight" className="main-right-full-width">
                            <UserJoinConfirm />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default ConfirmJoinUser;