import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { FilterMatchMode, FilterOperator } from 'primereact/api';
import { Calendar } from 'primereact/calendar';
import { InputText } from 'primereact/inputtext';
import React, { useState, useEffect, useCallback } from 'react';
import { Dialog } from 'primereact/dialog';
import { BlockUI } from 'primereact/blockui';
import moment from 'moment';
import { DateFilter } from "../DateFilter"

import StudentService from "../../Service/StudentService";
import MessageConverter from '../MessageConverter'
import './Archive.scss'
import MessageHandler from "../MessageHandler"

function BulkArchive()
{
    const [selectedStudents, setSelectedStudents] = useState([]);
    const [loading, setLoading] = useState(true);
    const [refreshKey, setRefreshKey] = useState(0);
    const [studentlist, setStudentlist] = useState([]);
    const [visibleArchive, setVisibleArchive] = useState(false);
    const [visibleDelete, setVisibleDelete] = useState(false);
    const [blocked, setBlocked] = useState(false);

    const [filters, setFilters] = useState({
        birthdate: { value: null, matchMode: FilterMatchMode.DATE_IS },
        fullname: { value: null, matchMode: FilterMatchMode.CONTAINS },
    });

    const [msg, setMsg] = useState([]);

    const newMessageHandler = (enteredMsg) =>
    {
        setMsg(enteredMsg);
    }

    const setMessageHandler = (msg) =>
    {
        newMessageHandler(msg);
    }

    // Omzetten van de data
    const getStudentList = (data) =>
    {
        return [...(data || [])].map((d) =>
        {
            d.birthdate = new Date(d.birthdate);

            return d;
        });
    };

    const datebody = (rowData) =>
    {
        return <>{moment(rowData.birthdate).format("DD-MM-YYYY")}</>;
    };

    const dateDeletebody = (rowData) =>
    {
        return <>{moment(rowData.dateRemoveData).format("DD-MM-YYYY")}</>;
    };

    const textEditor = (options) =>
    {
        return <InputText type="date" min={moment().format('YYYY-MM-DD')} value={moment(options.value).format("YYYY-MM-DD")} onChange={(e) => options.editorCallback(e.target.value)} />;
    };

    const onRowEditComplete = (e) =>
    {
        let _students = [...studentlist];
        let { newData, index } = e;
        _students[index] = newData;

        setStudentlist(_students);
    };

    useEffect(() =>
    {
        StudentService.getListToArchive().then((res) =>
        {
            setStudentlist(getStudentList(res.data));
            res.data.map(items =>
            {
                if (items.gender == 1)
                {
                    return items.gender = "Jongen"
                }
                if (items.gender == 2)
                {
                    return items.gender = "Meisje"
                }
            })
            setLoading(false);
        }).catch((err) => setMessageHandler(MessageConverter.convertBusinessMsg(err)));
    }, [refreshKey]);

    const onButtonArchive = useCallback((e) =>
    {
        e.preventDefault();
        setMessageHandler();
        if (selectedStudents.length !== 0)
        {
            setVisibleArchive(true);
        } else setMessageHandler(MessageConverter.convertBusinessMsg("Selecteer een kind", 1))
    }, [selectedStudents]);

    const archive = useCallback((e) =>
    {
        e.preventDefault();
        setBlocked(true);
        var i;
        for (i = 0; i < selectedStudents.length; i++)
        {
            try
            {
                StudentService.archiveStudent(selectedStudents[i].studentSchoolID, selectedStudents[i].dateRemoveData).then((res) =>
                {
                    setVisibleArchive(false);
                    setRefreshKey(oldKey => oldKey + 1);
                    setMessageHandler(MessageConverter.convertBusinessMsg("Kind(eren) succesvol gearchiveerd", 0));
                    setBlocked(false);
                }).catch((err) => { setMessageHandler(MessageConverter.convertBusinessMsg(err)); setBlocked(false); });
            } catch (error)
            {
                setMessageHandler(MessageConverter.convertBusinessMsg(error));
                setBlocked(false);
                setVisibleArchive(false);
            }
        }
    }, [selectedStudents]);

    const onButtonDelete = useCallback((e) =>
    {
        e.preventDefault();
        setMessageHandler();
        if (selectedStudents.length !== 0)
        {
            setVisibleDelete(true);
        } else setMessageHandler(MessageConverter.convertBusinessMsg("Selecteer een kind", 1))
    }, [selectedStudents]);

    const deleteStudents = useCallback((e) =>
    {
        e.preventDefault();
        setBlocked(true);
        var i;
        for (i = 0; i < selectedStudents.length; i++)
        {
            try
            {
                StudentService.deleteStudent(selectedStudents[i].id).then((res) =>
                {
                    setVisibleDelete(false);
                    setRefreshKey(oldKey => oldKey + 1);
                    setMessageHandler(MessageConverter.convertBusinessMsg("Kind(eren) succesvol verwijderd", 0));
                    setBlocked(false);
                }).catch((err) => { setMessageHandler(MessageConverter.convertBusinessMsg(err)); setBlocked(false); });
            } catch (error)
            {
                setMessageHandler(MessageConverter.convertBusinessMsg(error));
                setBlocked(false);
                setVisibleDelete(false);
            }
        }
    }, [selectedStudents]);

    return (
        <form autoComplete="off">
            <BlockUI blocked={blocked} fullScreen />
            <MessageHandler messages={msg} />
            <div className="maincontent-item">
                <div className="card">
                    <DataTable
                        size="small"
                        value={studentlist}
                        dataKey="id"
                        paginator={true}
                        paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                        currentPageReportTemplate="{first} - {last} van {totalRecords}"
                        rows={10}
                        loading={loading}
                        rowsPerPageOptions={[10, 20, 50, 100]}
                        responsiveLayout="scroll"
                        selectionMode={'rowClick'}
                        selection={selectedStudents}
                        onSelectionChange={(e) => setSelectedStudents(e.value)}
                        filters={filters}
                        filterDisplay="row"
                        editMode="row"
                        onRowEditComplete={onRowEditComplete}
                        emptyMessage="Geen resultaten gevonden"
                        stateKey="Archive-Bulk"                    >
                        <Column selectionMode="multiple" headerStyle={{ width: '3rem' }}></Column>
                        <Column field="fullname"
                            filter={true}
                            filterPlaceholder="Zoek op naam"
                            sortable={true}
                            showFilterMenu={false}
                            style={{ width: '16rem' }}
                            header="Naam" />
                        <Column header="Geboortedatum"
                            filterField="birthdate"
                            dataType="date"
                            showFilterMenu={false}
                            style={{ width: '10rem' }}
                            sortable={true}
                            field="birthdate"
                            body={datebody}
                            filter={true}
                            filterElement={DateFilter} />
                        <Column field="groupyear"
                            style={{ width: '5rem' }}
                            showFilterMenu={false}
                            sortable={true}
                            header="Groepjaar" />
                        <Column field="groupname"
                            style={{ width: '8rem' }}
                            sortable={true}
                            showFilterMenu={false}
                            header="Groep(naam)" />
                        <Column field="gender"
                            sortable={true}
                            style={{ width: '5rem' }}
                            showFilterMenu={false}
                            header="Geslacht" />
                        <Column field="dateRemoveData"
                            sortable={true}
                            header="Te verwijderen op"
                            style={{ width: '9rem' }}
                            showFilterMenu={false}
                            editor={(options) => textEditor(options)}
                            body={dateDeletebody} />
                        <Column
                            rowEditor
                            headerStyle={{ width: '10%', minWidth: '6rem' }}
                            bodyStyle={{ textAlign: 'center' }} />
                    </DataTable>
                    <Dialog
                        className="delete-dialog"
                        header={"Weet je zeker dat je deze kinderen wilt archiveren?"}
                        visible={visibleArchive}
                        onHide={() => setVisibleArchive(false)}
                        draggable={false}
                    >
                        <input type="submit" value="Ja" className="delete-button" onClick={archive} />
                        <input type="submit" value="Nee" className="cancel-delete-button" onClick={() => setVisibleArchive(false)} />
                    </Dialog>
                    <Dialog
                        className="delete-dialog"
                        header={"Weet je zeker dat je deze kinderen wilt verwijderen?"}
                        visible={visibleDelete}
                        onHide={() => setVisibleDelete(false)}
                        draggable={false}
                    >
                        <input type="submit" value="Ja" className="delete-button" onClick={deleteStudents} />
                        <input type="submit" value="Nee" className="cancel-delete-button" onClick={() => setVisibleDelete(false)} />
                    </Dialog>
                    <div className="clear"></div>
                </div>
            </div>
            <input type="submit" value="Archiveren" className="management-buttons" onClick={onButtonArchive} />
            <input type="submit" value="Verwijderen" className="management-buttons" onClick={onButtonDelete} />
        </form>
    );
}

export default BulkArchive;