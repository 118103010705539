import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { FilterMatchMode, FilterOperator } from 'primereact/api';
import React, { useState, useEffect, useCallback } from 'react';
import { Calendar } from 'primereact/calendar';
import { Dialog } from 'primereact/dialog';
import moment from 'moment';
import Select from 'react-select';
import { BlockUI } from 'primereact/blockui';
import { DateFilter } from "../DateFilter"

import StudentService from "../../Service/StudentService";
import GroupService from "../../Service/GroupService";
import MessageConverter from '../MessageConverter'
import MessageHandler from "../MessageHandler"

function BackFromArchive()
{
    const [studentlist, setStudentlist] = useState([]);
    const [selectedStudents, setSelectedStudents] = useState([]);
    const [groupList, setGroupList] = useState([]);
    const [selectedGroup, setSelectedGroup] = useState([]);

    const [blocked, setBlocked] = useState(false);

    const [refreshKey, setRefreshKey] = useState(0);
    const [visibleArchive, setVisibleArchive] = useState(false);
    const [loading, setLoading] = useState(true);
    const [defaultSelected, setDefaultSelected] = useState(null);

    const [filters, setFilters] = useState({
        birthdate: { value: null, matchMode: FilterMatchMode.DATE_IS },
        fullname: { value: null, matchMode: FilterMatchMode.CONTAINS },
    });

    const [msg, setMsg] = useState([]);

    const newMessageHandler = (enteredMsg) =>
    {
        setMsg(enteredMsg);
    }

    const setMessageHandler = (msg) =>
    {
        newMessageHandler(msg);
    }

    // Omzetten van de data
    const getArchivedList = (data) =>
    {
        return [...(data || [])].map((d) =>
        {
            d.birthdate = new Date(d.birthdate);

            return d;
        });
    };

    const datebody = (rowData) =>
    {
        return <>{moment(rowData.birthdate).format("DD-MM-YYYY")}</>;
    };

    const dateDeletebody = (rowData) =>
    {
        return <>{moment(rowData.dateRemoveData).format("DD-MM-YYYY")}</>;
    };

    useEffect(() =>
    {
        StudentService.getListArchived().then((res) =>
        {
            setStudentlist(getArchivedList(res.data));
            res.data.map(items =>
            {
                if (items.gender == 1)
                {
                    return items.gender = "Jongen"
                }
                if (items.gender == 2)
                {
                    return items.gender = "Meisje"
                }
            })
            setLoading(false);
        })
    }, [refreshKey])

    const onButtonRevertFromArchive = useCallback((e) =>
    {
        e.preventDefault();
        setMessageHandler();
        if (selectedStudents.length !== 0)
        {
            setBlocked(true);
            GroupService.getAllInCurrentYearGroup().then((res) =>
            {
                setGroupList(res.data)
                setVisibleArchive(true);
                setBlocked(false);
                if (res.data.length == 1)
                {
                    setDefaultSelected(res.data[0]);
                    setSelectedGroup(res.data[0]);
                }
            }).catch((err) => { setMessageHandler(MessageConverter.convertBusinessMsg(err)); setBlocked(false) });
        } else setMessageHandler(MessageConverter.convertBusinessMsg("Selecteer een kind", 1))
    }, [selectedStudents]);

    const revertFromArchive = useCallback((e) =>
    {
        e.preventDefault();
        if (selectedGroup !== null)
        {
            if (selectedGroup.length !== 0)
            {
                setBlocked(true);
                StudentService.revertStudentFromArchive(selectedStudents.studentSchoolID, selectedGroup.id).then((res) =>
                {
                    setRefreshKey(oldKey => oldKey + 1);
                    setVisibleArchive(false);
                    setSelectedGroup(null);
                    setMessageHandler(MessageConverter.convertBusinessMsg("Kind succesvol teruggehaald uit archief", 0));
                    setBlocked(false);
                }).catch((err) =>
                {
                    setMessageHandler(MessageConverter.convertBusinessMsg(err));
                    setBlocked(false);
                    setVisibleArchive(false);
                });
            } else setMessageHandler(MessageConverter.convertBusinessMsg("Selecteer een groep", 1))
        } else setMessageHandler(MessageConverter.convertBusinessMsg("Selecteer een groep", 1))
    }, [selectedGroup]);

    return (
        <form autoComplete="off">
            <BlockUI blocked={blocked} fullScreen />
            <MessageHandler messages={msg} />
            <div className="maincontent-item">
                <div className="card">
                    <DataTable
                        size="small"
                        value={studentlist}
                        dataKey="id"
                        paginator={true}
                        paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                        currentPageReportTemplate="{first} - {last} van {totalRecords}"
                        rows={10}
                        loading={loading}
                        rowsPerPageOptions={[10, 20, 50, 100]}
                        responsiveLayout="scroll"
                        selectionMode="single"
                        selection={selectedStudents}
                        onSelectionChange={(e) => setSelectedStudents(e.value)}
                        filters={filters}
                        filterDisplay="row"
                        emptyMessage="Geen resultaten gevonden"
                        stateKey="Archive-BackFromArchive">
                        <Column field="fullname"
                            filter={true}
                            filterPlaceholder="Zoek op naam"
                            style={{ width: '16rem' }}
                            sortable={true}
                            showFilterMenu={false}
                            header="Naam" />
                        <Column header="Geboortedatum"
                            filterField="birthdate"
                            dataType="date"
                            showFilterMenu={false}
                            style={{ width: '15rem' }}
                            field="birthdate"
                            body={datebody}
                            sortable={true}
                            filter={true}
                            filterElement={DateFilter} />
                        <Column field="gender"
                            style={{ width: '10rem' }}
                            showFilterMenu={false}
                            sortable={true}
                            header="Geslacht" >
                        </Column>
                        <Column field="dateRemoveData"
                            sortable={true}
                            header="Te verwijderen op"
                            style={{ width: '10rem' }}
                            body={dateDeletebody} >
                        </Column>
                    </DataTable>
                    <Dialog
                        className="delete-dialog"
                        header={"Selecteer een groep"}
                        visible={visibleArchive}
                        onHide={() => setVisibleArchive(false)}
                        draggable={false}
                    >
                        <div className="in-dialog">
                            <Select
                                className="select-back-from-archive"
                                options={groupList}
                                key={selectedGroup}
                                getOptionLabel={(option) => option.name}
                                getOptionValue={(option) => option.id}
                                onChange={(gr) =>
                                {
                                    setSelectedGroup(gr);
                                }}
                                value={groupList.find((element) => { return element.id == selectedGroup?.id })}
                                isSearchable={true}
                                isMulti={false}
                                placeholder="Selecteer een groep"
                                noOptionsMessage={() => 'Geen resultaten gevonden'}
                                defaultValue={defaultSelected}
                            />
                        </div>
                        <input type="submit" value="Opslaan" className="management-buttons" onClick={revertFromArchive} />
                    </Dialog>
                    <div className="clear"></div>
                </div>
            </div>
            <input type="submit" value="Terughalen uit archief" className="management-buttons" onClick={onButtonRevertFromArchive} />
        </form>
    );
}

export default BackFromArchive;