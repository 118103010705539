import React, { useState, useEffect } from "react";
import { Dialog } from 'primereact/dialog';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import Select from 'react-select';
import { BlockUI } from 'primereact/blockui';
import moment from 'moment'

import GroupService from '../../Service/GroupService';
import MessageConverter from "../MessageConverter";
import StudentService from '../../Service/StudentService';
import SchoolyearService from '../../Service/SchoolyearService';
import AuthService from '../../Service/AuthService';
import '../GeneralStyling.scss'
import MessageHandler from "../MessageHandler";
import ExternalSystemSettingsService from "../../Service/ExternalSystemSettingsService"

function StudentItem()
{
    const { state } = useLocation();
    const navigate = useNavigate();

    const [studentDto, setStudentDto] = useState({});
    const [studentSchoolDto, setStudentSchoolDto] = useState({});
    const [studentGroupDto, setStudentGroupDto] = useState({});

    const [groupList, setGroupList] = useState([]);
    const [formatDate, setFormatDate] = useState("");
    const [title, setTitle] = useState("");

    const [blocked, setBlocked] = useState(false);
    const [blockedUWLR, setBlockedUWLR] = useState(false);
    const [msg, setMsg] = useState([]);
    const [uwlrMsg, setUwlrMsg] = useState([]);
    const [defaultSelected, setDefaultSelected] = useState(null);

    const newMessageHandler = (enteredMsg) =>
    {
        setMsg(enteredMsg);
    }

    const setMessageHandler = (msg) =>
    {
        newMessageHandler(msg);
    }

    const UwlrMessageHandler = (props) =>
    {
        if (props.msg !== undefined && props.msg.length !== 0)
        {
            return (
                <p className="uwlr-message">
                    {props.msg}
                </p>)

        } else return <></>
    }

    const saveStudent = async (e) =>
    {
        e.preventDefault();
        setMessageHandler();
        setBlocked(true);
        try
        {
            if (state !== null)
            {
                await StudentService.updateStudent(
                    studentDto,
                    studentSchoolDto,
                    studentGroupDto
                ).then(() =>
                {
                    navigate("/Kinderen", { state: { confirmedMessage: "Kind is succesvol opgeslagen" } });
                    setBlocked(false);
                });
            } else if (state === null)
            {
                await StudentService.createStudent(
                    studentDto,
                    studentSchoolDto,
                    studentGroupDto
                ).then(() =>
                {
                    navigate("/Kinderen", { state: { confirmedMessage: "Kind is succesvol toegevoegd" } });
                    setBlocked(false);
                });
            }
        }
        catch (err)
        {
            setMessageHandler(MessageConverter.convertBusinessMsg(err));
            setBlocked(false);
        }
    }

    const setListWithGroups = (group, res) =>
    {
        SchoolyearService.getSchoolyearList().then((result) =>
        {
            const inList = result.data.find(e => e.id == group.schoolyearID)
            group.name = group.name + " (" + inList.name + ")"
        }).then(() =>
        {
            if (res !== null)
            {
                setGroupList(res.data);
            } else
            {
                setGroupList(groupList => [...groupList, group]);
            }
        });
    }

    useEffect(() =>
    {
        GroupService.getAllInCurrentYearGroup().then((res) =>
        {
            res.data.map((group) =>
            {
                setListWithGroups(group, res);
            })
            if (res.data.length == 1)
            {
                setDefaultSelected(res.data[0]);
                setStudentGroupDto({ ...studentGroupDto, groupID: res.data[0].id });
                setStudentDto({ ...studentDto, groupyearID: res.data[0].groupyearID });
            }
        })

        if (state !== null)
        {
            const student = state;
            StudentService.getStudentForEdit(student.studentSchoolID).then((res) =>
            {
                setStudentDto(res.data.studentDto);
                setStudentSchoolDto(res.data.studentSchoolDto);
                setStudentGroupDto(res.data.studentGroupDto);
                if (res.data.studentDto.eckID !== null)
                {
                    ExternalSystemSettingsService.getCurrentESS().then((res) =>
                    {
                        if (res.status == 200 && res.data.active == true)
                        {
                            setBlockedUWLR(true);
                            setUwlrMsg("Sommige velden zijn niet aan te passen. Reden hiervoor is dat deze informatie wordt overgenomen middels de UWLR koppeling die jullie hebben ingesteld. Om de data aan te passen, zul je dit in jullie administratie systeem moeten doen. Dit wordt dan aanstaande nacht automatisch verwerkt.");
                        }
                    }).catch(err => { setMessageHandler(MessageConverter.convertBusinessMsg(err)); });
                }
            })
            setTitle("Bewerken");
        } else if (state === null)
        {
            const schoolID = AuthService.getSchoolID();
            setStudentGroupDto({ ...studentGroupDto, type: 1 });
            setStudentSchoolDto({ ...studentSchoolDto, schoolID: schoolID });
            setTitle("Toevoegen");
        }
    }, []);

    useEffect(() =>
    {
        if (groupList !== null && groupList !== undefined && groupList.length !== 0)
        {
            if (studentGroupDto !== null && studentGroupDto !== undefined && studentGroupDto.studentID !== undefined)
            {
                const inList = groupList.find(e => e.id == studentGroupDto.groupID)

                if (inList == undefined)
                {
                    GroupService.getGroup(studentGroupDto.groupID).then((group) =>
                    {
                        setListWithGroups(group.data, null);
                    })
                }
            }
        }
    }, [groupList, studentGroupDto])

    // Format date
    useEffect(() =>
    {
        var date = studentDto.birthdate;
        if (date !== undefined && date !== null)
        {
            var convertDate = moment(date).format('YYYY-MM-DD');
            setFormatDate(convertDate);
        }
    })

    return (
        <div id="Page">
            <BlockUI blocked={blocked} fullScreen />
            <div id="MainContentContainer">
                <div className="page-information">
                    <div id="PageHeader" className="page-header">
                        <span>Kinderen: {title}</span>
                    </div>
                    <div>
                        <div id="InfoButton">
                            <a className="btn-info"></a>
                        </div>
                    </div>
                    <div className="clear"></div>
                </div>
                <div className="maincontent">
                    <div className="message-div">
                        <MessageHandler messages={msg} />
                    </div>
                    <div className="message-div">
                        <UwlrMessageHandler msg={uwlrMsg} />
                    </div>
                    <form onSubmit={saveStudent} autoComplete="off">
                        <div className="maincontent-item">
                            <label htmlFor="firstname">Voornaam*</label>
                            <input
                                disabled={blockedUWLR}
                                id="firstname"
                                type="text"
                                required={true}
                                onInvalid={e => e.target.setCustomValidity("Dit veld is verplicht")}
                                onInput={e => e.target.setCustomValidity("")}
                                defaultValue={studentDto.firstname}
                                onChange={(e) => setStudentDto({ ...studentDto, firstname: e.target.value })}
                                title="Dit veld is verplicht"
                            />
                        </div>

                        <div className="maincontent-item">
                            <label htmlFor="middlename">Tussenvoegsel</label>
                            <input
                                disabled={blockedUWLR}
                                id="middlename"
                                type="text"
                                defaultValue={studentDto.middlename}
                                onChange={(e) => setStudentDto({ ...studentDto, middlename: e.target.value })}
                            />
                        </div>

                        <div className="maincontent-item">
                            <label htmlFor="surname">Achternaam*</label>
                            <input
                                disabled={blockedUWLR}
                                id="surname"
                                type="text"
                                required={true}
                                title="Dit veld is verplicht"
                                onInvalid={e => e.target.setCustomValidity("Dit veld is verplicht")}
                                onInput={e => e.target.setCustomValidity("")}
                                defaultValue={studentDto.surname}
                                onChange={(e) => setStudentDto({ ...studentDto, surname: e.target.value })}
                            />
                        </div>

                        <div className="maincontent-item">
                            <label htmlFor="gender">Geslacht*</label>
                            <div className="radio-buttons">
                                <input
                                    disabled={blockedUWLR}
                                    type="radio"
                                    id="meisje"
                                    value={2}
                                    checked={studentDto.gender === 2}
                                    onChange={() => setStudentDto({ ...studentDto, gender: 2 })}
                                />
                                <label htmlFor="meisje">Meisje</label>

                                <input
                                    disabled={blockedUWLR}
                                    type="radio"
                                    id="jongen"
                                    value={1}
                                    checked={studentDto.gender === 1}
                                    onChange={() => setStudentDto({ ...studentDto, gender: 1 })}
                                />
                                <label htmlFor="jongen">Jongen</label>
                            </div>
                        </div>

                        <div className="maincontent-item">
                            <label htmlFor="birthdate">Geboortedatum*</label>
                            <input
                                disabled={blockedUWLR}
                                id="birthdate"
                                type="date"
                                required={true}
                                defaultValue={formatDate}
                                max={moment().format('YYYY-MM-DD')}
                                onChange={(e) => setStudentDto({ ...studentDto, birthdate: e.target.value })}
                            />
                        </div>

                        <div className="maincontent-item">
                            <label htmlFor="comment" className="student-item-comment">Opmerkingen</label>
                            <textarea
                                id="comment"
                                defaultValue={studentSchoolDto.comment}
                                onChange={(e) => setStudentSchoolDto({ ...studentSchoolDto, comment: e.target.value })}
                            />
                        </div>

                        <div className="maincontent-item">
                            <label htmlFor="group">Groep*</label>
                            <Select
                                isDisabled={blockedUWLR}
                                options={groupList}
                                required={true}
                                getOptionLabel={(option) => option.name}
                                getOptionValue={(option) => option.id}
                                onChange={(selectedGroup) =>
                                {
                                    setStudentGroupDto({ ...studentGroupDto, groupID: selectedGroup.id });
                                    setStudentDto({ ...studentDto, groupyearID: selectedGroup.groupyearID })
                                }}
                                value={groupList.find((element) => { return element.id == studentGroupDto.groupID })}
                                isSearchable={true}
                                isMulti={false}
                                id="group"
                                placeholder="Selecteer een groep"
                                noOptionsMessage={() => 'Geen resultaten gevonden'}
                                defaultValue={defaultSelected}
                            />
                        </div>

                        <div className="maincontent-item-buttons">
                            <input
                                type="submit"
                                value="Opslaan"
                                className="user-add-buttons" />
                            <Link to="/Kinderen">
                                <input type="submit" value="Annuleren" className="user-add-buttons" />
                            </Link>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    );
}

export default StudentItem;