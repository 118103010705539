import React, { useCallback } from "react";
import '../GeneralStyling.scss'
import MessageConverter from "../MessageConverter";
import SchoolService from "../../Service/SchoolService"
import UserService from "../../Service/UserService"
import AuthService from "../../Service/AuthService"
import MessageHandler from "../MessageHandler";

import { useState, useEffect } from 'react';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { FilterMatchMode, FilterOperator } from 'primereact/api';
import { Dropdown } from 'primereact/dropdown';
import { useNavigate, useLocation } from 'react-router-dom';
import { BlockUI } from 'primereact/blockui';

function UserManagement()
{
    const navigate = useNavigate();
    const [userList, setUserList] = useState([]);
    const [selectedUser, setSelectedUser] = useState(null);
    const [loading, setLoading] = useState(true);
    const [blocked, setBlocked] = useState(false);

    const [filters, setFilters] = useState({
        'fullname': { value: null, matchMode: FilterMatchMode.CONTAINS },
        'email': { value: null, matchMode: FilterMatchMode.CONTAINS },
        'schoolName': { value: null, matchMode: FilterMatchMode.CONTAINS },
        'roleID': { value: null, matchMode: FilterMatchMode.EQUALS },
        'status': { value: null, matchMode: FilterMatchMode.EQUALS },
        'active': { value: null, matchMode: FilterMatchMode.EQUALS },
        'blocked': { value: null, matchMode: FilterMatchMode.EQUALS },
    });

    const roles = [
        'Estrategy admin', 'Bazalt admin', 'Beheerder', 'Directie / Intern Begeleider', 'Leraar', 'Adviseur', 'Manager', 'LicenseAdministration'
    ];
    const dropdownState = [
        'Ja', 'Nee'
    ];

    const [msg, setMsg] = useState([]);

    const newMessageHandler = (enteredMsg) =>
    {
        setMsg(enteredMsg);
    }

    const setMessageHandler = (msg) =>
    {
        newMessageHandler(msg);
    }

    const rolesBodyTemplate = (rowData) =>
    {
        return <span className={`customer-badge status-${rowData.roleID}`}>{rowData.roleID}</span>;
    }
    const rolesItemTemplate = (option) =>
    {
        return <span className={`customer-badge status-${option}`}>{option}</span>;
    }
    const rolesRowFilterTemplate = (options) =>
    {
        return <Dropdown value={options.value} options={roles}
            onChange={(e) => options.filterApplyCallback(e.value)}
            itemTemplate={rolesItemTemplate}
            className="element" showClear />;
    }

    const activeBodyTemplate = (rowData) =>
    {
        return <span className={`customer-badge active-${rowData.active}`}>{rowData.active}</span>;
    }
    const activeItemTemplate = (option) =>
    {
        return <span className={`customer-badge active-${option}`}>{option}</span>;

    }
    const activeRowFilterTemplate = (option) =>
    {
        return <Dropdown value={option.value} options={dropdownState}
            onChange={(e) => option.filterApplyCallback(e.value)}
            itemTemplate={activeItemTemplate}
            className="element" showClear />;
    }

    const blockedBodyTemplate = (rowData) =>
    {
        return <span className={`customer-badge active-${rowData.blocked}`}>{rowData.blocked}</span>;
    }
    const blockedItemTemplate = (option) =>
    {
        return <span className={`customer-badge active-${option}`}>{option}</span>;
    }
    const blockedRowFilterTemplate = (option) =>
    {
        return <Dropdown value={option.value} options={dropdownState}
            onChange={(e) => option.filterApplyCallback(e.value)}
            itemTemplate={blockedItemTemplate}
            className="element" showClear />;
    }

    const adoptUser = useCallback(() =>
    {
        setMessageHandler();
        if (selectedUser == null)
            setMessageHandler(MessageConverter.convertBusinessMsg("Geen gebruiker geselecteerd", 1))
        else
        {
            setBlocked(true);
            UserService.adoptUser(selectedUser.userSchoolID).then((res) =>
            {
                if (res?.status === 200)
                {
                    var origAuth = AuthService.getAuth();
                    AuthService.setAuth(res.data);
                    AuthService.updateNewAccessToken(res.data);
                    // Set adopted username
                    AuthService.setCurrentUsername(selectedUser.fullname);
                    AuthService.setSchoolID(selectedUser.schoolID);
                    // set school name
                    SchoolService.getSchool(selectedUser.schoolID).then((schoolRes) =>
                    {
                        if (res?.status === 200)
                        {
                            AuthService.setCurrentSchoolname(schoolRes.data.name);
                            AuthService.setIsAdopted(true);
                            AuthService.setOrigUserAuth(origAuth);
                            AuthService.setPermissions(null); // reset admin permissions
                            AuthService.getPermissions().then((res) =>
                            {
                                AuthService.setPermissions(res);
                                navigate("/Home", { replace: true });
                                setBlocked(false);
                            });
                        }
                        else
                        {
                            setMessageHandler(MessageConverter.convertBusinessMsg(schoolRes?.status));
                            setBlocked(false);
                        }
                    });
                }
                else
                {
                    setMessageHandler(MessageConverter.convertBusinessMsg(res?.status));
                    setBlocked(false);
                }
            }).catch(err => { setMessageHandler(MessageConverter.convertBusinessMsg(err)); setBlocked(false); });
        }
    }, [selectedUser]);

    useEffect(() =>
    {
        UserService.getAdoptUserList().then((res) =>
        {
            setUserList(res.data);
            setLoading(false);
            res.data.map(content =>
            {
                if (content.roleID == 1) { return content.roleID = "Estrategy Admin" }
                if (content.roleID == 2) { return content.roleID = "Bazalt Admin" }
                if (content.roleID == 3) { return content.roleID = "Beheerder" }
                if (content.roleID == 4) { return content.roleID = "Directie / Intern Begeleider" }
                if (content.roleID == 5) { return content.roleID = "Leraar" }
                if (content.roleID == 6) { return content.roleID = "Adviseur" }
                if (content.roleID == 7) { return content.roleID = "Manager" }
                if (content.roleID == 8) { return content.roleID = "LicenseAdministration" }
            });

            res.data.map(activeResult =>
            {
                if (activeResult.active == true) { return activeResult.active = "Ja" }
                if (activeResult.active == false) { return activeResult.active = "Nee" }
            });

            res.data.map(blockedResult =>
            {
                if (blockedResult.blocked == true) { return blockedResult.blocked = "Ja" }
                if (blockedResult.blocked == false) { return blockedResult.blocked = "Nee" }
            });

            res.data.map(twoFAResult =>
            {
                if (twoFAResult.twoFASet == true) { return twoFAResult.twoFASet = "Ja" }
                if (twoFAResult.twoFASet == false) { return twoFAResult.twoFASet = "Nee" }
            });
        });
    }, []);

    const { state } = useLocation();
    useEffect(() =>
    {
        if (state !== null)
        {
            const { confirmedMessage } = state;
            setMessageHandler(MessageConverter.convertBusinessMsg(confirmedMessage, 0))
        }
    }, []);

    const copyBodyTemplate = (rowData) =>
    {
        const copy = rowData.fullname + " | " + rowData.schoolName + " | " + rowData.email + " |Helpdesk KIJK! Verder"
        return <img src="../img/clipboard.png" onClick={() => navigator.clipboard.writeText(copy)}></img>;
    }

    return (
        <div id="Page">
            <BlockUI blocked={blocked} fullScreen />
            <div id="MainContentContainer">
                <div className="page-information">
                    <div id="PageHeader" className="page-header">
                        <span>Gebruikers: Overnemen</span>
                    </div>
                    <div className="clear"></div>
                </div>
                <div>
                    <div className="maincontent">
                        <div className="message-div">
                            <MessageHandler messages={msg} />
                        </div>
                        <div className="card">
                            <DataTable
                                value={userList}
                                size="small"
                                paginator={true}
                                paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                                currentPageReportTemplate="{first} - {last} van {totalRecords}"
                                rows={20}
                                rowsPerPageOptions={[10, 20, 50, 100]}
                                dataKey="userSchoolID"
                                filters={filters} filterDisplay="row"
                                loading={loading}
                                responsiveLayout="scroll"
                                selectionMode="multiple"
                                selection={selectedUser}
                                onSelectionChange={e => setSelectedUser(e.value[0])}
                                emptyMessage="Geen resultaten gevonden"
                                stateKey="UserManagement-AdoptUser">
                                <Column field="fullname"
                                    header="Gebruiker"
                                    filter={true}
                                    filterPlaceholder="Zoek op naam"
                                    sortable={true}
                                    showFilterMenu={false}
                                    style={{ width: '15rem' }} />
                                <Column field="schoolName"
                                    header="School"
                                    filter={true}
                                    sortable={true}
                                    filterPlaceholder="Zoek op school"
                                    showFilterMenu={false}
                                    style={{ width: '18rem' }} />
                                <Column field="email"
                                    header="Email"
                                    filter={true}
                                    filterPlaceholder="Zoek op email"
                                    showFilterMenu={false}
                                    sortable={true}
                                    style={{ width: '15rem' }} />
                                <Column field="roleID"
                                    header="Rol"
                                    body={rolesBodyTemplate}
                                    filter={true}
                                    sortable={true}
                                    filterElement={rolesRowFilterTemplate}
                                    showFilterMenu={false}
                                    style={{ width: '6rem' }} />
                                <Column field="active"
                                    header="Actief"
                                    body={activeBodyTemplate}
                                    filter={true}
                                    sortable={true}
                                    filterElement={activeRowFilterTemplate}
                                    showFilterMenu={false}
                                    style={{ maxWidth: '6rem' }} />
                                <Column field="blocked"
                                    header="Geblokkeerd"
                                    body={blockedBodyTemplate}
                                    filter={true}
                                    sortable={true}
                                    filterElement={blockedRowFilterTemplate}
                                    showFilterMenu={false}
                                    style={{ maxWidth: '8rem' }} />
                                <Column field="twoFASet"
                                    sortable={true}
                                    header="2FA" showFilterMenu={false} />
                                <Column
                                    body={copyBodyTemplate} />
                            </DataTable>
                            <div className="clear"></div>
                        </div>
                    </div>
                    <div className="buttons">
                        <input type="submit" value="Overnemen" className="management-buttons" onClick={adoptUser} />
                    </div>
                </div>
            </div>
        </div>
    );
}

export default UserManagement;