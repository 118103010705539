import '../../GeneralStyling.scss'
import ReportService from "../../../Service/Report/SoCo/SoCoReportService";
import SchoolyearService from "../../../Service/SchoolyearService";
import GroupService from "../../../Service/GroupService";
import GroupyearService from "../../../Service/GroupyearService";
import StudentService from "../../../Service/StudentService";
import MessageConverter from "../../MessageConverter";
import MessageHandler from "../../MessageHandler";
import HelpPageService from '../../../Service/HelpPageService';

import React, { useState, useEffect } from 'react';
import Select from 'react-select';
import { ListBox } from 'primereact/listbox';
import moment from 'moment'
import { Dialog } from 'primereact/dialog';
import { BlockUI } from 'primereact/blockui';
import { Editor, EditorState, convertFromRaw, CompositeDecorator } from "draft-js";
import HelpItemDescription from '../../HelpPage/HelpItemDescription';

function SoCoGroupReport()
{
    const [blocked, setBlocked] = useState(false);

    const [schoolyears, setSchoolyears] = useState([]);
    const [groups, setGroups] = useState([]);
    const [groupyears, setGroupyears] = useState([]);
    const [students, setStudents] = useState([]);
    const [subItemsVisible, setSubItemsVisible] = useState(true);
    const [visible, setVisible] = useState(false);

    const [selectedSchoolyear, setSelectedSchoolyear] = useState();
    const [selectedGroups, setSelectedGroups] = useState([]);
    const [selectedGroupyears, setSelectedGroupyears] = useState([]);
    const [selectedStudents, setSelectedStudents] = useState([]);

    const [selectedClosedate, setSelectedClosedate] = useState(null);
    const [closedates, setCloseDates] = useState([]);

    const [hideSubQuestions, setHideSubQuestions] = useState(true);
    const [type, setType] = useState(1);
    const [startDomainOnNewPage, setStartDomainOnNewPage] = useState(true);
    const [msg, setMsg] = useState([]);
    const [defaultSelectedClosedate, setDefaultSelectedClosedate] = useState(null);
    const [defaultSelectedSchoolyear, setDefaultSelectedSchoolyear] = useState(null);

    const [helpItem, setHelpItem] = useState(null);
    const [helpVisible, setHelpVisible] = useState(false);
    const [helpButtonHidden, sethelpButtonHidden] = useState(true);
    const currentPathName = window.location.pathname;

    // Prevent going back
    useEffect(() =>
    {
        window.addEventListener('popstate', (e) =>
        {
            window.history.go(1);
        });
    }, []);

    // Preload helpItems
    useEffect(() =>
    {
        if (helpItem == null)
        {
            HelpPageService.getHelpItembyPage(currentPathName).then((res) =>
            {
                if (res.data != undefined && res.data != null && res.data != '')
                {
                    setHelpItem(res.data);
                    sethelpButtonHidden(false);
                }
                else
                {
                    sethelpButtonHidden(true);
                }
            }).catch(err =>
            {
                setMessageHandler(MessageConverter.convertBusinessMsg(err));
            });
        }
    }, []);

    const newMessageHandler = (enteredMsg) =>
    {
        setMsg(enteredMsg);
    }

    const setMessageHandler = (msg) =>
    {
        newMessageHandler(msg);
    }

    const getReport = async () =>
    {
        setMessageHandler();
        if (selectedGroupyears !== null && selectedGroupyears.length !== 0)
        {
            if (selectedGroups !== null && selectedGroups.length !== 0)
            {
                if (selectedStudents.length !== 0)
                {
                    if (selectedClosedate !== null)
                    {
                        setBlocked(true);
                        var groupIDs = selectedGroups.map((gr) => gr.id);
                        var groupyearIDs = selectedGroupyears.map((gy) => gy.id);
                        var stuSchoolIDs = selectedStudents.map((stu) => stu.studentSchoolID);
                        var closeDate = moment(selectedClosedate, 'DD-MM-YYYY');
                        try
                        {
                            ReportService.getSoCoGroupReport(groupIDs, groupyearIDs, stuSchoolIDs, selectedSchoolyear.id, closeDate, hideSubQuestions, startDomainOnNewPage, type).then((reportRes) =>
                            {
                                setBlocked(false);
                                if (reportRes?.status === 200)
                                {
                                    const url = window.URL.createObjectURL(new Blob([reportRes.data], { type: 'application/pdf' }));
                                    const link = document.createElement('a');
                                    link.href = url;
                                    link.setAttribute('download', 'Groepsrapport.pdf');
                                    document.body.appendChild(link);
                                    link.click();
                                }
                                else if (reportRes.status == 204)
                                {
                                    setMessageHandler(MessageConverter.convertBusinessMsg("Het rapport bevat geen data", 1));
                                    setBlocked(false);
                                }
                                else
                                {
                                    setMessageHandler(MessageConverter.convertBusinessMsg(reportRes?.status));
                                    setBlocked(false);
                                }
                            }).catch((err) => { setMessageHandler(MessageConverter.convertBusinessMsg(err?.response.status)); setBlocked(false); });
                        } catch (error)
                        {
                            setMessageHandler(MessageConverter.convertBusinessMsg(error));
                            setBlocked(false);
                        }
                    } else setMessageHandler(MessageConverter.convertBusinessMsg("Selecteer een afsluitdatum", 1))
                } else setMessageHandler(MessageConverter.convertBusinessMsg("Selecteer een kind", 1))
            } else setMessageHandler(MessageConverter.convertBusinessMsg("Selecteer een groep", 1))
        } else setMessageHandler(MessageConverter.convertBusinessMsg("Selecteer een groepsjaar", 1))
    }

    useEffect(() =>
    {
        SchoolyearService.getSchoolyearListForSocoReports().then((schoolyearRes) =>
        {
            setSchoolyears(schoolyearRes.data);
            if (schoolyearRes.data.length == 1)
            {
                setDefaultSelectedSchoolyear(schoolyearRes.data[0]);
                setSelectedSchoolyear(schoolyearRes.data[0]);
                setSelectedGroups(null);
                setSubItemsVisible(false);
            } else
            {
                const currentSchoolyear = schoolyearRes.data.find((element) => element.active == true)
                if (currentSchoolyear !== undefined)
                {
                    setDefaultSelectedSchoolyear(currentSchoolyear);
                    setSelectedSchoolyear(currentSchoolyear);
                    setSelectedGroups(null);
                    setSubItemsVisible(false);
                }
            }
        }).catch((err) => setMessageHandler(MessageConverter.convertBusinessMsg(err)));
    }, []);

    // Groupyear
    useEffect(() =>
    {
        if (selectedSchoolyear !== undefined && selectedSchoolyear != null)
        {
            GroupyearService.getGroupyearListSoCo().then((res) =>
            {
                setGroupyears(res.data);
                setSelectedGroupyears([]);
            }).catch((err) => setMessageHandler(MessageConverter.convertBusinessMsg(err)));
        }
        else
        {
            setGroupyears([]);
            setSelectedGroupyears([]);
        }
    }, [selectedSchoolyear]);

    // Groups
    useEffect(() =>
    {
        if (selectedSchoolyear !== undefined && selectedSchoolyear != null &&
            selectedGroupyears !== undefined && selectedGroupyears != null && selectedGroupyears.length > 0)
        {
            GroupService.getGroupsWithClosedSoCoRegistrations(selectedSchoolyear.id, type, selectedGroupyears).then((groupRes) =>
            {
                setGroups(groupRes.data);
            }).catch((err) => setMessageHandler(MessageConverter.convertBusinessMsg(err)));
        }
        else
        {
            setGroups([]);
            setSelectedGroups([]);
            setSelectedStudents([]);
            setCloseDates([]);
            setDefaultSelectedClosedate(null);
        }
    }, [selectedSchoolyear, selectedGroupyears, type]);

    // students
    useEffect(() =>
    {
        if (selectedGroups !== undefined && selectedGroups != null && selectedGroups.length > 0)
        {
            var groupIDs = selectedGroups.map((gr) => gr.id);
            StudentService.getStudentWithClosedSoCoRegistrationMultiGroup(groupIDs, type).then((studentRes) =>
            {
                setStudents(studentRes.data);
                setSelectedStudents(studentRes.data);
            }).catch((err) => setMessageHandler(MessageConverter.convertBusinessMsg(err)));
        }
        else
        {
            setStudents(null);
            setSelectedStudents([]);
            setCloseDates([]);
            setDefaultSelectedClosedate(null);
        }
    }, [selectedSchoolyear, selectedGroupyears, selectedGroups, type]);

    useEffect(() =>
    {
        if (selectedStudents !== undefined && selectedStudents != null && selectedStudents.length > 0 && selectedGroups != null && selectedGroups.length > 0)
        {
            var groupIDs = selectedGroups.map((gr) => gr.id);
            var stuSchoolIDs = selectedStudents.map((stu) => stu.studentSchoolID);
            ReportService.getClosedatesForGroupReport(groupIDs, stuSchoolIDs, type).then((res) =>
            {
                var formattedDates = res.data.map((dt) => ({ value: moment(dt).format("DD-MM-YYYY"), label: moment(dt).format("DD-MM-YYYY") }));
                setCloseDates(formattedDates);
                if (formattedDates.length == 0)
                {
                    setCloseDates([]);
                    //setDefaultSelectedClosedate(null);
                    //setSelectedClosedate(null);
                }
                else if (formattedDates.length == 1)
                {
                    setDefaultSelectedClosedate(formattedDates[0]);
                    setSelectedClosedate(formattedDates[0].value);
                }
            }).catch((err) => { setCloseDates([]); setMessageHandler(MessageConverter.convertBusinessMsg(err)); });
        }
        else
        {
            setCloseDates([]);
            setDefaultSelectedClosedate(null);
        }
    }, [selectedStudents, selectedGroups, selectedGroupyears, type]);

    useEffect(() =>
    {
        if (closedates === undefined || closedates == null || closedates.length == 0)
        {
            setSelectedClosedate(null);
            setDefaultSelectedClosedate(null);
        }
    }, [closedates, defaultSelectedClosedate]);

    return (
        <div id="ManageChildPage">
            <BlockUI blocked={blocked} fullScreen />
            <div id="MainContentContainer">
                <div className="page-information">
                    <div id="PageHeader" className="page-header">
                        <span>Rapportage: Groep</span>
                    </div>
                    <div>
                        <div id="InfoButton" onClick={() => setHelpVisible(true)} hidden={helpButtonHidden}>
                            <i className="btn-info"></i>
                        </div>
                        <Dialog
                            header={"Rapportage: Groep"}
                            visible={helpVisible}
                            modal={true}
                            onHide={() => setHelpVisible(false)}
                            style={{ width: '75vw' }}
                        >
                            <Editor className="textfield" editorState={HelpItemDescription(helpItem)} readOnly="true" />
                        </Dialog>
                    </div>
                    <div className="clear"></div>
                </div>
                <div>
                    <div className="maincontent-header-bar">
                        <div className="header-bar-item">
                            <label>Schooljaar</label>
                            <Select
                                options={schoolyears}
                                getOptionLabel={(option) => option.name}
                                getOptionValue={(option) => option.id}
                                onChange={(sy) =>
                                {
                                    setSelectedGroups(null);
                                    setSelectedStudents(null);
                                    setSelectedSchoolyear(sy);
                                    setSubItemsVisible(false);
                                }}
                                value={schoolyears.find((element) => { return element.id == selectedSchoolyear?.id })}
                                isSearchable={true}
                                isMulti={false}
                                id="schoolyear"
                                placeholder="Selecteer een schooljaar"
                                noOptionsMessage={() => 'Geen resultaten gevonden'}
                                defaultValue={defaultSelectedSchoolyear}
                            />
                        </div>
                    </div>
                    <div className="message-div">
                        <MessageHandler messages={msg} />
                    </div>
                    <div className="maincontent-sub-bar" hidden={subItemsVisible}>
                        <div className="page-divider">
                            <div className="align-3">
                                <div className="sub-bar-item">
                                    <label>Type</label>
                                    <div className="radio-buttons">
                                        <input
                                            type="radio"
                                            id="basic"
                                            value={1}
                                            checked={type == 1}
                                            onChange={() => { setDefaultSelectedClosedate(null); setType(1); }}
                                        />
                                        <label htmlFor="basic">Leraar</label>
                                        <input
                                            type="radio"
                                            id="childlist"
                                            value={2}
                                            checked={type == 2}
                                            onChange={() => { setDefaultSelectedClosedate(null); setType(2); }}
                                        />
                                        <label htmlFor="childlist">Kind</label>
                                    </div>
                                </div>
                                <div className="sub-bar-item">
                                    <label>Start domein op nieuwe pagina</label>
                                    <div className="radio-buttons">
                                        <input
                                            type="radio"
                                            id="newpage-yes"
                                            value={true}
                                            checked={startDomainOnNewPage}
                                            onChange={() => setStartDomainOnNewPage(true)}
                                        />
                                        <label htmlFor="newpage-yes">Ja</label>
                                        <input
                                            type="radio"
                                            id="newpage-no"
                                            value={false}
                                            checked={!startDomainOnNewPage}
                                            onChange={() => setStartDomainOnNewPage(false)}
                                        />
                                        <label htmlFor="newpage-no">Nee</label>
                                    </div>
                                </div>
                                <div className="sub-bar-item">
                                    <label>Selectie</label>
                                    <div className="radio-buttons">
                                        <input
                                            type="radio"
                                            id="mainquestions"
                                            value={true}
                                            checked={hideSubQuestions}
                                            onChange={() => setHideSubQuestions(true)} />
                                        <label htmlFor="mainquestions">Hoofdvragen</label>
                                        <input
                                            type="radio"
                                            id="subquestions"
                                            value={false}
                                            checked={!hideSubQuestions}
                                            onChange={() => setHideSubQuestions(false)} />
                                        <label htmlFor="subquestions">Hoofd- en subvragen</label>
                                    </div>
                                </div>
                                <div className="sub-bar-item">
                                    <label>
                                        Afsluitdatum <i className="fas fa-circle-info" onClick={() => setVisible(true)} ></i>
                                    </label>
                                    <Dialog
                                        header="Afsluitdatum"
                                        visible={visible}
                                        onHide={() => setVisible(false)}
                                        draggable={false}
                                    >
                                        <div className="m-0">
                                            <p>
                                                De afsluitdatum wordt geladen op basis van de gekozen kinderen
                                            </p >
                                        </div>
                                    </Dialog>
                                    <Select
                                        key={closedates}
                                        options={closedates}
                                        onChange={(e) => { setSelectedClosedate(e.value); }}
                                        isSearchable={false}
                                        isMulti={false}
                                        defaultValue={defaultSelectedClosedate}
                                        id="closedate"
                                        placeholder="Selecteer een afsluitdatum"
                                        noOptionsMessage={() => 'Geen resultaten gevonden'}
                                    />
                                </div>
                                <button onClick={getReport} className="main-buttons"> Inzien </button>
                            </div>
                            <div className="align-2">
                                <div className="sub-bar-item">
                                    <label>Groepsjaren</label>
                                    <ListBox
                                        multiple
                                        value={selectedGroupyears}
                                        onChange={(e) =>
                                        {
                                            setSelectedGroupyears(e.value);
                                            setCloseDates([]);
                                            setSelectedClosedate(null);
                                        }}
                                        options={groupyears}
                                        emptyMessage="Geen resultaten gevonden"
                                        optionLabel="name" />
                                </div>
                                <div className="sub-bar-item">
                                    <label>Groep</label>
                                    <ListBox
                                        multiple
                                        value={selectedGroups}
                                        onChange={(e) =>
                                        {
                                            setSelectedStudents(null);
                                            setSelectedGroups(e.value);
                                            setSelectedClosedate(null);
                                        }}
                                        options={groups}
                                        emptyMessage="Geen resultaten gevonden"
                                        optionLabel="name" />
                                </div>
                            </div>
                            <div className="align-3">
                                <div className="sub-bar-item">
                                    <label>Kind(eren)</label>
                                    <ListBox
                                        multiple
                                        value={selectedStudents}
                                        onChange={(e) => setSelectedStudents(e.value)}
                                        options={students}
                                        optionLabel="fullname"
                                        emptyMessage="Geen resultaten gevonden"
                                        listStyle={{ maxHeight: '355px' }}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default SoCoGroupReport;