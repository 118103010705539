import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { FilterMatchMode, FilterOperator } from 'primereact/api';
import React, { useState, useEffect, useCallback } from 'react';
import { Calendar } from 'primereact/calendar';
import { Dialog } from 'primereact/dialog';
import { BlockUI } from 'primereact/blockui';

import MigrationService from "../../Service/MigrationService";
import moment from 'moment';
import MessageConverter from "../MessageConverter"
import MessageHandler from "../MessageHandler"
import { DateFilter } from "../DateFilter"

function ChildrenToMigrate()
{
    const [migrationList, setMigrationList] = useState([]);
    const [selectedChild, setSelectedChild] = useState([]);
    const [migrationDTO, setMigrationDTO] = useState([]);
    const [refreshKey, setRefreshKey] = useState(0);
    const [loading, setLoading] = useState(true);
    const [visibleDialog, setVisibleDialog] = useState(false);
    const [blocked, setBlocked] = useState(false);
    const [msg, setMsg] = useState([]);

    const [filters, setFilters] = useState({
        birthdate: { value: null, matchMode: FilterMatchMode.DATE_IS },
        fullname: { value: null, matchMode: FilterMatchMode.CONTAINS },
        schoolToName: { value: null, matchMode: FilterMatchMode.CONTAINS },
    });

    const newMessageHandler = (enteredMsg) =>
    {
        setMsg(enteredMsg);
    }

    const setMessageHandler = (msg) =>
    {
        newMessageHandler(msg);
    }

    // Omzetten van de data
    const getMigrationList = (data) =>
    {
        return [...(data || [])].map((d) =>
        {
            d.birthdate = new Date(d.birthdate);

            return d;
        });
    };

    const datebody = (rowData) =>
    {
        return <>{moment(rowData.birthdate).format("DD-MM-YYYY")}</>;
    };


    useEffect(() =>
    {
        MigrationService.getListToMigrate().then((res) =>
        {
            setMigrationList(getMigrationList(res.data));
            setLoading(false);
        }).catch((err) => setMessageHandler(MessageConverter.convertBusinessMsg(err)));
    }, [refreshKey]);

    const onRejectMigrationButton = useCallback((e) =>
    {
        e.preventDefault();
        setMessageHandler();
        if (selectedChild.length !== 0)
        {
            setBlocked(true);
            MigrationService.getMigration(selectedChild.id).then((res) =>
            {
                setMigrationDTO(res.data);
                setVisibleDialog(true);
                setBlocked(false);
            }).catch((err) => { setMessageHandler(MessageConverter.convertBusinessMsg(err)); setBlocked(false) });
        } else setMessageHandler(MessageConverter.convertBusinessMsg("Geen kind geselecteerd", 1));
    }, [selectedChild]);

    const RejectMigration = useCallback(() =>
    {
        setMessageHandler();
        if (migrationDTO.message !== null)
        {
            setBlocked(true);
            MigrationService.rejectMigrationBySender(migrationDTO).then((res) =>
            {
                setRefreshKey(oldKey => oldKey + 1);
                setVisibleDialog(false);
                setMessageHandler(MessageConverter.convertBusinessMsg("Verhuizing kind succesvol teruggedraaid", 0));
                setBlocked(false);
            }).catch((err) => { setMessageHandler(MessageConverter.convertBusinessMsg(err)); setBlocked(false) });
        } else setMessageHandler(MessageConverter.convertBusinessMsg("Dit veld is verplicht", 1));
    }, [migrationDTO]);

    return (
        <form autoComplete="off">
            <BlockUI blocked={blocked} fullScreen />
            <MessageHandler messages={msg} />
            <div className="maincontent-item">
                <div className="card">
                    <DataTable
                        value={migrationList}
                        dataKey="id"
                        size="small"
                        paginator={true}
                        paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                        currentPageReportTemplate="{first} - {last} van {totalRecords}"
                        rows={10}
                        loading={loading}
                        rowsPerPageOptions={[10, 20, 50, 100]}
                        responsiveLayout="scroll"
                        filters={filters}
                        filterDisplay="row"
                        selectionMode="single"
                        selection={selectedChild}
                        onSelectionChange={e => { setSelectedChild(e.value) }}
                        emptyMessage="Geen resultaten gevonden"
                        stateKey="Migration-ChildrenToMigrate">
                        <Column field="fullname"
                            filter={true}
                            showFilterMenu={false}
                            filterPlaceholder="Zoek op naam"
                            sortable={true}
                            style={{ width: '18rem' }}
                            header="Kind" />
                        <Column header="Geboortedatum"
                            filterField="birthdate"
                            dataType="date"
                            showFilterMenu={false}
                            style={{ width: '10rem' }}
                            sortable={true}
                            field="birthdate"
                            body={datebody}
                            filter={true}
                            filterElement={DateFilter} />
                        <Column field="schoolToName"
                            filter={true}
                            style={{ width: '18rem' }}
                            showFilterMenu={false}
                            sortable={true}
                            filterPlaceholder="Zoek op locatie"
                            header="Ontvangende school/locatie" />
                    </DataTable>
                    <Dialog
                        className="delete-dialog"
                        header={"Vul hier de reden van annuleren verhuizing in"}
                        visible={visibleDialog}
                        onHide={() => setVisibleDialog(false)}
                        draggable={false}
                    >
                        <textarea
                            className="textarea-in-dialog"
                            required={true}
                            onChange={(e) => setMigrationDTO({ ...migrationDTO, message: e.target.value })}
                        />
                        <input type="submit" value="Opslaan" className="management-buttons" onClick={RejectMigration} />
                    </Dialog>
                    <div className="clear"></div>
                </div>
            </div>
            <input type="submit" value="Terugdraaien" onClick={onRejectMigrationButton} className="management-buttons" />
        </form >
    );
}

export default ChildrenToMigrate;