import MessageConverter from "../MessageConverter";
import React, { useState, useEffect } from "react";
import { useNavigate } from 'react-router-dom';

import './Setting.scss'
import SettingService from '../../Service/SettingService'
import UserService from "../../Service/UserService";
import MessageHandler from "../MessageHandler"

function TwoFASetting()
{
	const navigate = useNavigate();
	const [mfaDTO, setMfaDTO] = useState([]);
	const [refreshKey, setRefreshKey] = useState(0);
	const [mfaSet, setMfaSet] = useState(true);
	const [msg, setMsg] = useState([]);

	const newMessageHandler = (enteredMsg) =>
	{
		setMsg(enteredMsg);
	}

	const setMessageHandler = (msg) =>
	{
		newMessageHandler(msg);
	}

	useEffect(() =>
	{
		if (mfaDTO.value == '0')
		{
			SettingService.saveMfaSetting(mfaDTO).then(() =>
			{
				setRefreshKey(oldKey => oldKey + 1);
			}).catch((err) => setMessageHandler(MessageConverter.convertBusinessMsg(err)));
		}

		if (mfaDTO.value == '1' || mfaDTO.value == '2')
		{
			if (mfaSet == true)
			{
				SettingService.saveMfaSetting(mfaDTO).then(() =>
				{
					setRefreshKey(oldKey => oldKey + 1);
				}).catch((err) => setMessageHandler(MessageConverter.convertBusinessMsg(err)));
			} else if (mfaSet == false)
			{
				SettingService.saveMfaSetting(mfaDTO).then(() =>
				{
					navigate("/TwoFA", { replace: true })
				}).catch((err) => setMessageHandler(MessageConverter.convertBusinessMsg(err)));
			}
		}
	}, [mfaDTO.value]);

	useEffect(() =>
	{
		SettingService.getMfaSetting().then((res) =>
		{
			setMfaDTO(res.data)
		}).catch((err) => setMessageHandler(MessageConverter.convertBusinessMsg(err)));
		UserService.isMfaSet().then((res) =>
		{
			setMfaSet(res.data);
		}).catch((err) => setMessageHandler(MessageConverter.convertBusinessMsg(err)));
	}, [refreshKey]);

	return (
		<div>
			<MessageHandler messages={msg} />
			<p className="twofa-p">
				Om KIJK! Verder nog veiliger te maken kun je tweestapsverificatie in te stellen. Dit is een methode waarmee de identiteit van gebruikers beter vastgesteld kan worden. Hiermee voorkomen we onrechtmatig gebruik van jouw account. De stappen voor het instellen hoef je maar eenmalig te doorlopen. Als de tweestapsverificatie succesvol is ingesteld, dien je in het vervolg bij het inloggen de verificatiecode in te vullen die je op je gekozen apparaat ontvangt. Volg de stappen om tweestapsverificatie in te stellen.
			</p>
			<div className="sub-bar-item">
				<label className="twoFA-setting">
					Geef hieronder aan voor welk gebruikertype je de tweestapsverificatie wilt verplichten:
				</label>
				<div className="radio-buttons">
					<input
						type="radio"
						id="nobody"
						name="twofa"
						checked={(mfaDTO.value == 0)}
						onChange={(e) => setMfaDTO({ ...mfaDTO, value: e.target.value })}
						value={0} />
					<label htmlFor="nobody">Niemand</label>

					<input
						type="radio"
						id="beheer"
						name="twofa"
						checked={(mfaDTO.value == 1)}
						onChange={(e) => setMfaDTO({ ...mfaDTO, value: e.target.value })}
						value={1} />
					<label htmlFor="beheer">Beheerder/Adviseur</label>
					<input
						type="radio"
						id="everyone"
						name="twofa"
						checked={(mfaDTO.value == 2)}
						onChange={(e) => setMfaDTO({ ...mfaDTO, value: e.target.value })}
						value={2} />
					<label htmlFor="everyone">Iedereen</label>
				</div>
			</div>
		</div>
	);
}

export default TwoFASetting;