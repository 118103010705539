import '../GeneralStyling.scss'
import MessageConverter from "../MessageConverter";
import SchoolyearService from "../../Service/SchoolyearService"
import MessageHandler from "../MessageHandler";

import moment from 'moment'
import React, { useState, useEffect } from 'react';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { BlockUI } from 'primereact/blockui';

function Schoolyear()
{
    const [loading, setLoading] = useState(true);
    const [list, setList] = useState([]);
    const [selectedYear, setSelectedYear] = useState([]);
    const [blocked, setBlocked] = useState(false);
    const [msg, setMsg] = useState([]);
    const [refreshKey, setRefreshKey] = useState(0);
    
    const newMessageHandler = (enteredMsg) =>
    {
        setMsg(enteredMsg);
    }

    const setMessageHandler = (msg) =>
    {
        newMessageHandler(msg);
    }

    const datebodyStart = (rowData) =>
    {
        return <>{moment(rowData.start).format("DD-MM-YYYY")}</>;
    };

    const datebodyEnd = (rowData) =>
    {
        return <>{moment(rowData.end).format("DD-MM-YYYY")}</>;
    };

    useEffect(() =>
    {
        SchoolyearService.getSchoolyearList().then((res) =>
        {
            setList(res.data);
            setLoading(false);
            res.data.map(activeResult =>
            {
                if (activeResult.active == true) { return activeResult.active = "Ja" }
                if (activeResult.active == false) { return activeResult.active = "Nee" }
            });
        }).catch(err => setMessageHandler(MessageConverter.convertBusinessMsg(err)))
    }, [refreshKey])

    // Prevent going back
    useEffect(() =>
    {
        window.addEventListener('popstate', (e) =>
        {
            window.history.go(1);
        });
    }, []);

    const onActivate = (e) =>
    {
        e.preventDefault();
        if (selectedYear.length !== 0 && selectedYear !== undefined)
        {
            if (selectedYear.active == "Ja") { selectedYear.active = true }
            if (selectedYear.active == "Nee") { selectedYear.active = false }

            try
            {
                SchoolyearService.activateSchoolyear(selectedYear).then((res) =>
                {
                    sessionStorage.removeItem("SchoolyearList");
                    setSelectedYear([]);
                    setRefreshKey(oldKey => oldKey + 1);
                }).catch(err => setMessageHandler(MessageConverter.convertBusinessMsg(err)))
            } catch (err)
            {
                setMessageHandler(MessageConverter.convertBusinessMsg(err))
            }
        } else setMessageHandler(MessageConverter.convertBusinessMsg("Geen schooljaar geselecteerd", 1))
    }

    return (
        <div id="Page">
            <BlockUI blocked={blocked} fullScreen />
            <div id="MainContentContainer">
                <div className="page-information">
                    <div id="PageHeader" className="page-header">
                        <span>Schooljaar: Overzicht</span>
                    </div>
                    <div className="clear"></div>
                </div>
                <div>
                    <div className="maincontent">
                        <div className="message-div">
                            <MessageHandler messages={msg} />
                        </div>
                        <div className="card">
                            <DataTable
                                value={list}
                                dataKey="id"
                                size="small"
                                loading={loading}
                                selection={selectedYear}
                                onSelectionChange={e => { setSelectedYear(e.value[0]); }}
                                responsiveLayout="scroll"
                                selectionMode="multiple"
                                emptyMessage="Geen resultaten gevonden">
                                <Column field="name"
                                    sortable={true}
                                    header="Naam"/>
                                <Column field="start"
                                    sortable={true}
                                    body={datebodyStart}
                                    header="Start" />
                                <Column field="end"
                                    sortable={true}
                                    body={datebodyEnd}
                                    header="Eind" />
                                <Column field="active"
                                    sortable={true}
                                    header="Actief" />
                            </DataTable>
                            <div className="clear"></div>
                        </div>
                    </div>
                    <div className="buttons">
                        <input type="submit" value="Activeren" className="management-buttons" onClick={onActivate} />
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Schoolyear;