import '../GeneralStyling.scss'
import MessageConverter from "../MessageConverter";
import CommunicationScreenService from "../../Service/CommunicationScreenService"
import MessageHandler from "../MessageHandler";

import moment from 'moment'
import React, { useState, useEffect, useCallback } from 'react';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { Dialog } from 'primereact/dialog';
import { FilterMatchMode } from 'primereact/api';
import { Dropdown } from 'primereact/dropdown';
import { useNavigate, useLocation } from 'react-router-dom';
import { BlockUI } from 'primereact/blockui';

function Overview()
{
    const navigate = useNavigate();

    const [list, setList] = useState([]);
    const [loading, setLoading] = useState(true);
    const [selectedMessage, setSelectedMessage] = useState(null);
    const [blocked, setBlocked] = useState(false);

    const [filters, setFilters] = useState({
        'description': { value: null, matchMode: FilterMatchMode.CONTAINS },
        'type': { value: null, matchMode: FilterMatchMode.CONTAINS },
        'active': { value: null, matchMode: FilterMatchMode.CONTAINS },
    });
    const types = [
        'Melding', 'Tips & Weetjes'
    ];
    const dropdownState = [
        'Ja', 'Nee'
    ];
    const [msg, setMsg] = useState([]);

    const newMessageHandler = (enteredMsg) =>
    {
        setMsg(enteredMsg);
    }

    const setMessageHandler = (msg) =>
    {
        newMessageHandler(msg);
    }

    const typesBodyTemplate = (rowData) =>
    {
        return <span className={`customer-badge status-${rowData.type}`}>{rowData.type}</span>;
    }
    const typesItemTemplate = (option) =>
    {
        return <span className={`customer-badge status-${option}`}>{option}</span>;
    }
    const typesRowFilterTemplate = (options) =>
    {
        return <Dropdown value={options.value} options={types}
            onChange={(e) => options.filterApplyCallback(e.value)}
            itemTemplate={typesItemTemplate} placeholder="Selecteer een type"
            className="element" showClear />;
    }

    const activeBodyTemplate = (rowData) =>
    {
        return <span className={`customer-badge status-${rowData.type}`}>{rowData.active}</span>;
    }
    const activeItemTemplate = (option) =>
    {
        return <span className={`customer-badge status-${option}`}>{option}</span>;
    }
    const activeRowFilterTemplate = (options) =>
    {
        return <Dropdown value={options.value} options={dropdownState}
            onChange={(e) => options.filterApplyCallback(e.value)}
            itemTemplate={activeItemTemplate} placeholder="Actief"
            className="element" showClear />;

    }

    const [visibleDelete, setVisibleDelete] = useState(false);
    const [refreshKey, setRefreshKey] = useState(0);

    useEffect(() =>
    {
        CommunicationScreenService.getCSList().then((res) => {
            setList(res.data);
            setLoading(false);
            res.data.map(type =>
            {
                if (type.type == 1)
                { return type.type = "Melding" }
                if (type.type == 2)
                { return type.type = "Tips & Weetjes" }
            });

            res.data.map(active =>
            {
                if (active.active == true)
                { return active.active = "Ja" }
                if (active.active == false)
                { return active.active = "Nee" }
            });
        }).catch((err) => setMessageHandler(MessageConverter.convertBusinessMsg(err)));
    }, [refreshKey]);

    const dateStartBody = (rowData) =>
    {
        if (rowData.startDate !== null) {
            return <>{moment(rowData.startDate).format("DD-MM-YYYY")}</>;
        } else return <></>
    };

    const dateEndBody = (rowData) =>
    {
        if (rowData.endDate !== null)
        {
            return <>{moment(rowData.endDate).format("DD-MM-YYYY")}</>;
        } else return <></>
    };

    const editItem = useCallback(() =>
    {
        setMessageHandler();
        if (selectedMessage == null)
        {
            setMessageHandler(MessageConverter.convertBusinessMsg("Geen item geselecteerd", 1));
        }
        else
        {
            setBlocked(true);
            navigate("/CommunicationScreenItem", { state: { message: selectedMessage } });
            setBlocked(false);
        }
    }, [selectedMessage]);

    const addItem = useCallback(() =>
    {
        setMessageHandler();
        setBlocked(true);
        navigate("/CommunicationScreenItem");
        setBlocked(false);
    }, []);

    const deleteMessage = useCallback(() =>
    {
        setBlocked(true);
        CommunicationScreenService.deleteCSItem(selectedMessage.id).then(() =>
        {
            setRefreshKey(oldKey => oldKey + 1);
            setMessageHandler(MessageConverter.convertBusinessMsg("Bericht is succesvol verwijderd", 0));
            setVisibleDelete(false);
            setBlocked(false);
        }).catch(err =>
        {
            setMessageHandler(MessageConverter.convertBusinessMsg(err));
            setBlocked(false);
            setVisibleDelete(false);
        });
    }, [selectedMessage]);

    const onDeleteButton = useCallback(() =>
    {
        setMessageHandler();
        if (selectedMessage == null)
        {
            setMessageHandler(MessageConverter.convertBusinessMsg("Geen item geselecteerd"));
        }
        else
        {
            setVisibleDelete(true);
        }
    }, [selectedMessage]);

    const { state } = useLocation();
    useEffect(() =>
    {
        if (state !== null)
        {
            const { confirmedMessage } = state;
            setMessageHandler(MessageConverter.convertBusinessMsg(confirmedMessage, 0));
        }
    }, []);

    // Prevent going back
    useEffect(() =>
    {
        window.addEventListener('popstate', (e) =>
        {
            window.history.go(1);
        });
    }, []);

    return (
        <div id="Page">
            <BlockUI blocked={blocked} fullScreen />
            <div id="MainContentContainer">
                <div className="page-information">
                    <div id="PageHeader" className="page-header">
                        <span>Communicatiescherm: Overzicht</span>
                    </div>
                    <div className="clear"></div>
                </div>
                <div>
                    <div className="maincontent">
                        <div className="message-div">
                            <MessageHandler messages={msg} />
                        </div>
                        <div className="card">
                            <DataTable
                                value={list}
                                dataKey="id"
                                size="small"
                                paginator={true}
                                paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                                currentPageReportTemplate="{first} - {last} van {totalRecords}"
                                rows={10}
                                rowsPerPageOptions={[10, 20, 50, 100]}
                                filters={filters} filterDisplay="row"
                                loading={loading}
                                selection={selectedMessage}
                                onSelectionChange={e => { setSelectedMessage(e.value[0]); }}
                                responsiveLayout="scroll"
                                selectionMode="multiple"
                                emptyMessage="Geen resultaten gevonden">
                                <Column field="description"
                                    sortable={true}
                                    filter={true}
                                    showFilterMenu={false}
                                    filterPlaceholder="Zoek op omschrijving"
                                    header="Omschrijving">
                                </Column>
                                <Column field="type"
                                    sortable={true}
                                    header="Type"
                                    showFilterMenu={false}
                                    body={typesBodyTemplate}
                                    filter={true}
                                    filterElement={typesRowFilterTemplate}>
                                </Column>
                                <Column field="startDate"
                                    sortable={true}
                                    body={dateStartBody}
                                    header="Start">
                                </Column>
                                <Column field="endDate"
                                    sortable={true}
                                    body={dateEndBody}
                                    header="Eind">
                                </Column>
                                <Column field="active"
                                    sortable={true}
                                    header="Actief"
                                    showFilterMenu={false}
                                    body={activeBodyTemplate}
                                    filter={true}
                                    filterElement={activeRowFilterTemplate}>
                                </Column>
                            </DataTable>
                            <div className="clear"></div>
                        </div>
                    </div>
                    <div className="buttons">
                        <input type="submit" value="Toevoegen" className="management-buttons" onClick={addItem} />
                        <input type="submit" value="Bewerken" className="management-buttons" onClick={editItem} />
                        <input type="submit" value="Verwijderen" className="management-buttons" onClick={onDeleteButton} />
                    </div>
                    <Dialog
                        className="delete-dialog"
                        header={"Weet je zeker dat je dit bericht wilt verwijderen?"}
                        visible={visibleDelete}
                        onHide={() => setVisibleDelete(false)}
                        draggable={false}
                    >
                        <input type="submit" value="Ja" className="delete-button" onClick={deleteMessage} />
                        <input type="submit" value="Nee" className="cancel-delete-button" onClick={() => setVisibleDelete(false)} />
                    </Dialog>
                </div>
            </div>
        </div>
    );
}

export default Overview;