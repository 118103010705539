import React from 'react';
import './GeneralStyle.scss'
import Footer from '../Components/Footer/Footer'
import Header from '../Components/Header/Header'
import Schoolyear from '../Components/Schoolyear/Schoolyear'
import SideNav from '../Components/SideNav/SideNav'

function SchoolyearPage()
{
    return (
        <div id="upd-main">
            <div className="main">
                <div className="main-wrapper">
                    <div id="mainLeft" className="main-left">
                        <SideNav />
                    </div>
                    <div id="mainRight" className="main-right">
                        <Header />
                        <Schoolyear />
                    </div>
                    <div className="clear"></div>
                </div>
            </div>
            <div className="footer">
                <Footer />
            </div>
        </div>
    );
}

export default SchoolyearPage;