import '../GeneralStyling.scss'
import MessageConverter from "../MessageConverter";
import JoinToSchoolService from "../../Service/JoinToSchoolService"
import MessageHandler from "../MessageHandler";

import React, { useState, useEffect, useCallback } from 'react';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { FilterMatchMode } from 'primereact/api';
import { Dropdown } from 'primereact/dropdown';
import { useNavigate } from 'react-router-dom';
import { Dialog } from 'primereact/dialog';
import { BlockUI } from 'primereact/blockui';

function UserManagement()
{
    const navigate = useNavigate();

    const [blocked, setBlocked] = useState(false);
    const [refreshKey, setRefreshKey] = useState(0);
    const [visibleDelete, setVisibleDelete] = useState(false);
    const [requestList, setRequestList] = useState([]);
    const [selectedRequest, setSelectedRequest] = useState(null);
    const [filters, setFilters] = useState({
        'fullname': { value: null, matchMode: FilterMatchMode.CONTAINS },
        'email': { value: null, matchMode: FilterMatchMode.CONTAINS },
        'roleID': { value: null, matchMode: FilterMatchMode.EQUALS },
    });
    const roles = [
        'Estrategy admin', 'Bazalt admin', 'Beheerder', 'Directie/Intern Begeleider', 'Leraar', 'Adviseur', 'Manager', 'LicenseAdministration'
    ];
    const [loading, setLoading] = useState(true);

    const [msg, setMsg] = useState([]);

    const newMessageHandler = (enteredMsg) =>
    {
        setMsg(enteredMsg);
    }

    const setMessageHandler = (msg) =>
    {
        newMessageHandler(msg);
    }

    const resendRequest = useCallback((e) =>
    {
        e.preventDefault();
        setMessageHandler();
        if (selectedRequest == null)
        {
            setMessageHandler(MessageConverter.convertBusinessMsg("Geen verzoek geselecteerd", 1));
        }
        else
        {
            setBlocked(true);
            JoinToSchoolService.resendJoinToSchool(selectedRequest.id).then(() =>
            {
                setMessageHandler(MessageConverter.convertBusinessMsg("Verzoek voor koppeling is succesvol opnieuw verzonden.", 0));
                setBlocked(false);
                setSelectedRequest(null);
            }).catch(err =>
            {
                setMessageHandler(MessageConverter.convertBusinessMsg(err));
                setBlocked(false);
            });
        }
    }, [selectedRequest]);

    // Delete request
    const deleteRequest = useCallback(() =>
    {
        setBlocked(true);
        JoinToSchoolService.deleteJoinToSchool(selectedRequest.id).then(() =>
        {
            setRefreshKey(oldKey => oldKey + 1);
            setMessageHandler(MessageConverter.convertBusinessMsg("Verzoek tot koppelen is succesvol ingetrokken.", 0));
            setVisibleDelete(false);
            setBlocked(false);
            setSelectedRequest(null);
        }).catch(err =>
        {
            setMessageHandler(MessageConverter.convertBusinessMsg(err));
            setBlocked(false);
            setVisibleDelete(false);
        });
    }, [selectedRequest]);

    const onDeleteButton = useCallback(() =>
    {
        setMessageHandler();
        if (selectedRequest == null)
        {
            setMessageHandler(MessageConverter.convertBusinessMsg("Geen verzoek geselecteerd", 1));
        }
        else
        {
            setVisibleDelete(true);
        }
    }, [selectedRequest]);

    const rolesBodyTemplate = (rowData) =>
    {
        return <span className={`customer-badge status-${rowData.roleID}`}>{rowData.roleID}</span>;
    }
    const rolesItemTemplate = (option) =>
    {
        return <span className={`customer-badge status-${option}`}>{option}</span>;
    }
    const rolesRowFilterTemplate = (options) =>
    {
        return <Dropdown value={options.value} options={roles}
            onChange={(e) => options.filterApplyCallback(e.value)}
            itemTemplate={rolesItemTemplate} placeholder="Selecteer een rol"
            className="element" showClear />;
    }

    useEffect(() =>
    {
        JoinToSchoolService.getList().then((res) =>
        {
            setRequestList(res.data);
            res.data.map(content =>
            {
                if (content.roleID == 1)
                { return content.roleID = "Estrategy Admin" }
                if (content.roleID == 2)
                { return content.roleID = "Bazalt Admin" }
                if (content.roleID == 3)
                { return content.roleID = "Beheerder" }
                if (content.roleID == 4)
                { return content.roleID = "Directie / Intern Begeleider" }
                if (content.roleID == 5)
                { return content.roleID = "Leraar" }
                if (content.roleID == 6)
                { return content.roleID = "Adviseur" }
                if (content.roleID == 7)
                { return content.roleID = "Manager" }
                if (content.roleID == 8)
                { return content.roleID = "LicenseAdministration" }
            });
            setLoading(false);
        })
    }, [refreshKey])

    return (
        <div id="Page">
            <BlockUI blocked={blocked} fullScreen />
            <div id="MainContentContainer">
                <div className="page-information">
                    <div id="PageHeader" className="page-header">
                        <span>Gebruikers: Verzoek voor koppeling</span>
                    </div>
                    <div className="clear"></div>
                </div>
                <div>
                    <div className="maincontent">
                        <div className="message-div">
                            <MessageHandler messages={msg} />
                        </div>
                        <div className="card">
                            <DataTable
                                value={requestList}
                                dataKey="id"
                                size="small"
                                paginator={true}
                                paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                                currentPageReportTemplate="{first} - {last} van {totalRecords}"
                                rows={10}
                                rowsPerPageOptions={[10, 20, 50, 100]}
                                filters={filters} filterDisplay="row"
                                loading={loading}
                                responsiveLayout="scroll"
                                selectionMode="multiple"
                                selection={selectedRequest}
                                onSelectionChange={e => setSelectedRequest(e.value[0])}
                                emptyMessage="Geen resultaten gevonden"
                                stateKey="UserManagement-UserJoinOverview">
                                <Column field="fullname"
                                    header="Gebruiker"
                                    filter={true}
                                    filterPlaceholder="Zoek op naam"
                                    sortable={true}
                                    showFilterMenu={false}
                                    style={{ width: '18rem' }} />
                                <Column field="email"
                                    header="Email"
                                    filter={true}
                                    filterPlaceholder="Zoek op email"
                                    sortable={true}
                                    showFilterMenu={false}
                                    style={{ width: '18rem' }} />
                                <Column field="roleID"
                                    header="Rol"
                                    body={rolesBodyTemplate}
                                    sortable={true}
                                    filter={true}
                                    showFilterMenu={false}
                                    filterElement={rolesRowFilterTemplate}
                                    style={{ width: '18rem' }} />
                            </DataTable>
                            <div className="clear"></div>
                        </div>
                    </div>
                    <div className="buttons">
                        <input type="submit" value="Verzoek opnieuw verzenden" className="management-buttons" onClick={resendRequest} />
                        <input type="submit" value="Verzoek intrekken" className="management-buttons" onClick={onDeleteButton} />
                    </div>
                    <Dialog
                        className="delete-dialog"
                        header={"Weet je zeker dat je dit verzoek tot koppeling wilt intrekken?"}
                        visible={visibleDelete}
                        onHide={() => setVisibleDelete(false)}
                        draggable={false}
                    >
                        <input type="submit" value="Ja" className="delete-button" onClick={deleteRequest} />
                        <input type="submit" value="Nee" className="cancel-delete-button" onClick={() => setVisibleDelete(false)} />
                    </Dialog>
                </div>
            </div>
        </div>
    );
}

export default UserManagement;