import '../GeneralStyling.scss'
import MessageConverter from "../MessageConverter";
import MessageHandler from "../MessageHandler";
import DocumentationService from '../../Service/DocumentationService'

import { BlockUI } from 'primereact/blockui';
import React, { useState, useEffect, useCallback } from 'react';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { FilterMatchMode } from 'primereact/api';
import { useNavigate, useLocation } from 'react-router-dom';
import { Dialog } from 'primereact/dialog';

function DocumentationEditView()
{
    const navigate = useNavigate();

    const [blocked, setBlocked] = useState(false);
    const [msg, setMsg] = useState([]);
    const [list, setList] = useState([]);
    const [selectedDoc, setSelectedDoc] = useState(null);
    const [loading, setLoading] = useState(true);
    const [filters, setFilters] = useState({
        'category': { value: null, matchMode: FilterMatchMode.CONTAINS },
        'displayName': { value: null, matchMode: FilterMatchMode.CONTAINS },
    });
    const [visibleDelete, setVisibleDelete] = useState(false);
    const [refreshKey, setRefreshKey] = useState(0);

    // Prevent going back
    useEffect(() =>
    {
        window.addEventListener('popstate', (e) =>
        {
            window.history.go(1);
        });
    }, []);

    const newMessageHandler = (enteredMsg) =>
    {
        setMsg(enteredMsg);
    }

    const setMessageHandler = (msg) =>
    {
        newMessageHandler(msg);
    }

    useEffect(() =>
    {
        DocumentationService.getDocumentationList().then((res) =>
        {
            setLoading(false);
            setList(res.data);
            res.data.map(category =>
            {
                if (category.category == 1)
                { return category.category = "Algemeen" }
                if (category.category == 2)
                { return category.category = "SoCo" }
            });

        }).catch(err => setMessageHandler(MessageConverter.convertBusinessMsg(err)))
    }, [refreshKey]);

    // Edit
    const editItem = useCallback(() =>
    {
        setMessageHandler();

        if (selectedDoc == null)
        {
            setMessageHandler(MessageConverter.convertBusinessMsg("Geen document geselecteerd", 1));
        }
        else
        {
            setBlocked(true);
            navigate("/Documentation/Item", { state: { documentID: selectedDoc.id } });
            setBlocked(false);
        }
    }, [selectedDoc]);

    // Add
    const addItem = useCallback(() =>
    {
        setMessageHandler();
        setBlocked(true);
        navigate("/Documentation/Item");
        setBlocked(false);
    }, []);

    // Delete
    const deleteMessage = useCallback(() =>
    {
        setBlocked(true);
        DocumentationService.deleteDocumentation(selectedDoc.id).then(() =>
        {
            setRefreshKey(oldKey => oldKey + 1);
            setMessageHandler(MessageConverter.convertBusinessMsg("Bericht is succesvol verwijderd", 0));
            setVisibleDelete(false);
            setBlocked(false);
        }).catch(err => { setMessageHandler(MessageConverter.convertBusinessMsg(err)); setBlocked(false); setVisibleDelete(false) });
    }, [selectedDoc]);

    const onDeleteButton = useCallback(() =>
    {
        setMessageHandler();
        if (selectedDoc == null)
        {
            setMessageHandler(MessageConverter.convertBusinessMsg("Geen item geselecteerd"));
        }
        else
        {
            setVisibleDelete(true);
        }
    }, [selectedDoc]);

    const imageBody = (item) =>
    {
        return <img src={`../img/${item.iconUrl}`} alt={item.displayName} className="document-icon"></img>
    };

    // General message
    const { state } = useLocation();
    useEffect(() =>
    {
        if (state !== null)
        {
            setMessageHandler(MessageConverter.convertBusinessMsg(state.confirmedMessage, 0));
        }
    }, []);

    return (
        <div id="Page">
            <BlockUI blocked={blocked} fullScreen />
            <div id="MainContentContainer">
                <div className="page-information">
                    <div id="PageHeader" className="page-header">
                        <span>Werkmaterialen beheer: Overzicht</span>
                    </div>
                    <div className="clear"></div>
                </div>
                <div>
                    <div className="maincontent">
                        <div className="message-div">
                            <MessageHandler messages={msg} />
                        </div>
                        <div className="card">
                            <DataTable
                                value={list}
                                dataKey="id"
                                size="small"
                                paginator={true}
                                paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                                currentPageReportTemplate="{first} - {last} van {totalRecords}"
                                rows={10}
                                rowsPerPageOptions={[10, 20, 50, 100]}
                                filters={filters} filterDisplay="row"
                                loading={loading}
                                selection={selectedDoc}
                                onSelectionChange={e => { setSelectedDoc(e.value[0]); }}
                                responsiveLayout="scroll"
                                selectionMode="multiple"
                                reorderableColumns
                                reorderableRows
                                onRowReorder={(e) => setList(e.value)}
                                emptyMessage="Geen resultaten gevonden">
                                <Column rowReorder style={{ width: '3rem' }} />
                                <Column field="category"
                                    sortable={true}
                                    filter={true}
                                    showFilterMenu={false}
                                    filterPlaceholder="Zoek op categorie"
                                    header="Categorie" />
                                <Column field="displayName"
                                    sortable={true}
                                    filter={true}
                                    showFilterMenu={false}
                                    filterPlaceholder="Zoek op bestandsnaam"
                                    header="Bestandsnaam" />
                                <Column field="iconUrl"
                                    body={imageBody}
                                />
                            </DataTable>
                            <div className="clear"></div>
                        </div>
                    </div>
                    <div className="buttons">
                        <input type="submit" value="Toevoegen" className="management-buttons" onClick={addItem} />
                        <input type="submit" value="Bewerken" className="management-buttons" onClick={editItem} />
                        <input type="submit" value="Verwijderen" className="management-buttons" onClick={onDeleteButton} />
                    </div>
                    <Dialog
                        className="delete-dialog"
                        header={"Weet je zeker dat je dit document wilt verwijderen?"}
                        visible={visibleDelete}
                        onHide={() => setVisibleDelete(false)}
                        draggable={false}
                    >
                        <input type="submit" value="Ja" className="delete-button" onClick={deleteMessage} />
                        <input type="submit" value="Nee" className="cancel-delete-button" onClick={() => setVisibleDelete(false)} />
                    </Dialog>
                </div>
            </div>
        </div>
    );
}

export default DocumentationEditView;