import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { EstrategyPassword } from "../estrategy.password.js";

import './ForgotPassword.scss';
import UserService from '../../Service/UserService';
import MessageConverter from "../MessageConverter";
import MessageHandler from '../MessageHandler';

function ShowPasswordRequirements()
{
    // password input field selector
    new EstrategyPassword(".login-field", {
        HideMaxLengthOnStart: true,
        // class that holds the password strength information
        CustomClass: ".password-strength-holder",
        ConfirmPasswordField: ".login-field2",
        // confirmpassword input field selector
        InfoTemplateText: "",
    });
}

function ResetPassword()
{
    const [password, setPassword] = useState("");
    const [passwordShown, setPasswordShown] = useState(false);
    const togglePassword = () => { setPasswordShown(!passwordShown); }
    const navigate = useNavigate();

    const [msg, setMsg] = useState([]);

    const setMessageHandler = (msg) =>
    {
        newMessageHandler(msg);
    }

    const newMessageHandler = (enteredMsg) =>
    {
        setMsg(enteredMsg);
    }

    const handleNewPassword = () =>
    {
        setMessageHandler();
        if (password !== "")
        {
            try
            {
                let urlElements = location.pathname.split('/')

                UserService.resetPassword(
                    parseInt(urlElements[2]), // userID
                    urlElements[3], // code
                    password
                ).then((res) =>
                {
                    if (res.status === 200)
                    {
                        navigate("/", { replace: true, state: { message: "Je kunt nu inloggen met je nieuwe wachtwoord", password: password } });
                    }
                }).catch((err) =>
                {
                    setMessageHandler(MessageConverter.convertBusinessMsg(err));
                });
            } catch (err)
            {
                if (err.status === 400)
                {
                    setMessageHandler(MessageConverter.convertBusinessMsg(err));
                }
                else
                {
                    setMessageHandler(MessageConverter.convertBusinessMsg(err));
                }
            }
        } else
        {
            setMessageHandler(MessageConverter.convertBusinessMsg("Geen nieuw wachtwoord ingevuld", 1));
        }

    };

    let initializedPasswordCheck = false;

    useEffect(() =>
    {
        ShowPasswordRequirements();

        if (!initializedPasswordCheck)
        {
            initializedPasswordCheck = true;
        }
    }, []);

    return (
        <div className="wrapperresetpassword" className="forgotpw-bg">
            <div id="MainContent_udpContainer">
                <div className="forgotpw-container">
                    <div className="forgotpw-header">
                        <div className="forgotpw-header-title">Wachtwoord opnieuw instellen</div>
                        <div className="clear"></div>
                    </div>
                    <MessageHandler messages={msg} />
                    <div className="forgotpw-email">
                        <span>Nieuw wachtwoord:</span>
                        <div className="forgotpw-wrapper">
                            <input
                                type={passwordShown ? "text" : "password"}
                                id="password"
                                className="login-field"
                                placeholder="Wachtwoord"
                                required
                                onChange={(e) => setPassword(e.target.value)}
                            >
                            </input>
                            <span className="password-toggle" onClick={togglePassword}>Toon</span>
                        </div>
                    </div>
                    <div className="forgotpw-email">
                        <span>Herhaal wachtwoord:</span>
                        <div className="forgotpw-wrapper">
                            <input
                                type={passwordShown ? "text" : "password"}
                                id="password"
                                className="login-field2"
                                placeholder="Wachtwoord"
                                required
                                onChange={(e) => setPassword(e.target.value)}
                            >
                            </input>
                            <span className="password-toggle" onClick={togglePassword}>Toon</span>
                        </div>
                    </div>
                    <div className="password-strength-holder" />
                    <div className="forgotpw-buttons">
                        <div className="request-button">
                            <input type="submit" className="forgotpw-button-style" value="Aanvragen" onClick={handleNewPassword} />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default ResetPassword;