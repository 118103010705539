import React from 'react';
import '../GeneralStyle.scss'
import SideNav from '../../Components/SideNav/SideNav'
import Footer from '../../Components/Footer/Footer'
import Header from '../../Components/Header/Header'
import Overview from '../../Components/SoCoRegistration/InputTeacher/Overview'

function OverviewTeacherPage()
{
    return (
        <div className="wrapper">
            <div id="upd-main">
                <div className="main">
                    <div className="main-wrapper">
                        <div id="mainLeft" className="main-left">
                            <SideNav />
                        </div>
                        <div id="mainRight" className="main-right">
                            <Header />
                            <Overview />
                        </div>
                        <div className="clear"></div>
                    </div>
                </div>
                <div className="footer">
                    <Footer />
                </div>
            </div>
        </div>
    );
}

export default OverviewTeacherPage;