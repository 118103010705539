import React, { useState } from 'react';
import '../GeneralStyle.scss'
import SelectSchoolc from '../../Components/Login/SelectSchool'
import SideNav from '../../Components/SideNav/SideNav'
import Footer from '../../Components/Footer/Footer'
import Header from '../../Components/Header/Header'
import MessageHandler from '../../Components/MessageHandler';


function SelectSchool()
{
    const [msg, setMsg] = useState([]);

    const newMessageHandler = (enteredMsg) =>
    {
        setMsg(enteredMsg);
    }

    return (
        <div id="upd-main">
            <div className="main">
                <div className="main-wrapper">
                    <div id="mainLeft" className="main-left">

                    </div>
                    <div id="mainRight" className="main-right-full-width hide-header-logo">
                        <Header />
                        <SelectSchoolc onNewMessage={newMessageHandler} />
                        <MessageHandler messages={msg} />
                    </div>
                    <div className="clear"></div>
                </div>
            </div>
            <div className="footer">
                <Footer />
            </div>
        </div>

    );
}

export default SelectSchool;