import React, { useState, useEffect } from "react";
import { Link, useNavigate, useLocation } from 'react-router-dom';
import { BlockUI } from 'primereact/blockui';
import moment from 'moment'
import { EstrategyPassword } from "../estrategy.password.js";

import UserService from '../../Service/UserService'
import UserSchoolService from '../../Service/UserSchoolService'
import AuthService from '../../Service/AuthService';
import MessageConverter from "../MessageConverter";
import MessageHandler from "../MessageHandler";
import Select from 'react-select';
import '../GeneralStyling.scss'

function ShowPasswordRequirements()
{
    // password input field selector
    new EstrategyPassword(".password-field", {
        HideMaxLengthOnStart: true,
        // class that holds the password strength information
        CustomClass: ".password-strength-holder",
        // confirmpassword input field selector
        ConfirmPasswordField: ".second-password-field",
        InfoTemplateText: "",
    });
}

function UserItem()
{
    const navigate = useNavigate();
    const [blocked, setBlocked] = useState(false);
    const { state } = useLocation();
    const roleValues = [
        {
            id: "1",
            label: "Beheerder",
            value: 3
        },
        {
            id: "2",
            label: "Directie/Intern Begeleider",
            value: 4
        },
        {
            id: "3",
            label: "Leraar",
            value: 5
        },
        {
            id: "5",
            label: "Manager",
            value: 7
        }
    ]

    const [validatePassword, setValidatePassword] = useState("");
    const [visible, setVisible] = useState(false);
    const [hiddenPasswordCheck, setHiddenPasswordCheck] = useState(true);
    const [placeholderPassword, setPlaceholderPassword] = useState("");
    const [requiredPassword, setRequiredPassword] = useState(true);
    const [formatDate, setFormatDate] = useState("");
    const [title, setTitle] = useState("");

    const [userDTO, setUserDTO] = useState({});
    const [userSchoolDTO, setUserSchoolDTO] = useState({});
    const [msg, setMsg] = useState([]);

    const newMessageHandler = (enteredMsg) =>
    {
        setMsg(enteredMsg);
    }

    const setMessageHandler = (msg) =>
    {
        newMessageHandler(msg);
    }

    useEffect(() =>
    {
        if (state !== null)
        {
            const userSchoolID = state;

            UserSchoolService.getUserSchool(userSchoolID.userSchoolID).then((res) =>
            {
                setUserSchoolDTO(res.data);

                UserService.getUser(res.data.userID).then((res) =>
                {
                    setUserDTO(res.data);
                    setValidatePassword(res.data.password);
                });
                setUserDTO({ ...userDTO, passwordType: 1 });
            });
            setPlaceholderPassword("Veld leeg laten indien ongewijzigd");
            setRequiredPassword(false);
            setTitle("Bewerken");
        } else if (state === null)
        {
            setTitle("Toevoegen");
            const schoolID = AuthService.getSchoolID();
            setUserSchoolDTO({ ...userSchoolDTO, schoolID: schoolID })
            setUserDTO({ ...userDTO, passwordType: 3 });
        }
    }, []);

    // Format birthdate from date-time to date
    useEffect(() =>
    {
        var date = userDTO.birthdate;
        if (date !== undefined && date !== null)
        {
            var convertDate = moment(date).format('YYYY-MM-DD');
            setFormatDate(convertDate);
        }
    })

    const saveUser = async (e) =>
    {
        e.preventDefault();
        setMessageHandler();
        if (userDTO.password === validatePassword)
        {
            setBlocked(true);
            try
            {
                if (state !== null)
                {
                    await UserService.updateUser(
                        userDTO,
                        userSchoolDTO
                    ).then(() =>
                    {
                        setBlocked(false);
                        navigate("/Gebruikers", { state: { confirmedMessage: "Gebruiker is succesvol opgeslagen" } });
                    });
                } else if (state === null)
                {
                    await UserService.createUser(
                        userDTO,
                        userSchoolDTO
                    ).then(() =>
                    {
                        setBlocked(false);
                        navigate("/Gebruikers", { state: { confirmedMessage: "Gebruiker is succesvol toegevoegd" } });
                    });
                }
            }
            catch (err)
            {
                setMessageHandler(MessageConverter.convertBusinessMsg(err))
                setBlocked(false);
            }
        } else if (userDTO.password !== validatePassword)
        {
            setMessageHandler(MessageConverter.convertBusinessMsg("Wachtwoorden komen niet overeen", 1));
        }
    }


    useEffect(() =>
    {
        if (userDTO.password !== null && userDTO.password !== undefined &&
            userDTO.password.length == 1 && hiddenPasswordCheck == true)
        {
            ShowPasswordRequirements();
            setHiddenPasswordCheck(false);
        }

    }, [userDTO.password]);

    return (
        <div id="ManageChildPage">
            <BlockUI blocked={blocked} fullScreen />
            <div id="MainContentContainer">
                <div className="page-information">
                    <div id="PageHeader" className="page-header">
                        <span>Gebruikers: {title}</span>
                    </div>
                    <div>
                        <div id="InfoButton">
                            <a className="btn-info"></a>
                        </div>
                    </div>
                    <div className="clear"></div>
                </div>
                <div className="maincontent">
                    <div className="message-div">
                        <MessageHandler messages={msg} />
                    </div>
                    <form onSubmit={saveUser} autoComplete="off">
                        <div className="maincontent-item">
                            <label>Rol*</label>
                            <Select
                                required={true}
                                options={roleValues}
                                getOptionLabel={(option) => option.label}
                                getOptionValue={(option) => option.value}
                                placeholder="Selecteer een Rol"
                                value={roleValues.find((element) => { return element.value == userSchoolDTO.roleID })}
                                onChange={(selectedRole) => setUserSchoolDTO({ ...userSchoolDTO, roleID: selectedRole.value })}
                                isSearchable={true}
                                isMulti={false}
                                noOptionsMessage={() => 'Geen resultaten gevonden'}
                            />
                        </div>

                        <div className="maincontent-item">
                            <label htmlFor="firstname">Voornaam*</label>
                            <input
                                id="firstname"
                                type="text"
                                required={true}
                                onInvalid={e => e.target.setCustomValidity("Dit veld is verplicht")}
                                onInput={e => e.target.setCustomValidity("")}
                                title="Dit veld is verplicht"
                                defaultValue={userDTO.firstname}
                                onChange={(e) => setUserDTO({ ...userDTO, firstname: e.target.value })}
                            />
                        </div>

                        <div className="maincontent-item">
                            <label htmlFor="middlename">Tussenvoegsel</label>
                            <input
                                id="middlename"
                                type="text"
                                defaultValue={userDTO.middlename}
                                onChange={(e) => setUserDTO({ ...userDTO, middlename: e.target.value })}
                            />
                        </div>

                        <div className="maincontent-item">
                            <label htmlFor="surname">Achternaam*</label>
                            <input
                                id="surname"
                                type="text"
                                defaultValue={userDTO.surname}
                                required={true}
                                title="Dit veld is verplicht"
                                onInvalid={e => e.target.setCustomValidity("Dit veld is verplicht")}
                                onInput={e => e.target.setCustomValidity("")}
                                onChange={(e) => setUserDTO({ ...userDTO, surname: e.target.value })}
                            />
                        </div>

                        <div className="maincontent-item">
                            <label htmlFor="email">E-mailadres*</label>
                            <input
                                id="email"
                                type="text"
                                defaultValue={userDTO.email}
                                required={true}
                                title="Dit veld is verplicht"
                                onInvalid={e => e.target.setCustomValidity("Dit veld is verplicht")}
                                onInput={e => e.target.setCustomValidity("")}
                                onChange={(e) => setUserDTO({ ...userDTO, email: e.target.value })}
                            />
                        </div>

                        <div className="maincontent-item">
                            <label htmlFor="password">Wachtwoord*</label>
                            <input
                                id="password"
                                className="password-field"
                                required={requiredPassword}
                                type="password"
                                title="Dit veld is verplicht"
                                autoComplete="new-password"
                                onInvalid={e => e.target.setCustomValidity("Dit veld is verplicht")}
                                onInput={e => e.target.setCustomValidity("")}
                                placeholder={placeholderPassword}
                                onChange={(e) => setUserDTO({ ...userDTO, password: e.target.value, passwordType: 3 })}
                            />
                        </div>

                        <div className="maincontent-item">
                            <label htmlFor="password2">Wachtwoord nogmaals*</label>
                            <input
                                id="password2"
                                className="second-password-field"
                                type="password"
                                placeholder={placeholderPassword}
                                required={requiredPassword}
                                title="Dit veld is verplicht"
                                onInvalid={e => e.target.setCustomValidity("Dit veld is verplicht")}
                                onInput={e => e.target.setCustomValidity("")}
                                onChange={(e) => setValidatePassword(e.target.value)} />
                        </div>

                        <div className="maincontent-item" hidden={hiddenPasswordCheck}>
                            <label></label>
                            <div className="password-strength-holder" />
                        </div>

                        <div className="maincontent-item">
                            <label htmlFor="birthdate">Geboortedatum</label>
                            <input
                                id="birthdate"
                                type="date"
                                defaultValue={formatDate}
                                max={moment().format('YYYY-MM-DD')}
                                onChange={(e) => setUserDTO({ ...userDTO, birthdate: e.target.value })} />
                        </div>

                        <div className="maincontent-item-buttons">
                            <input
                                type="submit"
                                value="Opslaan"
                                className="user-add-buttons" />
                            <Link to="/Gebruikers">
                                <input type="submit" value="Annuleren" className="user-add-buttons" />
                            </Link>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    );
}

export default UserItem;