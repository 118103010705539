import AuthService from "../../Service/AuthService";
import SchoolLicenseService from "../../Service/SchoolLicenseService";
import MessageConverter from "../MessageConverter";
import MessageHandler from "../MessageHandler"

import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { BlockUI } from 'primereact/blockui';

function LicenceSetting()
{
    const [schoolLicenseList, setSchoolLicenseList] = useState([]);
    const [loading, setLoading] = useState(true);
    const [licenseDTO, setLicenseDTO] = useState([]);
    const [refreshKey, setRefreshKey] = useState(0);
    const [blocked, setBlocked] = useState(false);
    const [msg, setMsg] = useState([]);

    const newMessageHandler = (enteredMsg) =>
    {
        setMsg(enteredMsg);
    }

    const setMessageHandler = (msg) =>
    {
        newMessageHandler(msg);
    }

    useEffect(() =>
    {
        const SchoolID = AuthService.getSchoolID();
        SchoolLicenseService.getListForSchool(SchoolID).then((res) =>
        {
            setSchoolLicenseList(res.data);
            setLoading(false);
            setLicenseDTO({ ...licenseDTO, id: res.data[0].id })
        })
    }, [refreshKey]);

    const startDatebody = (rowData) =>
    {
        return <>{moment(rowData.startDate).format("DD-MM-YYYY")}</>;
    };

    const endDatebody = (rowData) =>
    {
        if (rowData.endDate !== null)
        {
            return <>{moment(rowData.endDate).format("DD-MM-YYYY")}</>;
        }
    };

    const onSubmit = (e) =>
    {
        e.preventDefault();
        setMessageHandler();
        setBlocked(true);
        try
        {
            SchoolLicenseService.terminateSchoolLicense(licenseDTO).then((res) =>
            {
                setRefreshKey(oldKey => oldKey + 1);
                setMessageHandler(MessageConverter.convertBusinessMsg("Verzoek tot opzeggen module gelukt", 0));
                setBlocked(false);
            }).catch((err) => { setMessageHandler(MessageConverter.convertBusinessMsg(err)); setBlocked(false) });
        } catch (error)
        {
            setMessageHandler(MessageConverter.convertBusinessMsg(error));
            setBlocked(false);
        }
    }

    return (
        <form autoComplete="off" onSubmit={onSubmit}>
            <MessageHandler messages={msg} />
            <BlockUI blocked={blocked} fullScreen />
            <div className="page-divider">
                <div className="align-left">
                    <div className="sub-bar-item">
                        <label className="licence-settings">Licenties</label>
                        <DataTable
                            value={schoolLicenseList}
                            size="small"
                            loading={loading}
                            responsiveLayout="scroll"
                            style={{ width: '70%' }}
                            emptyMessage="Geen resultaten gevonden">
                            <Column
                                body="SoCo"
                                header="Module">
                            </Column>
                            <Column field="startDate"
                                body={startDatebody}
                                header="Begin">
                            </Column>
                            <Column field="endDate"
                                body={endDatebody}
                                header="Eind">
                            </Column>
                        </DataTable>
                    </div>
                </div>
                <div className="align-right">
                    <div className="sub-bar-item">
                        <label>Opzegging</label>
                        <p>Geef hieronder aan wat de einddatum moet worden. Tevens horen we graag waarom je de module op wilt zeggen.</p>
                    </div>
                    <div className="page-divider">
                        <div className="align-left-licence">
                            <div className="sub-bar-item">
                                <label>Module</label>
                                <p>Sociale Competentie</p>
                            </div>
                        </div>

                        <div className="align-right-licence">
                            <div className="sub-bar-item">
                                <label htmlFor="endDate">Einddatum*</label>
                                <input
                                    id="endDate"
                                    required={true}
                                    min={moment().add(2, 'M').startOf('month').format('YYYY-MM-DD')}
                                    onChange={(e) => setLicenseDTO({ ...licenseDTO, endDate: e.target.value })}
                                    type="date" />
                            </div>
                        </div>
                    </div>
                    <div className="sub-bar-item">
                        <p>Noteer hieronder het bankrekeningnummer waarop de restitutie van de licentie (indien van toepassing) op teruggestort kan worden.</p>
                        <input
                            placeholder="Rekeningnummer"
                            required={true}
                            className="licence-account-input"
                            onChange={(e) => setLicenseDTO({ ...licenseDTO, accountNumber: e.target.value })}
                            type="text" />
                        <input
                            placeholder="Naam rekeninghouder"
                            required={true}
                            className="licence-account-input"
                            onChange={(e) => setLicenseDTO({ ...licenseDTO, accountHolder: e.target.value })}
                            type="text" />
                        <input
                            placeholder="Plaats organisatie"
                            required={true}
                            onChange={(e) => setLicenseDTO({ ...licenseDTO, accountCity: e.target.value })}
                            type="text" />
                    </div>

                    <div className="sub-bar-item">
                        <p>Geef hieronder de reden op, waarom je de module(s) wilt be&euml;indigen:</p>
                        <textarea
                            className="textarea-end-module"
                            required={true}
                            onChange={(e) => setLicenseDTO({ ...licenseDTO, comment: e.target.value })}
                        />
                    </div>
                    <div className="sub-bar-item">
                        <p>Wat mis je in KIJK! Verder wat je wel vindt in een ander systeem?</p>
                        <textarea
                            className="textarea-end-module"
                            required={true}
                            onChange={(e) => setLicenseDTO({ ...licenseDTO, recommendation: e.target.value })}
                        />
                    </div>
                    <p>* De opzegtermijn betreft tenminste 1 volledige kalendermaand</p>
                    <div className="maincontent-item-buttons">
                        <input
                            type="submit"
                            value="Opzeggen bevestigen"
                            className="user-add-buttons" />
                    </div>
                </div>
            </div>


        </form>
    );
}

export default LicenceSetting;