const GetBase64 = (file) =>
{
    return new Promise(resolve =>
    {
        let fileInfo;
        let baseURL = "";
        // Make new FileReader
        let reader = new FileReader();

        // Convert the file to base64 text
        reader.readAsDataURL(file);

        // on reader load somthing...
        reader.onload = () =>
        {
            // Make a fileInfo Object
            baseURL = reader.result;
            resolve(baseURL);
        };
    });
};

const GetAttachmentDto = (file) =>
{
    return new Promise(resolve =>
    {
        GetBase64(file).then((base64Data) =>
        {
            // Skip the url part
            base64Data = base64Data
                .replace('data:', '')
                .replace(/^.+,/, '');
            resolve({
                file: base64Data,
                filename: file.name,
                mimetype: file.type
            });
        });
    });
};

const UploadHandler = {
    GetBase64,
    GetAttachmentDto
};

export default UploadHandler;