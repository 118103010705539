import BaseService from "../Service/BaseService";

const getSchool = (id) =>
{
    return new Promise((resolve, reject) =>
    {
        BaseService
            .get(`/School/${id}`, {})
            .then((response) =>
            {
                resolve(response);
            })
            .catch((error) => reject(error));
    });
};

const getForMigration = () =>
{
    return new Promise((resolve, reject) =>
    {
        BaseService
            .get('/School/list-available-for-migration/', {})
            .then((response) =>
            {
                resolve(response);
            })
            .catch((error) => reject(error));
    });
};

// Update school
const updateSchool = (schoolDTO) =>
{
    return new Promise((resolve, reject) =>
    {
        BaseService
            .put(
                '/School/update', schoolDTO, {})
            .then((response) =>
            {
                resolve(response);
            })
            .catch((error) => reject(error));
    });
};

const uploadLogoSchool = (logoDTO) =>
{
    return new Promise((resolve, reject) =>
    {
        BaseService
            .put(
                '/School/upload-logo', logoDTO, {})
            .then((response) =>
            {
                resolve(response);
            })
            .catch((error) => reject(error));
    });
};

const getLogoSchool = () =>
{
    return new Promise((resolve, reject) =>
    {
        BaseService
            .get('/School/get-logo/', {})
            .then((response) =>
            {
                resolve(response);
            })
            .catch((error) => reject(error));
    });
};

const uploadEdexML = (fileDTO) =>
{
    return new Promise((resolve, reject) =>
    {
        BaseService
            .put(
                '/School/upload-edexml', fileDTO, {})
            .then((response) =>
            {
                resolve(response);
            })
            .catch((error) => reject(error));
    });
};

const deleteLogo = () =>
{
    return new Promise((resolve, reject) =>
    {
        BaseService.delete('/School/remove-logo/', {})
            .then((response) =>
            {
                resolve(response);
            })
            .catch((error) => reject(error));
    });
};

const SchoolService = {
    getSchool,
    getForMigration,
    updateSchool,
    uploadLogoSchool,
    getLogoSchool,
    deleteLogo,
    uploadEdexML
};

export default SchoolService;