import React from "react";
import './GeneralStyle.scss'
import Footer from '../Components/Footer/Footer'
import Error from '../Components/404/Error'

function PageNotFound()
{
    return (
        <div id="wrapper">
            <div id="upd-main">
                <div className="main">
                    <div className="main-wrapper">
                        <div id="mainRight" className="main-right-full-width">
                            <Error/>
                        </div>
                    </div>
                </div>
                <div className="footer">
                    <Footer />
                </div>
            </div>
        </div>
    );
}

export default PageNotFound;