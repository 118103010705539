import baseService from "./BaseService";

const getDescriptionList = () =>
{
    return new Promise((resolve, reject) =>
    {
        var cacheKey = "SoCoSignalItemDescriptionList";
        var cacheVal = sessionStorage.getItem(cacheKey);
        if (cacheVal != null)
            resolve(JSON.parse(cacheVal));
        else
        {
            baseService
                .get('/SoCoSignalItemDescription/list/', {})
                .then((response) =>
                {
                    response.data.sort(function (a, b) { return b.sortOrder - a.sortOrder })
                    // store response
                    sessionStorage.setItem(cacheKey, JSON.stringify(response));
                    resolve(response);
                })
                .catch((error) => reject(error));
        }
    });
};

const SoCoSignalItemDescriptionService = {
    getDescriptionList
};

export default SoCoSignalItemDescriptionService;