import baseService from "../Service/BaseService";

const getSoCoRegistration = (id) =>
{
    return new Promise((resolve, reject) =>
    {
        baseService
            .get(`/SoCoRegistration/${id}`, {})
            .then((response) =>
            {
                resolve(response);
            })
            .catch((error) => reject(error));
    });
};

// Get the list of all available/visible schoolyears
const getClosedRegistrations = (schoolyearID) =>
{
    return new Promise((resolve, reject) =>
    {
        baseService
            .get(`/SoCoRegistration/list-for-edit/${schoolyearID}`, {})
            .then((response) =>
            {
                resolve(response);
            })
            .catch((error) => reject(error));
    });
};

const deleteRegistration = (id) =>
{
    return new Promise((resolve, reject) =>
    {
        baseService
            .delete(`/SoCoRegistration/${id}`, {})
            .then((response) =>
            {
                resolve(response);
            })
            .catch((error) => reject(error));
    });
};

const deleteRegistrationwithObservations = (id, deleteobservations) => {
    return new Promise((resolve, reject) => {
        baseService
            .delete(`/SoCoRegistration/del-with-obs/${id}/${deleteobservations}`, {})
            .then((response) => {
                resolve(response);
            })
            .catch((error) => reject(error));
    });
};

const reOpenRegistration = (soCoRegistrationDto) =>
{
    return new Promise((resolve, reject) =>
    {
        baseService
            .put(
                '/SoCoRegistration/re-open', soCoRegistrationDto, {})
            .then((response) =>
            {
                resolve(response);
            })
            .catch((error) => reject(error));
    });
};

const changeClosedate = (soCoRegistrationDto) =>
{
    return new Promise((resolve, reject) =>
    {
        baseService
            .put(
                '/SoCoRegistration/changeCloseDate', soCoRegistrationDto, {})
            .then((response) =>
            {
                resolve(response);
            })
            .catch((error) => reject(error));
    });
};

const getOpenRegSoCo = (studentSchoolID, groupID, type) =>
{
    return new Promise((resolve, reject) =>
    {
        baseService
            .get(`/SoCoRegistration/get-open-reg/${studentSchoolID}/${groupID}/${type}`, {})
            .then((response) =>
            {
                resolve(response);
            })
            .catch((error) => reject(error));
    });
};

//Update SoCoregistration
const updateSoCoRegistrationAsUser = (
    soCoRegistration,
    soCoRegistrationScoreList,
    previousSoCoRegistration
) =>
{
    return new Promise((resolve, reject) =>
    {
        baseService
            .put(
                '/SoCoRegistration/update-as-user',
                {
                    soCoRegistration,
                    soCoRegistrationScoreList,
                    previousSoCoRegistration
                },
            )
            .then((response) =>
            {
                resolve(response);
            })
            .catch((error) => reject(error));
    });
};

// Update SoCoregistration
const updateSoCoRegistrationAsStudent = (
    soCoRegistration,
    soCoRegistrationScoreList,
    previousSoCoRegistration
) =>
{
    return new Promise((resolve, reject) =>
    {
        baseService
            .put(
                '/SoCoRegistration/update-as-student',
                {
                    soCoRegistration,
                    soCoRegistrationScoreList,
                    previousSoCoRegistration
                },
            )
            .then((response) =>
            {
                resolve(response);
            })
            .catch((error) => reject(error));
    });
};

// Close SoCoregistration
const closeSocoRegistration = (
    registrationIDs,
    closedate
) =>
{
    return new Promise((resolve, reject) =>
    {
        baseService
            .put(
                '/SoCoRegistration/close',
                {
                    registrationIDs,
                    closedate
                },
            )
            .then((response) =>
            {
                resolve(response);
            })
            .catch((error) => reject(error));
    });
};

const SoCoRegistrationService = {
    getSoCoRegistration,
    getClosedRegistrations,
    deleteRegistration,
    reOpenRegistration,
    changeClosedate,
    getOpenRegSoCo,
    updateSoCoRegistrationAsUser,
    closeSocoRegistration,
    updateSoCoRegistrationAsStudent,
    deleteRegistrationwithObservations
};

export default SoCoRegistrationService;