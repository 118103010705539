import baseService from "../Service/BaseService";

// Get the list of all available/visible schoolyears
const getSchoolyearList = () =>
{
    return new Promise((resolve, reject) =>
    {
        var cacheKey = "SchoolyearList";
        var cacheVal = sessionStorage.getItem(cacheKey);
        if (cacheVal != null)
            resolve(JSON.parse(cacheVal));
        else
        {
            baseService
                .get('/Schoolyear/list/', { })
                .then((response) =>
                {
                    response.data.sort(function (a, b) { return b.sortOrder - a.sortOrder })
                    // store response
                    sessionStorage.setItem(cacheKey, JSON.stringify(response));
                    resolve(response);
                })
                .catch((error) => reject(error));
        }
    });
};

// Get the list of all available/visible schoolyears for soco reports
const getSchoolyearListForSocoReports = () =>
{
    return new Promise((resolve, reject) =>
    {
        var cacheKey = "SchoolyearListSoCoReports";
        var cacheVal = sessionStorage.getItem(cacheKey);
        if (cacheVal != null)
            resolve(JSON.parse(cacheVal));
        else
        {
            baseService
                .get('/Schoolyear/soco/get-for-report', { })
                .then((response) =>
                {
                    response.data.sort(function (a, b) { return b.sortOrder - a.sortOrder })
                    // store response
                    sessionStorage.setItem(cacheKey, JSON.stringify(response));
                    resolve(response);
                })
                .catch((error) => reject(error));
        }
    });
};

// Get the active schoolyear
const getActiveSchoolyear = () =>
{
    return new Promise((resolve, reject) =>
    {
        var cacheKey = "ActiveSchoolyear";
        var cacheVal = sessionStorage.getItem(cacheKey);
        if (cacheVal != null)
            resolve(JSON.parse(cacheVal));
        else
        {
            baseService
                .get('/Schoolyear/get-active', {})
                .then((response) =>
                {
                    // store response
                    sessionStorage.setItem(cacheKey, JSON.stringify(response));
                    resolve(response);
                })
                .catch((error) => reject(error));
        }
    });
};

// Activate schoolyear
const activateSchoolyear = (schoolyearDTO) =>
{
    return new Promise((resolve, reject) =>
    {
        baseService
            .put(
                '/Schoolyear/activate', schoolyearDTO, {})
            .then((response) =>
            {
                resolve(response);
            })
            .catch((error) => reject(error));
    });
};

const SchoolyearService = {
    getSchoolyearList,
    getSchoolyearListForSocoReports,
    getActiveSchoolyear,
    activateSchoolyear,
};

export default SchoolyearService;