import React, { useState, useRef } from 'react';
import { Calendar } from 'primereact/calendar';
import moment from 'moment';
import { addLocale } from 'primereact/api';

export const DateFilter = (options) =>
{
    addLocale('nl', {
        firstDayOfWeek: 1,
        dayNames: ['zondag', 'maandag', 'dinsdag', 'woensdag', 'donderdag', 'vrijdag', 'zaterdag'],
        dayNamesShort: ['zo', 'ma', 'di', 'wo', 'do', 'vr', 'za'],
        dayNamesMin: ['zo', 'ma', 'di', 'wo', 'do', 'vr', 'za'],
        monthNames: ['januari', 'februari', 'maart', 'april', 'mei', 'juni', 'juli', 'augustus', 'sptember', 'oktober', 'november', 'december'],
        monthNamesShort: ['jan', 'feb', 'mar', 'apr', 'mei', 'jun', 'jul', 'aug', 'sep', 'okt', 'nov', 'dec'],
        today: 'Vandaag',
    });

    const dateRef = useRef();

    const [defaultDate, setDefaultDate] = useState(null);

    const onKeyDown = (event) =>
    {
        if (event.key === 'Enter')
        {
            const date = moment(event.target.value, 'DD-MM-YYYY').toDate();
            options.filterApplyCallback(date, options.index);
            dateRef.current.hide();
            setDefaultDate(null);
        }
    };

    return (
        <Calendar
            locale="nl"
            value={options.value || defaultDate}
            ref={dateRef}
            onChange={(e) =>
            {
                dateRef.current.show();
                if (e.value !== null && e.value !== undefined)
                {
                    setDefaultDate(e.value);
                    dateRef.current.updateViewDate(null, e.value);
                    options.filterApplyCallback(e.value, options.index)
                    setDefaultDate(null);
                }
            }}
            dateFormat="dd-mm-yy"
            placeholder="dd-mm-jjjj"
            mask="99-99-9999"
            onKeyDown={onKeyDown}
        />
    );
};