import '../GeneralStyling.scss'
import CommunicationScreenService from "../../Service/CommunicationScreenService"
import MessageConverter from '../MessageConverter'
import './Home.scss'
import MessageHandler from "../MessageHandler";

import { ScrollPanel } from 'primereact/scrollpanel';
import { Editor, EditorState, convertFromRaw, CompositeDecorator } from "draft-js";
import React, { useEffect, useState } from 'react';
import Slider from "react-slick";
import moment from 'moment'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import HelpPageService from '../../Service/HelpPageService';
import { Dialog } from 'primereact/dialog';
import HelpItemDescription from '../HelpPage/HelpItemDescription';

function Home(props)
{
    const announcementItems = [];
    const tipsItems = [];

    const [announcementContent, setAnnouncementcontent] = useState([]);
    const [tipsContent, setTipsContent] = useState([]);
    const [dates, setDates] = useState([]);
    const [tipsTitles, setTipsTitles] = useState([]);
    const [announcementTitles, setAnnouncementTitles] = useState([]);
    
    const [msg, setMsg] = useState([]);

    const [helpItem, setHelpItem] = useState(null);
    const [helpVisible, setHelpVisible] = useState(false);
    const [helpButtonHidden, sethelpButtonHidden] = useState(true);

    const currentPathName = window.location.pathname;
    
    const findImageEntities = (contentBlock, callback, contentState) => {
        contentBlock.findEntityRanges(
            (character) => {
                const entityKey = character.getEntity();
                return (
                    entityKey !== null &&
                    contentState.getEntity(entityKey).getType() === 'IMAGE'
                );
            },
            callback
        );
    }
        
    const Image = (props) => {
        const { height, src, width } = props.contentState.getEntity(props.entityKey).getData();
        return (
            <img src={src} height={height} width={width}/>
        );
    };

    const customDecorators = [{
        strategy: findImageEntities,
        component: Image,
    },
    ];

    const newMessageHandler = (enteredMsg) =>
    {
        setMsg(enteredMsg);
    }

    const setMessageHandler = (msg) =>
    {
        newMessageHandler(msg);
    }

    useEffect(() =>
    {
        CommunicationScreenService.getCSListUI().then((res) =>
        {
            // Divide messages in tips and announcements
            res.data.map((item) =>
            {
                if (item.type == 1)
                {
                    announcementItems.push(item)
                } else if (item.type == 2)
                {
                    tipsItems.push(item)
                }
            })

            // Get content from messages
            announcementItems.map((announcement) =>
            {
                setAnnouncementcontent(oldContent => [...oldContent, EditorState.createWithContent(convertFromRaw(JSON.parse(announcement.content)), new CompositeDecorator(customDecorators))])
                setDates(oldContent => [...oldContent, announcement.startDate]);
                setAnnouncementTitles(oldContent => [...oldContent, announcement.description]);
            })

            tipsItems.map((tips) =>
            {
                setTipsContent(oldContent => [...oldContent, EditorState.createWithContent(convertFromRaw(JSON.parse(tips.content)), new CompositeDecorator(customDecorators))]);
                setTipsTitles(oldContent => [...oldContent, tips.description]);
            })
        }).catch((err) => setMessageHandler(MessageConverter.convertBusinessMsg(err)));
    }, [])

    // Preload helpItems
    useEffect(() => {
        if (helpItem == null) {
            HelpPageService.getHelpItembyPage(currentPathName).then((res) => {
                if (res.data != undefined && res.data != null && res.data != '') {
                    setHelpItem(res.data);
                    sethelpButtonHidden(false);
                }
                else {
                    sethelpButtonHidden(true);
                }
            }).catch(err => {
                setMessageHandler(MessageConverter.convertBusinessMsg(err));
            });
        }
    }, []);

    const [sliderRef, setSliderRef] = useState(null)
    const [sliderRef2, setSliderRef2] = useState(null)
    const [activeSlide, setActiveSlide] = useState(0)
    const [activeSlide2, setActiveSlide2] = useState(0)

    const sliderSettings = {
        // removes default buttons
        arrows: false,
        slidesToShow: 1,
        slidesToScroll: 1,
        infinite: true,
        speed: 200,
        swipeToSlide: true,
    }

    const checkDate = (date) =>
    {
        if (date !== null && date !== undefined)
        {
            return moment(date).format('LL')
        }
        else return ""
    }

    return (
        <div id="Page">
            <div id="MainContentContainer">
                <div className="page-information">
                    <div id="PageHeader" className="page-header">
                        <span>Hoofdscherm: Start</span>
                    </div>
                    <div>
                        <div id="InfoButton" onClick={() => setHelpVisible(true)} hidden={helpButtonHidden}>
                            <i className="btn-info"></i>
                        </div>
                        <Dialog
                            header={"Hoofdscherm"}
                            visible={helpVisible}
                            modal={true}
                            onHide={() => setHelpVisible(false)}
                            style={{ width: '75vw' }}
                        >
                            <Editor className="textfield" editorState={HelpItemDescription(helpItem)} readOnly="true" />
                        </Dialog>
                    </div>
                    <div className="clear"></div>
                </div>
                <div className="message-div">
                    <MessageHandler messages={msg} />
                </div>
                <div className="maincontent">
                    <div className="homepage-divider">
                        <div className="homepage-1">
                            <p>Je bent nu in de digitale omgeving van KIJK! Verder. Hier registreer je de ontwikkeling van de kinderen en kunnen kinderen een eigen lijst invullen. Je kunt hier verschillende rapportages inzien en printen. Op de meeste pagina's vind je een knop met een vraagteken (rechtsboven) waarin je uitleg krijgt over die betreffende pagina.</p>
                            <p>Om goed met KIJK! Verder te werken heb je de bijbehorende boeken nodig. Je kunt deze bestellen in de <a href="https://shop.bazalt.nl/search?sSearch=kijk" target="_blank">webshop</a>. We adviseren ook een training te volgen om de uitgangspunten en de werkwijze goed te doorgronden. Zo kun je KIJK! Verder optimaal gebruiken. We bieden trainingen via de <a href="https://www.bazaltgroep.nl/agenda/" target="_blank">open inschrijving</a> of we bieden de <a href="https://www.bazaltgroep.nl/dienst/?search=KIJK%21&related_services_themes%5B0%5D=8226" target="_blank">training op locatie</a>.</p>

                        </div>
                        <div className="homepage-2">
                            <div className="header-block-2">
                                <div className="header-icon info-icon"></div>
                                <div className="header-title">Tips &amp; weetjes</div>
                                <div className="navigation-controls">
                                    <div className="message-prev" onClick={sliderRef?.slickPrev}></div>
                                    <div className="message-next" onClick={sliderRef?.slickNext}></div>
                                </div>
                            </div>
                            <div className="body-block-2">
                                <ScrollPanel className="scrollpanel">
                                    <Slider ref={setSliderRef} {...sliderSettings} beforeChange={(current, next) => setActiveSlide2(next)}>
                                        {tipsContent.map((feature, index) => (
                                            <div className="home-content" key={index}>
                                                <h2 key={tipsTitles[activeSlide2]}>{tipsTitles[activeSlide2]}</h2>
                                                <Editor className="textfield" editorState={feature} readOnly={true} key={index} decorators={customDecorators} />
                                            </div>
                                        ))}
                                    </Slider>
                                </ScrollPanel>
                            </div>
                        </div>
                        <div className="homepage-3">
                            <div className="header-block-3">
                                <div className="header-icon info-icon"></div>
                                <div className="header-title">Melding {checkDate(dates[activeSlide])}</div>
                                <div className="navigation-controls">
                                    <div className="message-prev" onClick={sliderRef2?.slickPrev}></div>
                                    <div className="message-next" onClick={sliderRef2?.slickNext}></div>
                                </div>
                            </div>
                            <div className="body-block-3">
                                <ScrollPanel className="scrollpanel">
                                    <Slider ref={setSliderRef2} {...sliderSettings} beforeChange={(current, next) => setActiveSlide(next)}>
                                        {announcementContent.map((feature, index) => (
                                            <div className="home-content" key={index}>
                                                <h2>{announcementTitles[activeSlide]}</h2>
                                                <Editor className="textfield" editorState={feature} readOnly={true} key={index} decorators={customDecorators} />
                                            </div>
                                        ))}
                                    </Slider>
                                </ScrollPanel>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Home;