import React, { useState } from 'react';
import './ForgotPassword.scss';
import UserService from '../../Service/UserService';
import MessageConverter from "../MessageConverter";
import MessageHandler from '../MessageHandler';

function ForgotPassword()
{
    const [email, setEmail] = useState("");
    const [msg, setMsg] = useState([]);

    const setMessageHandler = (msg) =>
    {
        newMessageHandler(msg);
    }

    const newMessageHandler = (enteredMsg) =>
    {
        setMsg(enteredMsg);
    }

    const handlePasswordRequest = async (e) =>
    {
        setMessageHandler();
        e.preventDefault();
        if (email !== "")
        {
            try
            {
                UserService.forgotPasswordRequest(email).then((res) =>
                {
                    if (res?.status === 200)
                    {
                        setMessageHandler(MessageConverter.convertBusinessMsg(res, 0));
                    }
                }).catch((err) =>
                {
                    if (err.status === 404)
                        setMessageHandler(MessageConverter.convertBusinessMsg("Indien het e-mailadres bekend is, is er een e-mail verzonden", 0));
                    else
                    {
                        setMessageHandler(MessageConverter.convertBusinessMsg(err));
                    }
                });
            } catch (err)
            {
                setMessageHandler(MessageConverter.convertBusinessMsg(err));
            }
        } else setMessageHandler(MessageConverter.convertBusinessMsg("Vul een email adres in", 1))
    };


    return (
        <div id="content" className="forgotpw-bg">
            <div id="MainContent_udpContainer">
                <div className="forgotpw-container">
                    <div className="forgotpw-header">
                        <div className="forgotpw-header-title">Wachtwoord vergeten</div>
                        <div className="clear"></div>
                    </div>
                    <MessageHandler messages={msg} />
                    <div className="forgotpw-email">
                        <span>Geef hieronder uw e-mailadres op.<br />Nadat u op de knop aanvragen heeft geklikt ontvangt u een e-mail, met daarin een link waarmee het wachtwoord opnieuw ingesteld kan worden.</span>
                        <div className="forgotpw-wrapper">
                            <span>E-mailadres:</span>
                            <input
                                type="email"
                                id="email"
                                className="login-field"
                                placeholder="Vul je e-mailadres in"
                                required
                                onChange={(e) => setEmail(e.target.value)} />
                        </div>
                    </div>
                    <div className="forgotpw-buttons">
                        <div className="request-button">
                            <input type="submit" className="forgotpw-button-style" value="Aanvragen" onClick={handlePasswordRequest} />
                        </div>
                    </div>
                </div>
            </div>
            <input type="image" className="kijk-logo" src="/img/kijk-logo.png" alt="logo kijk"></input>
        </div>
    );
}

export default ForgotPassword;