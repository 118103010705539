import baseService from "../Service/BaseService";

const createRemoteRestriction = (restrictionDTO) =>
{
    return new Promise((resolve, reject) =>
    {
        baseService
            .put(
                '/RemoteRestriction/create', restrictionDTO, {})
            .then((response) =>
            {
                resolve(response);
            })
            .catch((error) => reject(error));
    });
};

const updateRemoteRestriction = (restrictionDTO) =>
{
    return new Promise((resolve, reject) =>
    {
        baseService
            .put(
                '/RemoteRestriction/update', restrictionDTO, {})
            .then((response) =>
            {
                resolve(response);
            })
            .catch((error) => reject(error));
    });
};

const getRemoteRestritionList = () =>
{
    return new Promise((resolve, reject) =>
    {
        baseService
            .get('/RemoteRestriction/list', {})
            .then((response) =>
            {
                resolve(response);
            })
            .catch((error) => { reject(error) });
    });
};

const getIPRemoteRestrition = () =>
{
    return new Promise((resolve, reject) =>
    {
        baseService
            .get('/RemoteRestriction/get-ip', {})
            .then((response) =>
            {
                resolve(response);
            })
            .catch((error) => { reject(error) });
    });
};

const deleteRemoteRestriction = (id) =>
{
    return new Promise((resolve, reject) =>
    {
        baseService.delete(`/RemoteRestriction/${id}`, {})
            .then((response) =>
            {
                resolve(response);
            })
            .catch((error) => reject(error));
    });
};

const getRemoteRestrition = (id) =>
{
    return new Promise((resolve, reject) =>
    {
        baseService
            .get(`/RemoteRestriction/${id}`, {})
            .then((response) =>
            {
                resolve(response);
            })
            .catch((error) => { reject(error) });
    });
};


const RemoteRestrictionService = {
    createRemoteRestriction,
    getRemoteRestritionList,
    getIPRemoteRestrition,
    updateRemoteRestriction,
    deleteRemoteRestriction,
    getRemoteRestrition
};

export default RemoteRestrictionService;