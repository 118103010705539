import baseService from "./BaseService";

// Create Join to school
const createJoinToSchool = (
    userID,
    schoolID,
    roleID
) =>
{
    return new Promise((resolve, reject) =>
    {
        baseService
            .put(
                '/JoinToSchool/create',
                {
                    userID,
                    schoolID,
                    roleID
                }
            )
            .then((response) =>
            {
                resolve(response);
            })
            .catch((error) => reject(error));
    });
};

// Confirm Join to school
const confirmJoinToSchool = (
    userID,
    id,
    code
) =>
{
    return new Promise((resolve, reject) =>
    {
        baseService
            .put(
                `/JoinToSchool/confirm/${code}`,
                {
                    id,
                    userID
                }
            )
            .then((response) =>
            {
                resolve(response);
            })
            .catch((error) => reject(error));
    });
};

const getList = () =>
{
    return new Promise((resolve, reject) =>
    {
        baseService
            .get('/JoinToSchool/list', {})
            .then((response) =>
            {
                resolve(response);
            })
            .catch((error) => reject(error));
    });
};

// Confirm Join to school
const resendJoinToSchool = (id) =>
{
    return new Promise((resolve, reject) =>
    {
        baseService
            .put(
                `/JoinToSchool/resend/${id}`, {}
            )
            .then((response) =>
            {
                resolve(response);
            })
            .catch((error) => reject(error));
    });
};

const deleteJoinToSchool = (id) =>
{
    return new Promise((resolve, reject) =>
    {
        baseService.delete(`/JoinToSchool/${id}`, {})
            .then((response) =>
            {
                resolve(response);
            })
            .catch((error) => reject(error));
    });
};

const JoinToSchoolService = {
    createJoinToSchool,
    confirmJoinToSchool,
    getList,
    resendJoinToSchool,
    deleteJoinToSchool
};

export default JoinToSchoolService;