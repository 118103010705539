import React from 'react';
import './Error.scss';
import { Link } from 'react-router-dom';

function Error()
{
	return (
		<div id="Page">
			<div id="MainContentContainer">
				<div className="error-container">
					<div className="error-header">
						<div className="error-header-title">404 Error - Pagina niet gevonden</div>
						<div className="clear"></div>
					</div>
					<div className="error-buttons">
						<div className="error-button">
							<Link to="/Home" type="submit" className="error-button-style">Home</Link>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}

export default Error;