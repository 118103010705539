import BaseService from "./BaseService";

// Get the list of permissions for user
const getPermissionListForUser = () =>
{
    return new Promise((resolve, reject) =>
    {
        BaseService
            .get('/Permission/list-for-user', { })
            .then((response) =>
            {
                resolve(response);
            })
            .catch((error) => reject(error));
    });
};

const PermissionService = {
    getPermissionListForUser
};

export default PermissionService;