import React from 'react';
import '../../GeneralStyle.scss'
import SideNav from '../../../Components/SideNav/SideNav'
import Footer from '../../../Components/Footer/Footer'
import Header from '../../../Components/Header/Header'
import SoCoGroupReport from '../../../Components/Reports/SoCo/SoCoGroupReport'

function SoCoGroupReportPage()
{
    return (
        <div id="upd-main">
            <div className="main">
                <div className="main-wrapper">
                    <div id="mainLeft" className="main-left">
                        <SideNav />
                    </div>
                    <div id="mainRight" className="main-right">
                        <Header />
                        <SoCoGroupReport />
                    </div>
                    <div className="clear"></div>
                </div>
            </div>
            <div className="footer">
                <Footer />
            </div>
        </div>
    );
}

export default SoCoGroupReportPage;