import React, { useEffect, useState } from 'react';
import { getUA } from 'react-device-detect';
import { Dialog } from 'primereact/dialog';
import { Editor, EditorState, convertFromRaw, CompositeDecorator } from "draft-js";

import '../GeneralStyling.scss'
import './info.scss'
import MessageConverter from "../MessageConverter";
import MessageHandler from "../MessageHandler";
import UserService from '../../Service/UserService';
import HelpPageService from '../../Service/HelpPageService';
import HelpItemDescription from '../HelpPage/HelpItemDescription';

function Info()
{
    const [info, setInfo] = useState([]);
    const [browser, setBrowser] = useState("");
    const [msg, setMsg] = useState([]);

    const [helpItem, setHelpItem] = useState(null);
    const [helpVisible, setHelpVisible] = useState(false);
    const [helpButtonHidden, sethelpButtonHidden] = useState(true);
    const currentPathName = window.location.pathname;

    const newMessageHandler = (enteredMsg) =>
    {
        setMsg(enteredMsg);
    }

    const setMessageHandler = (msg) =>
    {
        newMessageHandler(msg);
    }

    useEffect(() =>
    {
        setBrowser(getUA);

        UserService.getUserInfo().then((res) =>
        {
            setInfo(res.data);
        }).catch((err) => setMessageHandler(MessageConverter.convertBusinessMsg(err)));
    }, []);

    // Prevent going back
    useEffect(() =>
    {
        window.addEventListener('popstate', (e) =>
        {
            window.history.go(1);
        });
    }, []);

    // Preload helpItems
    useEffect(() => {
        if (helpItem == null) {
            HelpPageService.getHelpItembyPage(currentPathName).then((res) => {
                if (res.data != undefined && res.data != null && res.data != '') {
                    setHelpItem(res.data);
                    sethelpButtonHidden(false);
                }
                else {
                    sethelpButtonHidden(true);
                }
            }).catch(err => {
                setMessageHandler(MessageConverter.convertBusinessMsg(err));
            });
        }
    }, []);

    return (
        <div id="Page">
            <div id="MainContentContainer">
                <div className="page-information">
                    <div id="PageHeader" className="page-header">
                        <span>Info: Overzicht</span>
                    </div>
                    <div id="InfoButton">
                        <div className="btn-info" onClick={() => setHelpVisible(true)} hidden={helpButtonHidden}></div>
                    </div>
                    <Dialog
                        header={"Info: Overzicht"}
                        visible={helpVisible}
                        modal={true}
                        onHide={() => setHelpVisible(false)}
                        style={{ width: '75vw' }}
                    >
                        <Editor className="textfield" editorState={HelpItemDescription(helpItem)} readOnly="true" />
                    </Dialog>
                    <div className="clear" />
                </div>
                <div>
                    <div className="maincontent">
                        <div className="message-div">
                            <MessageHandler messages={msg} />
                        </div>
                        <div className="maincontent-item">
                            <label>Gebruiker ID:</label>
                            <label>{info.userID}</label>
                        </div>
                        <div className="maincontent-item">
                            <label>Gebruiker school ID:</label>
                            <label>{info.userSchoolID}</label>
                        </div>
                        <div className="maincontent-item">
                            <label>School ID:</label>
                            <label>{info.schoolID}</label>
                        </div>
                        <div className="maincontent-item">
                            <label>IP-Adres:</label>
                            <label>{info.ipAddress}</label>
                        </div>
                        <div className="maincontent-item">
                            <label>Browser(useragent):</label>
                            <label className="info-browser">{browser}</label>
                        </div>
                        <div className="maincontent-item">
                            <label>Versienummer:</label>
                            <label>{process.env.REACT_APP_VERS}</label>
                        </div>
                        <div className="sub-bar-item">
                            <label className="info-cookies">
                                Het gebruik van cookies:
                            </label>
                            <p className="info-page-text">
                                In deze applicatie worden cookies gebruikt om de integriteit van de registratieprocedure te waarborgen en deze te kunnen personaliseren op basis van login gegevens. Cookies zijn kleine informatiebestandjes die bij het bezoeken van een website automatisch kunnen worden opgeslagen op of uitgelezen van jouw device (waaronder een PC, tablet of smartphone). Dat gebeurt via de webbrowser op het device. De informatie die een cookie verkrijgt over je gebruik van de website kan worden overgebracht naar de beveiligde server van kijkregistratie.nl.
                            </p>

                            <p>
                                Binnen kijkregistratie worden cookies gebruikt om:
                            </p>
                            <ul>
                                <li>functionaliteiten van de applicatie mogelijk te maken en om de applicatie te beschermen (technische of functionele cookies);</li>
                                <li>het gebruik van de applicatie te analyseren en op basis daarvan de applicatie te verbeteren (analytische cookies);</li>
                            </ul>

                            <p><b>Technische of functionele cookies</b></p>
                            <p className="info-page-text">
                                Het voornaamste doel van cookies is om jouw tijd en moeite te besparen. Als je bijvoorbeeld een webpagina aan jouw persoonlijke voorkeuren aanpast of als je navigeert in een website, worden dankzij cookies de gekozen instellingen en voorkeuren onthouden voor toekomstig gebruik. Wanneer je de website later weer bezoekt, kunnen de eerder ingevoerde gegevens worden opgehaald door de cookies, waardoor je eenvoudig weer de mogelijkheden van de website kunt gebruiken zoals je die eerder hebt ingesteld.
                            </p>

                            <p><b>Analytische cookies</b></p>
                            <p>
                                Daarnaast worden er in deze applicatie cookies gebruikt voor het bijhouden van geanonimiseerde statistieken, zodat wij inzicht kunnen krijgen in het gebruik van de applicatie. KIJK! Verder gebruikt de geanonimiseerde analytische cookies ten behoeve van Google Analytics, welke wordt gebruikt voor performance analyse.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Info;