import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.scss';
import App from './App';
import "bootstrap/dist/css/bootstrap.min.css";
import 'primereact/resources/themes/lara-light-purple/theme.css';
import "primereact/resources/primereact.min.css";
import { library } from '@fortawesome/fontawesome-svg-core'
import{ faChevronRight, faChevronLeft, faChevronDown,
        faRotate, faFilter, faFilterCircleXmark,
        faAnglesRight, faAnglesLeft
    } from '@fortawesome/free-solid-svg-icons'
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';

library.add(faChevronRight, faChevronLeft, faChevronDown, faRotate, faFilter, faFilterCircleXmark, faAnglesRight, faAnglesLeft)

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(<App />);