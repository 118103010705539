import HelpPageService from "../../Service/HelpPageService";
import '../GeneralStyling.scss';
import './HelpScreen.scss'
import MessageConverter from "../MessageConverter";
import MessageHandler from "../MessageHandler";

import moment from 'moment';
import React, { useEffect, useState, useRef } from 'react';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import Select from 'react-select';
import { Editor } from 'react-draft-wysiwyg';
import { BlockUI } from 'primereact/blockui';
import { Carousel } from 'primereact/carousel';
import { ContextMenu } from 'primereact/contextmenu';

const baseUrl = process.env.REACT_APP_API_URL;
function getImageInfo(imageslist) {
	if (imageslist == null || imageslist.length == 0)
	{
		return [{}];
	}
	else {
		return imageslist;
	}
}

function Overview() {
	const navigate = useNavigate();
	const { state } = useLocation();
	const [title, setTitle] = useState("");
	const [list, setList] = useState({});
	const [contentState, setContentState] = useState(state == null ? "" : JSON.parse(state.message.content))
	const [blocked, setBlocked] = useState(false);
	const [imagelist, setimagelist] = useState([]);
	const [loading, setLoading] = useState(true);
	const [selectedItem, setSelectedItem] = useState(null);

	const [msg, setMsg] = useState([]);

	const newMessageHandler = (enteredMsg) => {
		setMsg(enteredMsg);
	}

	const setMessageHandler = (msg) => {
		newMessageHandler(msg);
	}

	const parseUrl = textToParse => {

		var checkForBaseUrl = true;

		while (checkForBaseUrl) {
			if (textToParse.includes(baseUrl)) {
				textToParse = textToParse.replace(baseUrl, '');
			}
			else {
				checkForBaseUrl = false;
			}
		}
		return textToParse;
	}

	const setcarouselVisibility = (list) =>
	{
		if (list.length == 0) {
			document.getElementById("carousel").style.visibility = "hidden";
		}
		else {
			document.getElementById("carousel").style.visibility = "visible";
		}
	}

	const onContentStateChange = (contentState) => {
		setContentState(contentState);
		var text = JSON.stringify(contentState)

		text = parseUrl(text);

		setList({ ...list, content: text });
	};

	useEffect(() => {
		if (state !== null) {
			const message = state.message;
			HelpPageService.getHelpItem(message.id).then((res) => {
				setList(res.data);
			})

			setTitle("Bewerken");
		} else if (state === null) {
			setTitle("Toevoegen");
		}
	}, []);

	useEffect(() => {
		HelpPageService.getHelpImages().then((res) => {
			setimagelist(res.data);
			setcarouselVisibility(res.data);
			setLoading(false);
		}).catch((err) => setMessageHandler(MessageConverter.convertBusinessMsg(err)));
	}, []);

	const saveMessage = async (e) =>
	{
		e.preventDefault();
		setMessageHandler();
		if (list.content !== undefined)
		{
			if (list.url !== undefined && list.url.toString().startsWith('/'))
			{
				setBlocked(true);
				try
				{
					if (state !== null) {
						await HelpPageService.updateHelpItem(
							list
						).then(() => {
							navigate("/HelpBeheer", { state: { confirmedMessage: "Help tekst is succesvol opgeslagen" } });
							setBlocked(false);
						});
					}
					else if (state === null) {
						await HelpPageService.createHelpItem(
							list
						).then(() => {
							navigate("/HelpBeheer", { state: { confirmedMessage: "Help tekst is succesvol toegevoegd" } });
							setBlocked(false);
						});
					}
				}
				catch (err)
				{
					setMessageHandler(MessageConverter.convertBusinessMsg(err))
					setBlocked(false);
				}
			}
			else
			{
				setMessageHandler(MessageConverter.convertBusinessMsg("Url moet beginnen met '/'", 1))
			}
		} else setMessageHandler(MessageConverter.convertBusinessMsg("Geen inhoud ingevuld", 1))
	}

	const getbase64 = imagefile =>
	{
		return new Promise(resolve => {
			let baseurl = "";
			let reader = new FileReader();

			reader.readAsDataURL(imagefile);

			reader.onload = () => {
				baseurl = reader.result;
				resolve(baseurl);
			}

		})
	}

	const uploadImageCallBack = async (imagefile) => {

		let lnewUrl = "";
		var lStream = "";

		await getbase64(imagefile).then(result => { lStream = result; });

		const image =
		{
			filename: imagefile.name,
			filestream: lStream
		};

		await HelpPageService.UploadHelpItemImage(image).then((res) => { lnewUrl = res.data });

		await HelpPageService.getHelpImages().then((res) => {
			setimagelist(res.data);
			setcarouselVisibility(res.data);
			setLoading(false);
		}).catch((err) => setMessageHandler(MessageConverter.convertBusinessMsg(err)));

		return new Promise((resolve, reject) => {
			resolve({
				data: {
					link: baseUrl + lnewUrl } });
		});
	}

	const getItemUrl = imageUrl =>
	{
		var doCheck = true;
		var lResult = imageUrl;

		while (doCheck) {
			if (imageUrl != null || imageUrl != undefined) {
				lResult = lResult.toString().replace('\\', '/');
			}

			var lIndex = lResult.toString().indexOf('\\');
			if (lIndex == -1) {
				doCheck = false;
			}
		}

		return baseUrl + lResult;
	}

	const getItemName = imageUrl => {
		if (imageUrl != null || imageUrl != undefined) {
			var doCheck = true;
			var lResult = imageUrl;

			while (doCheck)
			{ 
				var lIndex = lResult.toString().indexOf('\\');
				if (lIndex == -1)
				{
					doCheck = false;
				}
				else {
					lResult = lResult.toString().substring(lIndex + 1);
					}
			}
			return lResult.toString();
		}
		else {
			return 'onbekend';
		}
	}

	const cm = useRef(null);
	const contextMenuItems = [
		{
			label: 'Copy Url',
			command: (e) => {
				if (selectedItem != null)
				{
					navigator.clipboard.writeText(baseUrl + selectedItem.alt);
				}
			}
		},
		{
			label: 'Delete',
			command: async(e) => {
				if (selectedItem != null) {
					var imageName = getItemName(selectedItem.alt);
					await HelpPageService.deleteImage(imageName).then(() => {
						HelpPageService.getHelpImages().then((res) => {
							setimagelist(res.data);
							setcarouselVisibility(res.data);
							setLoading(false);
						}).catch((err) => setMessageHandler(MessageConverter.convertBusinessMsg(err)))
					});
					
				}
			}
		}
	];

	const onClickContextMenu = e =>
	{
		if (e?.currentTarget != null)
		{
			setSelectedItem(e.currentTarget);
		}
		return cm.current.show(e);
	}

	const ImageTileTemplate = (imageinfo) => {
		return (
			<div className="image-tile">
				<div className="image-tile-content" width="150px">
					<ContextMenu model={contextMenuItems} ref={cm}></ContextMenu>
					<img className="image-block" src={getItemUrl(imageinfo)} alt={imageinfo} onContextMenu={onClickContextMenu} height="150px"></img>
					<div className="image-name">
						<label>{getItemName(imageinfo)}</label>	
					</div>
				</div>
		</div>
		)
	}

	return (
		<div id="Page">
			<BlockUI blocked={blocked} fullScreen />
			<div id="MainContentContainer">
				<div className="page-information">
					<div id="PageHeader" className="page-header">
						<span>Help: {title} </span>
					</div>
					<div className="clear"></div>
				</div>
				<div>
					<div className="maincontent">
						<div className="message-div">
							<MessageHandler messages={msg} />
						</div>
						<form autoComplete="off" onSubmit={saveMessage}>
							<div className="maincontent-item">
								<label htmlFor="Omschrijving">Omschrijving*</label>
								<input
									id="Omschrijving"
									type="text"
									required={true}
									onInvalid={e => e.target.setCustomValidity("Dit veld is verplicht")}
									onInput={e => e.target.setCustomValidity("")}
									title="Dit veld is verplicht"
									defaultValue={list.description}
									onChange={(e) => setList({ ...list, description: e.target.value })}
								/>
							</div>

							<div className="maincontent-item">
								<label>Url*</label>
								<input
									id="url"
									type="text"
									required={true}
									onInvalid={e => e.target.setCustomValidity("Dit veld is verplicht")}
									onInput={e => e.target.setCustomValidity("")}
									title="Dit veld is verplicht"
									defaultValue={list.url}
									onChange={(e) => setList({ ...list, url: e.target.value })}
								/>
							</div>

							<div className="maincontent-item">
								<label>Inhoud*</label>
							</div>
							<div className="maincontent-item">
								<Editor
									toolbar={
										{
											options: ['inline', 'blockType', 'list', 'link', 'history', 'image'],
											inline: {
												inDropdown: false,
												className: undefined,
												component: undefined,
												dropdownClassName: undefined,
												options: ['bold', 'italic', 'underline', 'strikethrough', 'monospace', 'superscript', 'subscript'],
											},
											blockType: {
												inDropdown: true,
												options: ['Normal', 'H1', 'H2', 'H3', 'H4', 'H5', 'H6'],
												className: undefined,
												component: undefined,
												dropdownClassName: undefined,
											},
											list: {
												inDropdown: false,
												className: undefined,
												component: undefined,
												dropdownClassName: undefined,
												options: ['unordered', 'ordered', 'indent', 'outdent'],
											},
											link: {
												inDropdown: false,
												className: undefined,
												component: undefined,
												popupClassName: undefined,
												dropdownClassName: undefined,
												showOpenOptionOnHover: true,
												defaultTargetOption: '_self',
												options: ['link', 'unlink'],
												linkCallback: undefined
											},
											image: {
												className: undefined,
												component: undefined,
												popupClassName: undefined,
												urlEnabled: true,
												uploadEnabled: true,
												alignmentEnabled: true,
												uploadCallback: uploadImageCallBack
												, previewImage: true,
												inputaccept: 'image/gif,image/jpeg,image/jpg,image/png,image/svg',
												alt: { present: false, mandatory: false },
												defaultsize: {
													height: 'auto',
													width: 'auto',
												},
												history: {
													inDropdown: false,
													className: undefined,
													component: undefined,
													dropdownClassName: undefined,
													options: ['undo', 'redo'],
												}
											}
										}
									}
									wrapperClassName="demo-wrapper"
									editorClassName="demo-editor"
									onContentStateChange={onContentStateChange}
									initialContentState={contentState}
								/>
							</div>
							<div className="image-carousel">
								<Carousel id="carousel" value={getImageInfo(imagelist)} numVisible={3} numScroll={1} orientation="horizontal" itemTemplate={ImageTileTemplate} header={<h4>Geuploade afbeeldingen</h4>}/>
							</div>
								
							<div className="maincontent-item-buttons">
								<input
									type="submit"
									value="Opslaan"
									className="user-add-buttons" />
								<Link to="/HelpBeheer">
									<input type="submit" value="Annuleren" className="user-add-buttons" />
								</Link>
							</div>
						</form>
					</div>
				</div>
			</div>
		</div>
	);
}

export default Overview;