import PermissionService from "./PermissionService";

const AuthDTO = "auth";

// get the session storage
const getAuthStorage = () =>
{
    return sessionStorage.getItem(AuthDTO);
};

// get the access token
const getLocalAccessToken = () =>
{
    const auth = JSON.parse(getAuthStorage());
    return auth?.accessToken;
};

// get the access token
const getValidAccessToken = () =>
{
    const auth = JSON.parse(getAuthStorage());
    return auth?.accessTokenValidUntil;
};

// get the refresh token out of the session storage
const getLocalRefreshToken = () =>
{
    const auth = JSON.parse(getAuthStorage());
    return auth?.refreshToken;
};

// update the access token
const updateNewAccessToken = (token) =>
{
    setAuth(token);
};

// set the authentication
const setAuth = (auth) =>
{
    sessionStorage.setItem(AuthDTO, JSON.stringify(auth));
    setCurrentUsername(auth.username);
};
// get the authentication
const getAuth = () =>
{
    const auth = JSON.parse(getAuthStorage());
    return auth;
};
// set selected school id
const setSchoolID = (schoolID) =>
{
    sessionStorage.setItem("schoolID", schoolID);
};
const getSchoolID = () =>
{
    var id = sessionStorage.getItem("schoolID");
    return parseInt(id);
};

// save current username
const setCurrentUsername = (username) =>
{
    sessionStorage.setItem("username", username);
};
// save current schoolname
const setCurrentSchoolname = (schoolname) =>
{
    sessionStorage.setItem("schoolname", schoolname);
};
// get current username
const getCurrentUsername = () =>
{
    return sessionStorage.getItem("username");
};
// get current username
const getCurrentSchoolname = () =>
{
    return sessionStorage.getItem("schoolname");
};

const setOrigUserAuth = (auth) =>
{
    sessionStorage.setItem("OrigUserAuth", JSON.stringify(auth));
};
const getOrigUserAuth = () =>
{
    return JSON.parse(sessionStorage.getItem("OrigUserAuth"));
};
const getIsAdopted = () =>
{
    return sessionStorage.getItem("IsAdopted") == "1";
};
// save user is adopted
const setIsAdopted = (adopted) =>
{
    sessionStorage.setItem("IsAdopted", adopted ? "1" : "0");
};

const clearAdopted = () =>
{
    sessionStorage.setItem("IsAdopted", "0");
    sessionStorage.setItem("OrigUserAuth", "");
};

// remove the autentication
const removeAuth = () =>
{
    sessionStorage.removeItem(AuthDTO);
};

const setPermissions = (permissions) =>
{
    if (permissions == null)
        sessionStorage.removeItem("UserPermissions");
    else
        sessionStorage.setItem("UserPermissions", JSON.stringify(permissions));
};

const getPermissions = () =>
{
    return new Promise((resolve, reject) =>
    {
        let permissions = sessionStorage.getItem("UserPermissions");
        if (permissions == null)
        {
            PermissionService.getPermissionListForUser().then((res) =>
            {
                if (res?.status === 200)
                {
                    AuthService.setPermissions(res.data);
                    resolve(res.data);
                }
                else
                    setErrorMessage(schoolRes?.status);
            }).catch((error) =>
            {
                reject(error);
            });
        }
        else
            resolve(JSON.parse(permissions));
    });
};

const logout = () =>
{
    return new Promise((resolve, reject) =>
    {
        sessionStorage.clear();
        if (sessionStorage.length === 0)
        {
            resolve(true);
        } else { reject(Error(false)); }
    });
};

const removeUserSpecificItems = () =>
{
    if (sessionStorage.getItem("ExternalSystemSetting") !== null)
    {
        sessionStorage.removeItem("ExternalSystemSetting");
    }
    if (sessionStorage.getItem("SchoolyearList") !== null)
    {
        sessionStorage.removeItem("SchoolyearList");
    }
    if (sessionStorage.getItem("GroupyearListSoCo") !== null)
    {
        sessionStorage.removeItem("GroupyearListSoCo");
    }
    if (sessionStorage.getItem("ActiveSchoolyear") !== null)
    {
        sessionStorage.removeItem("ActiveSchoolyear");
    }
};

const AuthService = {
    getAuthStorage,
    getLocalAccessToken,
    getValidAccessToken,
    getLocalRefreshToken,
    updateNewAccessToken,
    removeAuth,
    getAuth,
    setAuth,
    getSchoolID,
    setSchoolID,
    setCurrentUsername,
    setCurrentSchoolname,
    getCurrentUsername,
    getCurrentSchoolname,
    setIsAdopted,
    getIsAdopted,
    clearAdopted,
    setOrigUserAuth,
    getOrigUserAuth,
    setPermissions,
    getPermissions,
    logout,
    removeUserSpecificItems
};

export default AuthService;
