import React, { useEffect, useState } from 'react';
import { BlockUI } from 'primereact/blockui';

import SchoolService from '../../Service/SchoolService';
import MessageConverter from "../MessageConverter";
import './Setting.scss'
import UploadHandler from '../UploadHandler'
import MessageHandler from "../MessageHandler"
import SettingSerivce from '../../Service/SettingService';

function ReportSetting()
{
    const [logoDTO, setLogoDTO] = useState([]);
    const [observationRemoteVisibleTypeDto, setObservationRemoteVisibleTypeDto] = useState([]);
    const [currentLogoDTO, setCurrentLogoDTO] = useState([]);
    const [remoteVisibleType, setRemoteVisibleType] = useState(0);
    const [refreshKey, setRefreshKey] = useState(0);
    const [blocked, setBlocked] = useState(false);
    const [msg, setMsg] = useState([]);

    const newMessageHandler = (enteredMsg) =>
    {
        setMsg(enteredMsg);
    }

    const setMessageHandler = (msg) =>
    {
        newMessageHandler(msg);
    }

    const handleLogoFileChange = (e) =>
    {
        if (e.target.files)
        {
            setBlocked(true);
            UploadHandler.GetAttachmentDto(e.target.files[0]).then((res) =>
            {
                setLogoDTO(res);
                setBlocked(false);
            });
        }
    };

    const onSave = (e) =>
    {
        e.preventDefault();
        setBlocked(true);
        setMessageHandler();
        try
        {
            if (logoDTO.file !== undefined && currentLogoDTO.id > 0)
            {
                // Delete old logo
                SchoolService.deleteLogo().then().catch((err) => { setMessageHandler(MessageConverter.convertBusinessMsg(err)); setBlocked(false) });
            }
            if (logoDTO.file !== undefined)
            {
                // upload new
                SchoolService.uploadLogoSchool(logoDTO).then(() =>
                {
                    setMessageHandler(MessageConverter.convertBusinessMsg("Logo toevoegen gelukt", 0));
                    setRefreshKey(oldKey => oldKey + 1);
                    setLogoDTO([]);
                    setBlocked(false);
                }).catch((err) => { setMessageHandler(MessageConverter.convertBusinessMsg(err)); setBlocked(false) });
            }
            SettingSerivce.saveObservationRemoteVisibleTypeSetting(observationRemoteVisibleTypeDto).then((res) =>
            {
                setObservationRemoteVisibleTypeDto(res.data);
                setBlocked(false);
            }).catch((err) => { setMessageHandler(MessageConverter.convertBusinessMsg(err)); setBlocked(false) });
        } catch (error)
        {
            setMessageHandler(MessageConverter.convertBusinessMsg(error));
            setBlocked(false);
        }
    }

    useEffect(() =>
    {
        SchoolService.getLogoSchool().then((res) =>
        {
            if (res.status === 200)
            {
                setCurrentLogoDTO(res.data);
            } else setCurrentLogoDTO([]);
        }).catch((err) =>
        {
            setMessageHandler(MessageConverter.convertBusinessMsg(err));
        });
    }, [refreshKey]);

    useEffect(() =>
    {
        if (remoteVisibleType !== 0)
            return;
        SettingSerivce.getObservationRemoteVisibleTypeSetting().then((res) =>
        {
            if (res.status === 200)
            { setObservationRemoteVisibleTypeDto(res.data); setRemoteVisibleType(res.data.value); }

        }).catch((err) =>
        {
            if (err.response.status !== 404)
                setMessageHandler(MessageConverter.convertBusinessMsg(err));
            // fallback to default
            setRemoteVisibleType("1");
        });
    }, [observationRemoteVisibleTypeDto]);

    const deleteLogo = () =>
    {
        setBlocked(true);
        setMessageHandler();
        SchoolService.deleteLogo().then(() =>
        {
            setMessageHandler(MessageConverter.convertBusinessMsg("Logo verwijderen gelukt", 0));
            setRefreshKey(oldKey => oldKey + 1);
            setCurrentLogoDTO([]);
            setBlocked(false);
        }).catch((err) => { setMessageHandler(MessageConverter.convertBusinessMsg(err)); setBlocked(false) });
    }
    return (
        <form autoComplete="off" onSubmit={onSave}>
            <MessageHandler messages={msg} />
            <BlockUI blocked={blocked} fullScreen />

            <div className="maincontent-item">
                <label htmlFor="uploadLogo">Upload logo (jpg, png of bmp):</label>
                <input
                    id="uploadLogo"
                    required={false}
                    key={refreshKey}
                    type="file"
                    title="Dit veld is verplicht"
                    accept="image/png, image/jpeg, image/bmp"
                    onChange={handleLogoFileChange}
                />
            </div>

            <div className="maincontent-item">
                <label>Huidig logo:</label>
                {currentLogoDTO.file !== undefined && currentLogoDTO.file !== null ?
                    <div className="remove-logo">
                        <img className="imagepreview" src={`data:${currentLogoDTO.mimetype};base64,${currentLogoDTO.file}`} title={currentLogoDTO.filename} />
                        <div className="btn-close" onClick={deleteLogo} />
                    </div>
                    : <></>
                }
            </div>

            <div className="maincontent-item">
                <label>Geselecteerd logo:</label>
                {logoDTO.file !== undefined && logoDTO.file !== null ?
                    <img className="imagepreview" src={`data:${logoDTO.mimetype};base64,${logoDTO.file}`} title={logoDTO.filename} />
                    : <></>
                }
            </div>
            <div className="sub-bar-item">
                <label className="remove-migration-data-label">
                    Observaties zichtbaar op nieuwe school
                </label>
                <div className="radio-buttons">
                    <input
                        name="orv"
                        type="radio"
                        id="orv-ja"
                        value="1"
                        checked={remoteVisibleType == "1"}
                        onChange={(e) => { setObservationRemoteVisibleTypeDto({ ...observationRemoteVisibleTypeDto, value: '1' }); setRemoteVisibleType(1); }}
                    />
                    <label htmlFor="orv-ja">Ja</label>
                    <input
                        name="orv"
                        type="radio"
                        id="orv-nee"
                        value="2"
                        checked={remoteVisibleType == "2"}
                        onChange={(e) => { setObservationRemoteVisibleTypeDto({ ...observationRemoteVisibleTypeDto, value: '2' }); setRemoteVisibleType(2); }}
                    />
                    <label htmlFor="orv-nee">Nee</label>
                </div>
            </div>

            <div className="maincontent-item-buttons">
                <input
                    type="submit"
                    value="Opslaan"
                    className="user-add-buttons" />
            </div>
        </form>
    );
}

export default ReportSetting;