import '../GeneralStyling.scss'
import './SoCoObservation.scss'
import MessageConverter from "../MessageConverter";
import MessageHandler from "../MessageHandler";
import HelpItemDescription from '../HelpPage/HelpItemDescription';
import GroupService from '../../Service/GroupService';
import StudentService from '../../Service/StudentService';
import HelpPageService from '../../Service/HelpPageService';
import SocoObservationService from '../../Service/SoCoObservationService';

import React, { useState, useEffect} from 'react';
import { Dialog } from 'primereact/dialog';
import Select from 'react-select';
import { useNavigate, useLocation } from 'react-router-dom';
import { BlockUI } from 'primereact/blockui';
import { Editor } from "draft-js";
import { RadioButton } from 'primereact/radiobutton';
import { Button } from 'primereact/button';
import { Carousel } from 'primereact/carousel';
import moment from 'moment';
import { Calendar } from 'primereact/calendar';

function SoCoObservationView() {
    const navigate = useNavigate();

    const [blocked, setBlocked] = useState(false);

    const [groupsForReg, setGroupsForReg] = useState(null);
    const [soCoObservationComment, setSoCoObservationComment] = useState({});
    const [ExistingSoCoObservationComments, setExistingSoCoObservationComments] = useState([]);
    const [soCoObservation, setSoCoObservation] = useState(null);
    const [selectedGroup, setSelectedGroup] = useState(null);
    const [selectedStudent, setSelectedStudent] = useState(null);
    const [students, setStudents] = useState(null);
    const [hideStudent, setHideStudent] = useState(true);
    const [defaultSelected, setDefaultSelected] = useState(null);
    const [hiddenObservation, sethiddenObservation] = useState(true);
    const [HiddenExistingObservations, setHiddenExistingObservations] = useState(true);

    const domains = [
        {
            name: 'Intrapersoonlijke ontwikkeling',
            key: 1
        },
        {
            name: 'Interpersoonlijke ontwikkeling',
            key: 2
        }, {
            name: 'Maatschappelijke betrokkenheid',
            key: 3
        }];

    const [helpItem, setHelpItem] = useState(null);
    const [helpVisible, setHelpVisible] = useState(false);
    const [helpButtonHidden, sethelpButtonHidden] = useState(true);
    const currentPathName = window.location.pathname;
    const [msg, setMsg] = useState([]);
    const [selectedDomain, setSelectedDomain] = useState(domains[0]);
    const [selectedDate, setSelectedDate] = useState(moment().format('YYYY-MM-DD'));

    const [dialogVisible, setDialogVisible] = useState([]);
    const [forceRender, setForceRender] = useState(0);

    const isDialogVisible = (commentID) => {
        var item = dialogVisible.find((item) => item.id === commentID);
        if (item != null)
            return item.state;
        else
            return false;
    }

    const toggleDialog = (commentID, state) => {
        var local = dialogVisible;
        var item = local.find((item) => item.id === commentID);

        if (item === undefined) {
            local.push({ id: commentID, state: state });

        }
        else {
            item.state = state;
        }
        setDialogVisible(local);
        setForceRender(forceRender + 1);
    }

    const newMessageHandler = (enteredMsg) => {
        setMsg(enteredMsg);
    }

    const setMessageHandler = (msg) => {
        newMessageHandler(msg);
    }

    const { state } = useLocation();
    useEffect(() => {
        if (state !== null) {
            const { confirmedMessage } = state;
            setMessageHandler(MessageConverter.convertBusinessMsg(confirmedMessage, 0));
        }
    }, []);

    // Get the groups
    useEffect(() => {
        setDefaultSelected(null);
        setSelectedStudent(null);

        GroupService.getGroupForRegSoCo().then((res) => {
            setGroupsForReg(res.data);
            if (res.data.length == 1) {
                setDefaultSelected(res.data[0]);
                setSelectedStudent(null);
                setHideStudent(false);
                setSelectedGroup(res.data[0]);
            }

        }).catch((err) => setMessageHandler(MessageConverter.convertBusinessMsg(err)));
    }, []);

    // Get the students
    useEffect(() => {
        if (selectedGroup !== undefined && selectedGroup != null) {
            StudentService.getStudentsAssignedToGroup(selectedGroup.id).then((res) => {
                setStudents(res.data);
            }).catch((err) => setMessageHandler(MessageConverter.convertBusinessMsg(err)));

            setHiddenExistingObservations(true);
            setExistingSoCoObservationComments([]);
            setSoCoObservationComment({});
        }
    }, [selectedGroup]);

    // Get the SocoObservation
    useEffect(() => {
        if (selectedStudent !== undefined && selectedStudent != null) {
            SocoObservationService.getOpenSoCoObservation(selectedStudent.id).then((res) => {
                setSoCoObservation(res.data);
                sethiddenObservation(false)
            }).catch((err) => setMessageHandler(MessageConverter.convertBusinessMsg(err)));
            setSoCoObservationComment({});
        }
    }, [selectedStudent]);


    const updateExisingCommentsList = () => {
        if ((selectedStudent !== undefined && selectedStudent != null) &&
            (selectedDomain !== undefined && selectedDomain != null)) {
            SocoObservationService.GetOpenSoCoObservationComments(selectedStudent.id).then((res) => {
                if (res.data.length > 0) {

                    setHiddenExistingObservations(true);
                    var lTempList = [];
                    var i;
                    for (i = 0; i < res.data.length; i++) {
                        if (res.data[i].soCoSignalID == selectedDomain.key) {
                            lTempList.push(res.data[i]);
                        }
                    }
                    setExistingSoCoObservationComments(lTempList);

                    if (lTempList.length > 0) {
                        setHiddenExistingObservations(false);
                    }
                }
                else
                {
                    setHiddenExistingObservations(true);
                    setExistingSoCoObservationComments([]);
                }
            }).catch((err) => setMessageHandler(MessageConverter.convertBusinessMsg(err)));
        }
    }

    // Get the SocoObservation
    useEffect(() => {
        updateExisingCommentsList();
    }, [selectedStudent, selectedDomain]);

    // Reset selected student
    useEffect(() => {
        if (students != null) {
            if (students.length !== 1)
                setSelectedStudent(null);
            else
                setSelectedStudent(students[0]);
        }
    }, [students]);

    // Prevent going back
    useEffect(() => {
        window.addEventListener('popstate', (e) => {
            window.history.go(1);
        });
    }, []);

    // Preload helpItems
    useEffect(() => {
        if (helpItem == null) {
            HelpPageService.getHelpItembyPage(currentPathName).then((res) => {
                if (res.data != undefined && res.data != null && res.data != '') {
                    setHelpItem(res.data);
                    sethelpButtonHidden(false);
                }
                else {
                    sethelpButtonHidden(true);
                }
            }).catch(err => {
                setMessageHandler(MessageConverter.convertBusinessMsg(err));
            });
        }
    }, []);

    // Push the comment in the Observation
    const pushComment = (comment) => {
        return soCoObservationComment.comment = comment;
    }

    const onSave = async (e) => {
        setBlocked(true);

        if (soCoObservation != undefined && soCoObservation != null) {
            if (soCoObservationComment.comment != undefined && soCoObservationComment.comment != null && soCoObservationComment.comment != "")
            {
                var succes = false;
                if (soCoObservationComment.dateTimeCreated != undefined && soCoObservation.dateTimeCreated != null) {

                    soCoObservationComment.observationDate = selectedDate;
                    await SocoObservationService.updateSoCoObservationComment(soCoObservationComment).then((res) => {
                        setBlocked(false);
                        setMessageHandler(MessageConverter.convertBusinessMsg("Observatie aangepast.", 0));
                        succes = true;   
                    }).catch(err => {
                        setMessageHandler(MessageConverter.convertBusinessMsg(err));
                        setBlocked(false);
                    });
                }
                else {
                    soCoObservationComment.soCoObservationID = soCoObservation.id;
                    soCoObservationComment.soCoSignalID = selectedDomain.key;
                    soCoObservationComment.observationDate = selectedDate;

                    await SocoObservationService.SaveSoCoObservationComment(soCoObservationComment).then((res) => {
                        setBlocked(false);
                        setMessageHandler(MessageConverter.convertBusinessMsg("Observatie opgeslagen.", 0));
                        succes = true;
                    }).catch(err => {
                        setMessageHandler(MessageConverter.convertBusinessMsg(err));
                        setBlocked(false);
                    });
                }
                if (succes == true)
                {
                    setSelectedStudent(null);
                    sethiddenObservation(true);
                    updateExisingCommentsList();
                    setSoCoObservationComment({});
                    setSelectedDate(moment().format('YYYY-MM-DD'));
                }
            }
            else {
                setMessageHandler(MessageConverter.convertBusinessMsg("Er moet een observatie worden ingevuld"));
                setBlocked(false);
            }
        }
        else {
            setMessageHandler(MessageConverter.convertBusinessMsg("Er is geen open observatie om op te registreren"));
            setBlocked(false);
        }

    }

    const onDelete = async (e) =>
    {
        setBlocked(true);
        if (soCoObservation != undefined && soCoObservation != null &&
            soCoObservation.id != undefined && soCoObservation.id)
        {
            await SocoObservationService.deleteSoCoObservationComment(soCoObservationComment.id).then((res) => {
                    setBlocked(false);
                    setMessageHandler(MessageConverter.convertBusinessMsg("Observatie verwijderd.", 0));
                }).catch(err => {
                    setMessageHandler(MessageConverter.convertBusinessMsg(err));
                    setBlocked(false);
                });
            updateExisingCommentsList();
            setSoCoObservationComment({});
            setSelectedDate(moment().format('YYYY-MM-DD'));
        }
        else
        {
            setMessageHandler(MessageConverter.convertBusinessMsg("Er is geen open observatie geselecteerd om te verwijderen"));
            setBlocked(false);
        }
    }   

    const Note = (props) => {
        return (
            <div className="note">
                <div>
                    <div className="content">
                        <textarea
                            className="textarea-soco-type"
                            onChange={(e) => pushComment(e.target.value)}
                            defaultValue={soCoObservationComment.comment}
                            maxLength="2048"
                        />
                    </div>
                </div>
            </div>
        );
    }

    //const onselectExistingComment = comment =>
    //{
    //    if (comment != undefined && comment != null)
    //    {
    //        setSoCoObservationComment(comment);
    //        setSelectedDate(moment(comment.observationDate).format('YYYY-MM-DD'));
    //    }
    //}

    const ondeleteExistingComment = async(comment) => {
        if (comment != undefined && comment != null) {
            await SocoObservationService.deleteSoCoObservationComment(comment.id).then((res) => {
                setBlocked(false);
                setMessageHandler(MessageConverter.convertBusinessMsg("Observatie verwijderd.", 0));
            }).catch(err => {
                setMessageHandler(MessageConverter.convertBusinessMsg(err));
                setBlocked(false);
            });
            updateExisingCommentsList();
            setSoCoObservationComment({});
            setSelectedDate(moment().format('YYYY-MM-DD'));
        }
    }
    const onEditExistingComment = comment => {
        if (comment != undefined && comment != null) {
            setSoCoObservationComment(comment);
            setSelectedDate(moment(comment.observationDate).format('YYYY-MM-DD'));
        }
    }

    const onChangeDomain = e => {
        setSelectedDomain(e.value);
        setSoCoObservationComment({});
        setSelectedDate(moment().format('YYYY-MM-DD'));
    }

    const getPresentableDate = date =>
    {
        if (date !== null && date !== undefined) {
            return moment(date).format('DD-MM-YYYY')
        } else return ""
    }

    const getSmallObservationText = comment => {
        if (comment !== null && comment !== undefined)
        {
            if (comment.length > 300)
            {
                return comment.substring(0, 297) + "...";
            }
            else
            {
                return comment;
            }
        } else return ""
    }

    const ExistingObservationTemplate = (observationcomment) => {
        return (
            <div className="observation-tile">
                <div className="observation-info">
                    <label className="observation-date">{getPresentableDate(observationcomment.observationDate)}</label>
                    <div className="observation-text-block">
                        <label className="observation-text" onClick={() => toggleDialog(observationcomment.id, true)}>
                            {getSmallObservationText(observationcomment.comment).split(/\n/).map(line => <div key={line}>{line}</div>)}    
                        </label>
                        <Dialog
                            header={getPresentableDate(observationcomment.observationDate)}
                            visible={isDialogVisible(observationcomment.id)}
                            modal={true}
                            onHide={() => toggleDialog(observationcomment.id, false)}
                            style={{ width: '75vw' }}
                        >
                            {observationcomment.comment.split(/\n/).map(line => <p>{line}</p>)}
                        </Dialog>
                    </div>
                </div>
                <div className="observation-buttons">
                    <div className="edit-observation-icon" onClick={() => {
                        onEditExistingComment(observationcomment)
                    }} />
                    <div className="delete-observation-icon" onClick={() => {
                        ondeleteExistingComment(observationcomment)
                        }} />
                </div>
            </div>
        )
    }

    return (
        <div id="Page">
            <BlockUI blocked={blocked} fullScreen />
            <div id="MainContentContainer">
                <div className="page-information">
                    <div id="PageHeader" className="page-header">
                        <span>Observatie</span>
                    </div>
                    <div id="InfoButton">
                        <div className="btn-info" onClick={() => setHelpVisible(true)} hidden={helpButtonHidden}></div>
                    </div>
                    <Dialog
                        header={"Observatie Kind"}
                        visible={helpVisible}
                        modal={true}
                        onHide={() => setHelpVisible(false)}
                        style={{ width: '75vw' }}
                    >
                        <Editor className="textfield" editorState={HelpItemDescription(helpItem)} readOnly="true" />
                    </Dialog>
                    <div className="clear" />
                </div>
                    <div className="maincontent-header-bar">
                        <div className="header-bar-item">
                            <label>Groep</label>
                            <Select
                                key={selectedGroup}
                                options={groupsForReg}
                                getOptionLabel={(option) => option.name}
                                getOptionValue={(option) => option.id}
                                isSearchable={true}
                                isMulti={false}
                                placeholder="Selecteer een groep"
                                onChange={(gr) => {
                                    setMessageHandler();
                                    setSelectedStudent(null);
                                    setHideStudent(false);
                                    setSelectedGroup(gr);
                                }}
                                noOptionsMessage={() => 'Geen resultaten gevonden'}
                                value={groupsForReg?.find((element) => { return element.id == selectedGroup?.id })}
                                defaultValue={defaultSelected}
                            />
                        </div>
                        <div className="header-bar-item" hidden={hideStudent}>
                            <label>Kind</label>
                            <Select
                            key={selectedStudent}
                            options={students}
                            getOptionLabel={(option) => option.fullname}
                            getOptionValue={(option) => option.id}
                            isSearchable={true}
                            isMulti={false}
                            placeholder="Selecteer een kind"
                            onChange={(st) => {
                                setMessageHandler();
                                setSelectedStudent(st)
                            }}
                            noOptionsMessage={() => 'Geen resultaten gevonden'}
                                value={students?.find((element) => { return element.id == selectedStudent?.id })}
                            />
                        </div>
                    </div>
                   <div className="message-div">
                       <MessageHandler messages={msg} />
                </div>
                <div className="maincontent-sub-bar" hidden={hiddenObservation}>
                    <div>
                        {
                            domains.map((domain) => {
                                return (
                                    <div key={domain.key} className="field-radiobutton">
                                        <RadioButton className="radiobutton-input" inputId={domain.key} name="category" value={domain} onChange={onChangeDomain} checked={selectedDomain.key === domain.key} disabled={domain.key === 0} />
                                        <label htmlFor={domain.key}>{domain.name}</label>
                                    </div>
                                )
                            })
                        }
                    </div>
                    <div className="sub-bar-item">
                        <label htmlFor="closeDate">Afsluitdatum</label>
                        <input
                            id="closeDate"
                            type="date"
                            max={moment().format('YYYY-MM-DD')}
                            onChange={(e) => setSelectedDate(e.target.value)}
                            defaultValue={moment().format('YYYY-MM-DD')}
                            className="closeDate-input"
                        />
                    </div>
                    {/*<Calendar className="calender_observation" defaultValue={selectedDate} value={selectedDate} onChange={(e) => setSelectedDate(e.value)} dateFormat="dd-mm-yy" />*/}
                    <Note title="Observatie"></Note>
                    <div className="buttons">
                        <input type="submit" value="Opslaan" className="main-buttons" onClick={onSave} />
                        <input type="submit" value="Verwijderen" className="main-buttons" onClick={onDelete} />
                    </div>
                    <div hidden={HiddenExistingObservations}>
                        <label className="observation-label">Eerdere Observaties</label>
                        <Carousel id="carousel" className="carousel-observation" value={ExistingSoCoObservationComments} numVisible={3} numScroll={1} orientation="horizontal" itemTemplate={ExistingObservationTemplate} indicatorsContentClassName="carousel-indicator" containerClassName="carousel-container" />
                    </div>
                </div>
            </div>
        </div>  
    );
}

export default SoCoObservationView;