import '../GeneralStyling.scss'
import MessageConverter from "../MessageConverter";
import SchoolService from "../../Service/SchoolService"
import StudentService from '../../Service/StudentService';
import MigrationService from '../../Service/MigrationService';
import SettingService from '../../Service/SettingService';
import MessageHandler from "../MessageHandler";
import HelpPageService from '../../Service/HelpPageService';

import moment from 'moment'
import React, { useState, useEffect, useCallback } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { FilterMatchMode } from 'primereact/api';
import { BlockUI } from 'primereact/blockui';
import { Dialog } from 'primereact/dialog';
import { Editor, EditorState, convertFromRaw, CompositeDecorator } from "draft-js";
import HelpItemDescription from '../HelpPage/HelpItemDescription';

function MigrateStudent()
{
    const { state } = useLocation();
    const navigate = useNavigate();
    const [schoolList, setSchoolList] = useState([]);
    const [selectedSchool, setSelectedSchool] = useState([]);

    const [archiveDate, setArchiveDate] = useState([]);
    const [remoteVisibleType, setRemoteVisibleType] = useState(0);

    const [loading, setLoading] = useState(true);
    const [blocked, setBlocked] = useState(false);

    const [hidden1, setHidden1] = useState(false);
    const [hidden2, setHidden2] = useState(true);
    const [filters, setFilters] = useState({
        'name': { value: null, matchMode: FilterMatchMode.CONTAINS },
        'management': { value: null, matchMode: FilterMatchMode.CONTAINS },
        'visitAdress': { value: null, matchMode: FilterMatchMode.CONTAINS },
        'visitZipCode': { value: null, matchMode: FilterMatchMode.CONTAINS },
        'visitCity': { value: null, matchMode: FilterMatchMode.CONTAINS },
    });

    const [helpItems, setHelpItems] = useState(null);
    const [helpVisible, setHelpVisible] = useState(false);
    const [helpButtonHidden, sethelpButtonHidden] = useState(true);
    const currentPathName = window.location.pathname;

    const [msg, setMsg] = useState([]);

    const newMessageHandler = (enteredMsg) =>
    {
        setMsg(enteredMsg);
    }

    const setMessageHandler = (msg) =>
    {
        newMessageHandler(msg);
    }

    // Preload helpItems
    useEffect(() =>
    {
        if (helpItems == null)
        {
            HelpPageService.getHelpItembyPage(currentPathName).then((res) =>
            {
                if (res.data != undefined && res.data != null && res.data != '')
                {
                    setHelpItem(res.data);
                    sethelpButtonHidden(false);
                }
                else
                {
                    sethelpButtonHidden(true);
                }
            }).catch(err =>
            {
                setMessageHandler(MessageConverter.convertBusinessMsg(err));
            });
        }
    }, []);

    useEffect(() =>
    {
        SchoolService.getForMigration().then((res) =>
        {
            setSchoolList(res.data);
            setLoading(false);
        }).catch((err) => setMessageHandler(MessageConverter.convertBusinessMsg(err)));

        if (state?.student !== undefined)
        {
            StudentService.calculateArchiveDate(moment(state.student.birthdate).format("YYYY-MM-DD")).then((res) =>
            {
                setArchiveDate(moment(res.data).format('YYYY-MM-DD'));
                setLoading(false);
            }).catch((err) => setMessageHandler(MessageConverter.convertBusinessMsg(err)));
            SettingService.getObservationRemoteVisibleType().then((res) =>
            {
                setRemoteVisibleType(res.data);
            }).catch((err) => setMessageHandler(MessageConverter.convertBusinessMsg(err)));
        }

    }, [])

    const onSelectSchool = useCallback(() =>
    {
        if (selectedSchool.length !== 0)
        {
            setHidden1(true);
            setHidden2(false);
        }
        else (setMessageHandler(MessageConverter.convertBusinessMsg("Er is geen school geselecteerd", 1)))
    }, [selectedSchool]);

    const onReturn = useCallback((e) =>
    {
        e.preventDefault();
        setHidden1(false);
        setHidden2(true);
    }, []);

    const createMigration = useCallback((e) =>
    {
        e.preventDefault();
        setBlocked(true);
        setMessageHandler();
        if (archiveDate.length !== 0 && archiveDate !== undefined)
        {
            MigrationService.createMigration(state.student.id, selectedSchool.id, archiveDate, remoteVisibleType).then((res) =>
            {
                navigate("/Kinderen", { state: { confirmedMessage: "Verhuisverzoek is verstuurd" } });
                setBlocked(false);
            }).catch((err) => { setMessageHandler(MessageConverter.convertBusinessMsg(err)); setBlocked(false) });
        } else setMessageHandler(MessageConverter.convertBusinessMsg("Geen datum geselecteerd", 1))
    }, [archiveDate, selectedSchool, remoteVisibleType]);

    return (
        <div id="Page">
            <BlockUI blocked={blocked} fullScreen />
            <div id="MainContentContainer">
                <div className="page-information">
                    <div id="PageHeader" className="page-header">
                        <span>Kinderen: Verhuizen</span>
                    </div>
                    <div id="InfoButton">
                        <div className="btn-info" onClick={() => setHelpVisible(true)} hidden={helpButtonHidden}></div>
                    </div>
                    <Dialog
                        header={"Kinderen: Verhuizen"}
                        visible={helpVisible}
                        modal={true}
                        onHide={() => setHelpVisible(false)}
                        style={{ width: '75vw' }}
                    >
                        <Editor className="textfield" editorState={HelpItemDescription(helpItems)} readOnly="true" />
                    </Dialog>
                    <div className="clear"></div>
                </div>
                <div hidden={hidden1}>
                    <div className="maincontent">
                        <div className="message-div">
                            <MessageHandler messages={msg} />
                        </div>
                        <p>Geselecteerd kind: {state?.student?.fullname}</p>
                        <div className="card">
                            <p>Selecteer een locatie waarnaar het kind verhuisd moet worden:</p>
                            <DataTable
                                value={schoolList}
                                size="small"
                                paginator={true}
                                paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                                currentPageReportTemplate="{first} - {last} van {totalRecords}"
                                rows={10}
                                rowsPerPageOptions={[10, 20, 50, 100]}
                                loading={loading}
                                filters={filters} filterDisplay="row"
                                responsiveLayout="scroll"
                                selectionMode="multiple"
                                selection={selectedSchool}
                                onSelectionChange={e => setSelectedSchool(e.value[0])}
                                emptyMessage="Geen resultaten gevonden">
                                <Column field="name"
                                    filter={true}
                                    showFilterMenu={false}
                                    sortable={true}
                                    filterPlaceholder="Zoek op naam"
                                    header="Naam">
                                </Column>
                                <Column field="management"
                                    filter={true}
                                    showFilterMenu={false}
                                    sortable={true}
                                    filterPlaceholder="Zoek op bestuur"
                                    header="Bestuur">
                                </Column>
                                <Column field="brincode"
                                    filter={true}
                                    showFilterMenu={false}
                                    sortable={true}
                                    filterPlaceholder="Zoek op brincode"
                                    header="Brincode">
                                </Column>
                                <Column field="visitAddress"
                                    filter={true}
                                    sortable={true}
                                    showFilterMenu={false}
                                    filterPlaceholder="Zoek op adres"
                                    header="Adres">
                                </Column>
                                <Column field="visitZipCode"
                                    filter={true}
                                    showFilterMenu={false}
                                    sortable={true}
                                    filterPlaceholder="Zoek op postcode"
                                    header="Postcode">
                                </Column>
                                <Column field="visitCity"
                                    filter={true}
                                    showFilterMenu={false}
                                    sortable={true}
                                    filterPlaceholder="Zoek op plaats"
                                    header="Plaats">
                                </Column>
                            </DataTable>
                            <div className="clear"></div>
                        </div>
                    </div>
                    <div className="buttons">
                        <input type="submit" value="Selecteer" className="management-buttons" onClick={onSelectSchool} />
                        <Link to="/Kinderen">
                            <input type="submit" value="Terug" className="management-buttons" />
                        </Link>
                    </div>
                </div>
                <div hidden={hidden2}>
                    <div className="maincontent">
                        <div className="message-div">
                            <MessageHandler messages={msg} />
                        </div>
                        <p>Kind: {state?.student?.fullname}</p>
                        <p>School: {selectedSchool.name !== undefined ? selectedSchool.name : ""} ({selectedSchool.visitAddress !== undefined ? selectedSchool.visitAddress : ""}, {selectedSchool.visitCity !== undefined ? selectedSchool.visitCity : ""})</p>
                        <form autoComplete="off">
                            <div className="sub-bar-item">
                                <label className="remove-migration-data-label">
                                    Datum waarop de gegevens van deze locatie automatisch verwijderd moeten worden
                                </label>
                                <input
                                    required={true}
                                    type="date"
                                    defaultValue={archiveDate}
                                    min={moment().format('YYYY-MM-DD')}
                                    onChange={(e) => setArchiveDate(e.target.value)} />
                            </div>
                            <div className="sub-bar-item">
                                <label className="remove-migration-data-label">
                                    Observaties zichtbaar op nieuwe school
                                </label>
                                <div className="radio-buttons">
                                    <input
                                        name="orv"
                                        type="radio"
                                        id="orv-ja"
                                        value="1"
                                        checked={remoteVisibleType == 1}
                                        onChange={(e) => setRemoteVisibleType(1)}
                                    />
                                    <label htmlFor="orv-ja">Ja</label>
                                    <input
                                        name="orv"
                                        type="radio"
                                        id="orv-nee"
                                        value="2"
                                        checked={remoteVisibleType == 2}
                                        onChange={(e) => setRemoteVisibleType(2)}
                                    />
                                    <label htmlFor="orv-nee">Nee</label>
                                </div>
                            </div>
                            <div className="maincontent-item-buttons">
                                <input
                                    type="submit"
                                    value="Verstuur verhuis verzoek"
                                    onClick={createMigration}
                                    className="user-add-buttons" />
                                <input type="submit" value="Terug" className="user-add-buttons" onClick={onReturn} />
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default MigrateStudent;