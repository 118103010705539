import '../GeneralStyling.scss'
import MessageConverter from "../MessageConverter";
import MessageHandler from "../MessageHandler";
import UploadHandler from '../UploadHandler'
import DocumentationService from "../../Service/DocumentationService";

import { BlockUI } from 'primereact/blockui';
import React, { useState, useEffect } from 'react';
import Select from 'react-select';
import { Link, useNavigate, useLocation } from 'react-router-dom';

function DocumentationItem()
{
    const navigate = useNavigate();
    const { state } = useLocation();
    const [blocked, setBlocked] = useState(false);
    const [disabledSelect, setDisabledSelect] = useState(false);
    const [requiredEdit, setRequiredEdit] = useState(true);
    const [msg, setMsg] = useState([]);
    const [documentDTO, setDocumentDTO] = useState({});
    const [title, setTitle] = useState("");
    const category = [
        {
            id: "1",
            label: "Algemeen",
            value: 1
        },
        {
            id: "2",
            label: "SoCo",
            value: 2
        },
    ]

    useEffect(() =>
    {
        if (state !== null)
        {
            const selectedDoc = state.documentID;
            DocumentationService.getDocumentation(selectedDoc).then((res) =>
            {
                setDocumentDTO(res.data);
                setDisabledSelect(true);
                setRequiredEdit(false);
                setTitle("Bewerken");
            })
        } else if (state === null)
        {
            setTitle("Toevoegen");
            setDocumentDTO({ ...documentDTO, sortOrder: 0 });
        }
    }, []);

    const newMessageHandler = (enteredMsg) =>
    {
        setMsg(enteredMsg);
    }

    const setMessageHandler = (msg) =>
    {
        newMessageHandler(msg);
    }

    const handleFileChange = (e) =>
    {
        setMessageHandler();
        setBlocked(true);
        if (e.target.files)
        {
            UploadHandler.GetAttachmentDto(e.target.files[0]).then((res) =>
            {
                setDocumentDTO({ ...documentDTO, fileName: res.filename, fileData: res.file, url: res.filename });
                setBlocked(false);
            }).catch(err => { setMessageHandler(MessageConverter.convertBusinessMsg(err)); setBlocked(false); });
        }
    };

    const saveDocument = async (e) =>
    {
        setMessageHandler();
        e.preventDefault();

        if (documentDTO.fileData !== undefined)
        {
            if (documentDTO.iconUrl !== undefined)
            {
                setBlocked(true);
                try
                {
                    if (state !== null)
                    {
                        await DocumentationService.updateDocumentation(
                            documentDTO
                        ).then(() =>
                        {
                            navigate("/Documentation/Bewerken", { state: { confirmedMessage: "Document is succesvol opgeslagen" } });
                            setBlocked(false);
                        });
                    } else if (state === null)
                    {
                        await DocumentationService.createDocumentation(
                            documentDTO
                        ).then(() =>
                        {
                            navigate("/Documentation/Bewerken", { state: { confirmedMessage: "Document is succesvol toegevoegd" } });
                            setBlocked(false);
                        });
                    }
                }
                catch (err)
                {
                    setMessageHandler(MessageConverter.convertBusinessMsg(err))
                    setBlocked(false);
                }
            } else setMessageHandler(MessageConverter.convertBusinessMsg("Geen icoon gekozen", 1))
        } else setMessageHandler(MessageConverter.convertBusinessMsg("Selecteer een bestand", 1))
    }

    return (
        <div id="Page">
            <BlockUI blocked={blocked} fullScreen />
            <div id="MainContentContainer">
                <div className="page-information">
                    <div id="PageHeader" className="page-header">
                        <span>Werkmaterialen beheer: {title}</span>
                    </div>
                    <div className="clear"></div>
                </div>
                <div>
                    <div className="maincontent">
                        <div className="message-div">
                            <MessageHandler messages={msg} />
                        </div>
                        <form autoComplete="off" onSubmit={saveDocument}>
                            <div className="maincontent-item">
                                <label htmlFor="name">Naam*</label>
                                <input
                                    id="name"
                                    type="text"
                                    required={true}
                                    onInvalid={e => e.target.setCustomValidity("Dit veld is verplicht")}
                                    onInput={e => e.target.setCustomValidity("")}
                                    title="Dit veld is verplicht"
                                    defaultValue={documentDTO.displayName}
                                    onChange={(e) => setDocumentDTO({ ...documentDTO, displayName: e.target.value })}
                                />
                            </div>

                            <div className="maincontent-item">
                                <label>Categorie*</label>
                                <Select
                                    options={category}
                                    required={requiredEdit}
                                    getOptionLabel={(option) => option.label}
                                    getOptionValue={(option) => option.value}
                                    placeholder="Selecteer een categorie"
                                    value={category.find((element) => { return element.value == documentDTO.category })}
                                    onChange={(selectedCategory) => setDocumentDTO({ ...documentDTO, category: selectedCategory.value })}
                                    isSearchable={true}
                                    isMulti={false}
                                    noOptionsMessage={() => 'Geen resultaten gevonden'}
                                    isDisabled={disabledSelect}
                                />
                            </div>

                            <div className="maincontent-item">
                                <label htmlFor="file">Bestand*</label>
                                <input
                                    id="file"
                                    required={requiredEdit}
                                    type="file"
                                    title="Dit veld is verplicht"
                                    accept=".pdf, .docx, .xlsx"
                                    onChange={handleFileChange}
                                />
                            </div>

                            <div className="maincontent-item">
                                <label>Icon*</label>
                                <div className="radio-buttons">
                                    <input
                                        type="radio"
                                        id="pdf"
                                        name="icon"
                                        checked={documentDTO.iconUrl === 'pdf.png'}
                                        onChange={() => setDocumentDTO({ ...documentDTO, iconUrl: 'pdf.png' })}
                                    />
                                    <img src="../img/pdf.png" />

                                    <input
                                        type="radio"
                                        id="word"
                                        name="icon"
                                        checked={documentDTO.iconUrl === 'word.png'}
                                        onChange={() => setDocumentDTO({ ...documentDTO, iconUrl: 'word.png' })}
                                    />
                                    <img src="../img/word.png" />

                                    <input
                                        type="radio"
                                        id="excel"
                                        name="icon"
                                        checked={documentDTO.iconUrl === 'excel.png'}
                                        onChange={() => setDocumentDTO({ ...documentDTO, iconUrl: 'excel.png' })}
                                    />
                                    <img src="../img/excel.png" />
                                </div>
                            </div>
                            <div className="maincontent-item-buttons">
                                <input
                                    type="submit"
                                    value="Opslaan"
                                    className="user-add-buttons" />
                                <Link to="/Documentation/Edit">
                                    <input type="submit" value="Annuleren" className="user-add-buttons" />
                                </Link>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default DocumentationItem;