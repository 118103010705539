import * as React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { useState, useEffect } from 'react';
import "./App.scss"
// Login
import UserLogin from './Pages/login/LoginPage'
import ForgotPassword from './Pages/ForgotPasswordPage'
import ResetPasswordPage from './Pages/ResetPasswordPage'
import SelectSchool from './Pages/login/SelectSchoolPage'
import HomePage from './Pages/HomePage'
// User management
import ManageUser from './Pages/user/UserManagementPage'
import UserItems from './Pages/user/UserItemPage'
import AdoptUser from './Pages/user/AdoptUserPage'
import JoinUser from './Pages/user/UserJoinPage'
import ConfirmJoinUser from './Pages/user/UserJoinConfirmPage'
import JoinUserOverview from './Pages/user/UserJoinOverviewPage'
// Group management
import ManageGroup from './Pages/group/GroupManagementPage'
import GroupItemPage from './Pages/group/GroupItemPage'
import Group_JoinUser from './Pages/group/JoinUser'
// Student management
import ManageStudent from './Pages/student/StudentManagementPage'
import StudentItems from './Pages/student/StudentItemPage'
import MigrateStudentPage from './Pages/student/MigrateStudentPage'
//SoCo registration
import OverviewTeacherPage from './Pages/SoCoRegistration/OverviewTeacherPage'
import CloseRegistrationPage from './Pages/SoCoRegistration/CloseRegistrationPage'
import EditRegistationPage from './Pages/SoCoRegistration/EditRegistationPage'
import OverviewStudentPage from './Pages/SoCoRegistration/OverviewStudentPage'
import ChildListPage from './Pages/SoCoRegistration/ChildListPage'
//Student migration
import MigrationPage from './Pages/MigrationPage'
// Reports
import SoCoIndividualReportPage from './Pages/Reports/SoCo/SoCoIndividualReportPage'
import SoCoGroupReportPage from './Pages/Reports/SoCo/SoCoGroupReportPage'
import SoCoCrossSectionReportPage from './Pages/Reports/SoCo/SoCoCrossSectionReportPage'
import SoCoTrendReportPage from './Pages/Reports/SoCo/SoCoTrendReportPage'
//Communication screen
import CommunicationPage from './Pages/Communication/CommunicationPage'
import CommunicationItemPage from './Pages/Communication/CommunicationItemPage'
//Help
import HelpPage from './Pages/HelpPage/HelpPage'
import HelpItemPage from './Pages/HelpPage/HelpItemPage'
//Archive
import ArchivePage from './Pages/ArchivePage'
// Settings
import SettingsPage from "./Pages/SettingsPage";
import TwoFALoginPage from "./Pages/TwoFA/TwoFALoginPage";
import TwoFAHomePage from "./Pages/TwoFA/TwoFAHomePage";
import TwoFAAndroidPage from "./Pages/TwoFA/TwoFAAndroidPage";
import TwoFAApplePage from "./Pages/TwoFA/TwoFAApplePage";
import TwoFAWindowsPage from "./Pages/TwoFA/TwoFAWindowsPage";
import TwoFAAppleDesktopPage from "./Pages/TwoFA/TwoFAAppleDesktopPage";
//Edex import
import EdexImportPage from "./Pages/EdexImportPage";
// Info
import InfoPage from "./Pages/InfoPage";
// Documentation
import DocumentationViewPage from './Pages/Documentation/DocumentationViewPage'
import DocumentationEditViewPage from './Pages/Documentation/DocumentationEditViewPage'
import DocumentationItemPage from './Pages/Documentation/DocumentationItemPage'
// Schoolyear
import SchoolyearPage from './Pages/SchoolyearPage'

import PageNotFound from './Pages/404Page'
import ErrorBoundary from './Components/ErrorBoundary'
import PrivateRoute from "./Service/PrivateRoute";
import ObservationPage from './Pages/Observation/ObservationPage';

function App()
{
    return (
        <div className="App">
            <Router>
                <ErrorBoundary>
                    <Routes>
                        // Public routes
                        <Route path="/" element={<UserLogin />} />
                        <Route path="/KoppelVerzoek/Bevestigen/:userID/:jtsID/:hash" element={<ConfirmJoinUser />} />
                        <Route path="/ResetWachtwoord/:userID/:hash" element={<ResetPasswordPage />} />
                        <Route path="/WachtwoordVergeten" element={<ForgotPassword />} />
                        <Route path="*" element={<PageNotFound />} />

                        // Private routes
                        <Route
                            path="/login/SelectSchool"
                            element={<PrivateRoute><SelectSchool /></PrivateRoute>} />
                        <Route
                            path="/login/TwoFA"
                            element={<PrivateRoute><TwoFALoginPage /></PrivateRoute>} />
                        <Route
                            path="/TwoFA"
                            element={<PrivateRoute><TwoFAHomePage /></PrivateRoute>} />
                        <Route
                            path="/TwoFA/Android"
                            element={<PrivateRoute><TwoFAAndroidPage /></PrivateRoute>} />
                        <Route
                            path="/TwoFA/Apple"
                            element={<PrivateRoute><TwoFAApplePage /></PrivateRoute>} />
                        <Route
                            path="/TwoFA/Windows"
                            element={<PrivateRoute><TwoFAWindowsPage /></PrivateRoute>} />
                        <Route
                            path="/TwoFA/AppleDesktop"
                            element={<PrivateRoute><TwoFAAppleDesktopPage /></PrivateRoute>} />
                        <Route
                            path="/Home"
                            element={<PrivateRoute><HomePage /></PrivateRoute>} />
                        <Route
                            path="/Kindlijst"
                            element={<PrivateRoute><ChildListPage /></PrivateRoute>} />
                        <Route
                            path="/AdoptUser"
                            element={<PrivateRoute permission='MENU_ADOPT_USER'  ><AdoptUser /></PrivateRoute>} />
                        <Route
                            path="/Gebruikers"
                            element={<PrivateRoute permission='MENU_ADMIN_USERS'><ManageUser /></PrivateRoute>} />
                        <Route
                            path="/GebruikerItem"
                            element={<PrivateRoute permission='MENU_ADMIN_USERS'><UserItems /></PrivateRoute>} />
                        <Route
                            path="/GebruikerKoppelen"
                            element={<PrivateRoute permission='MENU_ADMIN_USERS'><JoinUser /></PrivateRoute>} />
                        <Route
                            path="/GebruikerKoppelen/Overzicht"
                            element={<PrivateRoute permission='MENU_ADMIN_USERS'><JoinUserOverview /></PrivateRoute>} />
                        <Route
                            path="/Kinderen"
                            element={<PrivateRoute permission='MENU_ADMIN_STUDENTS'><ManageStudent /></PrivateRoute>} />
                        <Route
                            path="/KindItem"
                            element={<PrivateRoute permission='MENU_ADMIN_STUDENTS'><StudentItems /></PrivateRoute>} />
                        <Route
                            path="/KindVerhuizen"
                            element={<PrivateRoute permission='MENU_ADMIN_STUDENTS'><MigrateStudentPage /></PrivateRoute>} />
                        <Route
                            path="/Groepen"
                            element={<PrivateRoute permission='MENU_ADMIN_GROUPS'><ManageGroup /></PrivateRoute>} />
                        <Route
                            path="/GroepItem"
                            element={<PrivateRoute permission='MENU_ADMIN_GROUPS'><GroupItemPage /></PrivateRoute>} />
                        <Route
                            path="/Groepen/KoppelGebruiker"
                            element={<PrivateRoute permission='MENU_ADMIN_GROUPS'><Group_JoinUser /></PrivateRoute>} />
                        <Route
                            path="/CommunicationScreen"
                            element={<PrivateRoute permission='MENU_ADMIN_COMMUNICATIONSCREEN'><CommunicationPage /></PrivateRoute>} />
                        <Route
                            path="/CommunicationScreenItem"
                            element={<PrivateRoute permission='MENU_ADMIN_COMMUNICATIONSCREEN'><CommunicationItemPage /></PrivateRoute>} />
                        <Route
                            path="/HelpBeheer"
                            element={<PrivateRoute permission='MENU_ADMIN_HELPMANAGEMENT'><HelpPage /></PrivateRoute>} />
                        <Route
                            path="/HelpItem"
                            element={<PrivateRoute permission='MENU_ADMIN_HELPMANAGEMENT'><HelpItemPage /></PrivateRoute>} />
                        <Route
                            path="/KindArchief"
                            element={<PrivateRoute permission='MENU_ADMIN_STUDENT_ARCHIVE'><ArchivePage /></PrivateRoute>} />
                        <Route
                            path="/VerhuisOverzicht"
                            element={<PrivateRoute permission='MENU_ADMIN_STUDENT_MIGRATION'><MigrationPage /></PrivateRoute>} />
                        <Route
                            path="/RegistratieBewerken"
                            element={<PrivateRoute permission='MENU_ADMIN_SOCO_EDIT_REG'><EditRegistationPage /></PrivateRoute>} />
                        <Route
                            path="/Instellingen"
                            element={<PrivateRoute permission='MENU_ADMIN_SETTINGS'><SettingsPage /></PrivateRoute>} />
                        <Route
                            path="/EdexImport"
                            element={<PrivateRoute permission='MENU_ADMIN_EDEX_IMPORT'><EdexImportPage /></PrivateRoute>} />
                        <Route
                            path="/Registratie/Leraar"
                            element={<PrivateRoute permission='MENU_REGISTRATION_SOCO_TEACHER'><OverviewTeacherPage /></PrivateRoute>} />
                        <Route
                            path="/Registratie/Kind"
                            element={<PrivateRoute permission='MENU_REGISTRATION_SOCO_STUDENT'><OverviewStudentPage /></PrivateRoute>} />
                        <Route
                            path="/Registratie/Afsluiten"
                            element={<PrivateRoute permission='MENU_REGISTRATION_SOCO_TEACHER'><CloseRegistrationPage /></PrivateRoute>} />
                        <Route
                            path="/Rapportage/SoCo/Individueel"
                            element={<PrivateRoute permission='MENU_REPORT_SOCO_INDIVIDUAL'><SoCoIndividualReportPage /></PrivateRoute>} />
                        <Route
                            path="/Rapportage/SoCo/Groep"
                            element={<PrivateRoute permission='MENU_REPORT_SOCO_GROUP'><SoCoGroupReportPage /></PrivateRoute>} />
                        <Route
                            path="/Rapportage/SoCo/Dwarsdoorsnede"
                            element={<PrivateRoute permission='MENU_REPORT_SOCO_SCHOOL'><SoCoCrossSectionReportPage /></PrivateRoute>} />
                        <Route
                            path="/Rapportage/SoCo/Trend"
                            element={<PrivateRoute permission='MENU_REPORT_SOCO_SCHOOL'><SoCoTrendReportPage /></PrivateRoute>} />
                        <Route
                            path="/Info"
                            element={<PrivateRoute permission='MENU_ADMIN_INFO'><InfoPage /></PrivateRoute>} />
                        <Route
                            path="/Documentatie/Overzicht"
                            element={<PrivateRoute permission='MENU_DOCUMENTATION_VIEW'><DocumentationViewPage /></PrivateRoute>} />
                        <Route
                            path="/Documentatie/Bewerken"
                            element={<PrivateRoute permission='MENU_DOCUMENTATION_EDIT'><DocumentationEditViewPage /></PrivateRoute>} />
                        <Route
                            path="/Documentatie/Item"
                            element={<PrivateRoute permission='MENU_DOCUMENTATION_EDIT'><DocumentationItemPage /></PrivateRoute>} />
                        <Route
                            path="/SchoolYear"
                            element={<PrivateRoute permission='MENU_ADMIN_SCHOOLYEAR'><SchoolyearPage /></PrivateRoute>} />
                        <Route
                            path="/Observatie"
                            element={<PrivateRoute permission='MENU_OBSERVATION_REGISTRATION'><ObservationPage /></PrivateRoute>} />
                    </Routes>
                </ErrorBoundary>
            </Router>
        </div>
    );
}

export default App;