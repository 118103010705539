import React, { useEffect, useState } from 'react';
import { useCollapse } from 'react-collapsed';
import { Dialog } from 'primereact/dialog';
import '../SoCoRegistration.scss'

const soCoRegistrationScoreList = [];
var TempsoCoRegistrationScoreList = [];

function getItemDescription(list, itemID, groupyearID) 
{
    if (list == null)
    {
        return "";
    }
    else
    {
        var item = list.find((si) => { return si.soCoSignalItemID == itemID && si.groupyearID == groupyearID });
        return item?.description;
    }
}

function HeadContent(props)
{
    const [expand, setExpand] = useState(true);
    const [dialogVisible, setDialogVisible] = useState(false);

    const getSoCoRegistrationScore = (id) =>
    {
        var i;
        for (i = 0; i < TempsoCoRegistrationScoreList.length; i++) {
            if (TempsoCoRegistrationScoreList[i].soCoSignalItemID === id) {
                return TempsoCoRegistrationScoreList[i].score;
            }
        }
    }

    const checkAndSetAnswer = (soCoSignalItemID, newScore, soCoRegistrationID) =>
    {
        var index = TempsoCoRegistrationScoreList.findIndex(x => x.soCoSignalItemID == soCoSignalItemID);

        if (index === -1) {
            var tempScoreList = [...TempsoCoRegistrationScoreList, { soCoSignalItemID, score: newScore, soCoRegistrationID }];
            props.UpdateSoCoRegistrationScoreList(tempScoreList);
        }
        else {
            const tempScoreList = TempsoCoRegistrationScoreList.map(x => {
                if (x.soCoSignalItemID != soCoSignalItemID) { return x }
                else {
                    return { ...x, score: newScore }
                }
            });
            props.UpdateSoCoRegistrationScoreList(tempScoreList);
        }
    }

    const config = {
        defaultExpanded: props.defaultExpanded || false,
        collapsedHeight: props.collapsedHeight || 0
    };

    const { getCollapseProps, getToggleProps, isExpanded } = useCollapse(config);

    return (
        <div className="collapsible">
            <div className="question-header"  >
                <div className="aligning-left">
                    <div className="icon" {...getToggleProps()}>
                        <i className={'fas fa-chevron-' + (isExpanded ? 'up' : 'down')}></i>
                    </div>
                    <div className="title">{props.title}</div>
                    <div className="info-icon" onClick={() => setDialogVisible(true)}>
                        <i className="fas fa-circle-info"></i>
                    </div>
                    <Dialog
                        header={props.title}
                        visible={dialogVisible}
                        modal={true}
                        onHide={() => setDialogVisible(false)}
                    >
                        {getItemDescription(props.itemDescriptionList, props.questionID, props.groupyearID)}
                    </Dialog>
                </div>
                <div className="aligning-right">
                    <input
                        type="radio"
                        className="radio-green"
                        name={props.questionID}
                        value={1}
                        defaultChecked={getSoCoRegistrationScore(props.questionID) === 1}
                        onChange={() =>
                        {
                            checkAndSetAnswer(props.questionID, 1, props.soCoRegistrationID);
                        }}
                    />
                    <input
                        type="radio"
                        className="radio-orange"
                        name={props.questionID}
                        value={2}
                        defaultChecked={getSoCoRegistrationScore(props.questionID) === 2}
                        onChange={() =>
                        {
                            checkAndSetAnswer(props.questionID, 2, props.soCoRegistrationID);
                        }}
                    />
                    <input
                        className="radio-red"
                        type="radio"
                        name={props.questionID}
                        value={3}
                        defaultChecked={getSoCoRegistrationScore(props.questionID) === 3}
                        onChange={() =>
                        {
                            checkAndSetAnswer(props.questionID, 3, props.soCoRegistrationID);
                        }}
                    />
                    <div className="empty-space">
                        <label className="empty-space-label"></label>
                    </div>
                </div>
            </div>
            <div {...getCollapseProps()}>
                <div className="content">
                    {props.children}
                </div>
            </div>
        </div>
    );
}

function SubContent(props)
{
    const getSoCoRegistrationScore = (id) =>
    {
        var i;
        for (i = 0; i < TempsoCoRegistrationScoreList.length; i++) {
            if (TempsoCoRegistrationScoreList[i].soCoSignalItemID === id) {
                return TempsoCoRegistrationScoreList[i].score;
            }
        }
    }

    const checkAndSetAnswer = (soCoSignalItemID, newScore, soCoRegistrationID) =>
    {
        var index = TempsoCoRegistrationScoreList.findIndex(x => x.soCoSignalItemID == soCoSignalItemID);

        if (index === -1)
        {
            var tempScoreList = [...TempsoCoRegistrationScoreList, { soCoSignalItemID, score : newScore, soCoRegistrationID }];
            props.UpdateSoCoRegistrationScoreList(tempScoreList);
        }
        else
        {
            const tempScoreList = TempsoCoRegistrationScoreList.map(x => {
                if (x.soCoSignalItemID != soCoSignalItemID) { return x }
                else {
                    return { ...x, score: newScore }
                }
            });
            props.UpdateSoCoRegistrationScoreList(tempScoreList);
        }
    }

    const [dialogVisible, setDialogVisible] = useState([]);
    const [forceRender, setForceRender] = useState(0);

    const isDialogVisible = (questionID) =>
    {
        var item = dialogVisible.find((item) => item.id === questionID);
        if (item != null)
            return item.state;
        else
            return false;
    }
    const toggleDialog = (questionID, state) =>
    {
        var local = dialogVisible;
        var item = local.find((item) => item.id === questionID);

        if (item === undefined)
        {
            local.push({ id: questionID, state: state });

        }
        else
        {
            item.state = state;
        }
        setDialogVisible(local);
        setForceRender(forceRender + 1);
    }

    const removeSelection = (soCoSignalItemID) =>
    {
        var index = TempsoCoRegistrationScoreList.findIndex(x => x.soCoSignalItemID == soCoSignalItemID);

        (index !== -1)
        {
            var tempScoreList = [...TempsoCoRegistrationScoreList];
            tempScoreList = TempsoCoRegistrationScoreList.filter(x => x.soCoSignalItemID !== soCoSignalItemID);
            props.UpdateSoCoRegistrationScoreList(tempScoreList);
        }
    }

    if (props.question.soCoSignalItemID !== null)
    {
        return (
            props.question?.map((questions) =>
            {
                if (questions.soCoSignalItemID == props.questionID)
                {
                    return (
                        <div className="question-body" key={questions.id} data-force-render={forceRender}>
                            <div className="aligning-left">
                                <div className="title">{questions.name}</div>
                                <div className="info-icon" onClick={() => toggleDialog(questions.id, true)}>
                                    <i className="fas fa-circle-info"></i>
                                </div>
                                <Dialog
                                    header={questions.name}
                                    visible={isDialogVisible(questions.id)}
                                    modal={true}
                                    onHide={() => toggleDialog(questions.id, false)}
                                >
                                    {getItemDescription(props.itemDescriptionList, questions.id, props.groupyearID)}
                                </Dialog>
                            </div>
                            <div className="aligning-right">
                                <input
                                    className="radio-green"
                                    type="radio"
                                    name={questions.id}
                                    checked={getSoCoRegistrationScore(questions.id) == 1}
                                    value={1}
                                    onChange={() =>
                                    {
                                        checkAndSetAnswer(questions.id, 1, props.soCoRegistrationID);
                                    }}
                                />
                                <input
                                    className="radio-orange"
                                    type="radio"
                                    name={questions.id}
                                    checked={getSoCoRegistrationScore(questions.id) == 2}
                                    value={2}
                                    onChange={() =>
                                    {
                                        checkAndSetAnswer(questions.id, 2, props.soCoRegistrationID);
                                    }}
                                />
                                <input
                                    type="radio"
                                    className="radio-red"
                                    name={questions.id}
                                    checked={getSoCoRegistrationScore(questions.id) == 3}
                                    value={3}
                                    onChange={() =>
                                    {
                                        checkAndSetAnswer(questions.id, 3, props.soCoRegistrationID);
                                    }}
                                />
                            </div>
                            <div className="reset-icon" onClick={() => {
                                 removeSelection(questions.id)
                            }}>
                                <img src="/img/reset.svg"></img>
                            </div>
                            
                        </div>
                    )
                }
            })
        )
    }
}

function Note(props)
{
    const pushComment = (soCoSignalItemID, newComment, soCoRegistrationID) =>
    {
        var index = TempsoCoRegistrationScoreList.findIndex(x => x.soCoSignalItemID == soCoSignalItemID);

        if (index === -1) {
            var tempScoreList = [...TempsoCoRegistrationScoreList, { soCoSignalItemID, comment: newComment, soCoRegistrationID }];
            props.UpdateSoCoRegistrationScoreList(tempScoreList);
        }
        else {
            const tempScoreList = TempsoCoRegistrationScoreList.map(x => {
                if (x.soCoSignalItemID != soCoSignalItemID) { return x }
                else {
                    return { ...x, comment: newComment }
                }
            });
            props.UpdateSoCoRegistrationScoreList(tempScoreList);
        }
    }

    const getDefaultComment = (id) =>
    {
        var i;
        for (i = 0; i < TempsoCoRegistrationScoreList.length; i++) {
            if (TempsoCoRegistrationScoreList[i].soCoSignalItemID === id) {
                if (TempsoCoRegistrationScoreList[i].comment !== null) {
                    return (TempsoCoRegistrationScoreList[i].comment);
                } else return "";
            }
        }
    }

    const config = {
        defaultExpanded: props.defaultExpanded || false,
        collapsedHeight: props.collapsedHeight || 0
    };

    const { getCollapseProps, getToggleProps } = useCollapse(config);

    return (
        <div className="collapsible-note">
            <div className="question-header" >
                <div className="aligning-left" {...getToggleProps()}>
                    <div className="icon">
                        <i className="fas fa-pencil"></i>
                    </div>
                    <div className="title">{props.title}</div>
                </div>
            </div>
            <div {...getCollapseProps()}>
                <div className="content">
                    <textarea
                        className="textarea-input-teacher"
                        onChange={(e) =>
                        {
                            pushComment(props.questionID, e.target.value, props.soCoRegistrationID);
                        }}
                        defaultValue={getDefaultComment(props.questionID)}
                    />
                </div>
            </div>
        </div>
    );
}

function Question(props)
{
    TempsoCoRegistrationScoreList = soCoRegistrationScoreList;
    const [MainsoCoRegistrationScoreList, setsoCoRegistrationScoreList] = useState([]);

    const UpdateSoCoRegistrationScoreList = (value) => {
        setsoCoRegistrationScoreList([...value]);
        TempsoCoRegistrationScoreList = value;

        //rebuild the const list, needed for external access
        soCoRegistrationScoreList.splice(0, soCoRegistrationScoreList.length);
        var i;
        for (i = 0; i < value.length; i++)
        {
            if (TempsoCoRegistrationScoreList[i].score != null) {
                soCoRegistrationScoreList.push(value[i]);
            }
        }
    }

    if (props.question !== undefined)
    {
        return (
            props.question.map((question) =>
            {
                if (question.soCoSignalItemID === null)
                {
                    return (
                        <div className="preferences" key={question.id}>
                            <HeadContent
                                title={question.name}
                                questionID={question.id}
                                soCoRegistrationID={props.soCoRegistrationID}
                                groupyearID={props.groupyearID}
                                itemDescriptionList={props.itemDescriptionList}
                                UpdateSoCoRegistrationScoreList={UpdateSoCoRegistrationScoreList}
                            >
                                <Note title="notitie bij dit item maken"
                                    questionID={question.id}
                                    soCoRegistrationID={props.soCoRegistrationID}
                                    UpdateSoCoRegistrationScoreList={UpdateSoCoRegistrationScoreList}
                                />
                                <label>Het kind:</label>
                                <SubContent
                                    question={props.question}
                                    questionID={question.id}
                                    soCoRegistrationID={props.soCoRegistrationID}
                                    groupyearID={props.groupyearID}
                                    itemDescriptionList={props.itemDescriptionList}
                                    UpdateSoCoRegistrationScoreList={UpdateSoCoRegistrationScoreList}
                                />
                            </HeadContent>
                        </div>
                    );
                }
            })
        )
    }
}

const QuestionHandlerTeacher = {
    Question,
    soCoRegistrationScoreList
}

export default QuestionHandlerTeacher;