function convertBusinessMsg(msg, type)
{
    let message = []

    if (msg.code === "ERR_NETWORK")
    {
        message.push({
            type: 1,
            message: 'Netwerk fout. Server niet beschikbaar'
        })
        return message;
    }
    // If there is no msg.response and the type is not set
    if ((msg.response === undefined) && (type !== undefined))
    {
        if (Array.isArray(msg.data))
        {
            var i;
            for (i = 0; i < msg.data.length; i++)
            {
                message.push({
                    type: type,
                    message: msg.data[i],
                })
            }
        } else
        {
            message.push({
                type: type,
                message: String(msg),
            })
        }
        return message;
    }
    else if ((msg.response !== undefined) && (type === undefined) && msg.response.status == "400")
    {
        if (msg.response.data.length === undefined)
            message.push({
                type: 1,
                message: "Onbekende fout. Neem contact op met de helpdesk als dit probleem blijft voordoen.",
            })
        else
            message.push({
                type: 1,
                message: msg.response.data,
            })
        return message;
    }
    else if ((msg.response === undefined) && (type === undefined))
    {
        if (msg == "403")
            message.push({
                type: 1,
                message: "Geen rechten",
            })
        else if (msg == "500")
            message.push({
                type: 1,
                message: "Server fout",
            })
        else
            message.push({
                type: 1,
                message: "Error " + String(msg),
            })
        return message;
    }
    // If the message is an array and bigger than 0
    else if ((Array.isArray(msg.response.data)) && (msg.response.data.length > 0))
    {
        // If the message is an array but has no response.data.message and the type is undefined
        if ((msg.response.data[0].message === undefined) && (type === undefined))
        {
            message.push({
                type: 1,
                message: msg.response.data,
            })
        }
        // If the message has an response.data.message and a type
        else
        {
            var i;
            for (i = 0; i < msg.response.data.length; i++)
            {
                message.push({
                    type: msg.response.data[i].type,
                    message: msg.response.data[i].message,
                })
            }
        }
        return message;
    }
    else if (typeof msg.response.data === 'object')
    {
        if (msg.response.data.title != null && msg.response.data.title != undefined) {
            message.push({
                type: 1,
                message: msg.response.data.title
            })
        }
        else {
            message.push({
                type: 1,
                message: msg.response.data.message
            })
        }
        return message;
    }
    else if (msg.response.data !== "") // TODO maybe exclude some defaults?
    {
        message.push({
            type: 1,
            message: msg.response.data,
        })
        return message;
    }
    // If there is a response.data but it is empty
    else if (msg.response.data === "")
    {
        message.push({
            type: 1,
            message: 'Error: ' + msg.response.status,
        })
        return message;
    }
    else
    {
        message.push({
            type: 1,
            message: "Er is een onbekende fout opgetreden",
        })
        return message;
    }
}

const MessageConverter = {
    convertBusinessMsg,
};

export default MessageConverter;