import './Login.scss';
import { Link } from "react-router-dom";
import { useNavigate, useLocation } from "react-router-dom";
import React, { useState, useEffect } from 'react';
import { BlockUI } from 'primereact/blockui';

import AuthService from "../../Service/AuthService";
import BaseService from '../../Service/BaseService';
import UserService from "../../Service/UserService";
import UserSchoolService from "../../Service/UserSchoolService";
import SchoolService from "../../Service/SchoolService";
import MessageConverter from "../MessageConverter";
import MessageHandler from '../MessageHandler';

import Home from "../../Pages/HomePage";

function Login()
{
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [blocked, setBlocked] = useState(false);

    const navigate = useNavigate();

    const setMessageHandler = (msg) =>
    {
        newMessageHandler(msg);
    }

    const [msg, setMsg] = useState([]);

    const newMessageHandler = (enteredMsg) =>
    {
        setMsg(enteredMsg);
    }

    const handleSubmit = async (e) =>
    {
        e.preventDefault();
        setMessageHandler();
        setBlocked(true);
        try
        {
            const response = await BaseService.post("/User/authorize", {
                email,
                password
            });

            if (response?.status === 200)
            {
                setBlocked(false);
                if (response.data.accessToken)
                {
                    AuthService.setAuth(response.data);
                    UserSchoolService.getUserSchoolList().then((res) =>
                    {
                        const userSchoolList = res.data;

                        if (userSchoolList.length > 1)
                        {
                            AuthService.getPermissions().then((res) =>
                            {
                                AuthService.setPermissions(res);
                                navigate("/login/SelectSchool", { replace: true })
                            }).catch(err => setMessageHandler(MessageConverter.convertBusinessMsg(err)));
                        }
                        else
                        {
                            var schoolID = userSchoolList[0].schoolID;
                            {
                                UserService.setSchool(schoolID).then((res) =>
                                {
                                    if (res?.status === 200)
                                    {
                                        AuthService.updateNewAccessToken(res.data);
                                        AuthService.setSchoolID(schoolID);
                                        if (res.data.mfaSetupNeeded === true)
                                        {
                                            navigate("/TwoFA", { replace: true });
                                        }
                                        else if (res.data.mfaRedirectNeeded == true)
                                        {
                                            navigate("/login/TwoFA");
                                        }
                                        else
                                        {
                                            // set school name
                                            SchoolService.getSchool(schoolID).then((schoolRes) =>
                                            {
                                                if (res?.status === 200)
                                                {
                                                    AuthService.setCurrentSchoolname(schoolRes.data.name);

                                                    if (schoolID == 1)
                                                    {
                                                        AuthService.getPermissions().then((res) =>
                                                        {
                                                            AuthService.setPermissions(res);
                                                            navigate("/AdoptUser", { replace: true })
                                                        });
                                                    }
                                                    else
                                                    {
                                                        AuthService.getPermissions().then((res) =>
                                                        {
                                                            AuthService.setPermissions(res);
                                                            navigate("/Home", { replace: true })
                                                        });
                                                    }
                                                }
                                                else
                                                {
                                                    setMessageHandler(MessageConverter.convertBusinessMsg(schoolRes?.status));
                                                }
                                            }) // Don't handle result
                                        }
                                    }
                                    else
                                    {
                                        setMessageHandler(MessageConverter.convertBusinessMsg(res?.status));
                                    }
                                }).catch(err => setMessageHandler(MessageConverter.convertBusinessMsg(err)))
                            }
                        }
                    }).catch(err =>
                    {
                        setMessageHandler(MessageConverter.convertBusinessMsg(err)); setBlocked(false);
                    });
                }
            } else
            {
                setMessageHandler(MessageConverter.convertBusinessMsg(res?.status));
                setBlocked(false);
            }
        } catch (err)
        {
            setMessageHandler(MessageConverter.convertBusinessMsg(err));
            setBlocked(false);
        }
    };

    // General message
    const { state } = useLocation();
    useEffect(() =>
    {
        if (state !== null)
        {
            if (state.confirmedMessage !== undefined)
            {
                setMessageHandler(MessageConverter.convertBusinessMsg(state.confirmedMessage, 0));
            } else if (state.errorMessage !== undefined)
            {
                setMessageHandler(MessageConverter.convertBusinessMsg(state.errorMessage, 1));
            }
        }
    }, []);

    // If user has valid login, redirect to home
    let isAuthenticated = sessionStorage.getItem("auth");
    let permissions = JSON.parse(sessionStorage.getItem("UserPermissions"));

    // If the authenticator is not empty go to the page. If not, navigate to the login page.
    if (isAuthenticated !== undefined && isAuthenticated != null &&
        permissions !== undefined && permissions != null)
    {
        return (<Home />);
    }
    else
        return (
            <div id="login-head" className="login-bg">
                <BlockUI blocked={blocked} fullScreen />
                <div id="header">
                    <div className="login-header-button-wrapper">
                        <a href="https://www.bazaltgroep.nl/kijk/" className="login-header-button floatLeft" target="_blank">
                            Meer informatie over KIJK!
                        </a>
                        <a href="https://licenties.bazalt.nl/DemoRequest.aspx?product=KIJK-VERDER" className="login-header-button floatRight" target="_blank">
                            Vraag een demo aan
                        </a>
                        <div className="clear"></div>
                    </div>
                </div>

                <div id="content">
                    <input type="image" className="kijk-logo" alt="logo kijk verder" src="/img/kijk-logo.png"></input>
                    <div>
                        <div className="login-container">
                            <form onSubmit={handleSubmit}>
                                <div className="login-header">
                                    <div className="login-header-title">
                                        Observeren, registreren en stimuleren van ontwikkeling
                                    </div>
                                    <div className="clear"></div>
                                </div>
                                <MessageHandler messages={msg} />
                                <div className="login-user">
                                    <div className="login-user-email">
                                        <span>E-mailadres:</span>
                                        <input
                                            type="email"
                                            id="email"
                                            required={true}
                                            maxLength={100}
                                            onChange={(e) => setEmail(e.target.value)}
                                        >
                                        </input>
                                    </div>
                                    <div className="login-user-password">
                                        <span>Wachtwoord:</span>
                                        <input
                                            type="password"
                                            id="password"
                                            required
                                            onChange={(e) => setPassword(e.target.value)}
                                        >
                                        </input>
                                    </div>
                                </div>
                                <div className="login-buttons">
                                    <div className="login-forgotpass">
                                        <Link to="/WachtwoordVergeten">
                                            Wachtwoord vergeten?
                                        </Link>
                                    </div>
                                    <div className="login-button">
                                        <input type="submit" value="Inloggen" className="login-button-style" />
                                    </div>
                                    <div className="clear"></div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        );

}


export default Login;