import RemoteRestrictionService from '../../Service/RemoteRestrictionService';
import SettingService from '../../Service/SettingService';
import AuthService from '../../Service/AuthService';
import MessageConverter from "../MessageConverter";
import MessageHandler from "../MessageHandler"
import './Setting.scss'

import { ListBox } from 'primereact/listbox';
import React, { useState, useEffect } from "react";
import { Dialog } from 'primereact/dialog';
import { BlockUI } from 'primereact/blockui';

function RemoteRestrictionSetting()
{
    const [settingDTO, setSettingDTO] = useState([]);
    const [remoteRestrictionDTO, setRemoteRestrictionDTO] = useState([]);
    const [ipList, setIPList] = useState([]);
    const [selectedIP, setSelectedIP] = useState([]);
    const [ip, setIP] = useState("");
    const [value, setValue] = useState(0);
    const [refreshKey, setRefreshKey] = useState(0);

    const [visibleAdd, setVisibleAdd] = useState(true);
    const [visibleEdit, setVisibleEdit] = useState(true);
    const [visibleMain, setVisibleMain] = useState(false);
    const [visibleMainButtons, setVisibleMainButtons] = useState(true);
    const [visibleDelete, setVisibleDelete] = useState(false);
    const [blocked, setBlocked] = useState(false);

    const [msg, setMsg] = useState([]);

    const newMessageHandler = (enteredMsg) =>
    {
        setMsg(enteredMsg);
    }

    const setMessageHandler = (msg) =>
    {
        newMessageHandler(msg);
    }

    // On load: get or create the settings
    useEffect(() =>
    {
        RemoteRestrictionService.getIPRemoteRestrition().then((res) =>
        {
            setIP(res.data);
        })
        SettingService.getIPRestrition().then((res) =>
        {
            setSettingDTO(res.data);
            if (res.data.value === "1")
            {
                RemoteRestrictionService.getRemoteRestritionList().then((res) =>
                {
                    setIPList(res.data);
                })
                setVisibleMainButtons(false);
            }
        }).catch((err) =>
        {
            if (err.request.status === 404)
            {
                setSettingDTO({ ...settingDTO, schoolID: AuthService.getSchoolID(), type: 1 });
            }
            else setMessageHandler(MessageConverter.convertBusinessMsg(err));
        });
    }, [refreshKey]);

    // On switch 'afgeschermde toegang'
    useEffect(() =>
    {
        if (value !== 0)
        {
            if (settingDTO.id === undefined && settingDTO.value !== undefined)
            {
                try
                {
                    SettingService.createSetting(settingDTO).then((res) =>
                    {
                        setRefreshKey(oldKey => oldKey + 1);
                    }).catch((err) => setMessageHandler(MessageConverter.convertBusinessMsg(err)));
                } catch (error)
                {
                    setMessageHandler(MessageConverter.convertBusinessMsg(error));
                }
            }
            else
            {
                try
                {
                    SettingService.toggleIPRestriction(settingDTO).then((res) =>
                    {
                        setRefreshKey(oldKey => oldKey + 1);
                        setVisibleMainButtons(true);
                    }).catch((err) => setMessageHandler(MessageConverter.convertBusinessMsg(err)));
                } catch (error)
                {
                    setMessageHandler(MessageConverter.convertBusinessMsg(error));
                }
            }
        }
    }, [value]);

    // Add
    const onAdd = () =>
    {
        setMessageHandler();
        setVisibleMain(true);
        setVisibleAdd(false);
        setRemoteRestrictionDTO({ ...remoteRestrictionDTO, schoolID: AuthService.getSchoolID(), active: true, address: ip });
    }

    const onSaveAdd = (e) =>
    {
        e.preventDefault();
        setMessageHandler();
        if (remoteRestrictionDTO.address !== "")
        {
            setBlocked(true);
            try
            {
                RemoteRestrictionService.createRemoteRestriction(remoteRestrictionDTO).then((res) =>
                {
                    setVisibleMain(false);
                    setVisibleAdd(true);
                    setSelectedIP([]);
                    setRemoteRestrictionDTO([]);
                    setRefreshKey(oldKey => oldKey + 1);
                    setMessageHandler(MessageConverter.convertBusinessMsg("IP-Adres succesvol toegevoegd", 0));
                    setBlocked(false);
                }).catch((err) => { setMessageHandler(MessageConverter.convertBusinessMsg(err)); setBlocked(false) });
            } catch (error)
            {
                setMessageHandler(MessageConverter.convertBusinessMsg(error));
                setBlocked(false);
            }
        } else setMessageHandler(MessageConverter.convertBusinessMsg("Vul een IP-Adres in", 1));
    }

    const onCancelAdd = () =>
    {
        setVisibleMain(false);
        setVisibleAdd(true);
        setRemoteRestrictionDTO([]);
        setSelectedIP([]);
        setMessageHandler();
    }

    // Edit
    const onEdit = () =>
    {
        setMessageHandler();
        if (selectedIP !== null && selectedIP.length !== 0)
        {
            setBlocked(true);
            RemoteRestrictionService.getRemoteRestrition(selectedIP.id).then((res) =>
            {
                setVisibleMain(true);
                setVisibleEdit(false);
                setRemoteRestrictionDTO(res.data);
                setBlocked(false);
            }).catch((err) => { setMessageHandler(MessageConverter.convertBusinessMsg(err)); setBlocked(false) });
        } else setMessageHandler(MessageConverter.convertBusinessMsg("Selecteer een IP-Adres", 1));
    }

    const onSaveEdit = (e) =>
    {
        e.preventDefault();
        setMessageHandler();
        if (remoteRestrictionDTO.ipAddress !== "")
        {
            setBlocked(true);
            try
            {
                RemoteRestrictionService.updateRemoteRestriction(remoteRestrictionDTO).then((res) =>
                {
                    setVisibleMain(false);
                    setVisibleEdit(true);
                    setSelectedIP([])
                    setRemoteRestrictionDTO([]);
                    setRefreshKey(oldKey => oldKey + 1);
                    setMessageHandler(MessageConverter.convertBusinessMsg("IP-Adres succesvol bewerkt", 0));
                    setBlocked(false);
                }).catch((err) => { setMessageHandler(MessageConverter.convertBusinessMsg(err)); setBlocked(false) });
            } catch (error)
            {
                setMessageHandler(MessageConverter.convertBusinessMsg(error));
                setBlocked(false);
            }
        } else setMessageHandler(MessageConverter.convertBusinessMsg("Vul een IP-Adres in", 1));
    }

    const onCancelEdit = () =>
    {
        setMessageHandler();
        setVisibleMain(false);
        setVisibleEdit(true);
        setRemoteRestrictionDTO([]);
        setSelectedIP([]);
    }

    // Delete
    const deleteIP = () =>
    {
        setBlocked(true);
        setMessageHandler();
        RemoteRestrictionService.deleteRemoteRestriction(selectedIP.id).then(() =>
        {
            setRefreshKey(oldKey => oldKey + 1);
            setMessageHandler(MessageConverter.convertBusinessMsg("Het IP-Adres is succesvol verwijderd", 0));
            setVisibleDelete(false);
            setBlocked(false);
        }).catch((err) => { setMessageHandler(MessageConverter.convertBusinessMsg(err)); setBlocked(false) });
    }

    const onDeleteButton = () =>
    {
        setMessageHandler();
        if (selectedIP !== null)
        {
            setVisibleDelete(true);
        }
        else
        {
            setMessageHandler(MessageConverter.convertBusinessMsg("Geen IP-Adres geselecteerd", 1));
        }
    }

    return (
        <>
            <MessageHandler messages={msg} />
            <BlockUI blocked={blocked} fullScreen />
            <div hidden={visibleMain}>
                <div className="maincontent-item">
                    <label>Afgeschermde toegang</label>
                    <div className="radio-buttons">
                        <input
                            type="radio"
                            id="yes"
                            name="restriction"
                            checked={settingDTO.value === '1'}
                            onChange={(e) =>
                            {
                                setSettingDTO({ ...settingDTO, value: '1' });
                                setValue(1);
                            }}
                            value={1} />
                        <label htmlFor="yes">Ja</label>

                        <input
                            type="radio"
                            id="no"
                            name="restriction"
                            checked={settingDTO.value === '0'}
                            onChange={(e) =>
                            {
                                setSettingDTO({ ...settingDTO, value: '0' });
                                setValue(2);
                            }}
                            value={0} />
                        <label htmlFor="no">Nee</label>
                    </div>
                </div>

                <div className="sub-bar-item" hidden={visibleMainButtons}>
                    <label className="remote-restriction-label">Hieronder vind je het overzicht van reeds toegestane ip-adressen</label>
                    <ListBox
                        value={selectedIP}
                        onChange={(e) => { setSelectedIP(e.value); }}
                        listStyle={{ maxHeight: '355px' }}
                        optionLabel="ipAddress"
                        emptyMessage="Geen resultaten gevonden"
                        options={ipList} />
                </div>

                <div className="maincontent-item-buttons" hidden={visibleMainButtons}>
                    <input type="submit" value="Toevoegen" className="management-buttons" onClick={onAdd} />
                    <input type="submit" value="Bewerken" className="management-buttons" onClick={onEdit} />
                    <input type="submit" value="Verwijderen" className="management-buttons" onClick={onDeleteButton} />
                </div>
            </div>

            <div hidden={visibleAdd}>
                <div className="sub-bar-item">
                    <p>Opmerking: Een ipv4 adres wordt automatisch gemapped als ipv6 adres met de '::ffff:' prefix.</p>
                </div>
                <div className="maincontent-item">
                    <label htmlFor="ipadress">Toegestaan IP-Adres*</label>
                    <input
                        id="ipadress"
                        type="text"
                        required={true}
                        defaultValue={remoteRestrictionDTO.address}
                        onChange={(e) => setRemoteRestrictionDTO({ ...remoteRestrictionDTO, address: e.target.value })}
                        onInvalid={e => e.target.setCustomValidity("Dit veld is verplicht")}
                        onInput={e => e.target.setCustomValidity("")}
                        title="Dit veld is verplicht"
                    />
                </div>
                <div className="maincontent-item-buttons">
                    <input type="submit" value="Opslaan" className="management-buttons" onClick={onSaveAdd} />
                    <input type="submit" value="Annuleren" className="management-buttons" onClick={onCancelAdd} />
                </div>
            </div>

            <div hidden={visibleEdit}>
                <div className="sub-bar-item">
                    <p htmlFor="ipadress">Opmerking: Een ipv4 adres wordt automatisch gemapped als ipv6 adres met de '::ffff:' prefix.</p>
                </div>
                <div className="maincontent-item">
                    <label htmlFor="ipadress">Toegestaan IP-Adres*</label>
                    <input
                        type="text"
                        required={true}
                        defaultValue={remoteRestrictionDTO.address}
                        onChange={(e) => setRemoteRestrictionDTO({ ...remoteRestrictionDTO, address: e.target.value })}
                        onInvalid={e => e.target.setCustomValidity("Dit veld is verplicht")}
                        onInput={e => e.target.setCustomValidity("")}
                        title="Dit veld is verplicht"
                    />
                </div>
                <div className="maincontent-item-buttons">
                    <input type="submit" value="Opslaan" className="management-buttons" onClick={onSaveEdit} />
                    <input type="submit" value="Annuleren" className="management-buttons" onClick={onCancelEdit} />
                </div>
            </div>

            <Dialog
                className="delete-dialog"
                header={"Weet je zeker dat je dit IP-Adres wilt verwijderen?"}
                visible={visibleDelete}
                onHide={() => setVisibleDelete(false)}
                draggable={false}
            >
                <input type="submit" value="Ja" className="delete-button" onClick={deleteIP} />
                <input type="submit" value="Nee" className="cancel-delete-button" onClick={() => setVisibleDelete(false)} />
            </Dialog>
        </>
    );
}

export default RemoteRestrictionSetting;