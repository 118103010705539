import baseService from "../Service/BaseService";

// Get the list of all available/visible schoolyears
const getGroupyearListSoCo = () =>
{
    return new Promise((resolve, reject) =>
    {
        var cacheKey = "GroupyearListSoCo";
        var cacheVal = sessionStorage.getItem(cacheKey);
        if (cacheVal != null)
            resolve(JSON.parse(cacheVal));
        else
        {
            baseService
                .get('/Groupyear/list-for-soco', { })
                .then((response) =>
                {
                    // store response
                    sessionStorage.setItem(cacheKey, JSON.stringify(response));
                    resolve(response);
                })
                .catch((error) => reject(error));
        }
    });
};

const GroupyearService = {
    getGroupyearListSoCo
};

export default GroupyearService;