import React, { useState, useEffect } from 'react';
import { Link, useNavigate, useLocation } from "react-router-dom";
import { BlockUI } from 'primereact/blockui';
import Select from 'react-select';

import '../GeneralStyling.scss'
import GroupService from "../../Service/GroupService";
import SchoolyearService from "../../Service/SchoolyearService";
import GroupyearService from "../../Service/GroupyearService";
import AuthService from "../../Service/AuthService";
import MessageConverter from "../MessageConverter";
import MessageHandler from "../MessageHandler";

function GroupItem() {
    const navigate = useNavigate();
    const [title, setTitle] = useState("");
    const [groupDTO, setGroupDTO] = useState({});
    const [groupyearList, setGroupyearList] = useState([]);
    const [schoolyearList, setSchoolyearList] = useState([]);
    const [blocked, setBlocked] = useState(false);
    const [defaultSelectedSchoolyear, setDefaultSelectedSchoolyear] = useState(null);
    const [defaultSelectedGroupyear, setDefaultSelectedGroupyear] = useState(null);

    const { state } = useLocation();
    const [msg, setMsg] = useState([]);

    const newMessageHandler = (enteredMsg) => {
        setMsg(enteredMsg);
    }

    const setMessageHandler = (msg) => {
        newMessageHandler(msg);
    }

    useEffect(() => {
        GroupyearService.getGroupyearListSoCo().then((res) => {
            setGroupyearList(res.data)
            if (res.data.length == 1) {
                setDefaultSelectedGroupyear(res.data[0]);
                setGroupDTO({ ...groupDTO, groupyearID: res.data[0].id })
            }
        });
        if (state !== null) {
            SchoolyearService.getSchoolyearList().then((res) => {
                setSchoolyearList(res.data);
                if (res.data.length == 1) {
                    setDefaultSelectedSchoolyear(res.data[0]);
                    setGroupDTO({ ...groupDTO, schoolyearID: res.data[0].id })
                }
            });
            GroupService.getGroup(state.groupID).then((groupRes) => {
                setGroupDTO(groupRes.data);
            });
            setTitle("Bewerken");
        } else if (state === null) {
            setTitle("Toevoegen");
            SchoolyearService.getActiveSchoolyear().then((res) => {
                const activeSchoolyear = [res.data];
                setSchoolyearList(activeSchoolyear);
                setDefaultSelectedSchoolyear(activeSchoolyear);
                setGroupDTO({ ...groupDTO, schoolyearID: res.data.id, schoolID: AuthService.getSchoolID(), name: '', groupyearID: null });
            })
        }
    }, []);

    const saveGroup = async (e) => {
        setMessageHandler();
        setBlocked(true);
        e.preventDefault();
        try {
            if (state !== null) {
                await GroupService.updateGroup(groupDTO).then(() => {
                    setBlocked(false);
                    navigate("/Groepen", { state: { confirmedMessage: "De groep is succesvol opgeslagen" } });
                });
            } else if (state === null) {
                await GroupService.createGroup(groupDTO).then(() => {
                    setBlocked(false);
                    navigate("/Groepen", { state: { confirmedMessage: "De groep is succesvol toegevoegd" } });
                });
            }
        }
        catch (err) {
            setBlocked(false);
            setMessageHandler(MessageConverter.convertBusinessMsg(err))
        }
    }

    return (
        <div id="Page">
            <BlockUI blocked={blocked} fullScreen />
            <div id="MainContentContainer">
                <div className="page-information">
                    <div id="PageHeader" className="page-header">
                        <span>Groep: {title}</span>
                    </div>
                    <div>
                        <div id="InfoButton">
                            <a className="btn-info"></a>
                        </div>
                    </div>
                    <div className="clear"></div>
                </div>
                <div className="maincontent">
                    <div className="message-div">
                        <MessageHandler messages={msg} />
                    </div>
                    <form onSubmit={saveGroup} autoComplete="off">
                        <div className="maincontent-item">
                            <label>Schooljaar*</label>
                            <Select
                                isDisabled={true}
                                required={true}
                                options={schoolyearList}
                                getOptionLabel={(option) => option.name}
                                getOptionValue={(option) => option.id}
                                placeholder="Selecteer een schooljaar"
                                value={schoolyearList.find((element) => { return element.id == groupDTO.schoolyearID })}
                                onChange={(selectedSchoolyear) => setGroupDTO({ ...groupDTO, schoolyearID: selectedSchoolyear.id })}
                                isSearchable={true}
                                noOptionsMessage={() => 'Geen resultaten gevonden'}
                                defaultValue={defaultSelectedSchoolyear}
                                isMulti={false} />
                        </div>

                        <div className="maincontent-item">
                            <label>Groepsjaar*</label>
                            <Select
                                required={true}
                                options={groupyearList}
                                getOptionLabel={(option) => option.name}
                                getOptionValue={(option) => option.id}
                                placeholder="Selecteer een groepsjaar"
                                value={groupyearList.find((element) => { return element.id == groupDTO.groupyearID })}
                                onChange={(selectedGroupyear) => setGroupDTO({ ...groupDTO, groupyearID: selectedGroupyear.id })}
                                isDisabled={groupDTO?.id !== undefined}
                                noOptionsMessage={() => 'Geen resultaten gevonden'}
                                defaultValue={defaultSelectedGroupyear}
                                isMulti={false} />
                        </div>
                        <div className="maincontent-item">
                            <label htmlFor="Groepsnaam">Groepsnaam*</label>
                            <input
                                id="Groepsnaam"
                                type="text"
                                required={true}
                                onInvalid={e => e.target.setCustomValidity("Dit veld is verplicht")}
                                onInput={e => e.target.setCustomValidity("")}
                                title="Dit veld is verplicht"
                                defaultValue={groupDTO.name}
                                onChange={(e) => setGroupDTO({ ...groupDTO, name: e.target.value })}
                            />
                        </div>

                        <div className="maincontent-item-buttons">
                            <input
                                type="submit"
                                value="Opslaan"
                                className="user-add-buttons" />
                            <Link to="/Groepen">
                                <input type="submit" value="Annuleren" className="user-add-buttons" />
                            </Link>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    );
}

export default GroupItem;