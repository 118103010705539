import '../GeneralStyling.scss'
import MessageConverter from '../MessageConverter'
import MessageHandler from "../MessageHandler";
import SchoolyearService from '../../Service/SchoolyearService'
import SoCoRegistrationService from "../../Service/SoCoRegistrationService";
import HelpPageService from '../../Service/HelpPageService';

import Select from 'react-select';
import React, { useCallback, useEffect, useState } from 'react';
import moment from 'moment'
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { FilterMatchMode } from 'primereact/api';
import { Calendar } from 'primereact/calendar';
import { Dialog } from 'primereact/dialog';
import { BlockUI } from 'primereact/blockui';
import { Editor, EditorState, convertFromRaw, CompositeDecorator } from "draft-js";
import HelpItemDescription from '../HelpPage/HelpItemDescription';

function EditRegistration()
{
    const [registrationList, setRegistrationList] = useState([]);
    const [schoolyearList, setSchoolyearList] = useState([]);
    const [selectedSchoolyear, setSelectedSchoolyear] = useState([]);
    const [selectedRegistration, setSelectedRegistration] = useState([]);
    const [soCoRegistrationDto, setSoCoRegistrationDto] = useState([]);

    const [visibleDialogCloseDate, setVisibleDialogCloseDate] = useState(false);
    const [visibleDialogDelete, setVisibleDialogDelete] = useState(false);
    const [visibleDialogReopen, setVisibleDialogReopen] = useState(false);
    const [visibleDialogObservation, setVisibleDialogObservation] = useState(false);
    const [hideSubbar, setHideSubbar] = useState(true);
    const [hideButtons, setHideButtons] = useState(true);
    const [hideChangeClosedate, setHideChangeClosedate] = useState(false);

    const [loading, setLoading] = useState(true);
    const [refreshKey, setRefreshKey] = useState(0);
    const [filters, setFilters] = useState({
        'fullname': { value: null, matchMode: FilterMatchMode.CONTAINS },
        'closedate': { value: null, matchMode: FilterMatchMode.DATE_IS },
    });
    const [blocked, setBlocked] = useState(false);
    const [msg, setMsg] = useState([]);

    const [defaultSelected, setDefaultSelected] = useState(null);

    const [helpItem, setHelpItem] = useState(null);
    const [helpVisible, setHelpVisible] = useState(false);
    const [helpButtonHidden, sethelpButtonHidden] = useState(true);
    const currentPathName = window.location.pathname;

    // Prevent going back
    useEffect(() =>
    {
        window.addEventListener('popstate', (e) =>
        {
            window.history.go(1);
        });
    }, []);

    // Preload helpItems
    useEffect(() => {
        if (helpItem == null) {
            HelpPageService.getHelpItembyPage(currentPathName).then((res) => {
                if (res.data != undefined && res.data != null && res.data != '') {
                    setHelpItem(res.data);
                    sethelpButtonHidden(false);
                }
                else {
                    sethelpButtonHidden(true);
                }
            }).catch(err => {
                setMessageHandler(MessageConverter.convertBusinessMsg(err));
            });
        }
    }, []);

    const newMessageHandler = (enteredMsg) =>
    {
        setMsg(enteredMsg);
    }

    const setMessageHandler = (msg) =>
    {
        newMessageHandler(msg);
    }

    const datebody = (rowData) =>
    {
        if (rowData.closedate !== "0001-01-01T00:00:00" && rowData.closedate !== null)
        {
            return <>{moment(rowData.closedate).format("DD-MM-YYYY")}</>;
        } else return ''
    };

    const dateFilterTemplate = (options) =>
    {
        return (
            <Calendar
                value={options.value}
                //onChange={(e) => options.filterCallback(e.value, options.index)}
                dateFormat="dd/mm/yy"
                placeholder="dd-mm-yyyy"
                mask="99-99-9999"
            />
        );
    };

    // Get schoolyears
    useEffect(() =>
    {
        SchoolyearService.getSchoolyearList().then((res) =>
        {
            setSchoolyearList(res.data);
            if (res.data.length == 1)
            {
                setDefaultSelected(res.data[0]);
                setSelectedSchoolyear(res.data[0]);
                setHideSubbar(false);
            }
        })
    }, []);

    // Get closed registrations in schoolyear
    useEffect(() =>
    {
        if (selectedSchoolyear !== undefined && selectedSchoolyear.id > 0)
        {
            if (selectedSchoolyear.active === true)
            {
                setHideButtons(false);
            } else setHideButtons(true);
            SoCoRegistrationService.getClosedRegistrations(selectedSchoolyear.id).then((res) =>
            {
                setRegistrationList(res.data);
                setLoading(false);
            }).catch((err) => setMessageHandler(MessageConverter.convertBusinessMsg(err)));
        }
        else
        {
            setRegistrationList(null);
        }
    }, [selectedSchoolyear, refreshKey]);

    // Remove button "Afsluitdatum aanpassen" if registration is open
    useEffect(() =>
    {
        if (selectedRegistration.closedate === null)
        {
            setHideChangeClosedate(true);
        } else setHideChangeClosedate(false);
    }, [selectedRegistration]);

    // After pressing "Afsluitdatum aanpassen"
    const getRegistrationForClosedate = useCallback(() =>
    {
        setMessageHandler();
        if (selectedRegistration.length !== 0)
        {
            setBlocked(true);
            SoCoRegistrationService.getSoCoRegistration(selectedRegistration.id).then((res) =>
            {
                setSoCoRegistrationDto(res.data);
                setVisibleDialogCloseDate(true);
                setBlocked(false);
            }).catch(err => { setMessageHandler(MessageConverter.convertBusinessMsg(err)); setBlocked(false); });
        } else setMessageHandler(MessageConverter.convertBusinessMsg("Selecteer een registratie", 1));
    }, [selectedRegistration]);

    // After pressing "opslaan" in dialog of "Afsluitdatum aanpassen"
    const changeCloseDate = useCallback((e) =>
    {
        e.preventDefault();
        try
        {
            setBlocked(true);
            SoCoRegistrationService.changeClosedate(soCoRegistrationDto).then(() =>
            {
                setVisibleDialogCloseDate(false);
                setRefreshKey(oldKey => oldKey + 1);
                setSoCoRegistrationDto(null);
                setMessageHandler(MessageConverter.convertBusinessMsg("Afsluitdatum succesvol aangepast", 0));
                setBlocked(false);
            }).catch(err => { setMessageHandler(MessageConverter.convertBusinessMsg(err)); setBlocked(false); });
        } catch (error)
        {
            setMessageHandler(MessageConverter.convertBusinessMsg(error));
            setBlocked(false);
            setVisibleDialogCloseDate(false);
        }
    }, [soCoRegistrationDto]);

    // After pressing "Verwijderen"
    const getRegistrationForDelete = useCallback(() =>
    {
        setMessageHandler();
        if (selectedRegistration.length !== 0)
        {
            setVisibleDialogDelete(true);
        } else setMessageHandler(MessageConverter.convertBusinessMsg("Selecteer een registratie", 1));
    }, [selectedRegistration]);

    // After pressing "Ja" in dialog of "Verwijderen"
    const deleteRegistration = useCallback((e) =>
    {
        e.preventDefault();
        try
        {
            
            if (selectedRegistration.socoObservationID != undefined && selectedRegistration.socoObservationID != null)
            {
                setVisibleDialogDelete(false);
                setVisibleDialogObservation(true);
            }
            else
            {
                setBlocked(true);
                SoCoRegistrationService.deleteRegistration(selectedRegistration.id).then((res) => {
                    setVisibleDialogDelete(false);
                    setRefreshKey(oldKey => oldKey + 1);
                    setMessageHandler(MessageConverter.convertBusinessMsg("Registratie succesvol verwijderd", 0));
                    setBlocked(false);
                }).catch(err => { setMessageHandler(MessageConverter.convertBusinessMsg(err)); setBlocked(false); });
            }
        } catch (error)
        {
            setMessageHandler(MessageConverter.convertBusinessMsg(error));
            setBlocked(false);
            setVisibleDialogDelete(false);
        }
    }, [selectedRegistration]);


    const deleteObservations = (deleteObservation) =>
    {
        try
        {
            setBlocked(true);
            SoCoRegistrationService.deleteRegistrationwithObservations(selectedRegistration.id, deleteObservation).then((res) => {
                setVisibleDialogObservation(false);
                setRefreshKey(oldKey => oldKey + 1);
                setMessageHandler(MessageConverter.convertBusinessMsg("Registratie succesvol verwijderd", 0));
                setBlocked(false);
            }).catch(err => { setMessageHandler(MessageConverter.convertBusinessMsg(err)); setBlocked(false); setVisibleDialogObservation(false); });
        } catch (error) {
            setMessageHandler(MessageConverter.convertBusinessMsg(error));
            setBlocked(false);
            setVisibleDialogDelete(false);
        }
    };

    // After pressing "Heropenen"
    const getRegistrationForReopen = useCallback(() =>
    {
        setMessageHandler();
        if (selectedRegistration.length !== 0)
        {
            setBlocked(true);
            SoCoRegistrationService.getSoCoRegistration(selectedRegistration.id).then((res) =>
            {
                setSoCoRegistrationDto(res.data);
                setVisibleDialogReopen(true);
                setBlocked(false);
            }).catch(err => { setMessageHandler(MessageConverter.convertBusinessMsg(err)); setBlocked(false); });
        } else setMessageHandler(MessageConverter.convertBusinessMsg("Selecteer een registratie", 1));
    }, [selectedRegistration]);

    // After pressing "Ja" in dialog of "Heropenen"
    const ReopenRegistration = useCallback((e) =>
    {
        e.preventDefault();
        try
        {
            setBlocked(true);
            SoCoRegistrationService.reOpenRegistration(soCoRegistrationDto).then(() =>
            {
                setVisibleDialogReopen(false);
                setRefreshKey(oldKey => oldKey + 1);
                setSoCoRegistrationDto(null);
                setMessageHandler(MessageConverter.convertBusinessMsg("Registratie succesvol heropend", 0));
                setBlocked(false);
            }).catch(err => {
                setMessageHandler(MessageConverter.convertBusinessMsg(err));
                setBlocked(false);
                setVisibleDialogReopen(false);
            });
        } catch (error)
        {
            setMessageHandler(MessageConverter.convertBusinessMsg(error));
            setBlocked(false);
            setVisibleDialogReopen(false);
        }
    }, [soCoRegistrationDto]);

    return (
        <div id="Page">
            <BlockUI blocked={blocked} fullScreen />
            <div id="MainContentContainer">
                <div className="page-information">
                    <div id="PageHeader" className="page-header">
                        <span>Registratie bewerken: Overzicht</span>
                    </div>
                    <div id="InfoButton">
                        <div className="btn-info" onClick={() => setHelpVisible(true)} hidden={helpButtonHidden}></div>
                    </div>
                    <Dialog
                        header={"Registratie bewerken: Overzicht"}
                        visible={helpVisible}
                        modal={true}
                        onHide={() => setHelpVisible(false)}
                        style={{ width: '75vw' }}
                    >
                        <Editor className="textfield" editorState={HelpItemDescription(helpItem)} readOnly="true" />
                    </Dialog>
                    <div className="clear" />
                </div>
                <div>
                    <div className="maincontent-header-bar">
                        <div className="header-bar-item">
                            <label>Schooljaar</label>
                            <Select
                                options={schoolyearList}
                                getOptionLabel={(option) => option.name}
                                getOptionValue={(option) => option.id}
                                isSearchable={true}
                                isMulti={false}
                                placeholder="Selecteer een schooljaar"
                                onChange={(sy) =>
                                {
                                    setSelectedSchoolyear(sy);
                                    setHideSubbar(false);
                                }}
                                value={schoolyearList.find((element) => { return element.id == selectedSchoolyear?.id })}
                                noOptionsMessage={() => 'Geen resultaten gevonden'}
                                defaultValue={defaultSelected}
                            />
                        </div>
                    </div>
                    <div className="message-div">
                        <MessageHandler messages={msg} />
                    </div>
                    <div className="maincontent-sub-bar" hidden={hideSubbar}>
                        <div className="card">
                            <DataTable
                                value={registrationList}
                                loading={loading}
                                dataKey="id"
                                size="small"
                                paginator={true}
                                paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                                currentPageReportTemplate="{first} - {last} van {totalRecords}"
                                rows={10}
                                rowsPerPageOptions={[10, 20, 50, 100]}
                                responsiveLayout="scroll"
                                selectionMode="single"
                                selection={selectedRegistration}
                                onSelectionChange={e => { setSelectedRegistration(e.value) }}
                                filters={filters} filterDisplay="row"
                                emptyMessage="Geen resultaten gevonden"
                                stateKey="SoCoRegistration-EditRegistration">
                                <Column field="fullname"
                                    filter={true}
                                    showFilterMenu={false}
                                    filterPlaceholder="Zoek op naam"
                                    style={{ minWidth: '10rem' }}
                                    sortable={true}
                                    header="Kind" >
                                </Column>
                                <Column field="closedate"
                                    filter={true}
                                    filterField="date"
                                    filterElement={dateFilterTemplate}
                                    filter={true}
                                    body={datebody}
                                    style={{ minWidth: '10rem' }}
                                    sortable={true}
                                    showFilterMenu={false}
                                    header="Afsluitdatum" >
                                </Column>
                                <Column field="groupname"
                                    showFilterMenu={false}
                                    style={{ minWidth: '12rem' }}
                                    sortable={true}
                                    header="Groep" >
                                </Column>
                                <Column field="type"
                                    showFilterMenu={false}
                                    style={{ minWidth: '8rem' }}
                                    sortable={true}
                                    header="Type" >
                                </Column>
                            </DataTable>
                            <Dialog //Dialog afsluitdatum aanpassen
                                className="delete-dialog"
                                header={"Selecteer een afsluitdatum"}
                                visible={visibleDialogCloseDate}
                                onHide={() => setVisibleDialogCloseDate(false)}
                                draggable={false}
                            >
                                <form onSubmit={changeCloseDate}>
                                    <div className="maincontent-item" >
                                        <input
                                            type="date"
                                            required
                                            defaultValue={moment(selectedRegistration.closedate).format('YYYY-MM-DD')}
                                            onChange={(e) => setSoCoRegistrationDto({ ...soCoRegistrationDto, closedate: e.target.value })}
                                        />
                                    </div>
                                    <input type="submit" value="Opslaan" className="management-buttons" />
                                </form>
                            </Dialog>
                            <Dialog // Dialog verwijderen
                                className="delete-dialog"
                                header={"Weet je zeker dat je deze registratie wilt verwijderen?"}
                                visible={visibleDialogDelete}
                                onHide={() => setVisibleDialogDelete(false)}
                                draggable={false}
                            >
                                <input type="submit" value="Ja" className="delete-button" onClick={deleteRegistration} />
                                <input type="submit" value="Nee" className="cancel-delete-button" onClick={() => setVisibleDialogDelete(false)} />
                            </Dialog>
                            <Dialog // Dialog observaties aanwezig
                                header={"Er zijn observaties gekoppeld aan deze registratie, wil je deze ook verwijderen? (indien je dit niet wilt, wordt geprobeerd de observatie opnieuw te openen)"}
                                visible={visibleDialogObservation}
                                onHide={() => setVisibleDialogObservation(false)}
                                draggable={false}
                                style={{ width: '75vw' }}
                            >
                                <input type="submit" value="Ja" className="delete-button" onClick={() => deleteObservations(true)} />
                                <input type="submit" value="Nee" className="cancel-delete-button" onClick={() => deleteObservations(false)} />
                            </Dialog>
                            <Dialog // Dialog heropenen
                                className="delete-dialog"
                                header={"Weet je zeker dat je deze registratie wilt heropenen?"}
                                visible={visibleDialogReopen}
                                onHide={() => setVisibleDialogReopen(false)}
                                draggable={false}
                            >
                                <input type="submit" value="Ja" className="delete-button" onClick={ReopenRegistration} />
                                <input type="submit" value="Nee" className="cancel-delete-button" onClick={() => setVisibleDialogReopen(false)} />
                            </Dialog>
                            <div className="clear"></div>
                        </div>
                        <button className="main-buttons" hidden={hideButtons} onClick={getRegistrationForDelete}>Verwijderen</button>
                        <button className="main-buttons" hidden={hideButtons} onClick={getRegistrationForReopen}>Heropenen</button>
                        <button className="main-buttons" hidden={hideChangeClosedate} onClick={getRegistrationForClosedate}>Afsluitdatum aanpassen</button>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default EditRegistration;