import React, { useCallback, useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { Dropdown } from 'primereact/dropdown';
import { Dialog } from 'primereact/dialog';
import { BlockUI } from 'primereact/blockui';
import { Editor, EditorState, convertFromRaw, CompositeDecorator } from "draft-js";

import '../GeneralStyling.scss'
import GroupService from "../../Service/GroupService";
import SchoolyearService from "../../Service/SchoolyearService";
import GroupyearService from "../../Service/GroupyearService";
import MessageConverter from "../MessageConverter";
import MessageHandler from "../MessageHandler";
import HelpPageService from '../../Service/HelpPageService';
import HelpItemDescription from '../HelpPage/HelpItemDescription';

function GroupManagement()
{
    const navigate = useNavigate();
    const [visibleDelete, setVisibleDelete] = useState(false);
    const [visible, setVisible] = useState(false);
    const [blocked, setBlocked] = useState(false);
    const [loading, setLoading] = useState(true);

    const [groupList, setGroupList] = useState([]);
    const [groupyearList, setGroupyearList] = useState([]);
    const [schoolyearList, setSchoolyearList] = useState([]);

    const [refreshKey, setRefreshKey] = useState(0);
    const [selectedGroup, setSelectedGroup] = useState(null);
    const [name, setName] = useState("");
    const [msg, setMsg] = useState([]);

    const [helpItem, setHelpItem] = useState(null);
    const [helpVisible, setHelpVisible] = useState(false);
    const [helpButtonHidden, sethelpButtonHidden] = useState(true);
    const currentPathName = window.location.pathname;

    const newMessageHandler = (enteredMsg) =>
    {
        setMsg(enteredMsg);
    }

    const setMessageHandler = (msg) =>
    {
        newMessageHandler(msg);
    }

    // Schoolyear
    const schoolyearBodyTemplate = (rowData) =>
    {
        return <span className={`customer-badge status-${rowData.schoolyearID}`}>{schoolyearList.find((element) => { return element.id === rowData.schoolyearID; })?.name}</span>;
    }
    const schoolyearFilterelement = (options) =>
    {
        return <Dropdown value={options.value} optionValue="id" optionLabel="name" options={schoolyearList}
            onChange={(e) => options.filterApplyCallback(e.value)} placeholder="Selecteer een schooljaar"
            className="p-column-filter" /*showClear*/ />;
    }
    const schoolyearFilter = (value, filter) =>
    {
        return filter === null ? true : value === filter;
    }

    // Groupyear
    const groupyearBodyTemplate = (rowData) =>
    {
        return <span className={`customer-badge status-${rowData.groupyearID}`}>{groupyearList.find((element) => { return element.id === rowData.groupyearID; })?.name}</span>;
    }
    const groupyearFilterElement = (options) =>
    {
        return <Dropdown value={options.value} optionValue="id" optionLabel="name" options={groupyearList}
            onChange={(e) => options.filterApplyCallback(e.value)} placeholder="Selecteer een groepsjaar"
            className="p-column-filter" /*showClear*/ />;
    }
    const groupyearFilter = (value, filter) =>
    {
        return filter === null ? true : value === filter;
    }

    // Edit group
    const editGroup = useCallback(() =>
    {
        setMessageHandler();
        if (selectedGroup == null)
        {
            setMessageHandler(MessageConverter.convertBusinessMsg("Geen groep geselecteerd", 1))
        }
        else
        {
            setBlocked(true);
            navigate("/GroepItem", { state: { groupID: selectedGroup.id } });
            setBlocked(false);
        }
    }, [selectedGroup]);

    // Add group
    const addGroup = useCallback(() =>
    {
        navigate("/GroepItem");
    }, []);

    // Delete group
    const deleteGroup = useCallback(() =>
    {
        setBlocked(true);
        try
        {
            GroupService.deleteGroup(selectedGroup.id).then(() =>
            {
                setRefreshKey(oldKey => oldKey + 1);
                setMessageHandler(MessageConverter.convertBusinessMsg("Groep is succesvol verwijderd", 0))
                setVisibleDelete(false);
                setBlocked(false);
            }).catch(err =>
            {
                setMessageHandler(MessageConverter.convertBusinessMsg(err));
                setBlocked(false);
                setVisibleDelete(false);
            });
        } catch (err)
        {
            setMessageHandler(MessageConverter.convertBusinessMsg(err));
            setBlocked(false);
            setVisibleDelete(false);
        };
    }, [selectedGroup]);

    // On click delete button
    const onDeleteButton = useCallback(() =>
    {
        setMessageHandler();
        if (selectedGroup == null)
        {
            setMessageHandler(MessageConverter.convertBusinessMsg("Geen groep geselecteerd", 1))
        }
        else
        {
            setBlocked(true);
            setVisibleDelete(true);
            setBlocked(false);
        }
    }, [selectedGroup]);

    // Join Users
    const joinUsers = useCallback(() =>
    {
        setMessageHandler();
        if (selectedGroup == null)
        {
            setMessageHandler(MessageConverter.convertBusinessMsg("Geen groep geselecteerd", 1))
        }
        else
        {
            setBlocked(true);
            navigate("/Groepen/KoppelGebruiker", { state: { group: selectedGroup } });
            setBlocked(false);
        }
    }, [selectedGroup]);

    // On load
    useEffect(() =>
    {
        SchoolyearService.getSchoolyearList().then((res) =>
        {
            setSchoolyearList(res.data);
            setLoading(false);
        }).catch(err =>
        {
            setMessageHandler(MessageConverter.convertBusinessMsg(err));
            setLoading(false);
        });

        GroupyearService.getGroupyearListSoCo().then((res) =>
        {
            setGroupyearList(res.data);
            setLoading(false);
        }).catch(err =>
        {
            setMessageHandler(MessageConverter.convertBusinessMsg(err));
            setLoading(false);
        });

        GroupService.getGroupListForOverview().then((res) =>
        {
            setGroupList(res.data);
            setLoading(false);
        }).catch(err =>
        {
            setMessageHandler(MessageConverter.convertBusinessMsg(err));
            setLoading(false);
        });
    }, [refreshKey]);

    // General message
    const { state } = useLocation();
    useEffect(() =>
    {
        if (state !== null)
        {
            const { confirmedMessage } = state;
            setMessageHandler(MessageConverter.convertBusinessMsg(confirmedMessage, 0));
        }
    }, []);

    // Prevent going back
    useEffect(() =>
    {
        window.addEventListener('popstate', (e) =>
        {
            window.history.go(1);
        });
    }, []);

    // Preload helpItems
    useEffect(() => {
        if (helpItem == null) {
            HelpPageService.getHelpItembyPage(currentPathName).then((res) => {
                if (res.data != undefined && res.data != null && res.data != '') {
                    setHelpItem(res.data);
                    sethelpButtonHidden(false);
                }
                else {
                    sethelpButtonHidden(true);
                }
            }).catch(err => {
                setMessageHandler(MessageConverter.convertBusinessMsg(err));
            });
        }
    }, []);

    return (
        <div id="Page">
            <BlockUI blocked={blocked} fullScreen />
            <div id="MainContentContainer">
                <div className="page-information">
                    <div id="PageHeader" className="page-header">
                        <span>Groepen: Overzicht</span>
                    </div>
                    <div id="InfoButton">
                        <div className="btn-info" onClick={() => setHelpVisible(true)} hidden={helpButtonHidden}></div>
                    </div>
                    <Dialog
                        header={"Groepen: Overzicht"}
                        visible={helpVisible}
                        modal={true}
                        onHide={() => setHelpVisible(false)}
                        style={{ width: '75vw' }}
                    >
                        <Editor className="textfield" editorState={HelpItemDescription(helpItem)} readOnly="true" />
                    </Dialog>
                    <div className="clear" />
                </div>
                <div>
                    <div className="maincontent">
                        <div className="message-div">
                            <MessageHandler messages={msg} />
                        </div>
                        <div className="card">
                            <DataTable
                                value={groupList}
                                paginator={true}
                                paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                                currentPageReportTemplate="{first} - {last} van {totalRecords}"
                                rowsPerPageOptions={[10, 20, 50, 100]}
                                size="small"
                                dataKey="id"
                                filterDisplay="row"
                                loading={loading}
                                selectionMode="single"
                                rows={10}
                                responsiveLayout="scroll"
                                selection={selectedGroup}
                                onSelectionChange={(e) => { setSelectedGroup(e.value); setName(e.value?.name) }}
                                emptyMessage="Geen resultaten gevonden."
                                stateKey="GroupManagement-Overview">
                                <Column field="schoolyearID"
                                    header="Schooljaar"
                                    showFilterMenu={false}
                                    filterMenuStyle={{ width: '10rem' }}
                                    style={{ minWidth: '12rem' }}
                                    sortable={true}
                                    body={schoolyearBodyTemplate}
                                    filterElement={schoolyearFilterelement}
                                    filterMatchMode="custom" filterFunction={schoolyearFilter}
                                    filter={true} />
                                <Column field="name"
                                    header="Naam"
                                    filter
                                    filterMatchMode="contains"
                                    showFilterMenu={false}
                                    sortable={true}
                                    filterPlaceholder="Zoek op naam"
                                    style={{ minWidth: '12rem' }} />
                                <Column field="groupyearID"
                                    header="Groepsjaar"
                                    showFilterMenu={false}
                                    sortable={true}
                                    filterMenuStyle={{ width: '10rem' }}
                                    style={{ minWidth: '12rem' }}
                                    body={groupyearBodyTemplate}
                                    filterElement={groupyearFilterElement}
                                    filterMatchMode="custom"
                                    filterFunction={groupyearFilter}
                                    filter={true} />
                            </DataTable>
                            <div className="clear"></div>
                        </div>
                    </div>
                    <div className="buttons">
                        <input type="submit" value="Toevoegen" className="management-buttons" onClick={addGroup} />
                        <input type="submit" value="Bewerken" className="management-buttons" onClick={editGroup} />
                        <input type="submit" value="Verwijderen" className="management-buttons" onClick={onDeleteButton} />
                        <input type="submit" value="Gebruiker Koppelen" className="management-buttons" onClick={joinUsers} />
                    </div>
                    <Dialog
                        className="delete-dialog"
                        header={"Weet je zeker dat je " + name + " wilt verwijderen?"}
                        visible={visibleDelete}
                        onHide={() => setVisibleDelete(false)}
                        draggable={false}
                    >
                        <input type="submit" value="Ja" className="delete-button" onClick={deleteGroup} />
                        <input type="submit" value="Nee" className="cancel-delete-button" onClick={() => setVisibleDelete(false)} />
                    </Dialog>
                </div>
            </div>
        </div>
    );
}

export default GroupManagement;