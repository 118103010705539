import React from 'react';

function MessagesHandler(props)
{
    if (props.messages !== undefined)
    {
        return (
            <div>
                {props.messages.map((messages) =>
                {
                    if (messages.type === 0)
                    {
                        return (
                            <p key={messages.type} className="general-message">
                                {messages.message}
                            </p>
                        );
                    }
                    else if (messages.type === 1)
                    {
                        return (
                            <p key={messages.type} className="validation-error">
                                {messages.message}
                            </p>
                        );
                    }
                    else if (messages.type === 2)
                    {
                        return (
                            <p key={messages.type} className="technical-error">
                                {messages.message}
                            </p>
                        );
                    }
                    else if (messages.type === 3)
                    {
                        return (
                            <p key={messages.type} className="warning-message">
                                {messages.message}
                            </p>
                        );
                    }
                })}
            </div>
        )
    }
}
export default MessagesHandler;