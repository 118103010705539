import React, { useState, useEffect } from 'react';
import './Header.scss';
import { Link, useNavigate } from 'react-router-dom';
import AuthService from "../../Service/AuthService";
import UserSchoolService from "../../Service/UserSchoolService";
import Countdown, { zeroPad } from 'react-countdown';
import CommunicationScreenService from '../../Service/CommunicationScreenService';

function Header()
{
    const navigate = useNavigate();
    const date = Date.now() + 1800000; // 30 minutes in milliseconds
    const [key, setKey] = useState(0);
    const [text, setText] = useState([]);

    const logout = (e) =>
    {
        if (AuthService.getIsAdopted())
        {
            // set orig EstrategyAdmin user
            var origAuth = AuthService.getOrigUserAuth()
            AuthService.setAuth(origAuth);
            AuthService.setSchoolID(1);
            AuthService.setCurrentSchoolname('Admin school');
            AuthService.setCurrentUsername(origAuth.username);
            AuthService.setPermissions(null);
            AuthService.removeUserSpecificItems()
            AuthService.getPermissions().then(() =>
            {
                AuthService.clearAdopted();
                e.preventDefault();
                navigate("/AdoptUser", { replace: true });
            });
        }
        else if (isNaN(AuthService.getSchoolID()))
        {
            // From select school
            sessionStorage.clear();
            AuthService.logout();
            navigate("/", { replace: true });
        }
        else
        {
            UserSchoolService.getUserSchoolList().then((res) =>
            {
                const userSchoolList = res.data;

                if (userSchoolList.length > 1)
                {
                    var origAuth = AuthService.getAuth()
                    if (origAuth == null)
                    {
                        AuthService.logout();
                        navigate("/", { replace: true });
                    } else
                    {
                        sessionStorage.clear();
                        AuthService.setAuth(origAuth);
                        AuthService.getPermissions().then((res) =>
                        {
                            AuthService.setPermissions(res);
                            navigate("/login/SelectSchool", { replace: true })
                        }).catch(err => navigate("/", { replace: true, state: { confirmedMessage: MessageConverter.convertBusinessMsg(err) } }));
                    }
                }
                else
                {
                    AuthService.logout();
                    navigate("/", { replace: true });
                }
            }).catch(err => navigate("/", { replace: true, state: { confirmedMessage: MessageConverter.convertBusinessMsg(err) } }));
        }
    };

    const resetTimer = () =>
    {
        setKey(Date.now());
    }

    function setWithExpiry(key, value, ttl)
    {
        const now = new Date()

        const item = {
            value: value,
            expiry: now.getTime() + ttl,
        }
        sessionStorage.setItem(key, JSON.stringify(item))
    }

    function getWithExpiry(key)
    {
        const itemStr = sessionStorage.getItem(key)
        if (itemStr == undefined || itemStr == null)
        {
            return null
        }
        const item = JSON.parse(itemStr)
        const now = new Date()
        if (now.getTime() > item.expiry)
        {
            sessionStorage.removeItem(key)
            return null
        }
        return item.value
    }

    useEffect(() =>
    {
        window.addEventListener("click", resetTimer);
        window.addEventListener("keypress", resetTimer);

        const cacheKey = "MaintenanceMessage"

        if (getWithExpiry(cacheKey) !== null)
        {
            setText(getWithExpiry(cacheKey));
        } else
        {
            CommunicationScreenService.getMaintenanceMessage().then((res) =>
            {
                if (res.status === 200)
                {
                    setText(res.data);
                    setWithExpiry(cacheKey, res.data, 60000);
                }
            });
        }
    }, []);

    const renderer = ({ minutes, seconds, completed }) =>
    {
        if (completed)
        {
            AuthService.logout().then((res) =>
            {
                if (res === true)
                {
                    return navigate("/", { replace: true, state: { confirmedMessage: "Je bent automatisch uitgelogd" } });
                }
            });
        }
        return (
            <span>
                {zeroPad(minutes)}:{zeroPad(seconds)}
            </span>
        );
    };

    // Show message if there is a message
    const DisplayMessage = () =>
    {
        if (text == "" || text.lenght == 0)
        {
            return <></>
        } else return (<div className="update-message" dangerouslySetInnerHTML={{ __html: text }} />)
    }

    return (
        <div className="header">
            <div className="mainheader">
                <div className="mainheader-logo">
                    <Link to="/Home">
                        <input type="image" name="header-logo" src="/img/kijk-logo.png" alt="logo kijk" />
                    </Link>
                </div>
                <div className="mainheader-login">
                    <div className="mainheader-login-inner">
                        <div className="mainheader-login-text">
                            Welkom, {AuthService.getCurrentUsername()} | {AuthService.getCurrentSchoolname()}
                        </div>
                        <div className="mainheader-logout">
                            <input type="button" title="Uitloggen" className="logoutButton" onClick={logout} />
                        </div>
                    </div>
                    <DisplayMessage />
                </div>
                <div className="countdowncontainer">
                    Je wordt automatisch uitgelogd na: <Countdown date={date} renderer={renderer} key={key} />
                </div>
                <div className="clear"></div>
            </div>
        </div>
    );
}

export default Header;