import { Editor, EditorState, convertFromRaw, CompositeDecorator } from "draft-js";
import React, { useEffect, useState } from 'react';
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'

function HelpItemDescription(item) {

    const findImageEntities = (contentBlock, callback, contentState) => {
        contentBlock.findEntityRanges(
            (character) => {
                const entityKey = character.getEntity();
                return (
                    entityKey !== null &&
                    contentState.getEntity(entityKey).getType() === 'IMAGE'
                );
            },
            callback
        );
    }

    const Image = (props) => {
        const { height, src, width } = props.contentState.getEntity(props.entityKey).getData();
        return (
            <img src={process.env.REACT_APP_API_URL + src} height={height} width={width} />
        );
    };

    const customDecorators = [{
        strategy: findImageEntities,
        component: Image,
    },
    ];

    if (item == null || item == '') {
        return EditorState.createEmpty();
    }
    else {
        return EditorState.createWithContent(convertFromRaw(JSON.parse(item?.content)), new CompositeDecorator(customDecorators));
    }
}

export default HelpItemDescription;