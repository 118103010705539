import baseService from "./BaseService";

// Get the soco list for groupyear
const getListForGroupyearSoCo = (groupyearID, type) =>
{
    return new Promise((resolve, reject) =>
    {
        baseService
            .get(`/SoCoSignalItem/list-for-groupyear/${groupyearID}/${type}`, {})
            .then((response) =>
            {
                resolve(response);
            })
            .catch((error) => reject(error));
    });
};

const SoCoSignalItemService = {
    getListForGroupyearSoCo,
};

export default SoCoSignalItemService;