import React, { useEffect, useState } from 'react';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import { BlockUI } from 'primereact/blockui';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';

import QuestionHandlerStudent from './QuestionHandlerStudent'
import '../SoCoRegistration.scss'
import '../../GeneralStyling.scss'
import AuthService from '../../../Service/AuthService';
import SoCoRegistrationService from '../../../Service/SoCoRegistrationService';
import MessageConverter from '../../MessageConverter';
import MessageHandler from "../../MessageHandler";

function ChildList()
{
    const [questions, setQuestions] = useState([]);
    const [soCoRegistration, setSoCoRegistration] = useState([]);
    const [hiddenSave, setHiddenSave] = useState(true);
    const [hiddenPageRefresh, setHiddenPageRefresh] = useState(true);
    const [hiddenQuestions, setHiddenQuestions] = useState(false);
    const [blocked, setBlocked] = useState(false);

    const { state } = useLocation();
    const [msg, setMsg] = useState([]);

    const newMessageHandler = (enteredMsg) =>
    {
        setMsg(enteredMsg);
    }

    const setMessageHandler = (msg) =>
    {
        newMessageHandler(msg);
    }

    useEffect(() =>
    {
        const navigationEntries = window.performance.getEntriesByType('navigation');
        if (navigationEntries.length > 0 && navigationEntries[0].type === 'reload')
        {
            {
                setHiddenSave(true);
                setHiddenQuestions(true);
                setHiddenPageRefresh(false);
                QuestionHandlerStudent.soCoRegistrationScoreList = [];
                setBlocked(false);
            }
        }
        else if (state !== null && state.questions !== undefined && state.soCoRegistration !== undefined)
        {
            setQuestions(state?.questions);
            setSoCoRegistration(state?.soCoRegistration);
        }
    }, []);

    const onSave = async (e) =>
    {
        setMessageHandler();

        if (questions.length === QuestionHandlerStudent.soCoRegistrationScoreList.length)
        {
            e.preventDefault();
            setBlocked(true);
            try
            {
                await SoCoRegistrationService.updateSoCoRegistrationAsStudent(soCoRegistration, QuestionHandlerStudent.soCoRegistrationScoreList, state.previousSoCoRegistration).then((res) =>
                {
                    setHiddenSave(false);
                    setHiddenQuestions(true);
                    QuestionHandlerStudent.soCoRegistrationScoreList = [];
                    setBlocked(false);
                })
            } catch (err) { setMessageHandler(MessageConverter.convertBusinessMsg(err)); setBlocked(false); };
        } else setMessageHandler(MessageConverter.convertBusinessMsg("Er zijn nog een paar bolletjes leeg. Vul je die ook nog in?", 1))
    }

    // Prevent going back
    useEffect(() =>
    {
        window.addEventListener('popstate', (e) =>
        {
            window.history.go(1);
        });
    }, []);

    // Push the comment in the soCoRegistrationScoreList
    const pushComment = (comment) =>
    {
        return soCoRegistration.comment = comment;
    }

    const Note = (props) =>
    {
        return (
            <div className="note">
                <div>
                    <div className="content">
                        <textarea
                            className="textarea-soco-type"
                            onChange={(e) => pushComment(e.target.value)}
                            defaultValue={soCoRegistration.comment}
                        />
                    </div>
                </div>
            </div>
        );
    }

    if (state === null || state.soCoRegistration === undefined)
        return (<h2 style={{ margin: '20px' }}>
            Er ging iets mis. Zet de lijst opnieuw klaar
        </h2>);
    else return (
        <div id="Page">
            <BlockUI blocked={blocked} fullScreen />
            <div id="MainContentContainer" className="content-container-childlist" hidden={hiddenQuestions}>
                <div className="maincontent-sub-bar" key={soCoRegistration?.id}>
                    <div className="message-div">
                        <MessageHandler messages={msg} />
                    </div>
                    <label className="soco-type">
                        Leerling: {AuthService.getAuth()?.username}
                    </label><br />
                    <QuestionHandlerStudent.Question
                        question={questions}
                        soCoRegistrationID={soCoRegistration?.id}
                        soCoRegistrationScoreList={state?.soCoRegistrationScoreList}
                    />
                    <label className="child_comment">
                        Wil je nog iets vertellen over hoe je je voelt op school en in de klas, over hoe je omgaat met andere kinderen en met de juf/meester, dan mag dat hier. Als je niets wilt vertellen, klik je op opslaan.
                    </label>
                    <br />
                    <Note title="Notitie bij dit item maken" ></Note>
                    <br />
                    <button onClick={onSave} className="main-buttons"> Opslaan </button>
                </div>

            </div>

            <div id="MainContentContainer" className="content-container-childlist" hidden={hiddenSave}>
                <div className="maincontent-sub-bar">
                    <label className="soco-type">Je bent klaar met invullen. Dank je wel.</label>
                </div>
            </div>

            <div id="MainContentContainer" className="content-container-childlist" hidden={hiddenPageRefresh}>
                <div className="maincontent-sub-bar">
                    <label className="soco-type">Pagina verversen is niet mogelijk.</label>
                </div>
            </div>

        </div>
    );
}

export default ChildList;