import '../GeneralStyling.scss'
import MessageConverter from "../MessageConverter";
import MessageHandler from "../MessageHandler";
import DocumentationService from '../../Service/DocumentationService';
import HelpPageService from '../../Service/HelpPageService';
import './Documentation.scss'
import { BlockUI } from 'primereact/blockui';
import React, { useState, useEffect } from 'react';
import { Dialog } from 'primereact/dialog';
import { Editor, EditorState, convertFromRaw, CompositeDecorator } from "draft-js";
import HelpItemDescription from '../HelpPage/HelpItemDescription';

function DocumentationView()
{
    const [blocked, setBlocked] = useState(false);
    const [msg, setMsg] = useState([]);
    const [list, setList] = useState([]);

    const [helpItem, setHelpItem] = useState(null);
    const [helpVisible, setHelpVisible] = useState(false);
    const [helpButtonHidden, sethelpButtonHidden] = useState(true);
    const currentPathName = window.location.pathname;

    const newMessageHandler = (enteredMsg) =>
    {
        setMsg(enteredMsg);
    }

    const setMessageHandler = (msg) =>
    {
        newMessageHandler(msg);
    }

    useEffect(() =>
    {
        DocumentationService.getDocumentationList().then((res) =>
        {
            setList(res.data);
        }).catch(err => { setMessageHandler(MessageConverter.convertBusinessMsg(err)) })
    }, []);

    const getDoc = (id, name) =>
    {
        setMessageHandler();
        setBlocked(true);
        DocumentationService.getDocumentationDoc(id).then((res) =>
        {
            setBlocked(false);
            if (res.status == 200)
            {
                const url = window.URL.createObjectURL(new Blob([res.data], { type: res.headers["content-type"] }));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', `${name}`);
                document.body.appendChild(link);
                link.click();
            }
        }).catch((err) => { setMessageHandler(MessageConverter.convertBusinessMsg(err)); setBlocked(false); });
    }

    const CommonContent = () =>
    {
        return (
            list.map(item =>
            {
                if (item.category == 1)
                {
                    return (
                        <tr key={item.id} className="table-row-documentation">
                            <td className="td-name">
                                <div onClick={() => getDoc(item.id, item.url)} className="document-link">
                                    {item.displayName}
                                </div>
                            </td>
                            <td className="td-icon">
                                <img src={`../img/${item.iconUrl}`} alt={item.displayName} className="document-icon"></img>
                            </td>
                        </tr>
                    )
                }
            })
        )
    }

    const SoCoContent = () =>
    {
        return (
            list.map(item =>
            {
                if (item.category == 2)
                {
                    return (
                        <tr key={item.id} className="table-row-documentation">
                            <td className="td-name">
                                <div onClick={() => getDoc(item.id, item.url)} className="document-link">
                                    {item.displayName}
                                </div>
                            </td>
                            <td className="td-icon">
                                <img src={`../img/${item.iconUrl}`} alt={item.displayName} className="document-icon"></img>
                            </td>
                        </tr>
                    )
                }
            })
        )
    }

    // Prevent going back
    useEffect(() =>
    {
        window.addEventListener('popstate', (e) =>
        {
            window.history.go(1);
        });
    }, []);

    // Preload helpItems
    useEffect(() => {
        if (helpItem == null) {
            HelpPageService.getHelpItembyPage(currentPathName).then((res) => {
                if (res.data != undefined && res.data != null && res.data != '') {
                    setHelpItem(res.data);
                    sethelpButtonHidden(false);
                }
                else {
                    sethelpButtonHidden(true);
                }
            }).catch(err => {
                setMessageHandler(MessageConverter.convertBusinessMsg(err));
            });
        }
    }, []);

    return (
        <div id="Page">
            <BlockUI blocked={blocked} fullScreen />
            <div id="MainContentContainer">
                <div className="page-information">
                    <div id="PageHeader" className="page-header">
                        <span>Werkmaterialen inzien: Overzicht</span>
                    </div>
                    <div id="InfoButton">
                        <div className="btn-info" onClick={() => setHelpVisible(true)} hidden={helpButtonHidden}></div>
                    </div>
                    <Dialog
                        header={"Werkmaterialen inzien: Overzicht"}
                        visible={helpVisible}
                        modal={true}
                        onHide={() => setHelpVisible(false)}
                        style={{ width: '75vw' }}
                    >
                        <Editor className="textfield" editorState={HelpItemDescription(helpItem)} readOnly="true" />
                    </Dialog>
                    <div className="clear"></div>
                </div>
                <div>
                    <div className="maincontent">
                        <div className="message-div">
                            <MessageHandler messages={msg} />
                        </div>
                        <div className="sub-bar-item">
                            <p>
                                Hieronder vind je de documenten behorend bij de licenties/modules
                            </p>
                            <label>Algemeen</label>
                            <div className="documents-item">
                                <table>
                                    <tbody>
                                        <CommonContent />
                                    </tbody>
                                </table>
                            </div>

                            <label>Sociale Competentie</label>
                            <div className="documents-item">
                                <table>
                                    <tbody>
                                        <SoCoContent />
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default DocumentationView;