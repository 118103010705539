import axios from "axios";
import AuthService from "../Service/AuthService";
import UserService from "../Service/UserService";

const baseService = axios.create({
    baseURL: process.env.REACT_APP_API_URL,
    headers: {
        "Content-Type": "application/json",
        accept: "application/json",
        "x-api-version": "1.0",
    }
});

baseService.interceptors.request.use(
    (config) =>
    {
        const token = AuthService.getLocalAccessToken();
        if (token)
        {
            config.headers["Authorization"] = `Bearer ${AuthService.getLocalAccessToken()}`;
        }
        return config;
    },
    (error) =>
    {
        return Promise.reject(error);
    }
);

baseService.interceptors.response.use(
    (res) =>
    {
        return res;
    },
    async (err) =>
    {
        const originalConfig = err.config;

        if (err.response)
        {
            // access token expired
            if (err.response.status === 401 && !originalConfig._retry)
            {
                // handle infinite loop
                originalConfig._retry = true;

                try
                {
                    const rs = await baseService.post('User/get-accesstoken', {
                        refreshToken: AuthService.getLocalRefreshToken(),
                    })

                    AuthService.updateNewAccessToken(rs.data);
                    UserService.setSchool(AuthService.getSchoolID())
                        .then((res) =>
                        {
                            if (res?.status === 200)
                            {
                                AuthService.updateNewAccessToken(res.data);
                                window.location.reload();
                            }
                        }).catch(err => { return Promise.reject(err) });
                    return baseService(originalConfig);
                } catch (_error)
                {
                    return Promise.reject(_error);
                }
            }
        }
        return Promise.reject(err);
    }
);

export default baseService;