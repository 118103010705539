import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { BlockUI } from 'primereact/blockui';

import UserService from "../../Service/UserService"
import SchoolService from "../../Service/SchoolService"
import JoinToSchoolService from "../../Service/JoinToSchoolService"
import '../GeneralStyling.scss'
import MessageConverter from "../MessageConverter";
import MessageHandler from "../MessageHandler";

function UserJoin()
{
    const navigate = useNavigate();

    const [blocked, setBlocked] = useState(false);

    const [email, setEmail] = useState("");
    const [name, setName] = useState("");
    const [user, setUser] = useState({});
    const [role, setRole] = useState({});
    const [school, setSchool] = useState({});

    const [handleState, setHandleState] = useState(0);

    const roleValues = [
        {
            id: "1",
            label: "Beheerder",
            value: 3
        },
        {
            id: "2",
            label: "Directie/Intern Begeleider",
            value: 4
        },
        {
            id: "3",
            label: "Leraar",
            value: 5
        },
        {
            id: "5",
            label: "Manager",
            value: 7
        }
    ]
    const [hidden, setHidden] = useState(true);
    const [hidden2, setHidden2] = useState(false);
    const [disabled, setDisabled] = useState(false);
    const [required, setRequired] = useState(false);
    const [msg, setMsg] = useState([]);

    const newMessageHandler = (enteredMsg) =>
    {
        setMsg(enteredMsg);
    }

    const setMessageHandler = (msg) =>
    {
        newMessageHandler(msg);
    }

    const handleSubmit = async (e) =>
    {
        e.preventDefault();
        setBlocked(true);
        setMessageHandler();
        try
        {
            if (handleState === 0)
            {
                await UserService.getUserByEmail(
                    email
                ).then((res) =>
                {
                    setUser(res.data);
                    setHidden(false);
                    setHidden2(true);
                    setDisabled(true);
                    setRequired(true);
                    setBlocked(false);

                    const schoolID = JSON.parse(sessionStorage.getItem('schoolID'));
                    SchoolService.getSchool(schoolID).then((res) =>
                        setSchool(res.data)
                    )
                    setHandleState(1);
                    if (res.data.middlename !== null)
                    {
                        setName(res.data.firstname + " " + res.data.middlename + " " + res.data.surname);
                    } else
                    {
                        setName(res.data.firstname + " " + res.data.surname);
                    }
                });
            } else if (handleState === 1)
            {
                await JoinToSchoolService.createJoinToSchool(
                    user.id,
                    school.id,
                    parseInt(role)
                ).then(() =>
                {
                    setBlocked(false);
                    navigate("/Gebruikers", { state: { confirmedMessage: "Verzoek tot koppeling is verzonden" } });
                });
            }
        } catch (err)
        {
            setMessageHandler(MessageConverter.convertBusinessMsg(err));
            setBlocked(false);
        }
    };

    const backButton = (e) =>
    {
        e.preventDefault();
        if (hidden == true)
        {
            navigate("/Gebruikers");
        } else navigate(-1);
    }

    return (
        <div id="Page">
            <BlockUI blocked={blocked} fullScreen />
            <div id="MainContentContainer">
                <div className="page-information">
                    <div id="PageHeader" className="page-header">
                        <span>Gebruikers: Koppel verzoek</span>
                    </div>
                    <div>
                        <div id="InfoButton">
                            <a className="btn-info"></a>
                        </div>
                    </div>
                    <div className="clear"></div>
                </div>
                <div className="maincontent">
                    <div className="message-div">
                        <MessageHandler messages={msg} />
                    </div>
                    <form autoComplete="off" onSubmit={handleSubmit}>

                        <div className="maincontent-item" hidden={hidden}>
                            <label htmlFor="user">Gebruiker</label>
                            <input
                                id="user"
                                type="text"
                                value={name}
                                disabled={disabled}
                                readOnly={true}
                            />
                        </div>

                        <div className="maincontent-item">
                            <label htmlFor="email">E-mailadres</label>
                            <input
                                id="email"
                                type="email"
                                title="Dit veld is verplicht"
                                onInvalid={e => e.target.setCustomValidity("Dit veld is verplicht")}
                                onInput={e => e.target.setCustomValidity("")}
                                onChange={(e) => setEmail(e.target.value)}
                                required={true}
                                disabled={disabled}
                            />
                        </div>

                        <div className="maincontent-item" hidden={hidden}>
                            <label>Gebruiker rol</label>
                            <select
                                className="select-user"
                                title="Selecteer een rol"
                                defaultValue=""
                                required={required}
                                onInvalid={e => e.target.setCustomValidity("Selecteer een rol")}
                                onInput={e => e.target.setCustomValidity("")}
                                onChange={(e) => setRole(e.target.value)}
                            >
                                <option
                                    value=""
                                    hidden={true}
                                >
                                    Selecteer een rol
                                </option>
                                {roleValues.map((role) => (
                                    <option
                                        value={role.value}
                                        key={role.id}
                                    >
                                        {role.label}
                                    </option>
                                ))}
                            </select>
                        </div>

                        <div className="maincontent-item" hidden={hidden}>
                            <label>Koppel aan locatie</label>
                            <input
                                type="text"
                                disabled={disabled}
                                value={school.name + " (" + school.visitCity + ")"}
                                readOnly={true}
                            />
                        </div>

                        <div className="maincontent-item-buttons">
                            <input
                                type="submit"
                                value="Zoek Gebruiker"
                                className="user-add-buttons"
                                hidden={hidden2}
                            />
                            <input
                                type="submit"
                                value="Koppel gebruiker"
                                className="user-add-buttons"
                                hidden={hidden}
                            />
                            <input
                                type="submit"
                                value="Vorige"
                                className="user-add-buttons"
                                onClick={(e) => backButton(e)}
                            />
                        </div>
                    </form>
                </div>
            </div>
        </div>
    );
}

export default UserJoin;