import baseService from "../Service/BaseService";

const getDocumentation = (id) =>
{
    return new Promise((resolve, reject) =>
    {
        baseService
            .get(`/Documentation/${id}`, {})
            .then((response) =>
            {
                resolve(response);
            })
            .catch((error) => reject(error));
    });
};

const deleteDocumentation = (id) =>
{
    return new Promise((resolve, reject) =>
    {
        baseService.delete(`/Documentation/${id}`, {})
            .then((response) =>
            {
                resolve(response);
            })
            .catch((error) => reject(error));
    });
};

const getDocumentationList = () =>
{
    return new Promise((resolve, reject) =>
    {
        baseService
            .get('Documentation/list', {})
            .then((response) =>
            {
                resolve(response);
            })
            .catch((error) => reject(error));
    });
};

const createDocumentation = (documentationDTO) =>
{
    return new Promise((resolve, reject) =>
    {
        baseService
            .put(
                '/Documentation/create', documentationDTO, {})
            .then((response) =>
            {
                resolve(response);
            })
            .catch((error) => reject(error));
    });
};

const updateDocumentation = (documentationDTO) =>
{
    return new Promise((resolve, reject) =>
    {
        baseService
            .put(
                '/Documentation/update', documentationDTO, {})
            .then((response) =>
            {
                resolve(response);
            })
            .catch((error) => reject(error));
    });
};

const getDocumentationDoc = (id) =>
{
    return new Promise((resolve, reject) =>
    {
        baseService
            .get(`/Documentation/get-doc/${id}`, { responseType: 'blob'})
            .then((response) =>
            {
                resolve(response);
            })
            .catch((error) => reject(error));
    });
};

const DocumentationService = {
    getDocumentation,
    deleteDocumentation,
    getDocumentationList,
    createDocumentation,
    updateDocumentation,
    getDocumentationDoc
};

export default DocumentationService;