import baseService from "../Service/BaseService";
import AuthService from "../Service/AuthService";

// Create new migration
const createMigration = (studentID, selectedSchoolID, archiveDate, observationRemoteVisibleType) =>
{
    return new Promise((resolve, reject) =>
    {
        baseService
            .put('/Migration/create', {
                studentID: studentID,
                schoolFromID: AuthService.getSchoolID(),
                status: 1,
                schoolToID: selectedSchoolID,
                dateRemoveData: archiveDate,
                observationRemoteVisibleType: observationRemoteVisibleType
            }, {})
            .then((response) =>
            {
                resolve(response);
            })
            .catch((error) => reject(error));
    });
};

const getListToMigrate = () =>
{
    return new Promise((resolve, reject) =>
    {
        baseService
            .get('/Migration/list-created', {})
            .then((response) =>
            {
                resolve(response);
            })
            .catch((error) => reject(error));
    });
};

const getListToRecieve = () =>
{
    return new Promise((resolve, reject) =>
    {
        baseService
            .get('/Migration/list-to-retreive', {})
            .then((response) =>
            {
                resolve(response);
            })
            .catch((error) => reject(error));
    });
};

const getAllAccepted = () =>
{
    return new Promise((resolve, reject) =>
    {
        baseService
            .get('/Migration/list-accepted', {})
            .then((response) =>
            {
                resolve(response);
            })
            .catch((error) => reject(error));
    });
};

const getMigration = (id) =>
{
    return new Promise((resolve, reject) =>
    {
        baseService
            .get(`/Migration/${id}`, {})
            .then((response) =>
            {
                resolve(response);
            })
            .catch((error) => reject(error));
    });
};

const rejectMigrationBySender = (migrationDTO) =>
{
    return new Promise((resolve, reject) =>
    {
        baseService
            .put(
                '/Migration/reject-by-sender', migrationDTO, {})
            .then((response) =>
            {
                resolve(response);
            })
            .catch((error) => reject(error));
    });
};

const rejectMigrationByReceiver = (migrationDTO) =>
{
    return new Promise((resolve, reject) =>
    {
        baseService
            .put(
                '/Migration/reject-by-receiver', migrationDTO, {})
            .then((response) =>
            {
                resolve(response);
            })
            .catch((error) => reject(error));
    });
};

const acceptMigration = (groupID, migrationDTO) =>
{
    return new Promise((resolve, reject) =>
    {
        baseService
            .put(
                `/Migration/accept/${groupID}`, migrationDTO, {})
            .then((response) =>
            {
                resolve(response);
            })
            .catch((error) => reject(error));
    });
};

const MigrationService = {
    createMigration,
    getListToMigrate,
    getListToRecieve,
    getAllAccepted,
    getMigration,
    rejectMigrationBySender,
    rejectMigrationByReceiver,
    acceptMigration
};

export default MigrationService;