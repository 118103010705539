import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from 'react-router-dom';

import MessageConverter from "../MessageConverter";
import MessageHandler from "../MessageHandler";
import UserService from '../../Service/UserService';
import '../GeneralStyling.scss';
import './TwoFA.scss';

function TwoFAHome()
{
	const navigate = useNavigate();
	const { state } = useLocation();
	const [hiddenSmartphone, setHiddenSmartphone] = useState(false);
	const [hiddenDesktop, setHiddenDesktop] = useState(true);
	const [qrCode, setQrCode] = useState([]);
	const [code, setCode] = useState([]);
	const [msg, setMsg] = useState([]);

	const newMessageHandler = (enteredMsg) =>
	{
		setMsg(enteredMsg);
	}

	const setMessageHandler = (msg) =>
	{
		newMessageHandler(msg);
	}

	const onClickNoPhone = () =>
	{
		setHiddenDesktop(false);
		setHiddenSmartphone(true);
	}

	useEffect(() =>
	{
		if (state === undefined || state === null)
		{
			UserService.getMfaSetupData().then((res) =>
			{
				setQrCode(res.data.qrCode);
				setCode(res.data.code);
			})
		}
		else if (state !== undefined && state !== null)
		{
			setQrCode(state.code.qrCode);
			setCode(state.code.code);
		}
	}, [])

	const android = () =>
	{
		navigate("/TwoFA/Android", { state: { qrCode: qrCode } })
	}

	const apple = () =>
	{
		navigate("/TwoFA/Apple", { state: { qrCode: qrCode } })
	}

	const windows = () =>
	{
		navigate("/TwoFA/Windows", { state: { code: code } })
	}

	const appleDesktop = () =>
	{
		navigate("/TwoFA/AppleDesktop", { state: { qrCode: qrCode } })
	}

	return (
		<div id="ManageChildPage">
			<div id="MainContentContainer">
				<div className="page-information">
					<div id="PageHeader" className="page-header">
						<span>Login: Tweestapsverificatie instellen</span>
					</div>
					<div className="clear"></div>
				</div>
				<div className="maincontent">
					<div className="message-div">
						<MessageHandler messages={msg} />
					</div>
					<p>
						Voor jouw account moet tweestapsverificatie ingesteld worden, wat verplicht is gesteld op jullie school/locatie. Dit is een methode waarmee de identiteit van gebruikers beter vastgesteld kan worden.
						Hiermee voorkomen we onrechtmatig gebruik van jouw account. De stappen voor het instellen hoef je maar eenmalig te doorlopen.
						Als de tweestapsverificatie succesvol is ingesteld, dien je in het vervolg bij het inloggen de verificatiecode in te vullen die je op je gekozen apparaat ontvangt.
						Volg de stappen om tweestapsverificatie in te stellen.
					</p>
					<div className="TwoFABlock" hidden={hiddenSmartphone}>
						<div className="TwoFA-divider">
							<div className="left">
								<img src="/img/2fa/step0/icon-android.png" alt="Android logo" />
								<label>Android smartphone</label>
								<p>Klik hier om de tweestapsverificatie in te stellen als je beschikt over een Android smartphone (bijvoorbeeld Samsung)</p>
								<input type="submit" value="Start hier" className="management-buttons" onClick={android} />
							</div>
							<div className="right">
								<img src="/img/2fa/step0/icon-apple.png" alt="Apple logo" />
								<label>Apple smartphone</label>
								<p>Klik hier om de tweestapsverificatie in te stellen als je beschikt over een Apple smartphone</p>
								<input type="submit" value="Start hier" className="management-buttons" onClick={apple} />
							</div>
						</div>
						<input type="submit" value="Geen smartphone? Klik hier" className="management-buttons" onClick={onClickNoPhone} />
					</div>
					<div className="TwoFABlock" hidden={hiddenDesktop}>
						<div className="TwoFA-divider">
							<div className="left">
								<img src="/img/2fa/step0/icon-desktop.png" alt="desktop" />
								<label>Microsoft Windows</label>
								<p>Klik hier om de tweestapsverificatie in te stellen als je gebruik maakt van het Windows besturingssysteem</p>
								<input type="submit" value="Start hier" className="management-buttons" onClick={windows} />
							</div>
							<div className="right">
								<img src="/img/2fa/step0/icon-desktop.png" alt="desktop" />
								<label>Apple smartphone</label>
								<p>Klik hier om de tweestapsverificatie in te stellen als je gebruik maakt van het iOS besturingssysteem van Apple</p>
								<input type="submit" value="Start hier" className="management-buttons" onClick={appleDesktop} />
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}

export default TwoFAHome;