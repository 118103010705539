import React from 'react'

class ErrorBoundary extends React.Component
{
    constructor(props)
    {
        super(props);
        this.state = { hasError: false };
    }

    static getDerivedStateFromError(error)
    {
        return { hasError: true };
    }

    render()
    {
        if (this.state.hasError)
        {
            return (
                <div style={{ margin: '20px' }}>
                    <h2>
                        Oeps, er ging iets mis.
                    </h2>
                    <div>Blijft deze fout optreden, neem dan contact op met de helpdesk.</div>
                </div>
            );
        }
        return this.props.children;
    }
}

export default ErrorBoundary;