import React from 'react';
import './Footer.scss';

function Footer()
{
    return (
        <div className="main-footer">
            <div className="container">
                <div className="row">
                    <div className="col">
                        <p>KIJK! Helpdesk</p>
                        <div className="footer-list">
                            <li>Heb je technische vragen? Neem contact op met de KIJK! Helpdesk.</li>
                            <li><div className="icon-phone" /><a href="tel:+310885570555">088 - 55 70 555</a></li>
                            <li><div className="icon-mail" /><a href="mailto:kijk@estrategy.nl">kijk@estrategy.nl</a></li>
                        </div>
                    </div>
                    <div className="col">
                        <p>TeamViewer</p>
                        <div className="footer-list">
                            <li>Vraagt de medewerker van de helpdesk om mee te kijken op jouw KIJK! Verder account?</li>
                            <li><div className="icon-download" /><a href="https://get.teamviewer.com/v3mjms6" >Download TeamViewer</a></li>
                        </div>
                    </div>
                    <div className="col">
                        <p>Bazalt Groep</p>
                        <div className="footer-list">
                            <li>Heb je vragen over het abonnement, inhoudelijke vragen over KIJK! of wil je een KIJK! Verder training volgen?</li>
                            <li><div className="icon-phone" /><a href="tel:+310885570500">088 - 55 70 500</a></li>
                            <li><div className="estrategy-link">Ontwikkeld door <a href="https://www.estrategy.nl">Estrategy</a></div></li>
                            <li><div className="logo-bazalt"><img src="/img/logo-bazalt.png" alt="Bazalt logo"></img></div></li>
                        </div>
                    </div>
                </div>
            </div>
            <div className="footer-bottom-border"></div>
        </div >
    );
}

export default Footer;