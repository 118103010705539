import SchoolService from "../../Service/SchoolService"
import MessageConverter from "../MessageConverter";
import MessageHandler from "../MessageHandler"

import { BlockUI } from 'primereact/blockui';
import React, { useEffect, useState } from 'react';

function GeneralSetting()
{
    const [schoolDTO, setSchoolDTO] = useState({});
    const [refreshKey, setRefreshKey] = useState(0);
    const [blocked, setBlocked] = useState(false);
    const [msg, setMsg] = useState([]);

    const newMessageHandler = (enteredMsg) =>
    {
        setMsg(enteredMsg);
    }

    const setMessageHandler = (msg) =>
    {
        newMessageHandler(msg);
    }

    useEffect(() =>
    {
        SchoolService.getSchool(sessionStorage.getItem("schoolID")).then((res) =>
        {
            setSchoolDTO(res.data);
        })
    }, [refreshKey]);

    const updateSchool = (e) =>
    {
        e.preventDefault();
        setMessageHandler();
        setBlocked(true);
        try
        {
            SchoolService.updateSchool(schoolDTO).then((res) =>
            {
                setRefreshKey(oldKey => oldKey + 1);
                setMessageHandler(MessageConverter.convertBusinessMsg("Wijzigingen succesvol opgeslagen", 0));
                setBlocked(false);
            }).catch(err => { setMessageHandler(MessageConverter.convertBusinessMsg(err)); setBlocked(false); });
        } catch (error)
        {
            setMessageHandler(MessageConverter.convertBusinessMsg(error));
            setBlocked(false);
        }
    }

    return (
        <form autoComplete="off" onSubmit={updateSchool}>
            <MessageHandler messages={msg} />
            <BlockUI blocked={blocked} fullScreen />
            <div className="maincontent-item">
                <label htmlFor="name">Naam locatie*</label>
                <input
                    id="name"
                    type="text"
                    title="Dit veld is verplicht"
                    disabled={true}
                    defaultValue={schoolDTO.name}
                />
            </div>

            <div className="maincontent-item">
                <label htmlFor="management">Naam bestuur/organisatie*</label>
                <input
                    id="management"
                    type="text"
                    title="Dit veld is verplicht"
                    disabled={true}
                    defaultValue={schoolDTO.management}
                />
            </div>

            <div className="maincontent-item">
                <label htmlFor="visitAddress">Bezoek adres*</label>
                <input
                    id="visitAddress"
                    required={true}
                    type="text"
                    title="Dit veld is verplicht"
                    onInvalid={e => e.target.setCustomValidity("Dit veld is verplicht")}
                    onInput={e => e.target.setCustomValidity("")}
                    defaultValue={schoolDTO.visitAddress}
                    onChange={(e) => setSchoolDTO({ ...schoolDTO, visitAddress: e.target.value })}
                />
            </div>

            <div className="maincontent-item">
                <label htmlFor="visitZipCode">Bezoek postcode*</label>
                <input
                    id="visitZipCode"
                    required={true}
                    type="text"
                    title="Dit veld is verplicht"
                    onInvalid={e => e.target.setCustomValidity("Dit veld is verplicht")}
                    onInput={e => e.target.setCustomValidity("")}
                    defaultValue={schoolDTO.visitZipCode}
                    onChange={(e) => setSchoolDTO({ ...schoolDTO, visitZipCode: e.target.value })}
                />
            </div>

            <div className="maincontent-item">
                <label htmlFor="visitCity">Bezoek plaats*</label>
                <input
                    id="visitCity"
                    required={true}
                    type="text"
                    title="Dit veld is verplicht"
                    onInvalid={e => e.target.setCustomValidity("Dit veld is verplicht")}
                    onInput={e => e.target.setCustomValidity("")}
                    defaultValue={schoolDTO.visitCity}
                    onChange={(e) => setSchoolDTO({ ...schoolDTO, visitCity: e.target.value })}
                />
            </div>

            <div className="maincontent-item">
                <label htmlFor="visitCountry">Land</label>
                <input
                    id="visitCountry"
                    type="text"
                    defaultValue={schoolDTO.visitCountry}
                    onChange={(e) => setSchoolDTO({ ...schoolDTO, visitCountry: e.target.value })}
                />
            </div>

            <div className="maincontent-item">
                <label htmlFor="invoiceName">Factuur naam</label>
                <input
                    id="invoiceName"
                    type="text"
                    defaultValue={schoolDTO.invoiceName}
                    onChange={(e) => setSchoolDTO({ ...schoolDTO, invoiceName: e.target.value })}
                />
            </div>

            <div className="maincontent-item">
                <label htmlFor="invoiceAddress">Factuur adres</label>
                <input
                    id="invoiceAddress"
                    type="text"
                    defaultValue={schoolDTO.invoiceAddress}
                    onChange={(e) => setSchoolDTO({ ...schoolDTO, invoiceAddress: e.target.value })}
                />
            </div>

            <div className="maincontent-item">
                <label htmlFor="invoiceZipCode">Factuur postcode</label>
                <input
                    id="invoiceZipCode"
                    type="text"
                    title="Dit veld is verplicht"
                    defaultValue={schoolDTO.invoiceZipCode}
                    onChange={(e) => setSchoolDTO({ ...schoolDTO, invoiceZipCode: e.target.value })}
                />
            </div>

            <div className="maincontent-item">
                <label htmlFor="invoiceCity">Factuur plaats</label>
                <input
                    id="invoiceCity"
                    type="text"
                    defaultValue={schoolDTO.invoiceCity}
                    onChange={(e) => setSchoolDTO({ ...schoolDTO, invoiceCity: e.target.value })}
                />
            </div>

            <div className="maincontent-item">
                <label htmlFor="invoiceCountry">Factuur land</label>
                <input
                    id="invoiceCountry"
                    type="text"
                    defaultValue={schoolDTO.invoiceCountry}
                    onChange={(e) => setSchoolDTO({ ...schoolDTO, invoiceCountry: e.target.value })}
                />
            </div>

            <div className="maincontent-item">
                <label htmlFor="invoiceEmail">Factuur e-mailadres*</label>
                <input
                    id="invoiceEmail"
                    required={true}
                    type="email"
                    title="Dit veld is verplicht"
                    onInvalid={e => e.target.setCustomValidity("Dit veld is verplicht")}
                    onInput={e => e.target.setCustomValidity("")}
                    defaultValue={schoolDTO.invoiceEmail}
                    onChange={(e) => setSchoolDTO({ ...schoolDTO, invoiceEmail: e.target.value })}
                />
            </div>

            <div className="maincontent-item">
                <label htmlFor="phone">Telefoon</label>
                <input
                    id="phone"
                    type="text"
                    defaultValue={schoolDTO.phone}
                    onChange={(e) => setSchoolDTO({ ...schoolDTO, phone: e.target.value })}
                />
            </div>

            <div className="maincontent-item">
                <label htmlFor="email">E-mailadres</label>
                <input
                    id="email"
                    type="email"
                    defaultValue={schoolDTO.email}
                    onChange={(e) => setSchoolDTO({ ...schoolDTO, email: e.target.value })}
                />
            </div>

            <div className="maincontent-item">
                <label htmlFor="brinCode">Brincode*</label>
                <input
                    id="brinCode"
                    type="text"
                    required={true}
                    defaultValue={schoolDTO.brinCode}
                    onChange={(e) => setSchoolDTO({ ...schoolDTO, brinCode: e.target.value })}
                />
            </div>

            <div className="maincontent-item">
                <label htmlFor="dependanceCode">Dependancecode*</label>
                <input
                    id="dependanceCode"
                    type="text"
                    required={true}
                    onInvalid={e => e.target.setCustomValidity("Indien het de hoofdvestiging betreft, vul dan 00 in")}
                    defaultValue={schoolDTO.dependanceCode}
                    onChange={(e) => setSchoolDTO({ ...schoolDTO, dependanceCode: e.target.value })}
                />
            </div>

            <div className="maincontent-item">
                <label htmlFor="reference">Referentie</label>
                <input
                    id="reference"
                    type="text"
                    disabled={true}
                    defaultValue={schoolDTO.reference}
                />
            </div>

            <div className="maincontent-item-buttons">
                <input
                    type="submit"
                    value="Opslaan"
                    className="user-add-buttons" />
            </div>
        </form>
    );
}

export default GeneralSetting;