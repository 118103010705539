import baseService from "../Service/BaseService";

// Get the list of all available groups for user
const getStudentListForSchool = () =>
{
    return new Promise((resolve, reject) =>
    {
        baseService
            .get('/Student/list-for-school/', {})
            .then((response) =>
            {
                resolve(response);
            })
            .catch((error) => { reject(error) });
    });
};

// Create a student
const createStudent = (
    studentDto,
    studentSchoolDto,
    studentGroupDto
) =>
{
    return new Promise((resolve, reject) =>
    {
        baseService
            .put(
                '/Student/create',
                {
                    studentDto,
                    studentSchoolDto,
                    studentGroupDto
                }
            )
            .then((response) =>
            {
                resolve(response);
            })
            .catch((error) => reject(error));
    });
};

// Update a student
const updateStudent = (
    studentDto,
    studentSchoolDto,
    studentGroupDto
) =>
{
    return new Promise((resolve, reject) =>
    {
        baseService
            .put(
                '/Student/update',
                {
                    studentDto,
                    studentSchoolDto,
                    studentGroupDto
                }
            )
            .then((response) =>
            {
                resolve(response);
            })
            .catch((error) => reject(error));
    });
};

// Get the student information
const getStudent = (id) =>
{
    return new Promise((resolve, reject) =>
    {
        baseService
            .get(`/Student/${id}`, {})
            .then((response) =>
            {
                resolve(response);
            })
            .catch((error) => reject(error));
    });
};

// Get the student group information
const getStudentGroup = (id) =>
{
    return new Promise((resolve, reject) =>
    {
        baseService
            .get(`/StudentGroup/${id}`, {})
            .then((response) =>
            {
                resolve(response);
            })
            .catch((error) => reject(error));
    });
};

// Get the student school information
const getStudentSchool = (id) =>
{
    return new Promise((resolve, reject) =>
    {
        baseService
            .get(`/StudentSchool/${id}`, {})
            .then((response) =>
            {
                resolve(response);
            })
            .catch((error) => reject(error));
    });
};

const getStudentForEdit = (id) =>
{
    return new Promise((resolve, reject) =>
    {
        baseService
            .get(`/Student/get-for-edit/${id}`, {})
            .then((response) =>
            {
                resolve(response);
            })
            .catch((error) => reject(error));
    });
};

// Delete a student
const deleteStudent = (id) =>
{
    return new Promise((resolve, reject) =>
    {
        baseService.delete(`/Student/${id}`, {})
            .then((response) =>
            {
                resolve(response);
            })
            .catch((error) => reject(error));
    });
};

// Get the list students with closed soco registration in group
const getStudentWithClosedSoCoRegistration = (groupID, type) =>
{
    return new Promise((resolve, reject) =>
    {
        baseService
            .get(`/Student/soco/with-closed-reg-in-group/${groupID}/${type}`, {})
            .then((response) =>
            {
                resolve(response);
            })
            .catch((error) => { reject(error) });
    });
};

// Get list of students with closed soco registration in group array
const getStudentWithClosedSoCoRegistrationMultiGroup = (groupIDs, type) =>
{
    return new Promise((resolve, reject) =>
    {
        baseService
            .post('/Student/soco/with-closed-reg-in-multi-group',
                {
                    groupIDs: groupIDs,
                    type: type
                }, {})
            .then((response) =>
            {
                resolve(response);
            })
            .catch((error) => { reject(error) });
    });
};

// Get the list students with closed soco registration in group
const getStudentListToClose = (groupID, type) =>
{
    return new Promise((resolve, reject) =>
    {
        baseService
            .get(`/Student/soco/list-to-close/${groupID}/${type}`, {})
            .then((response) =>
            {
                resolve(response);
            })
            .catch((error) => { reject(error) });
    });
};

// Get list of students, available to archive
const getListToArchive = () =>
{
    return new Promise((resolve, reject) =>
    {
        baseService
            .get('/Student/list-to-archive', {})
            .then((response) =>
            {
                resolve(response);
            })
            .catch((error) => { reject(error) });
    });
};

// Get list of archived students
const getListArchived = () =>
{
    return new Promise((resolve, reject) =>
    {
        baseService
            .get('/Student/list-archived', {})
            .then((response) =>
            {
                resolve(response);
            })
            .catch((error) => { reject(error) });
    });
};

// Get the calculated archive dat
const calculateArchiveDate = (birthdate) =>
{
    return new Promise((resolve, reject) =>
    {
        baseService
            .get(`/Student/calculate-archive-date/${birthdate}`, {})
            .then((response) =>
            {
                resolve(response);
            })
            .catch((error) => { reject(error) });
    });
};

const archiveStudent = (studentSchoolID, dateToRemoveData) =>
{
    return new Promise((resolve, reject) =>
    {
        baseService
            .put(`/Student/archive/${studentSchoolID}/${dateToRemoveData}`, {}, {})
            .then((response) =>
            {
                resolve(response);
            })
            .catch((error) => { reject(error) });
    });
};

const revertStudentFromArchive = (studentSchoolID, groupID) =>
{
    return new Promise((resolve, reject) =>
    {
        baseService
            .put(`/Student/revert-from-archive/${studentSchoolID}/${groupID}`, {}, {})
            .then((response) =>
            {
                resolve(response);
            })
            .catch((error) => { reject(error) });
    });
};

// Get the list of students who are assigned to the group
const getStudentsAssignedToGroup = (groupID) =>
{
    return new Promise((resolve, reject) =>
    {
        baseService
            .get(`/Student/soco/assigned-to-group/${groupID}`, {})
            .then((response) =>
            {
                resolve(response);
            })
            .catch((error) => reject(error));
    });
};


const StudentService = {
    getStudentListForSchool,
    createStudent,
    updateStudent,
    getStudent,
    getStudentGroup,
    getStudentSchool,
    getStudentForEdit,
    deleteStudent,
    getStudentWithClosedSoCoRegistration,
    getStudentWithClosedSoCoRegistrationMultiGroup,
    getStudentListToClose,
    getListToArchive,
    getListArchived,
    calculateArchiveDate,
    archiveStudent,
    revertStudentFromArchive,
    getStudentsAssignedToGroup
};

export default StudentService;