import baseService from "../Service/BaseService";

const getCurrentESS = () =>
{
    return new Promise((resolve, reject) =>
    {
        var cacheKey = "ExternalSystemSetting";
        var cacheVal = sessionStorage.getItem(cacheKey);
        if (cacheVal != null)
            resolve(JSON.parse(cacheVal));
        else
        {
            baseService
                .get('/ExternalSystemSetting/get-current', {})
                .then((response) =>
                {
                    // store response
                    sessionStorage.setItem(cacheKey, JSON.stringify(response));
                    resolve(response);
                })
                .catch((error) => reject(error));
        }
    });
};

const createESS = (ExternalSystemDTO) =>
{
    return new Promise((resolve, reject) =>
    {
        baseService
            .put(
                '/ExternalSystemSetting/create', ExternalSystemDTO, {})
            .then((response) =>
            {
                resolve(response);
            })
            .catch((error) => reject(error));
    });
};

const updateESS = (ExternalSystemDTO) =>
{
    return new Promise((resolve, reject) =>
    {
        baseService
            .put(
                '/ExternalSystemSetting/update', ExternalSystemDTO, {})
            .then((response) =>
            {
                resolve(response);
            })
            .catch((error) => reject(error));
    });
};

const deleteESS = (id) =>
{
    return new Promise((resolve, reject) =>
    {
        baseService.delete(`/ExternalSystemSetting/${id}`, {})
            .then((response) =>
            {
                resolve(response);
            })
            .catch((error) => reject(error));
    });
};

const ExternalSystemSettingsService = {
    getCurrentESS,
    createESS,
    updateESS,
    deleteESS
};

export default ExternalSystemSettingsService;