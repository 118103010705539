import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { FilterMatchMode, FilterOperator } from 'primereact/api';
import React, { useState, useEffect, useCallback } from 'react';
import { Calendar } from 'primereact/calendar';
import { Dialog } from 'primereact/dialog';
import moment from 'moment';
import Select from 'react-select';
import { BlockUI } from 'primereact/blockui';

import MigrationService from "../../Service/MigrationService";
import GroupService from "../../Service/GroupService";
import MessageConverter from "../MessageConverter"
import MessageHandler from "../MessageHandler"
import { DateFilter } from "../DateFilter"

function ReceivedChildren()
{
    const [migrationList, setMigrationList] = useState([]);
    const [selectedChild, setSelectedChild] = useState([]);
    const [migrationDTO, setMigrationDTO] = useState([]);
    const [refreshKey, setRefreshKey] = useState(0);
    const [groups, setGroups] = useState([]);
    const [selectedGroup, setSelectedGroup] = useState([]);
    const [loading, setLoading] = useState(true);
    const [visibleDialogReject, setVisibleDialogReject] = useState(false);
    const [visibleDialogAccept, setVisibleDialogAccept] = useState(false);
    const [defaultSelected, setDefaultSelected] = useState(null);

    const [filters, setFilters] = useState({
        birthdate: { value: null, matchMode: FilterMatchMode.DATE_IS },
        fullname: { value: null, matchMode: FilterMatchMode.CONTAINS },
        schoolFromName: { value: null, matchMode: FilterMatchMode.CONTAINS },
    });

    const [blocked, setBlocked] = useState(false);
    const [msg, setMsg] = useState([]);

    const newMessageHandler = (enteredMsg) =>
    {
        setMsg(enteredMsg);
    }

    const setMessageHandler = (msg) =>
    {
        newMessageHandler(msg);
    }

    // Omzetten van de data
    const getMigrationList = (data) =>
    {
        return [...(data || [])].map((d) =>
        {
            d.birthdate = new Date(d.birthdate);

            return d;
        });
    };

    const datebody = (rowData) =>
    {
        return <>{moment(rowData.birthdate).format("DD-MM-YYYY")}</>;
    };

    useEffect(() =>
    {
        MigrationService.getListToRecieve().then((res) =>
        {
            setMigrationList(getMigrationList(res.data));
            setLoading(false);
        }).catch((err) => setMessageHandler(MessageConverter.convertBusinessMsg(err)));
    }, [refreshKey]);

    const onReject = useCallback((e) =>
    {
        e.preventDefault();
        setMessageHandler();
        if (selectedChild.length !== 0)
        {
            setBlocked(true);
            MigrationService.getMigration(selectedChild.id).then((res) =>
            {
                setMigrationDTO(res.data);
                setVisibleDialogReject(true);
                setBlocked(false);
            }).catch((err) => { setMessageHandler(MessageConverter.convertBusinessMsg(err)); setBlocked(false) });
        } else setMessageHandler(MessageConverter.convertBusinessMsg("Geen kind geselecteerd", 1));
    }, [selectedChild]);

    const onSubmitReject = useCallback((e) =>
    {
        e.preventDefault();
        if (migrationDTO.message !== null)
        {
            setBlocked(true);
            MigrationService.rejectMigrationByReceiver(migrationDTO).then((res) =>
            {
                setRefreshKey(oldKey => oldKey + 1);
                setVisibleDialogReject(false);
                setMessageHandler(MessageConverter.convertBusinessMsg("Verhuizing kind succesvol geweigerd", 0));
                setBlocked(false);
            }).catch((err) => { setMessageHandler(MessageConverter.convertBusinessMsg(err)); setBlocked(false) });
        } else setMessageHandler(MessageConverter.convertBusinessMsg("Dit veld is verplicht", 1));
    }, [migrationDTO]);

    const onAccept = useCallback((e) =>
    {
        e.preventDefault();
        setMessageHandler();
        if (selectedChild.length !== 0)
        {
            setBlocked(true);
            MigrationService.getMigration(selectedChild.id).then((res) =>
            {
                setMigrationDTO(res.data);
                GroupService.getAllInCurrentYearGroup().then((res) =>
                {
                    setGroups(res.data);
                    if (res.data.length == 1)
                    {
                        setDefaultSelected(res.data[0]);
                        setSelectedGroup(res.data[0]);
                    }
                    setVisibleDialogAccept(true);
                    setBlocked(false);
                })
            }).catch((err) => { setMessageHandler(MessageConverter.convertBusinessMsg(err)); setBlocked(false) });
        } else setMessageHandler(MessageConverter.convertBusinessMsg("Geen kind geselecteerd", 1));
    }, [selectedChild]);

    const onSubmitAccept = useCallback((e) =>
    {
        e.preventDefault();
        if (selectedGroup.length !== 0)
        {
            setBlocked(true);
            MigrationService.acceptMigration(JSON.stringify(selectedGroup.id), migrationDTO).then((res) =>
            {
                setRefreshKey(oldKey => oldKey + 1);
                setVisibleDialogAccept(false);
                setMessageHandler(MessageConverter.convertBusinessMsg("Verhuizing kind succesvol geaccepteerd", 0));
                setBlocked(false);
            }).catch((err) => { setMessageHandler(MessageConverter.convertBusinessMsg(err)); setBlocked(false) });
        } else setMessageHandler(MessageConverter.convertBusinessMsg("Groep selecteren is verplicht", 1));
    }, [selectedGroup]);

    return (
        <form autoComplete="off">
            <BlockUI blocked={blocked} fullScreen />
            <MessageHandler messages={msg} />
            <div className="maincontent-item">
                <div className="card">
                    <DataTable
                        value={migrationList}
                        dataKey="id"
                        size="small"
                        paginator={true}
                        paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                        currentPageReportTemplate="{first} - {last} van {totalRecords}"
                        rows={10}
                        rowsPerPageOptions={[10, 20, 50, 100]}
                        responsiveLayout="scroll"
                        loading={loading}
                        filters={filters}
                        selectionMode="single"
                        selection={selectedChild}
                        onSelectionChange={e => { setSelectedChild(e.value) }}
                        filterDisplay="row"
                        emptyMessage="Geen resultaten gevonden"
                        stateKey="Migration-ReceivedChildren">
                        <Column field="fullname"
                            filter={true}
                            filterPlaceholder="Zoek op naam"
                            showFilterMenu={false}
                            sortable={true}
                            style={{ width: '18rem' }}
                            header="Kind" />
                        <Column header="Geboortedatum"
                            filterField="birthdate"
                            dataType="date"
                            showFilterMenu={false}
                            sortable={true}
                            style={{ width: '10rem' }}
                            field="birthdate"
                            body={datebody}
                            filter={true}
                            filterElement={DateFilter} />
                        <Column field="schoolFromName"
                            filter={true}
                            sortable={true}
                            showFilterMenu={false}
                            style={{ width: '18rem' }}
                            filterPlaceholder="Zoek op locatie"
                            header="Versturende locatie" />
                    </DataTable>
                    <Dialog
                        className="delete-dialog"
                        header={"Vul hier de reden van weigeren verhuizing in"}
                        visible={visibleDialogReject}
                        onHide={() => setVisibleDialogReject(false)}
                        draggable={false}
                    >
                        <textarea
                            className="textarea-in-dialog"
                            required={true}
                            onChange={(e) => setMigrationDTO({ ...migrationDTO, message: e.target.value })}
                        />
                        <input type="submit" value="Opslaan" className="management-buttons" onClick={onSubmitReject} />
                    </Dialog>
                    <Dialog
                        className="delete-dialog"
                        header={"Selecteer een groep"}
                        visible={visibleDialogAccept}
                        onHide={() => setVisibleDialogAccept(false)}
                        draggable={false}
                    >
                        <div className="in-dialog">
                            <Select
                                options={groups}
                                getOptionLabel={(option) => option.name}
                                getOptionValue={(option) => option.id}
                                onChange={(gr) =>
                                {
                                    setSelectedGroup(gr);
                                }}
                                value={groups.find((element) => { return element.id == selectedGroup?.id })}
                                isSearchable={true}
                                isMulti={false}
                                placeholder="Selecteer een groep"
                                defaultValue={defaultSelected}
                                noOptionsMessage={() => 'Geen resultaten gevonden'}
                            />
                        </div>
                        <input type="submit" value="Opslaan" className="management-buttons" onClick={onSubmitAccept} />
                    </Dialog>
                    <div className="clear"></div>
                </div>
            </div>
            <input type="submit" value="Accepteren" className="management-buttons" onClick={onAccept} />
            <input type="submit" value="Weigeren" className="management-buttons" onClick={onReject} />
        </form>
    );
}

export default ReceivedChildren;