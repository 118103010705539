/*!
 * Estrategy Password v2
 */
import './EstrategyPassword.scss';
export class EstrategyPassword
{
    constructor(PasswordField, options)
    {
        options.passwordField = PasswordField;
        this.setOptions(options);
        this.initialize();
    }

    setOptions(suppliedOptions)
    {
        const defaults = {
            PasswordField: ".password-check",
            ConfirmPasswordField: ".strong-password-check",
            CustomClass: "",
            CustomInfoClass: "",

            // Minimum requirements for a valid password
            MinCharLength: 8,
            MaxCharLength: 50,
            SpecialChars: "(?=.*[!@#$%^&*()\-_=+`~,./>?:;|)",
            MinSpecialCharsAmount: 1,
            MinDigitsAmount: 1,
            MinCapitalsAmount: 1,
            MinLowercaseAmount: 1,

            // Minimum requirements for a 100% password score
            MinRecomLength: 20,
            MinRecomDigitsAmount: 2,
            MinRecomCapitalsAmount: 2,
            MinRecomLowercaseAmount: 2,
            MinRecomSpecialCharsAmount: 2,

            HideMaxLengthOnStart: false,
            wasLonger: false,
            DisableSubmitButton: false,
            UseDynamicInfoText: true,
            UseCapslockWarning: false,
            ShowStrengthText: true,
            ShowProgressBar: true,
            ShowPwndInfo: true,
            PasswordStrengthText: "Wachtwoord sterkte: {0}",
            passwordStrengthsText: {
                "": "zeer zwak",
                "bar-1": "zeer zwak",
                "bar-2": "zwak",
                "bar-3": "sterk",
                "bar-4": "zeer sterk",
            },
            CapslockTooltip: ".tooltiptext",
            MinCharText: `Lengte van minimaal {0} tekens`,
            MaxCharText: `Lengte van maximaal {0} tekens`,
            CombinedMinMaxText: "",
            ReqDigitText: "Minimaal {0} cijfer",
            ReqCapitalText: "Minimaal {0} hoofdletter",
            ReqLowerCaseText: "Minimaal {0} kleine letter",
            ReqSpecialCharText: "Minimaal {0} speciaal teken {1}",
            ConfirmPasswordText: "Wachtwoorden moeten overeen komen",
            PwndPasswordText:
                'Komt niet voor in <a href="https://haveibeenpwned.com/" target="_blank">publieke datalekken</a>',
            InfoTemplateClass: "password-strength-info",
            InfoTemplateText: "Wachtwoord eisen:<br />",
            InfoFieldTemplate:
                '<div class="password-strength-info-item {0} {2}"><span></span>{1}</div>',
        };

        this.options = Object.assign({}, defaults, suppliedOptions);
    }

    initialize()
    {
        this.passwordFieldElement = document.querySelector(
            this.options.passwordField
        );
        if (this.passwordFieldElement == null)
            return console.warn("estrategy.password:", "No password field found!");

        if (this.options.ConfirmPasswordField != null)
        {
            this.ConfirmPasswordElement = document.querySelector(
                this.options.ConfirmPasswordField
            );
            if (this.ConfirmPasswordElement == null)
                return console.warn(
                    "estrategy.password:",
                    "No confirm password field found!"
                );
        }

        this.capslockHasEventListener = false;

        this.SetHTML();
        this.setEvents();

        if (this.passwordFieldElement.value.length > 0)
            this.checkPasswordStrength(this.passwordFieldElement);
    }

    SetHTML()
    {
        const styleTemplate =
            '<style type="text/css">' +
            ".password-strength-holder {margin-top: 5px;font-size: 11px;font-weight: normal;font-style: normal;text-decoration: none;font-family: 'Open Sans', sans-serif;color: #000;line-height: 1.42857143;}" +
            ".password-strength-text, .password-strength-info {text-align: left;}" +
            ".password-strength-info .password-strength-info-item {padding-left: 13px;position: relative}" +
            ".password-strength-info .password-strength-info-item:before {font-family: FontAwesome;font-weight: normal;font-style: normal;line-height: 1.6;position: absolute;left: 0px;}" +
            '.password-strength-info .loading:before {content: "\\f021";}' +
            '.password-strength-info .success:before {content: "\\f00c";color: #7bb642;}' +
            '.password-strength-info .error:before {content: "\\f00d";color: #da122f;}' +
            '.password-strength-info .unset:before {content: "\\f0da";margin-left: 3px;}' +
            '.password-strength-info .api-error:before {content: "\\3f";margin-left: 3px;}' +
            ".password-strength-info .password-strength-info-item span {font-size: 12px;display: inline-block;width: 15px;height: 20px;vertical-align: middle;margin-right: 5px;position: absolute;}" +
            ".password-strength {height: 8px;max-width: 250px;margin: 2px 0;text-align: left;background-color: #d7d7d7;position: relative;border-radius: 5px;}" +
            ".password-strength .password-strength-bar-1, .password-strength .password-strength-bar-2, .password-strength .password-strength-bar-3, .password-strength .password-strength-bar-4 {height: 100%;position: absolute;}" +
            ".password-strength .password-strength-bar-1 {width: 25%;}" +
            ".password-strength .password-strength-bar-2 {width: 50%;}" +
            ".password-strength .password-strength-bar-3 {width: 75%;}" +
            ".password-strength .password-strength-bar-4 {width: 100%;}" +
            ".password-strength .password-strength-bar-rating {display: none;height: 100%;position: relative;vertical-align: top;background-size: 40px 40px;border-top-left-radius: 5px;border-bottom-left-radius: 5px;}" +
            ".password-strength .password-strength-bar-rating.bar-1 {background-color: #da122f;display: inline-block;width: 25%;}" +
            ".password-strength .password-strength-bar-rating.bar-2 {background-color: #da122f;display: inline-block;width: 50%;}" +
            ".password-strength .password-strength-bar-rating.bar-3 {background-color: orange;display: inline-block;width: 75%;}" +
            ".password-strength .password-strength-bar-rating.bar-4 {background-color: #7cb723;display: inline-block;width: 100%;border-radius: 5px;}" +
            ".password-strength-tooltip {display: inline;position: relative;}" +
            ".password-strength-tooltip .tooltiptext {font-size: 11px;font-weight: normal;font-style: normal;text-decoration: none;font-family: 'Open Sans', sans-serif;display: none;position: absolute;z-index: 1;background-color: #fff;box-shadow: 2px 2px 2px #d0d0d0;border: solid 2px #f7f7f7;top: 30px;-ms-transform: translateX(-50%);transform: translateX(-50%);padding: 6px;box-sizing: border-box;text-align: center;white-space: nowrap;left: 50%;}" +
            '.password-strength-tooltip .tooltiptext::before {content: "";position: absolute;bottom: 100%;left: 50%;border: transparent 8px solid;border-bottom-color: #ffffff;}' +
            "</style>";

        //document.head.innerHTML += styleTemplate;
        const strengthBar = document.createElement("div");
        strengthBar.className = "password-strength";
        const strengthTextField = document.createElement("div");
        strengthTextField.className = "password-strength-text";

        const customClassElement = document.querySelector(this.options.CustomClass);

        if (this.options.ShowStrengthText)
        {
            if (this.options.CustomClass.length > 0 && customClassElement != null)
                customClassElement.appendChild(strengthTextField);
            else
                this.passwordFieldElement.parentNode.insertBefore(
                    strengthTextField,
                    this.passwordFieldElement.nextSibling
                );
            //$(strengthTextField).insertAfter($(this.el));

            const strengthText = this.options.passwordStrengthsText[""];
            strengthTextField.append(
                String.format(
                    this.options.PasswordStrengthText,
                    typeof strengthText !== "undefined" ? strengthText : ""
                )
            );
        }

        if (this.options.ShowProgressBar)
        {
            if (this.options.CustomClass.length > 0 && customClassElement != null)
                customClassElement.appendChild(strengthBar);
            else
                this.passwordFieldElement.parentNode.insertBefore(
                    strengthBar,
                    this.passwordFieldElement.nextSibling
                );
            //$(strengthBar).insertAfter($(this.el));

            strengthBar.innerHTML +=
                '<span class="password-strength-bar-1"></span>' +
                '<span class="password-strength-bar-2"></span>' +
                '<span class="password-strength-bar-3"></span>' +
                '<span class="password-strength-bar-4"></span>' +
                '<span class="password-strength-bar-rating"></span>';
        }
        this.ratingField = strengthBar;
        this.ratingTextField = strengthTextField;

        if (this.options.DisableSubmitButton)
        {
            const submutElement = document.querySelector(
                this.options.DisableSubmitButton
            );
            if (submutElement != null) submutElement.setAttribute("disabled", "");
            else console.warn("estrategy.password:", "Invalid submit button query!");
        }

        // Add text to html
        if (this.options.UseDynamicInfoText)
        {
            const infoText = document.createElement("div");
            infoText.className = this.options.InfoTemplateClass;
            infoText.innerHTML = this.options.InfoTemplateText;

            const resultItemTemplate = this.options.InfoFieldTemplate;

            // Hide min and max  character text when using the combined minMaxText
            if (this.options.CombinedMinMaxText.length == 0)
            {
                if (this.options.MinCharLength > 0)
                    infoText.innerHTML += String.format(
                        resultItemTemplate,
                        "unset",
                        String.format(this.options.MinCharText, this.options.MinCharLength),
                        "min-length"
                    );
                if (
                    this.options.MaxCharLength > 0 &&
                    !this.options.HideMaxLengthOnStart
                )
                    infoText.innerHTML += String.format(
                        resultItemTemplate,
                        "unset",
                        String.format(this.options.MaxCharText, this.options.MaxCharLength),
                        "max-length"
                    );
            }

            if (this.options.MinDigitsAmount > 0)
            {
                infoText.innerHTML += String.format(
                    resultItemTemplate,
                    "unset",
                    String.format(
                        this.options.ReqDigitText,
                        this.options.MinDigitsAmount
                    ),
                    "has-digit"
                );
            }
            if (this.options.MinCapitalsAmount > 0)
            {
                infoText.innerHTML += String.format(
                    resultItemTemplate,
                    "unset",
                    String.format(
                        this.options.ReqCapitalText,
                        this.options.MinCapitalsAmount
                    ),
                    "has-capital"
                );
            }
            if (this.options.MinLowercaseAmount > 0)
            {
                infoText.innerHTML += String.format(
                    resultItemTemplate,
                    "unset",
                    String.format(
                        this.options.ReqLowerCaseText,
                        this.options.MinLowercaseAmount
                    ),
                    "has-small"
                );
            }
            if (
                this.options.SpecialChars.length > 0 &&
                this.options.MinSpecialCharsAmount > 0
            )
            {
                infoText.innerHTML += String.format(
                    resultItemTemplate,
                    "unset",
                    String.format(
                        this.options.ReqSpecialCharText,
                        this.options.MinSpecialCharsAmount,
                        this.options.SpecialChars
                    ),
                    "one-special"
                );
            }

            if (
                this.options.ConfirmPasswordField != null &&
                this.ConfirmPasswordElement != null
            )
            {
                infoText.innerHTML += String.format(
                    resultItemTemplate,
                    "unset",
                    this.options.ConfirmPasswordText,
                    "confirm-pass"
                );
            }
            if (this.options.ShowPwndInfo)
            {
                infoText.innerHTML += String.format(
                    resultItemTemplate,
                    "unset",
                    this.options.PwndPasswordText,
                    "pwnd-pass"
                );
            }

            // Show combined minMaxText
            if (this.options.CombinedMinMaxText.length > 0)
                infoText.innerHTML += String.format(
                    resultItemTemplate,
                    "",
                    this.options.CombinedMinMaxText,
                    "combined-text"
                );

            // show seperate from progress bar
            if (this.options.CustomInfoClass != "")
            {
                const CustomInfoClassElement = document.querySelector(
                    this.options.CustomInfoClass
                );
                if (
                    this.options.CustomInfoClass.length > 0 &&
                    CustomInfoClassElement != null
                )
                {
                    CustomInfoClassElement.appendChild(infoText);
                } else
                {
                    if (this.options.ShowProgressBar)
                    {
                        strengthBar.parentNode.insertBefore(
                            infoText,
                            strengthBar.nextSibling
                        );
                        //$(infoText).insertAfter($(strengthBar));
                    } else
                    {
                        if (
                            this.options.CustomClass.length > 0 &&
                            customClassElement != null
                        )
                            customClassElement.appendChild.append(infoText);
                        else
                            this.passwordFieldElement.parentNode.insertBefore(
                                infoText,
                                this.passwordFieldElement.nextSibling
                            );
                        //$(infoText).insertAfter($(this.el));
                    }
                }
            }

            customClassElement.appendChild(infoText);
            this.infoField = infoText;
        }
    }

    setEvents()
    {
        const that = this;
        this.passwordFieldElement.addEventListener("keyup", function (event)
        {
            that.onKeyUp(event, that.passwordFieldElement);
        });

        // run same functionality for second password field
        if (this.options.ConfirmPasswordField != null)
        {
            if (this.ConfirmPasswordElement != null)
                this.ConfirmPasswordElement.addEventListener("keyup", function (event)
                {
                    that.onKeyUp(event, that.passwordFieldElement);
                });
        }
    }

    async getSha1(string)
    {
        var buffer = new TextEncoder("utf-8").encode(string);
        const buffer_1 = await crypto.subtle.digest("SHA-1", buffer);
        // Get the hex code
        var hexCodes = [];
        var view = new DataView(buffer_1);
        for (var i = 0; i < view.byteLength; i += 4)
        {
            var stringValue = view.getUint32(i).toString(16);
            var padding = "00000000";
            var paddedValue = (padding + stringValue).slice(-padding.length);
            hexCodes.push(paddedValue);
        }
        return hexCodes.join("");
    }

    onKeyUp(event, el)
    {
        this.checkPasswordStrength(el);

        if (this.options.UseCapslockWarning) this.checkForCapslock(event, el);
    }

    checkPasswordStrength(el)
    {
        const currentPassword = el.value;
        const confirmPassword = this.ConfirmPasswordElement.value;

        // password requirements for disabling buttons
        let meetsRequirements = true;
        if (currentPassword.length < this.options.MinCharLength)
            meetsRequirements = false;
        if (
            currentPassword.length > this.options.MaxCharLength &&
            this.options.MaxCharLength !== 0
        )
            meetsRequirements = false;
        if (this.options.MinDigitsAmount > 0)
            if (
                currentPassword.match(/\d/g) != null &&
                this.options.MinDigitsAmount > currentPassword.match(/\d/g).length
            )
                meetsRequirements = false;
        if (this.options.MinCapitalsAmount > 0)
            if (
                currentPassword.match(/[A-Z]/g) == null ||
                this.options.MinCapitalsAmount > currentPassword.match(/[A-Z]/g).length
            )
                meetsRequirements = false;
        if (this.options.MinCapitalsAmount > 0)
            if (
                currentPassword.match(/[a-z]/g) == null ||
                this.options.MinCapitalsAmount > currentPassword.match(/[a-z]/g).length
            )
                meetsRequirements = false;

        // password scoring
        let score = 0;

        if (currentPassword.length > 0) score += 20;
        if (
            currentPassword.match(/[0-9]/g) != null &&
            currentPassword.match(/[0-9]/g).length >=
            this.options.MinRecomDigitsAmount
        )
            score += 20;
        if (currentPassword.length >= this.options.MinRecomLength) score += 20;

        if (
            this.options.SpecialChars.length > 0 &&
            this.options.MinRecomSpecialCharsAmount > 0
        )
        {
            if (
                currentPassword.match(/[a-z]/g) != null &&
                currentPassword.match(/[a-z]/g).length >=
                this.options.MinRecomLowercaseAmount &&
                currentPassword.match(/[A-Z]/g) != null &&
                currentPassword.match(/[A-Z]/g).length >=
                this.options.MinRecomCapitalsAmount
            )
                score += 20;

            if (
                currentPassword.match(
                    new RegExp(`[${this.options.SpecialChars}]`, "g")
                ) != null &&
                currentPassword.match(new RegExp(`[${this.options.SpecialChars}]`, "g"))
                    .length >= this.options.MinRecomSpecialCharsAmount
            )
                score += 20;
        } else
        {
            if (
                currentPassword.match(/[a-z]/g) != null &&
                currentPassword.match(/[a-z]/g).length >=
                this.options.MinRecomLowercaseAmount
            )
                score += 20;

            if (
                currentPassword.match(/[A-Z]/g) != null &&
                currentPassword.match(/[A-Z]/g).length >=
                this.options.MinRecomCapitalsAmount
            )
                score += 20;
        }

        if (score == 100)
        {
            if (
                this.options.MaxCharLength > 0 &&
                currentPassword.length > this.options.MaxCharLength
            )
                score -= 20;
        }

        // score bars
        let passwordRating = "";
        if (score >= 20) passwordRating = "bar-1";
        if (score >= 60 || meetsRequirements) passwordRating = "bar-2";
        if (score >= 80) passwordRating = "bar-3";
        if (score == 100) passwordRating = "bar-4";

        const strengthBar = String.format(
            '<span class="password-strength-bar-1"></span>' +
            '<span class="password-strength-bar-2"></span>' +
            '<span class="password-strength-bar-3"></span>' +
            '<span class="password-strength-bar-4"></span>' +
            '<span class="password-strength-bar-rating {0}"></span>',
            passwordRating
        );

        this.ratingTextField.textContent = String.format(
            this.options.PasswordStrengthText,
            this.options.passwordStrengthsText[passwordRating]
        );
        this.ratingField.innerHTML = strengthBar;

        if (meetsRequirements && this.options.DisableSubmitButton)
            document
                .querySelector(this.options.DisableSubmitButton)
                .removeAttribute("disabled");

        // Add info text to html

        if (this.options.UseDynamicInfoText)
        {
            const infoText = document.createElement("div");
            infoText.className = this.options.InfoTemplateClass;
            infoText.innerHTML = this.options.InfoTemplateText;

            const resultItemTemplate = this.options.InfoFieldTemplate;

            // Hide min and max  character text when using the combined minMaxText
            if (this.options.CombinedMinMaxText.length == 0)
            {
                // Minimal password length
                if (this.options.MinCharLength > 0)
                {
                    const formattedMinCharText = String.format(
                        this.options.MinCharText,
                        this.options.MinCharLength
                    );
                    const minCharLengthField =
                        this.infoField.querySelector(".min-length");
                    if (currentPassword.length >= this.options.MinCharLength)
                        infoText.innerHTML += String.format(
                            resultItemTemplate,
                            "success",
                            formattedMinCharText,
                            "min-length"
                        );
                    else if (
                        minCharLengthField.classList.contains("success") ||
                        minCharLengthField.classList.contains("error")
                    )
                        infoText.innerHTML += String.format(
                            resultItemTemplate,
                            "error",
                            formattedMinCharText,
                            "min-length"
                        );
                    else
                        infoText.innerHTML += String.format(
                            resultItemTemplate,
                            "unset",
                            formattedMinCharText,
                            "min-length"
                        );
                }

                // Maximal password length
                if (currentPassword.length > this.options.MaxCharLength)
                    this.options.wasLonger = true;

                if (
                    this.options.MaxCharLength > 0 &&
                    (!this.options.HideMaxLengthOnStart || this.options.wasLonger)
                )
                {
                    const formattedMaxCharText = String.format(
                        this.options.MaxCharText,
                        this.options.MaxCharLength
                    );
                    const maxCharLengthField =
                        this.infoField.querySelector(".max-length");
                    if (
                        currentPassword.length > 0 &&
                        currentPassword.length <= this.options.MaxCharLength
                    )
                        infoText.innerHTML += String.format(
                            resultItemTemplate,
                            "success",
                            formattedMaxCharText,
                            "max-length"
                        );
                    else if (
                        maxCharLengthField.classList.contains("success") ||
                        maxCharLengthField.classList.contains("error") ||
                        this.options.HideMaxLengthOnStart
                    )
                        infoText.innerHTML += String.format(
                            resultItemTemplate,
                            "error",
                            formattedMaxCharText,
                            "max-length"
                        );
                    else
                        infoText.innerHTML += String.format(
                            resultItemTemplate,
                            "unset",
                            formattedMaxCharText,
                            "max-length"
                        );
                }
            }

            // show if enough digits
            if (this.options.MinDigitsAmount > 0)
            {
                const formattedReqDigitText = String.format(
                    this.options.ReqDigitText,
                    this.options.MinDigitsAmount
                );
                const mixDigitsAmountField = this.infoField.querySelector(".has-digit");
                if (
                    currentPassword.match(/[0-9]/g) != null &&
                    this.options.MinDigitsAmount <= currentPassword.match(/[0-9]/g).length
                )
                    infoText.innerHTML += String.format(
                        resultItemTemplate,
                        "success",
                        formattedReqDigitText,
                        "has-digit"
                    );
                else if (
                    mixDigitsAmountField.classList.contains("success") ||
                    mixDigitsAmountField.classList.contains("error")
                )
                    infoText.innerHTML += String.format(
                        resultItemTemplate,
                        "error",
                        formattedReqDigitText,
                        "has-digit"
                    );
                else
                    infoText.innerHTML += String.format(
                        resultItemTemplate,
                        "unset",
                        formattedReqDigitText,
                        "has-digit"
                    );
            }

            // show if enough capitals
            if (this.options.MinCapitalsAmount > 0)
            {
                const formattedReqCapitalText = String.format(
                    this.options.ReqCapitalText,
                    this.options.MinCapitalsAmount
                );
                const minCapitalsAmountField = document.querySelector(".has-capital");
                if (
                    currentPassword.match(/[A-Z]/g) != null &&
                    this.options.MinCapitalsAmount <=
                    currentPassword.match(/[A-Z]/g).length
                )
                    infoText.innerHTML += String.format(
                        resultItemTemplate,
                        "success",
                        formattedReqCapitalText,
                        "has-capital"
                    );
                else if (
                    minCapitalsAmountField.classList.contains("success") ||
                    minCapitalsAmountField.classList.contains("error")
                )
                    infoText.innerHTML += String.format(
                        resultItemTemplate,
                        "error",
                        formattedReqCapitalText,
                        "has-capital"
                    );
                else
                    infoText.innerHTML += String.format(
                        resultItemTemplate,
                        "unset",
                        formattedReqCapitalText,
                        "has-capital"
                    );
            }

            // show if enough small characters
            if (this.options.MinLowercaseAmount > 0)
            {
                const formattedReqLowerCaseText = String.format(
                    this.options.ReqLowerCaseText,
                    this.options.MinLowercaseAmount
                );
                const minLowerCaseAmountField = document.querySelector(".has-small");
                if (
                    currentPassword.match(/[a-z]/g) != null &&
                    this.options.MinLowercaseAmount <=
                    currentPassword.match(/[a-z]/g).length
                )
                    infoText.innerHTML += String.format(
                        resultItemTemplate,
                        "success",
                        formattedReqLowerCaseText,
                        "has-small"
                    );
                else if (
                    minLowerCaseAmountField.classList.contains("success") ||
                    minLowerCaseAmountField.classList.contains("error")
                )
                    infoText.innerHTML += String.format(
                        resultItemTemplate,
                        "error",
                        formattedReqLowerCaseText,
                        "has-small"
                    );
                else
                    infoText.innerHTML += String.format(
                        resultItemTemplate,
                        "unset",
                        formattedReqLowerCaseText,
                        "has-small"
                    );
            }

            if (
                this.options.SpecialChars.length > 0 &&
                this.options.MinSpecialCharsAmount > 0
            )
            {
                const formattedReqSpexialCaseText = String.format(
                    this.options.ReqSpecialCharText,
                    this.options.MinSpecialCharsAmount,
                    this.options.SpecialChars
                );
                const specialCharsField = document.querySelector(".one-special");
                if (
                    currentPassword.match(
                        new RegExp(`[${this.options.SpecialChars}]`, "g")
                    ) != null &&
                    currentPassword.match(
                        new RegExp(`[${this.options.SpecialChars}]`, "g")
                    ).length >= this.options.MinSpecialCharsAmount
                )
                    infoText.innerHTML += String.format(
                        resultItemTemplate,
                        "success",
                        formattedReqSpexialCaseText,
                        "one-special"
                    );
                else if (
                    specialCharsField.classList.contains("success") ||
                    specialCharsField.classList.contains("error")
                )
                    infoText.innerHTML += String.format(
                        resultItemTemplate,
                        "error",
                        formattedReqSpexialCaseText,
                        "one-special"
                    );
                else
                    infoText.innerHTML += String.format(
                        resultItemTemplate,
                        "unset",
                        formattedReqSpexialCaseText,
                        "one-special"
                    );
            }

            if (
                this.options.ConfirmPasswordField != null &&
                this.ConfirmPasswordElement != null
            )
            {
                if (currentPassword == "" && confirmPassword == "")
                    infoText.innerHTML += String.format(
                        resultItemTemplate,
                        "unset",
                        this.options.ConfirmPasswordText,
                        "confirm-pass"
                    );
                else if (confirmPassword == currentPassword)
                    infoText.innerHTML += String.format(
                        resultItemTemplate,
                        "success",
                        this.options.ConfirmPasswordText,
                        "confirm-pass"
                    );
                else
                    infoText.innerHTML += String.format(
                        resultItemTemplate,
                        "error",
                        this.options.ConfirmPasswordText,
                        "confirm-pass"
                    );
            }

            if (this.options.ShowPwndInfo)
            {
                if (currentPassword != "")
                {
                    infoText.innerHTML += String.format(
                        resultItemTemplate,
                        "loading",
                        this.options.PwndPasswordText,
                        "pwnd-pass-check"
                    );
                    // run only once after 500 miliseconds of not typing
                    if (this.timeout)
                    {
                        clearTimeout(this.timeout);
                        this.timeout = null;
                    }
                    this.timeout = setTimeout(
                        this.checkHaveIBeenPwnd,
                        500,
                        this,
                        currentPassword
                    );
                } else
                {
                    infoText.innerHTML += String.format(
                        resultItemTemplate,
                        "unset",
                        this.options.PwndPasswordText,
                        "pwnd-pass-check"
                    );
                }
            }

            // Show combined minMaxText
            if (this.options.CombinedMinMaxText.length > 0)
            {
                // Minimal password length
                const combinedMinMaxTextField =
                    document.querySelector(".combined-text");
                if (
                    currentPassword.length >= this.options.MinCharLength &&
                    currentPassword.length <= this.options.MaxCharLength
                )
                    infoText.innerHTML += String.format(
                        resultItemTemplate,
                        "success",
                        this.options.CombinedMinMaxText,
                        "combined-text"
                    );
                else if (
                    combinedMinMaxTextField.classList.contains("success") ||
                    combinedMinMaxTextField.classList.contains("error")
                )
                    infoText.innerHTML += String.format(
                        resultItemTemplate,
                        "error",
                        this.options.CombinedMinMaxText,
                        "combined-text"
                    );
                else
                    infoText.innerHTML += String.format(
                        resultItemTemplate,
                        "unset",
                        this.options.CombinedMinMaxText,
                        "combined-text"
                    );
            }

            const infoFieldElement = document.getElementsByClassName(
                this.options.InfoTemplateClass
            )[0];
            infoFieldElement.parentNode.replaceChild(infoText, infoFieldElement);
            this.infoField = infoText;
        }
    }

    checkHaveIBeenPwnd(that, currentPassword)
    {
        const pwnedElement = document.querySelector(".pwnd-pass-check");
        const xhttp = new XMLHttpRequest();
        that.getSha1(currentPassword).then(function (passHash)
        {
            xhttp.open(
                "GET",
                "https://api.pwnedpasswords.com/range/" + passHash.substring(0, 5),
                true
            );
            xhttp.send();
            xhttp.onload = function (result)
            {
                const resultPassHash = passHash.slice(5).toUpperCase();
                var isPwned = false;
                for (const hash of result.target.response.split("\n"))
                {
                    if (hash.split(":")[0] == resultPassHash)
                    {
                        isPwned = true;
                        break;
                    }
                }
                if (isPwned)
                {
                    pwnedElement.classList.remove("loading");
                    pwnedElement.classList.add("error");
                } else
                {
                    pwnedElement.classList.remove("loading");
                    pwnedElement.classList.add("success");
                }
            };
            xhttp.onerror = function (err)
            {
                console.log("Estrategy password check: ", err);
                pwnedElement.classList.remove("loading");
                pwnedElement.classList.add("api-error");
            };
        });
        clearTimeout(this.timeout);
    }

    // Example:
    // <div class="password-strength-tooltip">
    //     <input id="password-field" />
    //     <span class="tooltiptext">Caps Lock staat aan</span>
    // </div>

    checkForCapslock(event, el)
    {
        const capslockTooltipElement = document.querySelector(
            this.options.CapslockTooltip
        );
        if (capslockTooltipElement == null)
            return console.warn(
                "estrategy.password:",
                "Capslock check not setup completely!"
            );

        if (
            !(
                typeof event.getModifierState === "function" &&
                event.getModifierState("CapsLock")
            )
        )
            return (capslockTooltipElement.style.display = "none");
        capslockTooltipElement.style.display = "block";

        if (this.capslockHasEventListener) return;

        document.addEventListener("click", function (event)
        {
            // If user clicks inside the element, do nothing
            if (event.target.closest(this.options.CapslockTooltip)) return;
            // If user clicks outside the element, hide it!
            capslockTooltipElement.style.display = "none";
        });
        this.capslockHasEventListener = true;
    }
}

if (typeof String.format != "function")
{
    String.format = function ()
    {
        let s = arguments[0];

        for (var i = 0; i < arguments.length - 1; i++)
        {
            var reg = new RegExp("\\{" + i + "\\}", "gm");
            s = s.replace(reg, arguments[i + 1]);
        }

        return s;
    };
}
