import MessageConverter from "../MessageConverter";
import ExternalSystemSettingsService from '../../Service/ExternalSystemSettingsService';
import SchoolService from "../../Service/SchoolService"
import AuthService from "../../Service/AuthService"
import MessageHandler from "../MessageHandler"

import { Dialog } from 'primereact/dialog';
import { BlockUI } from 'primereact/blockui';
import Select from 'react-select';
import React, { useEffect, useState } from 'react';

function ExternConnectionSetting()
{
    const externSystemValues = [
        {
            id: "1",
            label: "Esis",
            value: 1
        },
        {
            id: "2",
            label: "ParnasSys",
            value: 2
        },
    ]
    const [externSystemDTO, setExternSystemDTO] = useState([]);
    const [hideSub, setHideSub] = useState(true);
    const [hideSubDelete, setHideSubDelete] = useState(true);
    const [disableSelect, setDisableSelect] = useState(false);
    const [refreshKey, setRefreshKey] = useState(0);
    const [visibleDelete, setVisibleDelete] = useState(false);
    const [blocked, setBlocked] = useState(false);
    const [msg, setMsg] = useState([]);

    const newMessageHandler = (enteredMsg) =>
    {
        setMsg(enteredMsg);
    }

    const setMessageHandler = (msg) =>
    {
        newMessageHandler(msg);
    }

    // Check of er al een bestaande koppeling is
    useEffect(() =>
    {
        ExternalSystemSettingsService.getCurrentESS().then((res) =>
        {
            setExternSystemDTO(res.data);
            setDisableSelect(true);
            setHideSubDelete(false);
        })
    }, [refreshKey])

    // Als er een systeem gekozen is
    useEffect(() =>
    {
        // Er is nog geen huidige koppeling
        if (externSystemDTO.length !== 0)
        {
            if (externSystemDTO.id === undefined)
            {
                SchoolService.getSchool(AuthService.getSchoolID()).then((res) =>
                {
                    if (res.data.brincode !== null && res.data.dependanceCode !== null)
                    {
                        setHideSub(false);
                        setExternSystemDTO({ ...externSystemDTO, schoolID: AuthService.getSchoolID(), active: true })
                    } else setMessageHandler(MessageConverter.convertBusinessMsg("Geen brincode en/of dependancecode ingevuld in tab 'Algemeen'. Als de locatie geen dependance is vul dan 00 in bij de dependancecode", 1))
                })
            // Er is al een huidige koppeling
            } else setHideSub(false);
        }
    }, [externSystemDTO.type, refreshKey])

    const onSubmit = (e) =>
    {
        e.preventDefault();
        setMessageHandler();
        if (sessionStorage.getItem("ExternalSystemSetting") !== null)
        {
            sessionStorage.removeItem("ExternalSystemSetting");
        }
        if (externSystemDTO.authKey !== undefined && externSystemDTO.authKey !== "") {
            // Toevoegen als er nog geen systeem gekoppeld is
            setBlocked(true);
            if (externSystemDTO.id === undefined)
            {
                try
                {
                    ExternalSystemSettingsService.createESS(externSystemDTO).then(() =>
                    {
                        setExternSystemDTO([]);
                        setHideSub(true);
                        setRefreshKey(oldKey => oldKey + 1);
                        setMessageHandler(MessageConverter.convertBusinessMsg("Externe koppeling is succesvol toegevoegd", 0));
                        setBlocked(false);
                    }).catch((err) => { setMessageHandler(MessageConverter.convertBusinessMsg(err)); setBlocked(false) });
                } catch (error)
                {
                    setMessageHandler(MessageConverter.convertBusinessMsg(error));
                    setBlocked(false);
                }
                // Bewerken als er al een systeem gekoppeld is
            } else
            {
                try
                {
                    ExternalSystemSettingsService.updateESS(externSystemDTO).then(() =>
                    {
                        setExternSystemDTO([]);
                        setHideSub(true);
                        setRefreshKey(oldKey => oldKey + 1);
                        setMessageHandler(MessageConverter.convertBusinessMsg("Externe koppeling is succesvol opgeslagen", 0));
                        setBlocked(false);
                    }).catch((err) => { setMessageHandler(MessageConverter.convertBusinessMsg(err)); setBlocked(false) });
                } catch (error)
                {
                    setMessageHandler(MessageConverter.convertBusinessMsg(error));
                    setBlocked(false);
                }
            }
        } else setMessageHandler(MessageConverter.convertBusinessMsg("Vul de authorisatie sleutel in", 1))          
    }

    const deleteRemoteRestriction = (e) =>
    {
        e.preventDefault();
        setMessageHandler();
        if (sessionStorage.getItem("ExternalSystemSetting") !== null)
        {
            sessionStorage.removeItem("ExternalSystemSetting");
        }
        setBlocked(true);
        try
        {
            ExternalSystemSettingsService.deleteESS(externSystemDTO.id).then(() =>
            {
                setExternSystemDTO([]);
                setHideSub(true);
                setVisibleDelete(false);
                setMessageHandler(MessageConverter.convertBusinessMsg("Externe koppeling is succesvol verwijderd", 0))
                setRefreshKey(oldKey => oldKey + 1);
                setDisableSelect(false);
                setBlocked(false);
            }).catch((err) => { setMessageHandler(MessageConverter.convertBusinessMsg(err)); setBlocked(false) });
        } catch (error)
        {
            setMessageHandler(MessageConverter.convertBusinessMsg(error));
            setBlocked(false);
        }
    }

    // Na het klikken op de delete button
    const onDeleteButton = (e) =>
    {
        e.preventDefault();
        setVisibleDelete(true);
    }

    return (
        <form autoComplete="off">
            <BlockUI blocked={blocked} fullScreen />
            <MessageHandler messages={msg} />
            <div className="maincontent-item">
                <label>Naam extern systeem*</label>
                <Select
                    isDisabled={disableSelect}
                    options={externSystemValues}
                    getOptionLabel={(option) => option.label}
                    getOptionValue={(option) => option.value}
                    placeholder="Selecteer een extern systeem"
                    onChange={(e) =>
                    {
                        setExternSystemDTO({ ...externSystemDTO, type: e.value });
                    }}
                    value={externSystemValues.find((element) => { return element.id == externSystemDTO?.type })}
                    isSearchable={true}
                    isMulti={false}
                    noOptionsMessage={() => 'Geen resultaten gevonden'}
                />
            </div>

            <div className="maincontent-item" hidden={hideSub}>
                <label htmlFor="authKey">Authorisatie sleutel*</label>
                <input
                    id="authKey"
                    type="text"
                    required={true}
                    defaultValue={externSystemDTO.authKey}
                    onChange={(e) => setExternSystemDTO({ ...externSystemDTO, authKey: e.target.value })}
                    onInvalid={e => e.target.setCustomValidity("Dit veld is verplicht")}
                    onInput={e => e.target.setCustomValidity("")}
                    title="Dit veld is verplicht"
                />
            </div>

            <div className="maincontent-item" hidden={hideSub}>
                <label>Actief*</label>
                <div className="radio-buttons">
                    <input
                        type="radio"
                        id="yes"
                        name="active"
                        checked={externSystemDTO.active == true}
                        onChange={(e) => setExternSystemDTO({ ...externSystemDTO, active: true })}
                        value={2} />
                    <label htmlFor="yes">Ja</label>

                    <input
                        type="radio"
                        id="no"
                        name="active"
                        checked={externSystemDTO.active == false}
                        onChange={(e) => setExternSystemDTO({ ...externSystemDTO, active: false })}
                        value={1} />
                    <label htmlFor="no">Nee</label>
                </div>
            </div>

            <input
                hidden={hideSub}
                type="submit"
                value="Opslaan"
                className="user-add-buttons"
                onClick={onSubmit}
            />
            <input
                hidden={hideSubDelete}
                type="submit"
                value="Verwijderen"
                className="user-add-buttons"
                onClick={onDeleteButton}
            />
            <Dialog
                className="delete-dialog"
                header={"Weet je zeker dat je deze koppeling wilt verwijderen?"}
                visible={visibleDelete}
                onHide={() => setVisibleDelete(false)}
                draggable={false}
            >
                <input type="submit" value="Ja" className="delete-button" onClick={deleteRemoteRestriction} />
                <input type="submit" value="Nee" className="cancel-delete-button" onClick={() => setVisibleDelete(false)} />
            </Dialog>
        </form>
    );
}

export default ExternConnectionSetting;