import React, { useState } from 'react';
import '../GeneralStyle.scss'
import Footer from '../../Components/Footer/Footer'
import TwoFALogin from '../../Components/TwoFA/TwoFALogin'
import MessageHandler from '../../Components/MessageHandler';

function TwoFALoginPage()
{
    const [msg, setMsg] = useState([]);

    const newMessageHandler = (enteredMsg) =>
    {
        setMsg(enteredMsg);
    }

    return (
        <div id="wrapper">
            <div id="upd-main">
                <div className="main">
                    <div className="main-wrapper">
                        <div id="mainRight" className="main-right-full-width">
                            <MessageHandler messages={msg} />
                            <TwoFALogin onNewMessage={newMessageHandler} />
                        </div>
                    </div>
                </div>
                <div className="footer">
                    <Footer />
                </div>
            </div>
        </div>
    );
}

export default TwoFALoginPage;