import '../../GeneralStyling.scss'
import ReportService from "../../../Service/Report/SoCo/SoCoReportService";
import SchoolyearService from "../../../Service/SchoolyearService";
import GroupyearService from "../../../Service/GroupyearService";
import MessageConverter from "../../MessageConverter";
import MessageHandler from "../../MessageHandler";
import HelpPageService from '../../../Service/HelpPageService';

import React, { useState, useEffect } from 'react';
import Select from 'react-select';
import { ListBox } from 'primereact/listbox';
import { BlockUI } from 'primereact/blockui';
import { Dialog } from 'primereact/dialog';
import { Editor, EditorState, convertFromRaw, CompositeDecorator } from "draft-js";
import HelpItemDescription from '../../HelpPage/HelpItemDescription';

function SoCoTrendReport(props)
{
    const [blocked, setBlocked] = useState(false);

    const [schoolyears, setSchoolyears] = useState([]);
    const [selectedSchoolyearMin, setSelectedSchoolyearMin] = useState();
    const [selectedSchoolyearMax, setSelectedSchoolyearMax] = useState();

    const [groupyears, setGroupyears] = useState([]);
    const [selectedGroupyears, setSelectedGroupyears] = useState([]);

    const [type, setType] = useState(1);
    const [period, setPeriod] = useState(0);
    const [msg, setMsg] = useState([]);

    const [defaultSelected, setDefaultSelected] = useState(null);

    const [helpItem, setHelpItem] = useState(null);
    const [helpVisible, setHelpVisible] = useState(false);
    const [helpButtonHidden, sethelpButtonHidden] = useState(true);
    const currentPathName = window.location.pathname;

    // Prevent going back
    useEffect(() =>
    {
        window.addEventListener('popstate', (e) =>
        {
            window.history.go(1);
        });
    }, []);

    // Preload helpItems
    useEffect(() => {
        if (helpItem == null) {
            HelpPageService.getHelpItembyPage(currentPathName).then((res) => {
                if (res.data != undefined && res.data != null && res.data != '') {
                    setHelpItem(res.data);
                    sethelpButtonHidden(false);
                }
                else {
                    sethelpButtonHidden(true);
                }
            }).catch(err => {
                setMessageHandler(MessageConverter.convertBusinessMsg(err));
            });
        }
    }, []);

    const newMessageHandler = (enteredMsg) =>
    {
        setMsg(enteredMsg);
    }

    const setMessageHandler = (msg) =>
    {
        newMessageHandler(msg);
    }

    const getReport = async () =>
    {
        setMessageHandler();
        // TODO: Check for max 8 (or 5) years selected?
        if (selectedSchoolyearMin.id < selectedSchoolyearMax.id || (selectedSchoolyearMin.id == selectedSchoolyearMax.id && period == 0))
        {
            if (selectedGroupyears.length !== 0)
            {
                setBlocked(true);
                ReportService.getSoCoTrendReport(selectedSchoolyearMin.id, selectedSchoolyearMax.id, selectedGroupyears.map((gy) => gy.id), period, type).then((reportRes) =>
                {
                    setBlocked(false);
                    if (reportRes.status === 200)
                    {
                        const url = window.URL.createObjectURL(new Blob([reportRes.data], { type: 'application/pdf' }));
                        const link = document.createElement('a');
                        link.href = url;
                        link.setAttribute('download', 'Trendrapport.pdf');
                        document.body.appendChild(link);
                        link.click();
                    }
                    else if (reportRes.status == 204)
                    {
                        setMessageHandler(MessageConverter.convertBusinessMsg("Het rapport bevat geen data", 1));
                    }
                    else setMessageHandler(MessageConverter.convertBusinessMsg(reportRes.status))
                }).catch((err) => { setMessageHandler(MessageConverter.convertBusinessMsg(err.response.status)); setBlocked(false) });
            } else setMessageHandler(MessageConverter.convertBusinessMsg("Selecteer minimaal 1 groepsjaar", 1));
        } else setMessageHandler(MessageConverter.convertBusinessMsg("Het minimale schooljaar moet kleiner zijn dan het maximale schooljaar, of gelijk bij periode M+E", 1));
    }

    useEffect(() =>
    {
        SchoolyearService.getSchoolyearListForSocoReports().then((schoolyearRes) =>
        {
            setSchoolyears(schoolyearRes.data);
            if (schoolyearRes.data.length > 0)
            {
                const currentSchoolyear = schoolyearRes.data.find((element) => element.active == true)
                if (currentSchoolyear !== undefined)
                {
                    setSelectedSchoolyearMax(currentSchoolyear);
                }
                setSelectedSchoolyearMin(schoolyearRes.data[schoolyearRes.data.length - 1]);
            } else if (schoolyearRes.data.length == 1)
            {
                setDefaultSelected(schoolyearRes.data[0]);
                setSelectedSchoolyearMin(schoolyearRes.data[0]);
                setSelectedSchoolyearMax(schoolyearRes.data[0]);
            }
        }).catch((err) => setMessageHandler(MessageConverter.convertBusinessMsg(err)));
    }, []);

    useEffect(() =>
    {
        GroupyearService.getGroupyearListSoCo().then((res) =>
        {
            setGroupyears(res.data);
            setSelectedGroupyears(res.data);
        }).catch((err) => setMessageHandler(MessageConverter.convertBusinessMsg(err)));
    }, []);

    return (
        <div id="ManageChildPage">
            <BlockUI blocked={blocked} fullScreen />
            <div id="MainContentContainer">
                <div className="page-information">
                    <div id="PageHeader" className="page-header">
                        <span>Rapportage: Trend</span>
                    </div>
                    <div>
                        <div id="InfoButton" onClick={() => setHelpVisible(true)} hidden={helpButtonHidden}>
                            <i className="btn-info"></i>
                        </div>
                        <Dialog
                            header={"Rapportage: Trend"}
                            visible={helpVisible}
                            modal={true}
                            onHide={() => setHelpVisible(false)}
                            style={{ width: '75vw' }}
                        >
                            <Editor className="textfield" editorState={HelpItemDescription(helpItem)} readOnly="true" />
                        </Dialog>
                    </div>
                    <div className="clear"></div>
                </div>
                <div>
                    <div className="maincontent-header-bar">
                        <div className="header-bar-item">
                            <label>Schooljaar min</label>
                            <Select
                                options={schoolyears}
                                getOptionLabel={(option) => option.name}
                                getOptionValue={(option) => option.id}
                                onChange={(sy) =>
                                {
                                    setSelectedSchoolyearMin(sy);
                                }}
                                value={schoolyears.find((element) => { return element.id == selectedSchoolyearMin?.id })}
                                isSearchable={true}
                                isMulti={false}
                                noOptionsMessage={() => 'Geen resultaten gevonden'}
                                defaultValue={defaultSelected}
                                placeholder="Begin schooljaar" />
                        </div>
                        <div className="header-bar-item">
                            <label>Schooljaar max</label>
                            <Select
                                options={schoolyears}
                                getOptionLabel={(option) => option.name}
                                getOptionValue={(option) => option.id}
                                onChange={(sy) =>
                                {
                                    setSelectedSchoolyearMax(sy);
                                }}
                                value={schoolyears.find((element) => { return element.id == selectedSchoolyearMax?.id })}
                                isSearchable={true}
                                isMulti={false}
                                noOptionsMessage={() => 'Geen resultaten gevonden'}
                                defaultValue={defaultSelected}
                                placeholder="Eind schooljaar" />
                        </div>
                    </div>
                    <div className="message-div">
                        <MessageHandler messages={msg} />
                    </div>
                    <div className="maincontent-sub-bar">
                        <div className="sub-bar-item">
                            <label>Groepsjaren</label>
                            <ListBox
                                multiple
                                value={selectedGroupyears}
                                onChange={(e) => { setSelectedGroupyears(e.value); }}
                                options={groupyears}
                                emptyMessage="Geen resultaten gevonden"
                                optionLabel="name" />
                        </div>
                        <div className="sub-bar-item" hidden={true}>
                            <label>Type</label>
                            <div className="radio-buttons">
                                <input
                                    type="radio"
                                    id="basic"
                                    value={1}
                                    checked={type == 1}
                                    onChange={() => setType(1)} />
                                <label htmlFor="basic">Leraar</label>
                                <input
                                    type="radio"
                                    id="childlist"
                                    value={2}
                                    hidden={true}
                                    checked={type == 2}
                                    onChange={() => setType(2)}
                                    disabled={true} />
                                <label htmlFor="childlist" hidden={true} >Kind</label>
                            </div>
                        </div>
                        <div className="sub-bar-item">
                            <label htmlFor="type">Periode</label>
                            <div className="radio-buttons">
                                <input
                                    type="radio"
                                    id="periodm"
                                    value={1}
                                    checked={period == 1}
                                    onChange={() => setPeriod(1)} />
                                <label htmlFor="periodm">M</label>
                                <input
                                    type="radio"
                                    id="periode"
                                    value={2}
                                    checked={period == 2}
                                    onChange={() => setPeriod(2)} />
                                <label htmlFor="periode">E</label>
                                <input
                                    type="radio"
                                    id="periodme"
                                    value={0}
                                    checked={period == 0}
                                    onChange={() => setPeriod(0)} />
                                <label htmlFor="periodme">M + E</label>
                            </div>
                        </div>
                        <button onClick={getReport} className="main-buttons"> Inzien </button>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default SoCoTrendReport;