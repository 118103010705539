import { Navigate } from "react-router-dom";

export const PrivateRoute = ({ children, permission }) =>
{
    let permissions = JSON.parse(sessionStorage.getItem("UserPermissions"));

    // Get the authenticator
    let isAuthenticated = sessionStorage.getItem("auth");

    // If the authenticator is not empty go to the page. If not, navigate to the login page.
    if (!isAuthenticated || permission === undefined)
        return isAuthenticated ? children : <Navigate to="/" />;

    var i;
    // For each permission
    if (permissions === undefined || permissions == null || permissions.length == 0)
        return "";
    for (i = 0; i < permissions.length; i++)
    {
        // If the given permission is in the list of permissions
        if (permissions[i].name === permission)
        {
            // Go to the page
            return children;
        }
    }
    // Default return value
    return <Navigate to="/401" />;
};
export default PrivateRoute;