import React, { useState, useEffect } from "react";
import { Steps } from 'primereact/steps';
import { Link, useNavigate, useLocation } from 'react-router-dom';

import MessageConverter from "../MessageConverter";
import '../GeneralStyling.scss'
import './TwoFA.scss'
import UserService from "../../Service/UserService";
import AuthService from "../../Service/AuthService";
import SchoolService from "../../Service/SchoolService";
import MessageHandler from "../MessageHandler";

function TwoFAWindows(props)
{
	const navigate = useNavigate();
	const { state } = useLocation();
	const validationCode = (state.code);
	const [activeIndex, setActiveIndex] = useState(0);
	const [code, setCode] = useState("");
	const [hideStep1, setHideStep1] = useState(false);
	const [hideStep2, setHideStep2] = useState(true);
	const [hideStep3, setHideStep3] = useState(true);
	const [msg, setMsg] = useState([]);

	const newMessageHandler = (enteredMsg) =>
	{
		setMsg(enteredMsg);
	}

	const setMessageHandler = (msg) =>
	{
		newMessageHandler(msg);
	}

	const steps = [
		{
			label: 'App installeren'
		},
		{
			label: 'Verificatiecode invoeren'
		},
		{
			label: 'Gereed'
		},
	];

	const onNextStep1 = () =>
	{
		setHideStep1(true);
		setHideStep2(false);
		setActiveIndex(1);
	}

	const onBackStep2 = () =>
	{
		setHideStep1(false);
		setHideStep2(true);
		setActiveIndex(0);
	}

	const onNextStep2 = (e) =>
	{
		e.preventDefault();
		setMessageHandler();
		if (code !== "")
		{
			UserService.confirmMfaCode(code).then((res) =>
			{
				AuthService.updateNewAccessToken(res.data);
				setHideStep2(true);
				setHideStep3(false);
				setActiveIndex(2);
			}).catch(err => setMessageHandler(MessageConverter.convertBusinessMsg(err)));
		} else setMessageHandler(MessageConverter.convertBusinessMsg("Geen code ingevuld", 1));
	}

	const finish = (e) =>
	{
		e.preventDefault();
		setMessageHandler();
		try
		{
			SchoolService.getSchool(AuthService.getSchoolID()).then((schoolRes) =>
			{
				AuthService.setCurrentSchoolname(schoolRes.data.name);

				if (AuthService.getSchoolID() == 1)
				{
					AuthService.getPermissions().then((res) =>
					{
						AuthService.setPermissions(res);
						navigate("/AdoptUser", { replace: true })
					});
				}
				else
				{
					AuthService.getPermissions().then((res) =>
					{
						AuthService.setPermissions(res);
						navigate("/Home", { replace: true })
					});
				}
			}).catch(err => setMessageHandler(MessageConverter.convertBusinessMsg(err)));
		} catch (error)
		{
			setMessageHandler(MessageConverter.convertBusinessMsg(error))
		}
	}

	return (
		<div id="ManageChildPage">
			<div id="MainContentContainer">
				<div className="page-information">
					<div id="PageHeader" className="page-header">
						<span>Login: Tweestapsverificatie instellen</span>
					</div>
					<div className="clear"></div>
				</div>
				<div className="maincontent">
					<div className="message-div">
						<MessageHandler messages={msg} />
					</div>
					<p>
						Voor jouw account moet tweestapsverificatie ingesteld worden, wat verplicht is gesteld op jullie school/locatie. Dit is een methode waarmee de identiteit van gebruikers beter vastgesteld kan worden.
						Hiermee voorkomen we onrechtmatig gebruik van jouw account. De stappen voor het instellen hoef je maar eenmalig te doorlopen.
						Als de tweestapsverificatie succesvol is ingesteld, dien je in het vervolg bij het inloggen de verificatiecode in te vullen die je op je gekozen apparaat ontvangt.
						Volg de stappen om tweestapsverificatie in te stellen.
					</p>
					<div className="TwoFA" hidden={hideStep1}>
						<Steps model={steps} readOnly={true} activeIndex={activeIndex} />
						<div className="content">
							<label>Stap 1: Verificatie app installeren</label>
							<p>Beschik je over een pc of laptop met een Windows besturingssysteem? Dan kun je gebruik maken van het gratis progamma WinAuth om de verificatie in te stellen</p>
						</div>
						<div className="step">
							<div className="desktop-step-image">
								<img src="/img/2fa/step1/desktopwindows/img1.png" alt="WinAuth logo" />
							</div>
							<div className="step-text">
								<label>WinAuth downloaden</label>
								<p>
									Voor het instellen van de authenticatie in twee stappen kun je het programma WinAuth hier downloaden:<br /><a href="https://github.com/winauth/winauth/releases/download/3.5.1/WinAuth-3.5.1.zip">WinAuth 3.5.1</a><br /> 
									Afhankelijk van je browser instellingen wordt het bestand direct in je downloads map geplaatst, of wordt je gevraagd of je deze wilt opslaan of uitvoeren.
									Kies in dat geval voor opslaan. Kies zelf een logische map of plaats deze op je desktop.
									Heb je problemen met het opslaan of het je geen rechten om uitvoerbare bestanden te downloaden? Dan kun je het best je ICT beheerder vragen om assistentie. Deze kan het programma dan voor je beschikbaar stellen.
								</p>
								<p><b>Tip:</b> Plaats het programma bijvoorbeeld op je bureaublad zodat je deze altijd bij de hand hebt.</p>
							</div>
						</div>
						<div className="step-buttons">
							<Link to="/TwoFA">
								<input type="submit" value="Vorige" className="management-buttons" />
							</Link>
							<input type="submit" value="Volgende" className="management-buttons" onClick={onNextStep1} />
						</div>
					</div>

					<div className="TwoFA" hidden={hideStep2}>
						<Steps model={steps} readOnly={true} activeIndex={activeIndex} />
						<div className="content">
							<label>Stap 2: Verificatie code invoeren</label>
							<p>Na het installeren van de applicatie is de verificatie in te stellen.</p>
						</div>
						<div className="step">
							<div className="desktop-step-image">
								<img src="/img/2fa/step2/desktopwindows/img1.png" alt="select WinAuth" />
							</div>
							<div className="step-text">
								<label>WinAuth openen</label>
								<p>(Dubbel)klik op het icoontje van het programma, in de locatie welke je in de voorgaande stap hebt opgeslagen</p>
							</div>
						</div>
						<div className="step">
							<div className="desktop-step-image">
								<img src="/img/2fa/step2/desktopwindows/img2.png" alt="koppel winAuth aan KIJK!" className="desktop-image" />
							</div>
							<div className="step-text">
								<label>Je KIJK! account koppelen aan WinAuth</label>
								<p>Klik op de 'Add' knop en kies voor de optie 'Authenticator'</p> <br />
							</div>
						</div>
						<div className="step">
							<div className="desktop-step-image">
								<img src="/img/2fa/step2/desktopwindows/img3.png" alt="Add Authenticator scherm" className="desktop-image" />
							</div>
							<div className="step-text">
								<p>Het volgende scherm komt in beeld. Je vult hier de volgende gegevens in: <br />
									<b>Name:</b> KIJK! registratie (of een andere herkenbare naam voor jezelf als je bijvoorbeeld meerdere accounts gebruikt) <br />
									<b>1.</b> Secret code. Kopieer onderstaande code en plak deze in het veld:<br />
									<input type="text" defaultValue={validationCode} className="secret-code-field"></input> <br/>
									<b>2.</b> Kies de optie 'Time based'<br />
									<b>3.</b> Klik op de knop 'Verify Authenticator'
								</p>
							</div>
						</div>
						<div className="step">
							<div className="desktop-step-image">
								<img src="/img/2fa/step2/desktopwindows/img4.png" alt="Verify code scherm" className="desktop-image" />
							</div>
							<div className="step-text">
								<label>Verificatiecode invullen</label>
								<p>Er verschijnt een code in beeld, deze moet in onderstaand veld ingevuld worden. De code aan de linkerkant is ter voorbeeld. Na het invullen klik je op deze pagina op 'volgende in in WinAuth op de knop 'OK'</p>
							</div>
						</div>
						<div className="step">
							<div className="desktop-step-image">
								<img src="/img/2fa/step2/desktopwindows/img5.png" alt="Protection scherm" className="desktop-image" />
							</div>
							<div className="step-text">
								<p className="next-screen-message">Het volgende scherm komt in beeld. Je vult hier de volgende gegevens in:</p>
								<p><i>De code die hier links op de afbeelding weergegeven wordt, is slechts een voorbeeld en kan niet worden gebruikt.</i></p>
								<label className="label-input">Vul de code hieronder in:</label>
								<input type="text" onChange={(e) => setCode(e.target.value)}></input>
								<p>In het volgende scherm kun je 'protect with my own password' uitvinken, alle opties overslaan en op de knop 'OK' klikken.</p>
							</div>
						</div>
						<div className="step-buttons">
							<input type="submit" value="Vorige" className="management-buttons" onClick={onBackStep2} />
							<input type="submit" value="Volgende" className="management-buttons" onClick={onNextStep2} />
						</div>
					</div>

					<div className="TwoFA" hidden={hideStep3}>
						<Steps model={steps} readOnly={true} activeIndex={activeIndex} />
						<div className="content">
							<label>Stap 3: Gereed</label>
							<p>Je hebt succesvol de tweestapsverificatie voor jouw account ingesteld. Vanaf nu dien je gebruik te maken van deze methode als je in wilt loggen in je KIJK! account. Zorg dat je je smartphone bij de hand hebt als je de volgende keer gaat inloggen in KIJK! Inloggen gaat in het vervolg met onderstaande stappen:</p>
							<div className="end-image">
								<div className="column-1">
									<label>Vul je e-mail adres en wachtwoord in</label>
									<img src="/img/2fa/step3/img1.png" alt="Inlogscherm" />
								</div>
								<div className="column-2">
									<label>Verificatiecode op je desktop</label>
									<img src="/img/2fa/step3/img2desktopwindows.png" alt="Windows verificatie" className="verification-desktop" />
								</div>
								<div className="column-3">
									<label>Vul de code in om in te loggen</label>
									<img src="/img/2fa/step3/img3.png" alt="Code login" />
								</div>
							</div>
						</div>
						<div className="step-buttons">
							<input type="submit" value="Doorgaan" className="management-buttons" onClick={finish} />
						</div>
					</div>

				</div>
			</div>
		</div>
	);
}

export default TwoFAWindows;