import React, { useState } from 'react';
import '../GeneralStyle.scss'
import Footer from '../../Components/Footer/Footer'
import Header from '../../Components/Header/Header'
import TwoFAApple from '../../Components/TwoFA/TwoFAApple'
import MessageHandler from '../../Components/MessageHandler';

function TwoFAApplePage()
{
    const [msg, setMsg] = useState([]);

    const newMessageHandler = (enteredMsg) =>
    {
        setMsg(enteredMsg);
    }
    return (
        <div id="upd-main">
            <div className="main">
                <div className="main-wrapper">
                    <div id="mainRight" className="main-right">
                        <Header />
                        <MessageHandler messages={msg} />
                        <TwoFAApple onNewMessage={newMessageHandler} />
                    </div>
                    <div className="clear"></div>
                </div>
            </div>
            <div className="footer">
                <Footer />
            </div>
        </div>
    );
}

export default TwoFAApplePage;